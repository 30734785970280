<template>
  <div class="onx-period-comparisons__grid__current">
    <slot />
  </div>
</template>
<style lang="scss">
@use 'scss/mixins';
@import 'scss/onx-breakpoints.module';
// Current items fill the right column
.onx-period-comparisons__grid__current {
  min-width: 0;
  grid-column: 2;

  // On smaller screens, all items stack, with current items first
  @include smaller-than-laptop() {
    grid-column: 1;
  }
}
</style>
