import {
  DataTransformerConstructor,
  DataTransformerFnOptions,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import { MetricStructuresEnum } from '@/types/MetricStructures';

export type CongestionMostCongestedCellsRow = {
  operatorName: string;
  enodebid: number;
  cellId: number;
  connectionCategory: string;
  congestionScore: number | null;
  downloadThroughput: number | null;
  uploadThroughput: number | null;
  signalStrength: number | null;
  packetLossAndDiscard: number | null;
  downlinkScore: number | null;
  uplinkScore: number | null;
  downloadUploadDelta: number | null;
};

export const prepareCongestionMostCongestedCellsForTable: DataTransformerConstructor<
  [MetricStructuresEnum.CongestionMostCongestedCells],
  Required<Pick<DataTransformerFnOptions, 'operators'>>,
  CongestionMostCongestedCellsRow[]
> =
  ({ operators }) =>
  ([response]) => {
    const canonical_network_ids = operators.map((operator) => operator.canonical_network_id);
    return response.results
      .filter((row) => canonical_network_ids.includes(row.canonical_network_id))
      .map((row) => ({
        operatorName:
          operators.find((operator) => operator.canonical_network_id === row.canonical_network_id)?.name_mapped || '',
        enodebid: row.connection_enodebid,
        cellId: row.connection_cid,
        connectionCategory: row.connection_category,
        downloadThroughput: row.download_throughput,
        uploadThroughput: row.upload_throughput,
        signalStrength: row.signal_strength,
        packetLossAndDiscard: row.packet_loss_and_discard,
        congestionScore: row.congestion_score,
        downlinkScore: row.downlink_score,
        uplinkScore: row.uplink_score,
        downloadUploadDelta: row.dl_ul_delta,
      }));
  };
