import { DeploymentTypes } from '@/constants/constants';

export type AvailableDeploymentType = { value: DeploymentTypes; label: string; inTitleLabel: string };

export const availableDeploymentTypes: { readonly [key in DeploymentTypes]: AvailableDeploymentType } = {
  [DeploymentTypes.All]: { value: DeploymentTypes.All, label: 'All', inTitleLabel: '' },
  [DeploymentTypes.FiveGSA]: { value: DeploymentTypes.FiveGSA, label: '5G SA', inTitleLabel: 'SA' },
  [DeploymentTypes.FiveGNSA]: { value: DeploymentTypes.FiveGNSA, label: '5G NSA', inTitleLabel: 'NSA' },
};

export const availableDeploymentTypesList = Object.values(availableDeploymentTypes);
