import { isAfter, isEqual, isBefore } from 'date-fns';
import chunk from 'lodash/chunk';
import uniq from 'lodash/uniq';
import { frColorMap } from '../constants/colorScales';
import { CDNS, CP_LABELS, FREQUENCY_RANGES } from '../constants/constants';
import {
  V1_CDN_RES_ENDPOINT_LABELS,
  V2_CDN_RES_ENDPOINT_LABELS,
  V3_CDN_RES_ENDPOINT_LABELS,
  V1_CDN_RES,
  V2_CDN_RES,
  V3_CDN_RES,
  V1_CDN_RES_LABELS,
  V2_CDN_RES_LABELS,
  V3_CDN_RES_LABELS,
} from '@/constants/cdnRes';
import { cdnColorMap, noDataColor } from '@/constants/colorScales';
import { CDNS_LIST, CPS_LIST, MG_METRICS } from '@/constants/constants';
import { getSafeDate } from '@/utils/date';

function buildDataScaffold(operators) {
  return (
    operators &&
    operators.reduce((ac, o) => {
      ac[o.canonical_network_id] = {
        label: o.name_mapped,
        color: o.hex_color ? `#${o.hex_color}` : noDataColor,
        backgroundColor: o.hex_color ? `#${o.hex_color}` : noDataColor,
        data: [],
      };

      return ac;
    }, {})
  );
}

function buildDataScaffoldForOperator(cdns) {
  return (
    cdns &&
    cdns.reduce((ac, cdn) => {
      ac[cdn] = {
        label: cdn,
        color: cdnColorMap[cdn],
        data: [],
      };

      return ac;
    }, {})
  );
}

function buildDataScaffoldForCdnResOps(cdns, color, V2 = true, V3) {
  return (
    cdns &&
    cdns.reduce((ac, cdn) => {
      ac[cdn] = {
        label: V3 ? V3_CDN_RES_LABELS[cdn] : V2 ? V2_CDN_RES_LABELS[cdn] : V1_CDN_RES_LABELS[cdn],
        color: cdnColorMap[cdn],
        data: [],
      };

      return ac;
    }, {})
  );
}

function buildDataScaffoldForFr() {
  return FREQUENCY_RANGES.reduce((ac, band) => {
    ac[band] = {
      label: band,
      color: frColorMap[band],
      data: [],
    };

    return ac;
  }, {});
}

function getRelevantOps(dataOps, ops) {
  const availableOps = uniq(dataOps && dataOps.map((d) => d.canonical_network_id));
  return Array.isArray(ops) ? ops.filter((o) => availableOps.find((e) => e === o.canonical_network_id)) : [];
}

function filterGraphOps(dataOps, ops) {
  const relevantOps = getRelevantOps(dataOps, ops);

  return (
    dataOps &&
    dataOps.filter((operator) => {
      return relevantOps && relevantOps.find((d) => d.canonical_network_id === parseInt(operator.canonical_network_id));
    })
  );
}

const getDatumValue = (datum) => {
  return datum.mean ?? datum.estimate ?? datum.count ?? datum.counts ?? datum.percentage ?? datum.median;
};

function filterTrendData(data) {
  return data.filter(getDatumValue);
}

function getBarsByDate(operators, date, dataset) {
  if (!operators || !date) {
    return {
      max: 0,
      data: [],
      label: [],
      colors: [],
    };
  }

  let max = 0;
  const relevantData = dataset.filter((point) => point.date === date);

  const relevantOps = getRelevantOps(relevantData, operators);
  const start = Object.values(
    filterGraphOps(relevantData, relevantOps).reduce((ac, point) => {
      max = point.uci ? Math.max(max, point.uci) : max;
      ac[point.canonical_network_id].data.push({
        x: ac[point.canonical_network_id].label,
        y: point.estimate || point.mean || point.count || point.percentage,
        lci: point.lci,
        uci: point.uci,
        comparison: point.comparison,
        color: `#${relevantOps.find((o) => o.canonical_network_id === point.canonical_network_id).hex_color}`,
      });
      return ac;
    }, buildDataScaffold(relevantOps)),
  );
  const labels = start.map((d) => d.label);
  const colors = start.map((d) => d.color);
  const data = start.map((d) => d.data[0]);

  return {
    max: Math.ceil(max),
    data: data,
    label: labels,
    colors: colors,
  };
}

function getFormatedSingles(operators, mx, my) {
  if (!operators || !mx.length) {
    return {
      data: [],
      label: [],
      colors: [],
    };
  }

  const start = buildDataScaffold(operators);
  operators.map((op) => {
    const xp = mx.find((d) => d.canonical_network_id === op.canonical_network_id);
    const yp = my.find((d) => d.canonical_network_id === op.canonical_network_id);

    start[op.canonical_network_id].data = {
      x: xp && (xp.mean || xp.count),
      y: xp && (yp.mean || yp.count),
      xuci: xp && xp.uci,
      xlci: xp && xp.lci,
      yuci: xp && yp.uci,
      ylci: xp && yp.lci,
      text: start[op.canonical_network_id].label[0],
    };
  });

  const labels = Object.values(start).map((d) => d.label);
  const colors = Object.values(start).map((d) => d.color);
  const data = Object.values(start).map((d) => d.data);

  return {
    data: data,
    label: labels,
    colors: colors,
  };
}

export function getMinAndMaxDateFromSeries(dataset) {
  const nonNullData = filterTrendData(dataset);
  if (nonNullData.length === 0) {
    return false;
  }
  const oldestPoint = nonNullData.reduce((previous, current) =>
    isBefore(getSafeDate(previous.date), getSafeDate(current.date)) ? previous : current,
  );
  const newestPoint = nonNullData.reduce((previous, current) =>
    isAfter(getSafeDate(previous.date), getSafeDate(current.date)) ? previous : current,
  );
  return [oldestPoint.date, newestPoint.date];
}

function createTrendSeriesDataPoint(point) {
  return {
    x: point.date,
    y: getDatumValue(point),
    lci: point.lci,
    uci: point.uci,
    rank: point.rank,
  };
}

function getTrendSeries(operators, dataset, minDateString, maxDateString, transform) {
  const minDate = getSafeDate(minDateString);
  const maxDate = getSafeDate(maxDateString);
  const relevantData = dataset.filter(
    (point) => !minDateString || isAfter(getSafeDate(point.date), minDate) || isEqual(getSafeDate(point.date), minDate),
  );
  const relevantOps = getRelevantOps(relevantData, operators);

  return filterGraphOps(relevantData, relevantOps)
    .filter((point) => {
      const pointDate = getSafeDate(point.date);

      if (minDate && maxDate) {
        return (
          (isAfter(pointDate, minDate) || isEqual(pointDate, minDate)) &&
          (isAfter(maxDate, pointDate) || isEqual(maxDate, pointDate))
        );
      }

      return !minDate || isAfter(pointDate, minDate) || isEqual(pointDate, minDate);
    })
    .reduce((ac, point) => {
      const data = typeof transform === 'function' ? transform(point) : createTrendSeriesDataPoint(point);

      ac[point.canonical_network_id].data.push(data);
      return ac;
    }, buildDataScaffold(relevantOps));
}

function getTrendSeriesByCDN(endpoints, operators, dataset, minDateString, maxDateString) {
  const series = {};
  const nonNullData = filterTrendData(dataset);
  const minDate = getSafeDate(minDateString);
  const maxDate = getSafeDate(maxDateString);
  const relevantData = nonNullData.filter(
    (point) => !minDateString || isAfter(getSafeDate(point.date), minDate) || isEqual(getSafeDate(point.date), minDate),
  );

  endpoints.map((endpoint) => {
    const relevantOps = getRelevantOps(relevantData, operators);

    series[endpoint] = filterGraphOps(relevantData, relevantOps)
      .filter((point) => {
        const pointDate = getSafeDate(point.date);

        if (minDate && maxDate) {
          return (
            (isAfter(pointDate, minDate) || isEqual(pointDate, minDate)) &&
            (isAfter(maxDate, pointDate) || isEqual(maxDate, pointDate))
          );
        }

        return !minDate || isAfter(pointDate, minDate) || isEqual(pointDate, minDate);
      })
      .reduce((ac, point) => {
        ac[point.canonical_network_id].data.push({
          x: point.date,
          y: getDatumValue(point)[endpoint],
          lci: point.lci[endpoint],
          uci: point.uci[endpoint],
          rank: point.rank,
        });

        return ac;
      }, buildDataScaffold(relevantOps));
  });

  return series;
}

function getHourlyData(operators, date, dataset, isDp) {
  if (!operators) {
    return {
      data: [],
      label: [],
      max: 0,
      min: 0,
    };
  }

  let max = 0;
  let min = Number.MAX_SAFE_INTEGER;
  const hLabels = [];

  const increment = isDp ? 3 : 1;

  for (let i = 0; i < 24; i += increment) {
    hLabels.push(i);
  }

  const relevantData = dataset.filter((point) => point.date === date);
  const nonNullData = relevantData.filter((p) => p.mean || p.count || p.percent);

  const relevantOps = getRelevantOps(nonNullData, operators);

  const data = filterGraphOps(nonNullData, relevantOps).reduce((ac, n) => {
    ac[n.canonical_network_id].stack = n.canonical_network_id;
    ac[n.canonical_network_id].data = hLabels.map((l) => {
      const haveUci = n.uci && !isNaN(n.uci[`${l}`]);
      const haveLci = n.lci && !isNaN(n.lci[`${l}`]);
      const haveMean = n.mean && !isNaN(n.mean[`${l}`]);
      const havePercentage = n.percent && !isNaN(n.percent[`${l}`]);

      if (haveUci) {
        max = Math.max(max, n.uci[`${l}`]);
      } else if (haveMean) {
        max = Math.max(max, n.mean[`${l}`]);
      } else if (havePercentage) {
        max = Math.max(max, n.percent[`${l}`]);
      }

      if (haveLci) {
        min = Math.min(min, n.lci[`${l}`]);
      } else if (haveMean) {
        min = Math.min(min, n.mean[`${l}`]);
      } else if (havePercentage) {
        min = Math.min(min, n.percent[`${l}`]);
      }

      let y;

      if (haveMean) {
        y = n.mean[`${l}`];
      } else if (havePercentage) {
        y = n.percent[`${l}`];
      }

      return {
        y,
        lci: !haveLci ? null : n.lci[`${l}`],
        uci: !haveUci ? null : n.uci[`${l}`],
      };
    });

    return ac;
  }, buildDataScaffold(relevantOps));

  return {
    data: data,
    label: hLabels.map((l) => (l < 10 ? `0${l}` : `${l}`)),
    max: Math.ceil(max),
    min: Math.floor(min),
  };
}

function chunkByScale(data, scale) {
  return chunk(data, scale).map((arr) => arr.reduce((a, b) => a + b, 0));
}

function chunkLabelsByScale(labels, scale) {
  return scale > 1
    ? chunk(labels, scale).map((arr) => (arr.length > 1 ? `${arr[0]} - ${arr[arr.length - 1]}` : `${arr[0]}`))
    : labels;
}

const getMax = (stuff) => Math.round(Math.max(...stuff.reduce((ac, c) => [...ac, ...c], [])) * 100) / 100;

/*
 * @returns [....binsLabels]
 */
const getBinsLabels = (data) => {
  const maxIndex = data.reduce(
    (ac, arr, i) => ({
      max: Math.max(ac.max, arr.length),
      index: arr.length > ac.max ? i : ac.index,
    }),
    {
      max: 0,
      index: 0,
    },
  );

  return data[maxIndex.index].frequency.map((p) => p.key);
};

const getBins = (data, scale) =>
  chunkByScale(
    data.frequency.map((p) => p.value),
    scale,
  );

const getBinsCdn = (data, scale) => {
  return data.cdn.reduce(
    (ac, cur) => ({
      ...ac,
      [`${cur.key.toLowerCase()}`]: {
        data: chunkByScale(
          cur.frequency.map((i) => i.value),
          scale,
        ),
        labels: chunkLabelsByScale(
          cur.frequency.map((i) => parseFloat(i.key)),
          scale,
        ),
        max: getMax([
          chunkByScale(
            cur.frequency.map((i) => i.value),
            scale,
          ),
        ]),
      },
    }),
    {},
  );
};

const getBinsCdnRes = (data, scale) => {
  return data.cdn
    .map((videoCdn) =>
      videoCdn.resolutions.reduce(
        (ac, res) => ({
          ...ac,
          [`${videoCdn.key}_${res.key}`]: {
            data: chunkByScale(
              res.results.map((i) => i.frequency),
              scale,
            ),
            labels: chunkLabelsByScale(
              res.results.map((i) => parseFloat(i.bin)),
              scale,
            ),
            max: getMax([
              chunkByScale(
                res.results.map((i) => i.frequency),
                scale,
              ),
            ]),
          },
        }),
        {},
      ),
    )
    .reduce((ac, items) => ({ ...ac, ...items }), {});
};

function getRoundedBuckets(operators, dataset, scale, breakdown, V3) {
  if (!operators) {
    return;
  }

  const relevantOps = getRelevantOps(dataset, operators);
  const points = filterGraphOps(dataset, relevantOps);

  if (!points.length) {
    return {
      data: buildDataScaffold(relevantOps),
      label: [],
    };
  }

  const rawLabels = breakdown ? [] : getBinsLabels(dataset);

  const data = points.reduce((ac, n) => {
    ac[n.canonical_network_id].data = V3
      ? getBinsCdn(n, scale)
      : breakdown
      ? getBinsCdnRes(n, scale)
      : getBins(n, scale);

    return ac;
  }, buildDataScaffold(relevantOps));

  const max = breakdown ? 0 : getMax(Object.values(data).map((d) => d.data));

  return {
    data,
    max,
    label: chunkLabelsByScale(rawLabels, scale),
  };
}

function getCpTrends(operators, dataset) {
  const relevantOps = getRelevantOps(dataset, operators);

  const ops = filterGraphOps(dataset, relevantOps);

  const result =
    ops &&
    ops.reduce(
      (ac, point) => {
        Object.keys(ac).map((cp) => {
          ac[cp][point.canonical_network_id].data.push({
            x: point.date,
            y: point.mean && point.mean[cp],
            lci: point.lci && point.lci[cp],
            uci: point.uci && point.uci[cp],
          });
        });

        return ac;
      },
      {
        ec2: buildDataScaffold(relevantOps),
        gce: buildDataScaffold(relevantOps),
      },
    );

  return result;
}

function getCdnTrends(operators, dataset, isV3) {
  const relevantOps = getRelevantOps(dataset, operators);

  const ops = filterGraphOps(dataset, relevantOps);

  let scaffold;

  if (isV3) {
    scaffold = {
      akamai: buildDataScaffold(relevantOps),
      cloudfront: buildDataScaffold(relevantOps),
      googlecloud: buildDataScaffold(relevantOps),
      youtube: buildDataScaffold(relevantOps),
    };
  } else {
    scaffold = {
      akamai: buildDataScaffold(relevantOps),
      cloudfront: buildDataScaffold(relevantOps),
      'google storage': buildDataScaffold(relevantOps),
      'google.com': buildDataScaffold(relevantOps),
    };
  }

  const result =
    ops &&
    ops.reduce((ac, point) => {
      Object.keys(ac).map((cdn) => {
        ac[cdn][point.canonical_network_id].data.push({
          x: point.date,
          y: point.mean[cdn],
          lci: point.lci[cdn],
          uci: point.uci[cdn],
        });
      });

      return ac;
    }, scaffold);

  return result;
}
function _getProviderTrendsPerOperator(operators, dataset, providers) {
  const relevantOps = getRelevantOps(dataset, operators);
  const ops = filterGraphOps(dataset, relevantOps);

  const initialValue = {};

  operators.map((op) => {
    initialValue[op.canonical_network_id] = buildDataScaffoldForOperator(providers);
  });

  const result =
    ops &&
    ops.reduce((ac, point) => {
      providers.map((p) => {
        ac[parseInt(point.canonical_network_id)][p].data.push({
          x: point.date,
          y: point.mean && point.mean[p],
          lci: point.lci && point.lci[p],
          uci: point.uci && point.uci[p],
        });
      });

      return ac;
    }, initialValue);

  return result;
}

function getCpTrendsPerOperator(operators, dataset) {
  return _getProviderTrendsPerOperator(operators, dataset, CPS_LIST);
}

function getCdnTrendsPerOperator(operators, dataset) {
  return _getProviderTrendsPerOperator(operators, dataset, CDNS_LIST);
}

function getFocusBars(operators, date, dataset, providers) {
  let max = 0;
  let min = Number.MAX_SAFE_INTEGER;

  const relevantData = dataset.filter((point) => point.date === date);
  const relevantOps = getRelevantOps(relevantData, operators);

  const data = filterGraphOps(relevantData, relevantOps).reduce((ac, point) => {
    ac[point.canonical_network_id].stack = point.canonical_network_id;

    ac[point.canonical_network_id].data = providers.map((l) => {
      l = l.toLowerCase();
      max = point.uci && point.uci[l] ? Math.max(max, point.uci[l]) : max;
      min = point.lci && point.lci[l] ? Math.min(min, point.lci[l]) : min;
      return {
        y: point.mean && point.mean[l],
        lci: point.lci && point.lci[l],
        uci: point.uci && point.uci[l],
      };
    });

    return ac;
  }, buildDataScaffold(relevantOps));

  return {
    data: data,
    providers,
    max: Math.ceil(max),
    min: Math.floor(min),
  };
}

function getBinnedDistribution(rawData, operators) {
  const { data, label } = getRoundedBuckets(operators, rawData, 1);

  return {
    data: Object.values(data).map((item) => ({
      ...item,
      data: item.data.map((value) => Math.round(value * 100) / 100),
    })),
    label: label.map((value) => value.split(' - ')[0]),
  };
}

function getCdnBars(operators, date, dataset, providers, valueAccessor, dataProperty = 'y', operatorProperty = 'x') {
  const relevantData = dataset
    .filter((point) => point.date === date)
    .filter((point) => operators.some((op) => op.canonical_network_id === point.canonical_network_id));
  const relevantOps = getRelevantOps(relevantData, operators);
  const labels = relevantOps.map((op) => op.name_mapped);

  const ds = providers.reduce((acc, cur) => {
    acc[cur] = {
      label: CDNS[cur],
      data: [],
      backgroundColor: cdnColorMap[cur],
    };

    return acc;
  }, {});

  relevantData.map((op) => {
    providers.map((p) => {
      ds[p].data.push({
        [operatorProperty]: relevantOps.find((rop) => rop.canonical_network_id === op.canonical_network_id)
          ?.name_mapped,
        [dataProperty]: op[valueAccessor][p] ?? 0,
        // check for undefined because test level data has no confidence intervals
        lci: op.lci?.[p] ?? 0,
        uci: op.uci?.[p] ?? 0,
      });
    });

    return op;
  });

  return {
    labels,
    datasets: Object.values(ds),
  };
}

function getCpBars(operators, date, dataset, providers, valueAccessor) {
  const relevantData = dataset.filter((point) => point.date === date);
  const relevantOps = getRelevantOps(relevantData, operators);
  const labels = relevantOps.map((op) => op.name_mapped);

  const ds = providers.reduce((acc, cur) => {
    acc[cur] = {
      label: CP_LABELS[cur],
      data: [],
      backgroundColor: cdnColorMap[cur],
    };

    return acc;
  }, {});

  relevantData.map((op) => {
    providers.map((p) => {
      ds[p].data.push({
        x: relevantOps.find((rop) => rop.canonical_network_id === op.canonical_network_id)?.name_mapped,
        y: op[valueAccessor][p],
        lci: op.lci[p],
        uci: op.uci[p],
      });
    });

    return op;
  });

  return {
    labels,
    datasets: Object.values(ds),
  };
}

function getCdnResTrends(networks, data, cdnRes) {
  const nonNullData = filterTrendData(data);
  const relevantOps = getRelevantOps(nonNullData, networks);
  const ops = filterGraphOps(nonNullData, relevantOps);

  return (
    ops &&
    ops.reduce(
      (ac, point) => {
        Object.keys(ac).map((cdn) => {
          ac[cdn][point.canonical_network_id].data.push({
            x: point.date,
            y: point.mean[cdn],
            lci: point.lci[cdn],
            uci: point.uci[cdn],
          });
        });

        return ac;
      },
      Object.fromEntries(cdnRes.map((cdn) => [cdn, buildDataScaffold(relevantOps)])),
    )
  );
}

function getCdnResOpsTrends(operators, data, cdnRes, V2, V3) {
  const nonNullData = filterTrendData(data);
  const relevantOps = getRelevantOps(nonNullData, operators);
  const ops = filterGraphOps(nonNullData, relevantOps);

  const initialValue = {};

  operators.map((op) => {
    initialValue[op.canonical_network_id] = buildDataScaffoldForCdnResOps(cdnRes, op.hex_color, V2, V3);
  });

  const result =
    ops &&
    ops.reduce(
      (ac, point) => {
        cdnRes.map((cdn) => {
          ac[point.canonical_network_id][cdn].data.push({
            x: point.date,
            y: point.mean[cdn],
            lci: point.lci[cdn],
            uci: point.uci[cdn],
          });
        });

        return ac;
      },
      initialValue,
      // {
      //   'OPENSIGNAL_360p': buildDataScaffold(relevantOps),
      //   'OPENSIGNAL_720p': buildDataScaffold(relevantOps),
      //   'YOUTUBE_360p': buildDataScaffold(relevantOps),
      //   'YOUTUBE_720p': buildDataScaffold(relevantOps)
      // }
    );

  return result;
}

function getCdnResFocus(networks, allData, V2, V3) {
  let max = 0;
  let min = Number.MAX_SAFE_INTEGER;
  const nonNullData = filterTrendData(allData);

  const relevantOps = getRelevantOps(nonNullData, networks);
  const graphOps = filterGraphOps(allData, relevantOps);
  const labels = V3 ? V3_CDN_RES_ENDPOINT_LABELS : V2 ? V2_CDN_RES_ENDPOINT_LABELS : V1_CDN_RES_ENDPOINT_LABELS;
  const cdnRes = V3 ? V3_CDN_RES : V2 ? V2_CDN_RES : V1_CDN_RES;
  const data =
    graphOps &&
    graphOps.reduce((ac, point) => {
      ac[point.canonical_network_id].data = cdnRes.map((l) => {
        max = point.uci[l] ? Math.max(max, point.uci[l]) : max;
        min = point.lci[l] ? Math.min(min, point.lci[l]) : min;
        return {
          y: point.mean[l],
          lci: point.lci[l],
          uci: point.uci[l],
          // we have data from 2 cdns (Akamai and YouTube)
          // Akamai is registred as OPENSIGNAL
          cdn: labels[l.split('_')[0]],
          res: l.split('_')[1],
        };
      });

      return ac;
    }, buildDataScaffold(relevantOps));

  return {
    data,
    max: Math.ceil(max),
    min: Math.floor(min),
  };
}

function getRangesPerOperator(operators, dataset) {
  const relevantOps = getRelevantOps(dataset, operators);

  const points = filterGraphOps(dataset, relevantOps);

  const scaffold = {};

  relevantOps.map((o) => {
    scaffold[o.canonical_network_id] = buildDataScaffoldForFr();
  });

  return (
    points &&
    points.reduce((ac, point) => {
      ac[point.canonical_network_id][FREQUENCY_RANGES[point.nr_frequency_range - 1]].data.push({
        x: point.date,
        y: point.mean,
        lci: point.lci,
        uci: point.uci,
      });

      return ac;
    }, scaffold)
  );
}

function getDevicesFormated(networks, allData, plotTogether = false) {
  let max = 0;
  let min = Number.MAX_SAFE_INTEGER;
  const nonNullData = allData.filter(
    (point) => (point.estimate && Object.keys(point.estimate).length) || (point.mean && Object.keys(point.mean).length),
  );

  if (!nonNullData.length) {
    return {
      data: {},
      labels: [],
    };
  }

  const relevantOps = getRelevantOps(nonNullData, networks);
  const graphOpsData = filterGraphOps(nonNullData, relevantOps);

  let KEYS;

  if (plotTogether) {
    KEYS = uniq(graphOpsData.map((i) => (i.mean ? Object.keys(i.mean) : Object.keys(i.estimate))).flat()).sort();

    if (KEYS.length && KEYS[KEYS.length - 1].length < 3) {
      KEYS.sort((a, b) => a - b);
    }
  } else {
    KEYS = {};
  }

  const data =
    graphOpsData &&
    graphOpsData.reduce((ac, point) => {
      ac[point.canonical_network_id].stack = point.canonical_network_id;

      if (!plotTogether) {
        const labels = point.mean ? Object.keys(point.mean) : Object.keys(point.estimate);

        if (labels[labels.length - 1].length < 3) {
          labels.sort((a, b) => a - b);
        }

        KEYS[point.canonical_network_id] = labels;

        ac[point.canonical_network_id].data = KEYS[point.canonical_network_id].map((l) => {
          max = point.uci[l] ? Math.max(max, point.uci[l]) : max;
          min = point.lci[l] ? Math.min(min, point.lci[l]) : min;
          return {
            y: point.mean ? point.mean[l] : point.estimate[l],
            lci: point.lci[l],
            uci: point.uci[l],
            key: l,
          };
        });
      } else {
        ac[point.canonical_network_id].data = KEYS.map((l) => {
          max = point.uci[l] ? Math.max(max, point.uci[l]) : max;
          min = point.lci[l] ? Math.min(min, point.lci[l]) : min;
          return {
            y: point.mean ? point.mean[l] : point.estimate[l],
            lci: point.lci[l],
            uci: point.uci[l],
            key: l,
          };
        });
      }

      return ac;
    }, buildDataScaffold(relevantOps));

  return {
    data,
    labels: KEYS,
    max: Math.ceil(max),
    min: Math.floor(min),
  };
}

function getRankLabel(rank) {
  if (!rank) return '-';

  let rankSuffix;

  switch (rank % 10) {
    case 1:
      rankSuffix = 'st';
      break;
    case 2:
      rankSuffix = 'nd';
      break;
    case 3:
      rankSuffix = 'rd';
      break;
    default:
      rankSuffix = 'th';
  }

  return rank + rankSuffix;
}

function getDeltaColor(metric, item) {
  const value = item[metric + '_raw'];

  if (metric === 'good_availability_delta') {
    if (value > 0) {
      return MG_METRICS[metric].scale[0].color;
    }

    return MG_METRICS[metric].scale[1].color;
  }

  if (metric === 'device_share_delta') {
    const bucket = MG_METRICS[metric].scale.findIndex((item) => {
      if (item.range.min === undefined) {
        return value <= item.range.max;
      }
      if (item.range.max === undefined) {
        return value >= item.range.min;
      }

      return item.range.min < value && value < item.range.max;
    });

    if (bucket === -1) {
      return MG_METRICS[metric].scale[1].color;
    }

    return MG_METRICS[metric].scale[bucket].color;
  }

  if (metric === 'strategy_delta') {
    const scaleIndex = MG_METRICS[metric].scale.findIndex(
      (strategy) => item.strategic_position === strategy.label.toLowerCase(),
    );

    return MG_METRICS[metric].scale[scaleIndex].color;
  }

  return '#aaa';
}

function hasRank(dataItem, focusedNetworkId) {
  if (!dataItem.item.length) {
    return false;
  }
  const area = dataItem.item.find((item) => item.network === focusedNetworkId);
  if (!area) {
    return false;
  }
  return area.rank != null;
}

export {
  getBarsByDate,
  getCpTrends,
  getCdnTrends,
  getFocusBars,
  getCpBars,
  getCdnBars,
  getFormatedSingles,
  getHourlyData,
  getRoundedBuckets,
  getTrendSeries,
  getTrendSeriesByCDN,
  getCdnResTrends,
  getCdnResOpsTrends,
  getCpTrendsPerOperator,
  getCdnTrendsPerOperator,
  getCdnResFocus,
  // getRangesFocus,
  getRangesPerOperator,
  getDevicesFormated,
  getRankLabel,
  getDeltaColor,
  hasRank,
  getDatumValue,
  getBinnedDistribution,
  createTrendSeriesDataPoint,
};
