<script setup lang="ts">
import { computed } from 'vue';

import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';

type Props = {
  availableMetrics: { metricSubtype: string; label: string }[];
  selectedMetricLabel: string;
  selectedMetricSubtype: string;
};

const props = defineProps<Props>();
const emit = defineEmits(['metricChange']);

const items = computed(() => {
  return props.availableMetrics.map((metric) => ({
    keyAccessor: metric.metricSubtype,
    label: metric.label,
    value: metric.metricSubtype,
    checked: metric.metricSubtype === props.selectedMetricSubtype,
  }));
});

const onListItemClick = (metricSubtype: string) => {
  emit('metricChange', metricSubtype);
};
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Metrics:"
    :selected-label="selectedMetricLabel"
    :items="items"
    @list-item-click="onListItemClick"
  />
</template>

<style scoped lang="scss">
.onx-chart-metric-selector__label {
  color: black;
  display: inline-block;
  margin-right: 10px;
}
</style>
