<template>
  <div v-show="displayModal" class="modal">
    <div class="modal__content">
      <div class="modal__title">Explaining User Group metrics</div>

      <p>By default, Opensignal metrics are based on All Users for whom Opensignal collected any data.</p>

      <p>
        For Download Speed, Upload Speed, Games Experience, Voice App Experience, Video Experience and Availability
        metrics, data can also be analysed by group of users: 5G Users, 4G Users and 3G Users.
      </p>

      <p style="margin-bottom: 0">User Groups are defined as follows:</p>

      <ul>
        <li><strong>All Users:</strong> all users for whom Opensignal collected any data.</li>
        <li>
          <strong>5G Users:</strong> users who have seen 5G at least once on their current network. They might have also
          seen 4G, 3G, 2G and No signal. In other words, this user group is 5G or Below Users.
        </li>
        <li>
          <strong>4G Users:</strong> users who have seen 4G on their current network at least once and have never seen
          5G. They might have also seen 3G, 2G and No signal. In other words, this user group is 4G or Below Users.
        </li>
        <li>
          <strong>3G Users:</strong> users who have never seen 5G or 4G on their current network. They might have also
          seen 2G and No signal. In other words, this user group is 3G or Below Users.
        </li>
      </ul>

      <div class="modal__close" @click="confirm()">
        <CloseIcon />
      </div>
    </div>
  </div>
</template>

<script>
import fiveGDownloadSpeed from '@/assets/5g-download-speed.svg';
import downloadSpeedExperience5gUsers from '@/assets/download-speed-experience-5g-users.svg';
import CloseIcon from '@/components/onx/icons/CloseIcon';

export default {
  name: 'UserGroupInfoModal',
  components: {
    CloseIcon,
  },
  props: {
    displayModal: {
      type: Boolean,
      defualt: false,
    },
  },
  data() {
    return {
      icons: {
        fiveGDownloadSpeed,
        downloadSpeedExperience5gUsers,
      },
    };
  },
  methods: {
    confirm() {
      this.$emit('update:displayModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'scss/variables.module' as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(80, 100, 130, 0.5);
}

.modal__content {
  box-shadow: 0px 28px 56px 0px rgba(var(--charcoal-500), 0.12);
  width: 525px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 2px;
  padding: 64px 48px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 160%;
  color: var(--charcoal-400);

  @media screen and (max-width: $ci-breakpoint-tablet) {
    max-width: 90%;
    padding: 20px;
    max-height: calc(100vh - 40px);
  }

  ul {
    margin: 0;
    padding: 0 0 0 20px;
  }

  p {
    margin: 0 0 1em;
  }

  strong {
    color: var(--dark-teal-400);
  }
}

.modal__title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: var(--charcoal-500);
  margin-bottom: 16px;
}

.modal__close {
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  color: var(--charcoal-300);
}
</style>
