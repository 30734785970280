import { ref, computed, watchEffect } from 'vue';

import type { RefLike } from '@/types/helpers/RefLike';
import type { Operator } from '@/types/Operator';
import useVegaImageQuery from '@/composables/useVegaImageQuery';
import { Dashboards } from '@/constants/dashboards';
import useLocations from '@/composables/useLocations';
import useMapWithPolygons from '@/composables/useMapWithPolygons';

const useVegaMap = (
  dashboard: Dashboards,
  {
    enableGeohashes,
    endpoint,
    networkOperator,
    title,
  }: {
    endpoint: string;
    title?: string;
    networkOperator: RefLike<Operator>;
    enableGeohashes?: RefLike<boolean>;
  },
) => {
  const {
    bbox,
    computedLocationId,
    computedPolygonData,
    handleNewBounds,
    latMax,
    latMin,
    lngMax,
    lngMin,
    mapHeight,
    mapIsLoading: polygonsLoading,
    mapSizeAndBoundsReady,
    mapWidth,
  } = useMapWithPolygons(dashboard, { enableGeohashes });
  const { countryIso3 } = useLocations(dashboard);

  const computedCanonicalNetworkId = computed(() => {
    return networkOperator.value?.canonical_network_id;
  });

  const vegaCoverageImageRequestEnabled = ref(false);

  const {
    query: { data: vegaCoverageResponse, isLoading: vegaCoverageImageLoading },
    setupImageLayer,
  } = useVegaImageQuery(dashboard, endpoint, {
    chartWidth: mapWidth,
    chartHeight: mapHeight,
    countryIso3: countryIso3,
    latMin,
    latMax,
    lngMin,
    lngMax,
    canonicalNetworkId: computedCanonicalNetworkId,
    enabled: vegaCoverageImageRequestEnabled,
    bbox,
  });

  const mapIsLoading = computed(() => {
    return vegaCoverageImageLoading.value || polygonsLoading.value;
  });

  const computedLegendEntries = computed(() => {
    if (!vegaCoverageResponse.value) {
      return [];
    }

    return vegaCoverageResponse.value.data.legend.domain.map((domainEntry, i) => {
      return {
        color: vegaCoverageResponse.value.data.legend.range[i],
        label: domainEntry,
      };
    });
  });

  const computedTitle = computed(() => {
    if (!title) {
      return '';
    }

    if (!networkOperator.value) {
      return title;
    }

    return `${title} for ${networkOperator.value.name_mapped}`;
  });

  watchEffect(() => {
    vegaCoverageImageRequestEnabled.value = mapSizeAndBoundsReady.value && !!computedCanonicalNetworkId.value;
  });

  return {
    computedPolygonData,
    mapIsLoading,
    computedLegendEntries,
    computedTitle,
    handleNewBounds,
    computedLocationId,
    computedCanonicalNetworkId,
    bbox,
    setupImageLayer,
  };
};

export default useVegaMap;
