<script setup>
import { computed } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator(value) {
      return ['primary', 'secondary', 'tertiary'].includes(value);
    },
  },
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['sm', 'md'].includes(value);
    },
  },
  disabled: {
    type: Boolean,
    default: false,
    optional: true,
  },
});

const classObject = computed(() => ['onx-button', `onx-button--${props.variant}`, `onx-button--${props.size}`]);
</script>

<template>
  <button type="button" :disabled="disabled" :class="classObject">
    <slot />
  </button>
</template>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';

.onx-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid transparent;
  outline: none;
  border-radius: var(--onx-btn-border-radius);
  cursor: pointer;
  padding: 8px;
  min-height: 32px;
  font-size: pxToRem(14px);

  transition: transition();

  &:disabled {
    cursor: auto;
  }

  &--sm {
    padding: 6px;
    min-height: 24px;
    font-size: pxToRem(12px);
  }

  &--primary {
    background-color: var(--onx-btn-primary-bg-color);
    color: var(--onx-btn-primary-text-color);

    &:focus {
      background-color: var(--onx-btn-primary-focus-bg-color);
      color: var(--onx-btn-primary-focus-text-color);
      border: 1px solid var(--onx-btn-primary-focus-border-color);
    }

    &:hover {
      background-color: var(--onx-btn-primary-hover-bg-color);
      color: var(--onx-btn-primary-hover-text-color);
    }

    &:active {
      background-color: var(--onx-btn-primary-active-bg-color);
      color: var(--onx-btn-primary-active-text-color);
    }

    &:disabled {
      background-color: var(--onx-btn-primary-disabled-bg-color);
      color: var(--onx-btn-primary-disabled-text-color);
    }
  }

  &--secondary {
    background-color: var(--onx-btn-secondary-bg-color);
    color: var(--onx-btn-secondary-text-color);

    &:hover {
      background-color: var(--onx-btn-secondary-hover-bg-color);
      color: var(--onx-btn-secondary-hover-text-color);
    }

    &:active {
      background-color: var(--onx-btn-secondary-active-bg-color);
      color: var(--onx-btn-secondary-active-text-color);
    }

    &:disabled {
      background-color: var(--onx-btn-secondary-disabled-bg-color);
      color: var(--onx-btn-secondary-disabled-text-color);
    }

    &:focus {
      background-color: var(--onx-btn-secondary-focus-bg-color);
      color: var(--onx-btn-secondary-focus-text-color);
      border: 1px solid var(--onx-btn-secondary-focus-border-color);
    }
  }

  &--tertiary {
    background-color: var(--onx-btn-tertiary-bg-color);
    color: var(--onx-btn-tertiary-text-color);

    &:hover {
      background-color: var(--onx-btn-tertiary-hover-bg-color);
      color: var(--onx-btn-tertiary-hover-text-color);
    }

    &:active {
      background-color: var(--onx-btn-tertiary-active-bg-color);
      color: var(--onx-btn-tertiary-active-text-color);
    }

    &:disabled {
      background-color: var(--onx-btn-tertiary-disabled-bg-color);
      color: var(--onx-btn-tertiary-disabled-text-color);
    }

    &:focus {
      background-color: var(--onx-btn-tertiary-focus-bg-color);
      color: var(--onx-btn-tertiary-focus-text-color);
    }
  }
}
</style>
