import { METRIC_TYPES } from '@/constants/constants';
import { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';

const filterDeploymentTypeByConnectionCategory = (
  category: AvailableConnectionCategory,
  deploymentType: AvailableDeploymentType,
) => {
  return category.categoryValue === METRIC_TYPES.FiveG ? deploymentType : undefined;
};

export default filterDeploymentTypeByConnectionCategory;
