import { AxiosResponse } from 'axios';
import { Ref, computed } from 'vue';
import { MetricResponse } from '@/types/MetricResponse';

const useMetricSource = (
  metricResponse: Ref<AxiosResponse<MetricResponse<any>>> | Ref<undefined>,
  isSuccess: Ref<true> | Ref<false>,
) => {
  const metricSource = computed(() => {
    if (isSuccess.value) {
      return metricResponse.value?.data.source;
    }

    return null;
  });

  return {
    metricSource,
  };
};

export default useMetricSource;
