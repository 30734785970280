<script setup></script>

<template>
  <div class="onx-list">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.onx-list {
  padding-top: 8px;
  padding-bottom: 8px;
  background: var(--white);
}
</style>
