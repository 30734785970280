import { computed, Ref, unref, MaybeRef } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { isDate, format } from 'date-fns';
import { type AxiosRequestConfig } from 'axios';
import mapboxgl from 'mapbox-gl';

import type { DateIntervalEnum } from '@/types/MetricRequestParameters';

import osApi from '@/api/osApi';
import type { MetricResponse } from '@/types/MetricResponse';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { Dashboards } from '@/constants/dashboards';
import { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';

/**
 * Used to GET specific metric information
 * Example URL: https://platform-test.opensignal.com/api/api/v2/focus/metrics/gamespacketloss_5g/7/90days/
 */
const useMetric = <T extends MetricStructuresEnum>(
  dashboard: Dashboards,
  {
    aggregation,
    bbox,
    comparison = false,
    dateInterval,
    deploymentType,
    enabled,
    endDate,
    geohashes,
    location,
    metric,
    nbDays = 180,
    operatorInfo = true,
    otherRequestParams = {},
  }: {
    metric: Ref<string>;
    location: Ref<string | number>;
    aggregation: Ref<string>;
    operatorInfo?: MaybeRef<boolean>;
    nbDays?: MaybeRef<number>;
    comparison?: MaybeRef<boolean>;
    endDate?: MaybeRef<Date | undefined>;
    geohashes?: MaybeRef<string[]>;
    enabled?: MaybeRef<boolean | undefined>;
    bbox?: MaybeRef<mapboxgl.LngLatBounds | undefined>;
    dateInterval?: DateIntervalEnum;
    deploymentType?: MaybeRef<AvailableDeploymentType | undefined>;
    otherRequestParams?: AxiosRequestConfig['params'];
  },
  options: Omit<Parameters<typeof useQuery>[1], 'queryKey' | 'queryFn'> = {},
) => {
  const queryParams = computed(() => {
    const endDateValue = unref(endDate);
    const endDateString = endDateValue && isDate(endDateValue) ? format(endDateValue, 'yyyy-MM-dd') : endDateValue;
    const bboxValue = unref(bbox);
    const bboxString = bboxValue
      ? [bboxValue.getWest(), bboxValue.getSouth(), bboxValue.getEast(), bboxValue.getNorth()].join(',')
      : undefined;

    return {
      operator_info: unref(operatorInfo),
      nb_days: unref(nbDays),
      comparison: unref(comparison),
      end_date: endDateString,
      geohashes: unref(geohashes),
      bbox: bboxString,
      date_interval: dateInterval,
      deployment_type: unref(deploymentType)?.value,
      ...otherRequestParams,
    };
  });

  const url = computed(() => {
    return `/${dashboard}/metrics/${metric.value}/${location.value}/${aggregation.value}/`;
  });

  const query = useQuery({
    queryKey: ['metrics', url, queryParams],
    queryFn: async () => {
      return osApi.get<MetricResponse<T>>(url.value, {
        params: queryParams.value,
      });
    },
    staleTime: 1 * 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false,
    retry: 1,
    enabled,
    ...options,
  });

  return query;
};

export default useMetric;
