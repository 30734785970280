import { getDatapointsByIndex, getTooltipState } from '@/utils/charts';

type ValueFn = (args: { dataset: any; dataAxis: 'x' | 'y' }) => number | string;

const coverageConnectionCategoriesGeohashCountTooltip =
  ({
    horizontal,
    secondaryValueFn,
    valueFn,
  }: {
    horizontal?: boolean;
    valueFn?: ValueFn;
    secondaryValueFn?: ValueFn;
  }) =>
  (context: any, tooltip: any, chartData: any) => {
    const dataAxis = horizontal ? 'x' : 'y';

    const tt = context.tooltip;
    const tooltipState = getTooltipState(context.chart, tt);

    const currentDataset = chartData.value.datasets[chartData.value.labels.indexOf(tt.title[0])];
    const datapoints = getDatapointsByIndex(chartData.value.datasets, 0, horizontal).filter((d: any) => {
      return d.data[dataAxis] > 0;
    });

    const total = datapoints.reduce((acc: any, curr: any) => {
      return acc + curr.data[dataAxis];
    }, 0);

    if (datapoints.length) {
      tooltip.value = {
        ...tooltip.value,
        ...tooltipState,
        datapoints: [
          {
            ...currentDataset,
            value:
              typeof valueFn === 'function'
                ? valueFn({ dataset: currentDataset, dataAxis })
                : currentDataset.data[0][dataAxis],
            secondaryValue:
              typeof secondaryValueFn === 'function'
                ? secondaryValueFn({ dataset: currentDataset, dataAxis })
                : `${((currentDataset.data[0][dataAxis] / total) * 100).toFixed(2)}%`,
          },
        ],
        title: tt.title[0],
      };
    } else {
      tooltip.value.display = false;
    }
  };

export default coverageConnectionCategoriesGeohashCountTooltip;
