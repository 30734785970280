import { computed, watchEffect } from 'vue';

import type { Dashboards } from '@/constants/dashboards';
import useFilters from '@/composables/useFilters';
import useAvailableConnectionCategories from '@/focus/composables/useAvailableConnectionCategories';
import usePossibleConnectionCategories from '@/connection-categories-provider/usePossibleConnectionCategories';
import useAnalytics from '@/composables/useAnalytics';

// This is more "mapConnectionCategory" than "mainConnectionCategory". The "main" name is left over from the original implementation.
// TODO: Rename this and the component that uses it to useMapConnectionCategory, along with the query param associated with it.
const useMainConnectionCategory = (dashboard: Dashboards) => {
  const { mainConnectionCategory, setFilters } = useFilters(dashboard);
  const availableConnectionCategories = useAvailableConnectionCategories();
  const allPossibleConnectionCategories = usePossibleConnectionCategories();
  const { track } = useAnalytics();

  const mainConnectionCategories = computed(() => {
    return allPossibleConnectionCategories.map((category) => ({
      ...category,
      disabled: !availableConnectionCategories.value.includes(category.categoryValue),
    }));
  });

  const selectedMainConnectionCategory = computed({
    get: () => {
      return mainConnectionCategories.value.find((category) => category.categoryValue === mainConnectionCategory.value);
    },
    set: (connectionCategory: any) => {
      setFilters({ mainConnectionCategory: connectionCategory.categoryValue });
    },
  });

  const onMainConnectionCategoryChange = (connectionCategory: any) => {
    selectedMainConnectionCategory.value = connectionCategory;
    track('map connection category change', {
      connectionCategory,
    });
  };

  watchEffect(() => {
    if (availableConnectionCategories.value.length === 0) {
      return;
    }

    const selectedCategory = selectedMainConnectionCategory.value;
    if (selectedCategory?.disabled) {
      selectedMainConnectionCategory.value = mainConnectionCategories.value.find((category) => !category.disabled);
    }
  });

  return { selectedMainConnectionCategory, onMainConnectionCategoryChange, mainConnectionCategories };
};

export default useMainConnectionCategory;
