import { MetricSubtypes } from '@/types/MetricSubtypes';
import { MetricStructuresEnum } from '@/types/MetricStructures';

import { type CongestionUnderutilizedCellsRow } from '@/chart-metric-definitions/data-transformers/prepareCongestionUnderutilizedCellsForTable';
import { congestionUnderutilizedCellsColumns } from '@/360/base-station/table-columns/congestionUnderutilizedCellsColumns';

import createExportSimpleTableFullMetricToCsv from '@/360/csv-export-overrides/createExportSimpleTableFullMetricToCsv';

const exportCongestionUnderutilizedCapacityScoreToCsv = createExportSimpleTableFullMetricToCsv<
  MetricStructuresEnum.CongestionUnderutilizedCells,
  CongestionUnderutilizedCellsRow
>({ metricSubtype: MetricSubtypes.CongestionUnderutilizedCellsFull, columns: congestionUnderutilizedCellsColumns });

export default exportCongestionUnderutilizedCapacityScoreToCsv;
