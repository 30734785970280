const menuItem = <Value = string>(options: {
  label: string;
  value: Value;
  active: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  inset?: boolean;
}) => {
  const { active, disabled = false, highlighted = false, inset = false, label, value } = options;

  return {
    label,
    value,
    active,
    disabled,
    highlighted,
    inset,
  };
};

export default menuItem;
