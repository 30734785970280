import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import getLabelForMetricSubtype from '@/focus/metric-selector/labelsByMetricSubtype';
import { mapMetricDefinitionsByPage } from '@/focus/MapMetricDefinitions';

const getAvailableMapMetricsForSelector = (metricSubtype: SelectableMetrics, routeName: string) => {
  const metricDefinitions = mapMetricDefinitionsByPage[routeName];

  if (!metricDefinitions || !metricDefinitions[metricSubtype]) {
    return [];
  }

  return metricDefinitions[metricSubtype].map((metric) => ({
    metricSubtype: metric.metricSubtype,
    label: getLabelForMetricSubtype(metric.metricSubtype),
  }));
};

export default getAvailableMapMetricsForSelector;
