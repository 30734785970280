<template>
  <div
    :class="{
      'PerformanceTitle--dark': !transparent,
    }"
    class="PerformanceTitle ci-title"
  >
    <div class="PerformanceTitle__title">
      {{ fullTitle }}<span class="PerformanceTitle__title-modifier"> | {{ chartModifier }}</span>
      <CustomTooltip v-if="tooltip" :message="tooltip">
        <QuestionMark />
      </CustomTooltip>
    </div>
    <div v-if="geography && fullTitle" class="PerformanceTitle__subtitle">
      {{ geography }}
      <span v-if="metricUnit">
        <span class="PerformanceTitle__subtitle-modifier"> | in {{ metricUnit }}</span>
      </span>
      <span v-if="date" class="PerformanceTitle__subtitle-modifier">
        |
        <span class="PerformanceTitle__subtitle-date">{{ date }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import QuestionMark from '@/components/tooltip/QuestionMark';

export default {
  name: 'PerformanceTitle',
  components: {
    QuestionMark,
    CustomTooltip,
  },
  props: {
    geography: { type: String, default: undefined },
    fullTitle: { type: String, default: undefined },
    metricUnit: { type: String, default: undefined },
    chartModifier: { type: String, default: 'Overview' },
    tooltip: { type: String, default: undefined },
    transparent: { type: Boolean, default: false },
    date: { type: String, default: undefined },
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;

.PerformanceTitle {
  padding: 24px 24px 8px;
  margin-bottom: 8px;
  &--dark {
    background-color: $color-nav-bg;
  }
  &__title {
    color: var(--charcoal-500);
    font-weight: $font-weight-roboto-bold;
    font-size: $font-size-12;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  &__title-modifier {
    color: var(--charcoal-500);
  }
  &__subtitle {
    color: var(--charcoal-400);
    font-size: $font-size-10;
  }
  // &__subtitle-modifier {
  //   color: $color-blue-header;
  // }
  &__subtitle-date {
    color: var(--charcoal-400);
    font-weight: bold;
  }
}
</style>
