import type {
  DataTransformerConstructor,
  DataTransformerReturnedItem,
  DataTransformerFnOptions,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

import { METRIC_TYPE_NAMES } from '@/constants/constants';
import metricsDBToHeavyDBConnectionCategoryValue from '@/utils/metricsDBToHeavyDBConnectionCategoryValue';

const transformGeohashCountByConnectionCategoryByOperator: DataTransformerConstructor<
  [MetricStructuresEnum.CoverageGeohashCountsByCategory],
  Required<Pick<DataTransformerFnOptions, 'operators' | 'connectionCategory'>>
> =
  ({ connectionCategory, operators }) =>
  ([response], horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const normalizedConnectionCategory = metricsDBToHeavyDBConnectionCategoryValue(connectionCategory);

    const total = metricsFilteredByOperator.reduce((acc, datum) => {
      return acc + datum.counts[normalizedConnectionCategory];
    }, 0);

    const data = operators
      .map<DataTransformerReturnedItem | null>((operator) => {
        const datum = metricsFilteredByOperator.find((datum) => {
          return datum.canonical_network_id === operator.canonical_network_id;
        });

        if (!datum) {
          return null;
        }

        const color = `#${operator.hex_color}`;
        const dataAxisValue = (datum.counts[normalizedConnectionCategory] / total) * 100;

        return {
          data: [
            {
              ...datum,
              count: datum.counts[normalizedConnectionCategory],
              [dataAxis]: dataAxisValue,
              [labelAxis]: operator.name_mapped,
            },
          ],
          type: 'bar',
          backgroundColor: color,
          color,
          label: METRIC_TYPE_NAMES[connectionCategory as keyof typeof METRIC_TYPE_NAMES],
          yAxisID: 'y',
          xAxisID: 'x',
          dataAxis,
          labelAxis,
          meta: {
            imageExportLegend: {
              color,
              label: operator.name_mapped,
              y: `${dataAxisValue.toFixed(2)}%`,
            },
          },
        };
      })
      .filter((datum): datum is DataTransformerReturnedItem => datum !== null)
      .sort((a, b) => {
        const aValue = a.data[0][dataAxis];
        const bValue = b.data[0][dataAxis];

        if (typeof aValue !== 'number') {
          console.warn(`Sorting failed. Expected value to be number, got ${typeof aValue}`, { value: aValue });
          return 0;
        }

        if (typeof bValue !== 'number') {
          console.warn(`Sorting failed. Expected value to be number, got ${typeof bValue}`, { value: bValue });
          return 0;
        }

        return bValue - aValue;
      });

    return data;
  };

export default transformGeohashCountByConnectionCategoryByOperator;
