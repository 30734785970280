export default function create(namespace) {
  if (!namespace) {
    throw new TypeError('No namespace provided for the store 💣');
  }

  const types = {
    SET: `${namespace}:SET`,
    SET_PENDING: `${namespace}:SET_PENDING`,
    SET_SUCCESS: `${namespace}:SET_SUCCESS`,
    SET_FAILURE: `${namespace}:SET_FAILURE`,
    EMPTY: `${namespace}/empty`,
    ERROR: `${namespace}/error`,
    PENDING: `${namespace}/pending`,
  };

  const state = () => ({
    array: [],
    // [{ id: 1, name: 'foo' }] => { 1: { id: 1, name: 'foo' } }
    byIdentifier: {},
    pending: true,
    error: false,
  });

  const mutations = {
    [types.SET](state, { array, identifier }) {
      state.array = array;

      if (identifier) {
        state.byIdentifier = array.reduce((acc, item) => {
          acc[item[identifier]] = item;
          return acc;
        }, {});
      }
    },
    [types.SET_PENDING](state) {
      state.pending = true;
      state.error = false;
    },
    [types.SET_SUCCESS](state) {
      state.pending = false;
      state.error = false;
    },
    [types.SET_FAILURE](state) {
      state.pending = false;
      state.error = true;
    },
  };

  const getters = {
    [namespace]: (state) => state.array,
    [types.EMPTY]: (state) => !state.array.length && !state.pending,
    [types.PENDING]: (state) => (state.pending ? 'loading' : false),
    [types.ERROR]: (state) => state.error,
  };

  return {
    baseTypes: types,
    baseState: state,
    baseMutations: mutations,
    baseGetters: getters,
  };
}
