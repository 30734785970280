import { type ChartDataset, type Plugin } from 'chart.js';

import getOrCreateLegendList from '@/components/visual/chart/line-chart-plugins/getOrCreateLegendList';
import createBaseLegendItem from '@/components/visual/chart/line-chart-plugins/createBaseLegendItem';
import { cdnColorMap } from '@/constants/colorScales';
import { CDNS } from '@/constants/constants';

type ChartDatasetWithFilterOut = ChartDataset & { filterOut?: boolean };

const afterUpdate: Plugin['afterUpdate'] = (chart, args, options) => {
  const ul = getOrCreateLegendList(options.containerID);

  if (!ul) {
    return;
  }

  const cdns = [
    ...new Set(
      chart.data.datasets
        .filter((d: ChartDatasetWithFilterOut) => !d.filterOut)
        .flatMap((d) => d.data.map((p: any) => p.cdn)),
    ),
  ];

  if (!chart.options?.plugins?.legend?.labels?.generateLabels) {
    return;
  }

  const item = chart.options.plugins.legend.labels
    .generateLabels(chart)
    .filter((item) => item.lineWidth)
    .at(0);

  if (!item) {
    return;
  }

  const reusedCdns: string[] = [];

  // Remove old items by iterating through ul children and comparing their textContent to item.text
  Array.from(ul.children).forEach((li) => {
    const item = cdns.find((l) => l.text === li.textContent);
    if (!item) {
      ul.removeChild(li);
    } else {
      reusedCdns.push(item);
    }
  });

  cdns.forEach((cdn) => {
    if (!reusedCdns.includes(cdn)) {
      const li = createBaseLegendItem({
        border: `${item.lineWidth}px solid ${cdnColorMap[cdn as keyof typeof cdnColorMap]}`,
        fontColor: item.fontColor as string,
        text: CDNS[cdn as keyof typeof CDNS],
      });
      li.classList.add('inline');
      ul.appendChild(li);
    }
  });
};

const lineChartLegendCdnListPlugin = () => {
  return {
    id: 'lineChartLegendCdnListPlugin',
    afterUpdate,
  };
};

export default lineChartLegendCdnListPlugin;
