<script setup lang="ts">
import { type AxiosRequestConfig } from 'axios';
import { computed, toRefs } from 'vue';

import useTableColumnSorts from './useTableColumnSorts';
import type { DataTransformerResponseCallback } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import { Column } from '@/components/onx/table';
import OnxRawTable from '@/components/onx/table/OnxRawTable.vue';
import Paginator from '@/components/visual/Paginator.vue';
import { usePaginatedDataset } from '@/composables/usePaginatedDataset';
import { Dashboards } from '@/constants/dashboards';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';

interface Props {
  dashboard: Dashboards;
  location: string | number;
  metric: string;
  enabled: boolean;
  connectionCategories?: string[];

  onExportToCsv?: (rows: any[][], filename: string, params?: any) => void | Promise<void>;
  transform?: DataTransformerResponseCallback<any>;

  /* Table Options */
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: 'asc' | 'desc';

  /** Override the chart metric title */
  tableTitle: string;
  tableSubtitle?: string;
  exportTitle?: string;
  exportSubtitle?: string;

  tableTooltip?: string;
  otherRequestParams?: AxiosRequestConfig['params'];
}

const props = defineProps<Props>();
const { columns, enabled, location, metric, tableTitle, tableTooltip, transform } = toRefs(props);
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);

const { onClickColumn, sortColumnKey, sortDirection } = useTableColumnSorts(columns, {
  initialSortColumnKey: props.initialSortColumnKey,
  initialSortDirection: props.initialSortDirection,
});

const { data, gotoPage, isLoading, page, totalCount } = usePaginatedDataset<MetricStructuresEnum>(
  props.dashboard,
  metric,
  sortColumnKey,
  sortDirection,
  {
    location,
    enabled,
    canonicalNetworkIDs: computed(() => selectedOperators.value.map((operator) => operator.canonical_network_id)),
    connectionCategories: computed(() => props.connectionCategories || undefined),
  },
);

const rows = computed(() => {
  if (!data.value) {
    return [];
  }

  if (transform.value) {
    return transform.value(data.value.data as any);
  } else {
    return data.value.data;
  }
});

const maxPage = computed(() => {
  if (totalCount.value > 0) {
    return Math.ceil(totalCount.value / 20);
  } else {
    return 1;
  }
});
</script>

<template>
  <OnxChartContainer
    :title="tableTitle"
    :subtitle="tableSubtitle"
    :chart-title-tooltip="tableTooltip"
    :screenshot-title="exportTitle"
    :screenshot-subtitle="exportSubtitle"
    :enable-csv-export="false"
    :enable-image-export="false"
    :loading="isLoading"
    :no-data="rows.length === 0"
    data-test-id="onx-table__root"
  >
    <div class="onx-table-wrapper">
      <OnxRawTable
        :rows="rows"
        :columns="columns"
        :sort-column-key="sortColumnKey"
        :sort-direction="sortDirection"
        @column:click="onClickColumn"
      />
    </div>
    <template #tools>
      <div class="onx-dataset-item-count">{{ totalCount === 1 ? '1 item' : `${totalCount} items` }}</div>
      <div class="onx-dataset-paginator-container">
        <Paginator
          :goto-page="gotoPage"
          :page="page || 1"
          :max-page="maxPage"
          :disabled="isLoading"
          :total-count="totalCount"
        />
      </div>
    </template>
  </OnxChartContainer>
</template>

<style lang="scss">
.onx-table-wrapper {
  overflow-x: auto;
  width: 100%;
  max-height: 310px;
  overflow-y: scroll;
}

.onx-dataset-item-count {
  font-size: 0.75rem;
  color: var(--onx-btn-primary-disabled-text-color);
}

.onx-dataset-paginator-container {
  flex-grow: 1;
  flex-basis: 0;
}

.onx-table__export {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 8px;
}
</style>
