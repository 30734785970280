<script>
export default {
  props: {
    title: {
      type: String,
      default: 'squares anim',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div
    class="loader-grid"
    :class="{
      'loader-grid--fullscreen': fullscreen,
      'loader-grid--centered': fullscreen || centered,
    }"
  >
    <div v-if="overlay" class="loader-grid__overlay" />
    <svg class="loader-grid__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="48" width="48">
      <title>{{ title }}</title>
      <g class="nc-loop-squares-48-icon-f">
        <rect height="21" width="21" x="24" y="3" />
        <rect height="21" width="21" x="24" y="24" />
        <rect height="21" width="21" x="3" y="3" />
        <rect height="21" width="21" x="3" y="24" />
      </g>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.loader-grid--centered {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-grid--fullscreen {
  position: fixed;
}

.loader-grid__overlay {
  position: absolute;
  inset: 0;
  background-color: var(--charcoal-100);
  opacity: 0.5;
  z-index: 1;
}

.loader-grid__svg {
  z-index: 2;
}
</style>

<style>
.nc-loop-squares-48-icon-f {
  fill: var(--dark-teal-500);
}
.nc-loop-squares-48-icon-f > * {
  --animation-duration: 1.3s;
  transform-origin: 50% 50%;
  animation: nc-loop-squares-anim var(--animation-duration) infinite;
}
.nc-loop-squares-48-icon-f > :nth-child(2) {
  animation-delay: 0.1s;
}
.nc-loop-squares-48-icon-f > :nth-child(3) {
  animation-delay: 0.2s;
}
.nc-loop-squares-48-icon-f > :nth-child(4) {
  animation-delay: 0.3s;
}
@keyframes nc-loop-squares-anim {
  0%,
  100%,
  80% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
