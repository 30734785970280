import routes from '@/constants/routes';

const mapWidthClasses = 'onx-col-12 lg:onx-col-6 xxl:onx-col-4 chart-height';

export const threeSixtyMapDefinitionsByPage = {
  [routes.ThreeSixtyBaseStationSiteMapper]: [
    {
      title: 'Cell Site Locations',
      endpoint: 'maps/sitemapper',
      chartContainerClass: 'onx-grid-item__full-width',
      id: 'site-mapper-map',
    },
  ],
  [routes.ThreeSixtyCoverageNetworkCoverage]: [
    {
      title: 'Coverage',
      endpoint: 'maps/coverage',
      chartContainerClass: 'sm:onx-col-5 md:onx-col-8 chart-height',
      id: 'coverage-map',
    },
  ],
  [routes.ThreeSixtyCoverageAvailability5G]: [
    {
      title: '5G Availability Performance',
      endpoint: 'maps/availability5g-performance',
      chartContainerClass: mapWidthClasses,
      id: '5g-availability5g-performance-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: '5G Availability Importance',
      endpoint: 'maps/availability5g-avail-importance',
      chartContainerClass: mapWidthClasses,
      id: '5g-availability5g-avail-importance-map',
    },
    {
      title: '5G Share %',
      endpoint: 'maps/availability5g-5g-share',
      chartContainerClass: mapWidthClasses,
      id: '5g-availability5g-5g-share-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: '4G Share %',
      endpoint: 'maps/availability5g-4g-share',
      chartContainerClass: mapWidthClasses,
      id: '5g-availability5g-4g-share-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: 'No Signal Share %',
      endpoint: 'maps/availability5g-nosignal-share',
      chartContainerClass: mapWidthClasses,
      id: '5g-availability5g-nosignal-share-map',
    },
  ],
  [routes.ThreeSixtyCoverageAvailability]: [
    {
      title: '4G Availability Performance',
      endpoint: 'maps/availability4g-performance',
      chartContainerClass: mapWidthClasses,
      id: '4g-availability4g-performance-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: '4G Availability Importance',
      endpoint: 'maps/availability4g-avail-importance',
      chartContainerClass: mapWidthClasses,
      id: '4g-availability4g-avail-importance-map',
    },
    {
      title: '4G Share %',
      endpoint: 'maps/availability4g-4g-share',
      chartContainerClass: mapWidthClasses,
      id: '4g-availability4g-4g-share-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: '3G Share %',
      endpoint: 'maps/availability4g-3g-share',
      chartContainerClass: mapWidthClasses,
      id: '4g-availability4g-3g-share-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
    {
      title: 'No Signal Share %',
      endpoint: 'maps/availability4g-nosignal-share',
      chartContainerClass: mapWidthClasses,
      id: '4g-maps/availability4g-nosignal-share-map',
      mapAttrs: {
        legendUnit: '%',
      },
    },
  ],
  [routes.ThreeSixtyBaseStationCellCongestion]: [
    {
      title: 'Categorized Cells (Downlink)',
      endpoint: 'maps/congestion',
      chartContainerClass: mapWidthClasses,
      id: 'congestion/congestion-map',
    },
  ],
} as {
  [route: string]: [
    {
      title: string;
      endpoint: string;
      chartContainerClass: string;
      id: string;
      mapAttrs?: {
        legendUnit?: string;
      };
    },
  ];
};
