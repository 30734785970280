import html2canvas from 'html2canvas';

import { Dashboards } from '@/constants/dashboards';

import onx360Logo from '@/assets/onx/logo/onx360.svg';
import onxfocusLogo from '@/assets/onx/logo/onxfocus.svg';
import onxspotlightLogo from '@/assets/onx/logo/onxspotlight.svg';

type ImageExportArgs = {
  title: string;
  filename: string;
  legend: {
    rank?: number;
    lci?: number;
    uci?: number;
    color: string;
    label: string;
    value: number | string | undefined;
  }[];
  legendTitle?: string;
  chartId: string;
  showConfidenceInterval?: boolean;
  showLegend?: boolean;
  subtitle?: string;
  info?: string;
};

const dashboardToLogoMap = {
  [Dashboards.Focus]: onxfocusLogo,
  [Dashboards.Spotlight]: onxspotlightLogo,
  [Dashboards.ThreeSixty]: onx360Logo,
  '360': onx360Logo,
};

const prepareHtml = (dashboard: Dashboards, showLegend: boolean) => {
  const rootEl = document.createElement('div');
  rootEl.classList.add('onx-image-export');

  // Header (title)
  const headerEl = document.createElement('h1');
  headerEl.classList.add('onx-image-export__title');

  // Subheader
  const subheaderEl = document.createElement('div');
  subheaderEl.classList.add('onx-image-export__subtitle-container');

  // Content (image + legend)
  const contentEl = document.createElement('div');
  contentEl.classList.add('onx-image-export__content');

  const imageContainerEl = document.createElement('div');
  imageContainerEl.classList.add('onx-image-export__content__image-container');

  const canvasImageEl = document.createElement('canvas');
  imageContainerEl.appendChild(canvasImageEl);

  contentEl.appendChild(imageContainerEl);

  let legendEl = null;
  let legendTitleEl = null;
  let legendItemContainerEl = null;

  if (showLegend) {
    legendEl = document.createElement('div');
    legendEl.classList.add('onx-image-export__content__legend');

    legendTitleEl = document.createElement('h2');
    legendTitleEl.classList.add('onx-image-export__content__legend__title');

    legendItemContainerEl = document.createElement('div');
    legendItemContainerEl.classList.add('onx-image-export__content__legend__item-container');

    legendEl.appendChild(legendTitleEl);
    legendEl.appendChild(legendItemContainerEl);

    contentEl.appendChild(legendEl);
  }

  // Add them together
  rootEl.appendChild(headerEl);
  rootEl.appendChild(subheaderEl);
  rootEl.appendChild(contentEl);

  return {
    rootEl,
    headerEl,
    legendEl,
    legendTitleEl,
    legendItemContainerEl,
    subheaderEl,
    canvasImageEl,
    contentEl,
  };
};

const useImageExport = (dashboard: Dashboards, showLegend = true) => {
  const exportImage = async (args: ImageExportArgs) => {
    const {
      chartId,
      filename,
      info,
      legend,
      legendTitle = 'Legend',
      showConfidenceInterval = true,
      subtitle,
      title,
    } = args;

    const { canvasImageEl, contentEl, headerEl, legendEl, legendItemContainerEl, legendTitleEl, rootEl, subheaderEl } =
      prepareHtml(dashboard, showLegend && legend.length > 0);

    headerEl.innerText = title;
    const canvasToDraw = document.getElementById(chartId) as HTMLCanvasElement;

    const canvasImageElCtx = canvasImageEl.getContext('2d') as CanvasRenderingContext2D;
    canvasImageEl.width = canvasToDraw.width;
    canvasImageEl.height = canvasToDraw.height;

    canvasImageElCtx.drawImage(canvasToDraw, 0, 0);

    if (legendEl && legendTitleEl && legendItemContainerEl) {
      legendTitleEl.innerText = legendTitle;

      legend.forEach(({ color, label, lci, uci, value }) => {
        const legendItemEl = document.createElement('div');
        legendItemEl.classList.add('onx-image-export__content__legend__item');

        const labelContainerEl = document.createElement('div');
        labelContainerEl.classList.add('onx-image-export__content__legend__item__label-container');

        const colorEl = document.createElement('span');
        colorEl.classList.add('onx-image-export__content__legend__item__label-container__color');
        colorEl.style.backgroundColor = color;

        const labelEl = document.createElement('span');
        labelEl.classList.add('onx-image-export__content__legend__item__label');
        labelEl.innerText = label;

        labelContainerEl.appendChild(colorEl);
        labelContainerEl.appendChild(labelEl);

        const valueContainerEl = document.createElement('div');
        valueContainerEl.classList.add('onx-image-export__content__legend__item__value-container');

        if (value != null) {
          const valueEl = document.createElement('span');
          valueEl.classList.add('onx-image-export__content__legend__item__value-container__value');

          valueEl.innerText = value.toString();
          valueContainerEl.appendChild(valueEl);

          if (showConfidenceInterval && lci && uci) {
            const confidenceIntervalEl = document.createElement('div');
            confidenceIntervalEl.classList.add(
              'onx-image-export__content__legend__item__value-container__confidence-interval',
            );

            const lciEl = document.createElement('span');
            lciEl.innerText = lci?.toString() || '';

            const uciEl = document.createElement('span');
            uciEl.innerText = uci?.toString() || '';

            confidenceIntervalEl.appendChild(lciEl);
            confidenceIntervalEl.appendChild(uciEl);

            valueContainerEl.appendChild(confidenceIntervalEl);
          }
        }

        legendItemEl.appendChild(labelContainerEl);
        legendItemEl.appendChild(valueContainerEl);

        legendItemContainerEl.appendChild(legendItemEl);
      });
    }

    if (subtitle) {
      const subtitleEl = document.createElement('span');
      subtitleEl.classList.add('onx-image-export__subtitle-container__subtitle');
      subtitleEl.innerText = subtitle;

      subheaderEl.appendChild(subtitleEl);
    }

    if (info) {
      const infoEl = document.createElement('span');
      infoEl.classList.add('onx-image-export__subtitle-container__info');
      infoEl.innerText = info;

      subheaderEl.appendChild(infoEl);
    }

    document.body.appendChild(rootEl);

    if (legendEl && legendItemContainerEl) {
      const longestLegendLabel = Array.from(legendEl?.children).reduce((acc, curr) => {
        const width = curr.clientWidth;
        return width > acc ? width : acc;
      }, 0);

      legendItemContainerEl.style.gridTemplateColumns = `repeat(auto-fill, minmax(${longestLegendLabel}px, 1fr))`;

      if (legendEl.clientHeight > canvasImageEl.clientHeight * 1.5) {
        contentEl.classList.add('onx-image-export__content--direction-column');
      }

      // Set the width of the left labels to be the same
      const legendItemLeftLabels = Array.from(legendItemContainerEl.children);
      const longestLegendLeftLabel = legendItemLeftLabels.reduce((acc, curr) => {
        const width = curr.children[0].clientWidth;
        return width > acc ? width : acc;
      }, 0);

      legendItemLeftLabels.forEach((legendItem) => {
        const item = legendItem.children[0] as HTMLSpanElement;
        item.style.width = `${longestLegendLeftLabel}px`;
      });
    }

    const scale = window.devicePixelRatio < 2 ? 2 : 1;
    html2canvas(rootEl, { scale }).then((canvas) => {
      const baseLogoHeight = 40;
      const baseLogoWidth = 150;
      const baseCopyrightFontSize = 12;
      const basePadding = 16;

      const logoHeight = baseLogoHeight * scale;
      const logoWidth = baseLogoWidth * scale;
      const padding = basePadding * scale;
      const footerCanvas = document.createElement('canvas');
      footerCanvas.width = canvas.width;
      footerCanvas.height = logoHeight + padding;

      const logo = new Image();
      logo.onload = () => {
        const footerCanvasCtx = footerCanvas.getContext('2d') as CanvasRenderingContext2D;
        footerCanvasCtx.fillStyle = 'white';
        footerCanvasCtx.fillRect(0, 0, footerCanvas.width, footerCanvas.height);

        footerCanvasCtx.drawImage(logo, padding, 0, logoWidth, logoHeight);

        const copyrightText = `© Copyright ${new Date().getFullYear()} Opensignal`;
        // add text to canvas from the right
        footerCanvasCtx.textAlign = 'right';
        footerCanvasCtx.textBaseline = 'middle';
        footerCanvasCtx.fillStyle = 'black';
        footerCanvasCtx.font = `${baseCopyrightFontSize * scale}px BeVietnamPro`;
        footerCanvasCtx.fillText(copyrightText, footerCanvas.width - padding, logoHeight / 2);

        const finalCanvas = document.createElement('canvas');
        finalCanvas.width = canvas.width;
        finalCanvas.height = canvas.height + footerCanvas.height;

        // draw images from previous canvases to the final canvas
        const ctx = finalCanvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.drawImage(canvas, 0, 0);
        ctx.drawImage(footerCanvas, 0, canvas.height);

        const link = document.createElement('a');
        link.download = filename;
        link.href = finalCanvas.toDataURL('image/png');
        link.click();
        document.body.removeChild(rootEl);
      };

      logo.src = dashboardToLogoMap[dashboard];
    });
  };

  return { exportImage };
};

export default useImageExport;
