<script setup>
import { computed, provide } from 'vue';

const props = defineProps({
  selectedTab: {
    type: [String, Number],
    required: true,
  },
});

const selectedTab = computed(() => props.selectedTab);

provide('tabs:selectedTab', selectedTab);
</script>

<template>
  <slot />
</template>
