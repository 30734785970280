<template>
  <div class="Ci">
    <div v-if="matches.laptop.value" class="Ci__sidebar">
      <OnxSidebar>
        <CompetitiveMenu />
      </OnxSidebar>
    </div>
    <div v-if="ready" class="Ci__main">
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CompetitiveMenu from '../../components/specialized/CompetitiveMenu.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSidebar from '@/components/onx/OnxSidebar';
import { LATEST_STRING, DEFAULT_USER_GROUP } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import router from '@/router';
import { Dashboards } from '@/constants/dashboards';

export default defineComponent({
  name: 'CompetitiveIntelligence',
  components: {
    CompetitiveMenu,
    OnxSidebar,
  },
  beforeRouteUpdate(to, from, next) {
    this.checkDisclaimer();

    if (from.name !== to.name && from.name === ROUTES.CompetitiveMap) {
      this.setRouteParam({
        network: 'all',
      });
    }

    if (from.name === to.name) {
      this.track({
        dashboard: 'competitive',
        params: to.params,
        url: to.path,
        route: to.name,
      });
    }

    next();
  },
  setup() {
    const matches = useBreakpoints();

    return { matches };
  },
  data() {
    return {
      ready: false,
      ROUTES,
      Dashboards,
    };
  },
  computed: {
    ...mapGetters(['dashboardInfo', 'user']),
    ...mapGetters({
      mainMetric: 'metrics/primaryMetric',
      userGroup: 'competitive/userGroup',
    }),
  },
  watch: {
    mainMetric() {
      this.setUserGroup(this.mainMetric?.subcategory || this.userGroup || DEFAULT_USER_GROUP);
    },
  },
  mounted() {
    if (!this.user || !this.user.id) {
      this.setUser().then((res) => res && this.checkDisclaimer());
    }

    if (router.currentRoute.value.path === '/competitive/' || router.currentRoute.value.path === '/competitive') {
      const countryId = this.dashboardInfo.default_country || this.dashboardInfo.countries_visible_full[0].id;
      this.setUserSettings('competitive')
        .then(() => {
          this.$router.replace({
            name: ROUTES.CompetitiveOverview,
            params: {
              date: LATEST_STRING,
              location: countryId,
              compareTo: '90days',
            },
          });
        })
        .then(() => {
          this.ready = true;
        });
    } else {
      this.setUserSettings('competitive').then(() => {
        this.ready = true;
      });
    }
  },
  methods: {
    ...mapActions([
      'setUser',
      'trackRoute',
      'track',
      'setUserSettings',
      'resetUser',
      'checkDisclaimer',
      'setRouteParam',
      'setUserGroup',
    ]),
  },
});
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';
@import 'scss/onx-breakpoints.module';

.Ci {
  width: 100vw;
  height: calc(100vh - var(--onx-navbar-height));
  display: flex;
  overflow: hidden;
}

.Ci__sidebar {
  @extend %sideBar;
  overflow: auto;
}

.Ci__main {
  z-index: 0;
  flex-grow: 1;
  overflow: auto;
  position: relative;
}
</style>
