<script setup>
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import CloseIcon from '@/components/onx/icons/CloseIcon';
import FiltersIcon from '@/components/onx/icons/FiltersIcon';

const matches = useBreakpoints();
</script>

<template>
  <VDropdown
    :distance="0"
    :popper-hide-triggers="null"
    :eager-mount="!matches.laptop.value"
    :auto-hide="false"
    strategy="fixed"
    popper-class="onx-spotlight-filters__dropdown"
  >
    <FiltersIcon class="onx-spotlight-filters" />

    <template #popper="{ hide }">
      <slot />
      <CloseIcon class="onx-spotlight-filters__close" @click="hide()" />
    </template>
  </VDropdown>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;

.onx-spotlight-filters.onx-icon {
  width: pxToRem(48);
  height: pxToRem(48);
  cursor: pointer;

  &:hover {
    background: var(--light);
  }
}

.onx-spotlight-filters__dropdown.v-popper--theme-dropdown {
  width: 100%;

  .v-popper__inner {
    border: 0;
    border-radius: 0;
  }
}

.onx-spotlight-filters__close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--white);
  cursor: pointer;
}
</style>
