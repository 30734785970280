<script setup>
import { computed, ref, watchEffect } from 'vue';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon';
import { OnxList, OnxListItem } from '@/components/onx/OnxList';
import OnxDropdown from '@/components/onx/OnxDropdown.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  placeholder: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  renderItem: {
    type: Function,
    default: (item) => {
      return item;
    },
  },
  hideDropdownIcon: {
    type: Boolean,
    default: false,
  },
  insetProp: {
    type: String,
    default: 'inset',
  },
  combobox: {
    type: Boolean,
    default: false,
  },
  searchBy: {
    type: String,
    default: 'name',
  },
  searchInputOverride: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(['update:modelValue']);

const onChange = (option) => {
  if (option === props.modelValue) {
    searchInput.value = props.modelValue[props.searchBy];
    return;
  }

  emit('update:modelValue', option);
};

const hasSelected = computed(() => {
  const { modelValue } = props;

  return !!modelValue;
});

const searchInput = ref('');
const searchInputElRef = ref(null);
const searchableOptions = computed(() => {
  if (!props.combobox || !searchInput.value) {
    return props.options;
  }

  return props.options.filter((option) => {
    return option[props.searchBy].toLowerCase().includes(searchInput.value.toLowerCase());
  });
});

const highlightFirstOptionByDefault = ref(true);

const onDropdownOpen = () => {
  searchInput.value = '';

  if (searchInputElRef.value) {
    searchInputElRef.value.focus();
  }
};

const onDropdownHide = () => {
  searchInput.value = props.modelValue ? props.modelValue[props.searchBy] : '';
};

const selectFirst = () => {
  if (!searchableOptions.value.length) {
    return;
  }

  onChange(searchableOptions.value[0]);
  searchInputElRef.value.blur();
};

const onKeyDown = (event, hideDropdown) => {
  if (event.key === 'Enter') {
    selectFirst();
    hideDropdown();
  }
};

const renderModelValue = (item) => {
  return props.renderItem(item);
};

watchEffect(() => {
  if (!props.combobox) {
    return;
  }

  if (!props.modelValue) {
    searchInput.value = '';
    return;
  }

  searchInput.value = props.modelValue[props.searchBy];
});

watchEffect(() => {
  if (typeof props.searchInputOverride === 'string') {
    searchInput.value = props.searchInputOverride;
  } else {
    searchInput.value = props.modelValue ? props.modelValue[props.searchBy] : '';
  }
});
</script>

<template>
  <OnxDropdown
    class="onx-location-dropdown"
    :class="{
      'onx-location-dropdown--combobox': combobox,
    }"
    @hide="onDropdownHide"
    @click="onDropdownOpen"
  >
    <template #default="{ hide }">
      <div class="onx-location-dropdown__header">
        <span v-if="$slots['icon']" class="onx-location-dropdown__headerIcon">
          <slot name="icon" />
        </span>

        <slot>
          <input
            v-if="combobox"
            v-model="searchInput"
            class="onx-location-dropdown__headerInput"
            :placeholder="placeholder"
            ref="searchInputElRef"
            @keydown="
              (event) => {
                onKeyDown(event, hide);
              }
            "
          />
          <span
            v-else
            class="onx-location-dropdown__headerLabel"
            :class="{ 'onx-location-dropdown__headerLabel--selected': hasSelected }"
          >
            {{ modelValue ? renderModelValue(modelValue) : placeholder }}
          </span>
        </slot>

        <span v-if="!hideDropdownIcon" class="onx-location-dropdown__ripple">
          <ChevronDownIcon />
        </span>
      </div>
    </template>

    <template #content>
      <OnxList @mouseenter="highlightFirstOptionByDefault = false" @mouseleave="highlightFirstOptionByDefault = true">
        <OnxListItem
          v-for="(option, index) in searchableOptions"
          :key="renderItem(option)"
          @click="() => onChange(option)"
          :inset="option[insetProp]"
          :class="{ 'onx-location-dropdown__item--highlighted': index === 0 && highlightFirstOptionByDefault }"
        >
          {{ renderItem(option) }}
        </OnxListItem>
      </OnxList>
    </template>
  </OnxDropdown>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;

.onx-location-dropdown {
  height: 32px;
  padding: 4px 8px;
  margin: 0 -8px;
  border-radius: 4px;

  &--combobox:hover {
    // --charcoal-500, but Figma had it at 30% opacity
    background-color: rgba(32, 39, 41, 0.3);
  }

  &:focus-within {
    background-color: rgba(32, 39, 41, 0.3);
  }
}

.onx-location-dropdown__header {
  color: var(--white);
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}

.onx-location-dropdown__headerIcon {
  margin-right: 8px;
  height: 24px;

  .onx-icon,
  .onx-operator-avatar {
    width: 16px;
    margin: 0;
  }
}

.onx-location-dropdown__headerLabel,
.onx-location-dropdown__headerInput {
  font-size: $font-size-12;
  color: var(--charcoal-200);
  width: 100%;
}

.onx-location-dropdown__headerInput {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
  color: var(--white);

  &::placeholder {
    color: var(--charcoal-200);
    opacity: 1;
  }
}

.onx-location-dropdown__headerLabel--selected {
  color: var(--white);
}

.onx-location-dropdown__ripple {
  transition: transition();

  .v-popper--shown & {
    transform: rotate(-180deg);
  }
}

.onx-location-dropdown__item--highlighted {
  background: var(--light);
}
</style>
