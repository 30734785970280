import exportDatasetToCsv from '@/utils/exportDatasetToCsv';

export const exportCoverageSubcategoriesGeohashCountsToCsv = exportDatasetToCsv({
  headers: ['Coverage Sub-Category', 'Operator', 'Date', 'Geohash Count'],
  getRow: (datum, dataset, date) => [dataset.label, datum[dataset.labelAxis], date, datum[dataset.dataAxis]],
});

export const exportCoverageCategoriesGeohashCountsToCsv = exportDatasetToCsv({
  headers: ['Coverage Category', 'Operator', 'Date', 'Geohash Count'],
  getRow: (datum, dataset, date) => [datum[dataset.labelAxis], dataset.label, date, datum[dataset.dataAxis]],
});

export const exportIndividualCoverageCategoryGeohashCountsToCsv = exportDatasetToCsv({
  headers: ['Coverage Category', 'Operator', 'Date', 'Geohash Count', 'Geohash Percentage'],
  getRow: (datum, dataset, date) => [
    dataset.label,
    datum[dataset.labelAxis],
    date,
    datum.count,
    datum[dataset.dataAxis].toFixed(2),
  ],
});
