<template lang="html">
  <div class="TooltipWrapper">
    <slot />
    <div class="TooltipWrapper__tooltip">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TooltipWrapper',
  props: {
    title: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import '@material/elevation';
@import 'scss/components';
.TooltipWrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2rem;
  line-height: 2rem;
  &:hover {
    .TooltipWrapper__tooltip {
      display: block;
    }
  }
  &__tooltip {
    @extend %titleTooltip;
    @include elevation($elevation-2);
    display: none;
    right: auto;
    top: -2px;
    right: 0;
    height: auto;
    transform: translate(0, -100%);
  }
}
</style>
