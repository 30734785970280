import { type AxiosRequestConfig } from 'axios';

import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import { Column } from '@/components/onx/table';

export type MetricDefinitionWithSingleSubtype = {
  metricSubtype: MetricSubtypes;
  metricSubtypes?: never;
};

export type MetricDefinitionWithMultipleSubtypes = {
  metricSubtypes: MetricSubtypes[];
  metricSubtype?: never;
};

type ChartMetricDefinitionBase = {
  structure?: MetricStructuresEnum;
  label?: string;
  connectionCategory?: string;
  id?: string;
  chartGroup?: MetricSubtypes;
  chartAttrs?: Record<string, unknown> & {
    chartTitlePlaceholders?: string;

    /** Renders a tooltip icon beside the title header of a chart */
    chartTitleTooltip?: string;
  };
  chartContainerClass?: string;
  transformData?: (data: any) => any;
  tooltipPlugin?: (context: any, tooltip: any, chartData: any) => void;
  onExportCsv?: (...args: any[]) => any;
  exportAttrs?: Record<string, unknown>;
  requestParams?: AxiosRequestConfig['params'];
};

type OtherChartTypes = Exclude<ChartTypesEnum, ChartTypesEnum.Table>;

type OtherChartMetricDefinition = {
  chartType: OtherChartTypes;
};

type TableChartMetricDefinition = {
  chartType: ChartTypesEnum.Table;
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: 'asc' | 'desc';
};

type MultiMetricTableChartMetricDefinition = MetricDefinitionWithMultipleSubtypes & {
  chartType: ChartTypesEnum.MultiMetricTable;
  columns: Column<any>[];
};

export type DatasetChartMetricDefinition = ChartMetricDefinitionBase & {
  chartType: ChartTypesEnum.Dataset;
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: 'asc' | 'desc';
  dataset: string;

  chartAttrs: {
    chartTitlePlaceholders: string;

    /** Renders a tooltip icon beside the title header of a chart */
    chartTitleTooltip?: string;
  };
};

export type ChartMetricDefinitionByChartTypes = ChartMetricDefinitionBase &
  (
    | OtherChartMetricDefinition
    | TableChartMetricDefinition
    | MultiMetricTableChartMetricDefinition
    | DatasetChartMetricDefinition
  );
export type ChartMetricDefinitionWithSingleSubtype = ChartMetricDefinitionByChartTypes &
  MetricDefinitionWithSingleSubtype;
export type ChartMetricDefinitionWithMultipleSubtypes = ChartMetricDefinitionByChartTypes &
  MetricDefinitionWithMultipleSubtypes;
export type ChartMetricDefinition = ChartMetricDefinitionWithSingleSubtype | ChartMetricDefinitionWithMultipleSubtypes;

export type ChartMetricDefWithConnectionCategory = ChartMetricDefinition & {
  connectionCategory: string;
};

export const isChartMetricDefinitionWithSingleSubtype = (
  definition: ChartMetricDefinition,
): definition is ChartMetricDefinitionWithSingleSubtype => {
  return 'metricSubtype' in definition;
};

export const isTableChartMetricDefinition = (
  definition: ChartMetricDefinitionByChartTypes,
): definition is ChartMetricDefinitionBase & TableChartMetricDefinition => {
  return definition.chartType === ChartTypesEnum.Table;
};

export const isMultiMetricTableChartMetricDefinition = (
  definition: ChartMetricDefinitionByChartTypes,
): definition is ChartMetricDefinitionBase & MultiMetricTableChartMetricDefinition => {
  return definition.chartType === ChartTypesEnum.MultiMetricTable;
};

export const isGaugeChartMetricDefinition = (
  definition: ChartMetricDefinitionByChartTypes,
): definition is ChartMetricDefinitionWithSingleSubtype => {
  return definition.chartType === ChartTypesEnum.Gauge;
};

export const isDatasetChartMetricDefinition = (
  definition: ChartMetricDefinitionByChartTypes,
): definition is ChartMetricDefinitionBase & DatasetChartMetricDefinition => {
  return definition.chartType === ChartTypesEnum.Dataset;
};
