import { ChartTypesEnum } from '@/types/Charts';

export enum ChartTypeGroups {
  TREND = 'trend',
  DISTRIBUTION = 'distribution',
  BY_CDN = 'by-cdn',
  BY_CDN_IP = 'by-cdn-ip',
  BY_OPERATOR = 'by-operator',
  THRESHOLD = 'threshold',
  FAILURE = 'failure',
  SUBCATEGORIES_GEOHASH_COUNT = 'subcategories-geohash-count',
  HOURLY = 'hourly',

  // This isn't actually present in Focus QOE Details, but it's here for type safety.
  // One day we may add datasets to Focus.
  DATASET = 'dataset',
}

export const chartTypeGroupLabels: { [key in ChartTypeGroups]: string } = {
  [ChartTypeGroups.TREND]: 'Trend',
  [ChartTypeGroups.DISTRIBUTION]: 'Distribution',
  [ChartTypeGroups.BY_CDN]: 'By CDN',
  [ChartTypeGroups.BY_CDN_IP]: 'By CDN IP',
  [ChartTypeGroups.BY_OPERATOR]: 'By Operator',
  [ChartTypeGroups.THRESHOLD]: 'Thresholds',
  [ChartTypeGroups.FAILURE]: 'Failures',
  [ChartTypeGroups.SUBCATEGORIES_GEOHASH_COUNT]: 'Subcategories Geohash Count',
  [ChartTypeGroups.DATASET]: 'Dataset',
  [ChartTypeGroups.HOURLY]: 'Hourly',
};

export const chartTypeGroupingMap = {
  [ChartTypesEnum.Trend]: ChartTypeGroups.TREND,
  [ChartTypesEnum.CdnTrend]: ChartTypeGroups.TREND,
  [ChartTypesEnum.Distribution]: ChartTypeGroups.DISTRIBUTION,
  [ChartTypesEnum.CdnBinned]: ChartTypeGroups.DISTRIBUTION,
  [ChartTypesEnum.CdnBar]: ChartTypeGroups.BY_CDN,
  [ChartTypesEnum.CdnIpStackedBar]: ChartTypeGroups.BY_CDN_IP,
  [ChartTypesEnum.VideoResStackedBar]: ChartTypeGroups.BY_OPERATOR,
  [ChartTypesEnum.OperatorBar]: ChartTypeGroups.BY_OPERATOR,
  [ChartTypesEnum.ThresholdTrend]: ChartTypeGroups.THRESHOLD,
  [ChartTypesEnum.FailureStackedBar]: ChartTypeGroups.FAILURE,
  [ChartTypesEnum.BarChart]: ChartTypeGroups.SUBCATEGORIES_GEOHASH_COUNT,
  [ChartTypesEnum.Dataset]: ChartTypeGroups.DATASET,
  [ChartTypesEnum.HourlyTrend]: ChartTypeGroups.HOURLY,
};
