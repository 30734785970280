import { exportToCsv } from '@/utils/files';

const exportDataToCsv =
  ({ getRow, headers }: { headers: string[]; getRow: (datum: any, dataset: any, date: string) => any[] }) =>
  (data: { labels: string[]; datasets: any[] }, title: string) => {
    const rows = data.datasets.flatMap((dataset: any) => {
      return dataset.data.map((datum: any) => {
        const date = datum.date.split('T')[0];
        return getRow(datum, dataset, date);
      });
    });

    exportToCsv(`${title}.csv`, [headers, ...rows]);
  };

export const exportEcqCcqSubmetricToCsv = ({ metricColumnName }: { metricColumnName: string }) =>
  exportDataToCsv({
    headers: ['Operator', 'Date', metricColumnName],
    getRow: (datum, dataset, date) => [datum[dataset.labelAxis], date, datum[dataset.dataAxis]],
  });
