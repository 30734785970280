import { computed, Ref, isRef } from 'vue';
import type { AxiosResponse } from 'axios';

import { MaybeRef } from '@/types/helpers/RefLike';
import { MetricResponse } from '@/types/MetricResponse';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import getAvailableCDNsFromMetricResponse from '@/utils/getAvailableCDNsFromMetricResponse';
import { getMetricCdns } from '@/utils/metrics';

const useAvailableCDNsByMetricStructure = <Structure extends MetricStructuresEnum>(
  metric: MaybeRef<string>,
  response: Ref<AxiosResponse<MetricResponse<Structure>>> | Ref<undefined>,
  status: Ref<'success'> | Ref<'error'> | Ref<'pending'>,
) => {
  const availableCDNs = computed(() => {
    const unreffedMetric = isRef(metric) ? metric.value : metric;
    const defaultCDNs = getMetricCdns(unreffedMetric).sort() || [];

    if (status.value === 'pending') {
      return defaultCDNs;
    }

    if (status.value === 'error') {
      return defaultCDNs;
    }

    const data = response.value?.data;
    if (!data || data.results.length === 0) {
      return defaultCDNs;
    }

    return getAvailableCDNsFromMetricResponse(data);
  });

  return {
    availableCDNs,
  };
};

export default useAvailableCDNsByMetricStructure;
