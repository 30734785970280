<script setup lang="ts">
import CloseIcon from './onx/icons/CloseIcon.vue';

const props = defineProps(['open']);
const emit = defineEmits(['update:open']);

const handleClose = () => {
  if (props.open) {
    emit('update:open', false);
  }
};
</script>

<template>
  <Teleport to="body">
    <div :class="'modal-background' + (props.open ? ' open' : '')" @click="handleClose">
      <div class="modal-outer" v-if="props.open" @click.stop>
        <div class="modal-inner">
          <slot></slot>
        </div>
        <button class="close-button" @click="handleClose"><CloseIcon /></button>
      </div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.modal-background {
  height: 100vh;
  width: 100%;
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0);
  pointer-events: none;
  transition: background-color 0.3s ease;

  &.open {
    z-index: 999;
    pointer-events: auto;
    background-color: rgba(128, 128, 128, 0.5);
  }
}
.modal-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
}
.modal-inner {
  padding: 10px 20px;
}
.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
}
</style>
