<template>
  <div class="onx-sidebar">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.onx-sidebar {
  background-color: var(--onx-sidebar-background);
  min-height: 100%;
  width: 100%;
  padding: 16px;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: relative;
  height: calc(100vh - var(--onx-navbar-height));
  overflow: auto;
}
</style>
