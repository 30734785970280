export enum Dashboards {
  Focus = 'focus',
  Spotlight = 'competitive',
  ThreeSixty = 'onx360',
}

export enum DashboardTopics {
  COVERAGE = 'coverage',
  OVERSHOOTING = 'overshooting',
  CONGESTION = 'congestion',
}
