import { Ref, onScopeDispose, ref, watch } from 'vue';

const useIntersectionObserver = (el: Ref<HTMLElement | null>) => {
  const visible = ref(false);

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        visible.value = entry.isIntersecting;

        if (visible.value) {
          observer.disconnect();
        }
      });
    },
    {
      rootMargin: '0px',
      threshold: 0.5,
    },
  );

  watch(el, (value) => {
    if (value) {
      observer.observe(value);
    } else {
      observer.disconnect();
    }
  });

  onScopeDispose(() => {
    observer.disconnect();
  });

  return {
    visible,
  };
};

export default useIntersectionObserver;
