import { computed, MaybeRef, unref } from 'vue';

import { METRIC_TYPES } from '@/constants/constants';
import { type ChartConnectionCategory } from '@/composables/useSelectableChartConnectionCategories';

const useIs5GSelected = (maybeRefConnectionCategoryList: MaybeRef<ChartConnectionCategory[]>) => {
  return computed(() => {
    const list = unref(maybeRefConnectionCategoryList);
    const fiveGCategory = list.find((category) => category.categoryValue === METRIC_TYPES.FiveG);
    return fiveGCategory?.selected;
  });
};

export default useIs5GSelected;
