import { useGetters, useActions } from 'vuex-composition-helpers';

const useUser = () => {
  const { user } = useGetters(['user']);
  const { resetUser, setUser, setUserSettings } = useActions(['setUser', 'resetUser', 'setUserSettings']);

  return {
    user,
    setUser,
    resetUser,
    setUserSettings,
  };
};

export default useUser;
