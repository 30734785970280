<script setup lang="ts">
import OnxPaper from '@/components/onx/OnxPaper.vue';

type Props = {
  title?: string;
};

const props = defineProps<Props>();
</script>

<template>
  <OnxPaper class="onx-selector-block__container">
    <div class="onx-selector-block__title">
      <slot name="title">
        {{ props.title }}
      </slot>
    </div>
    <div class="onx-selector-block__selectors">
      <slot />
    </div>
  </OnxPaper>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';
.onx-selector-block__container {
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 8px;
  margin-bottom: 8px;

  @include laptop() {
    padding: 0 16px;
    margin-bottom: 16px;
  }
}

.onx-selector-block__title {
  font-size: 14px;
}

.onx-selector-block__selectors {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
