<template>
  <div ref="CompetitiveOverview" class="CompetitiveOverviewLayout">
    <div class="CompetitiveOverviewLayout__header">
      <slot name="header" :html-image-export-data="{ exportHeight: exportHeight, spotlightParent: spotlightParent }" />
    </div>
    <div class="onx-grid fluid">
      <slot name="competitiveTiles" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CompetitiveOverviewLayout',
  props: {
    // We can't get this number any other way because $slots isn't reactive
    numberOfTiles: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      spotlightParent: {
        ref: null,
      },
    };
  },
  computed: {
    ...mapGetters(['operators']),
  },
  mounted() {
    this.spotlightParent.ref = this.$refs.CompetitiveOverview;
  },
  updated() {
    this.spotlightParent.ref = this.$refs.CompetitiveOverview;
  },
  methods: {
    exportHeight() {
      // I would just get the ref's height here
      // return this.$refs.CompetitiveOverview.clientHeight;
      // but we change the layout to one with 3 columns
      // Instead, each element has about 450 pixels height

      const noTiles = this.numberOfTiles;
      const noRows = Math.ceil(noTiles / 3);

      const HEADER = 180 + 50;
      const ELEMENTS = noRows * 450;
      return HEADER + ELEMENTS;
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;
@import 'scss/onx-breakpoints.module';

.CompetitiveOverviewLayout {
  .onx-grid {
    padding: 0 8px;

    @include tablet {
      padding: 0 16px;
    }
  }

  padding-top: 8px;

  &__export {
    position: absolute;
    right: 45px;
    top: 65px;
  }
}
</style>
