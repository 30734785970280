<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM4.49078 7.18028L9.21053 2.46053C9.35198 2.32392 9.54144 2.24832 9.73808 2.25003C9.93473 2.25174 10.1228 2.33061 10.2619 2.46967C10.401 2.60873 10.4798 2.79684 10.4815 2.99348C10.4832 3.19013 10.4077 3.37958 10.271 3.52103L5.02103 8.77103C4.88039 8.91164 4.68966 8.99062 4.49078 8.99062C4.29191 8.99062 4.10118 8.91164 3.96053 8.77103L1.71053 6.52103C1.57392 6.37958 1.49832 6.19013 1.50003 5.99348C1.50174 5.79684 1.58061 5.60873 1.71967 5.46967C1.85873 5.33061 2.04684 5.25174 2.24348 5.25003C2.44013 5.24832 2.62958 5.32392 2.77103 5.46053L4.49078 7.18028Z"
      fill="currentColor"
    />
  </svg>
</template>
