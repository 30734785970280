<template>
  <div class="CompetitiveDetailsLayout">
    <slot name="header" />

    <div class="CompetitiveDetailsLayout__main">
      <div class="CompetitiveDetailsLayout__charts">
        <slot name="map" />
        <slot name="charts" />
      </div>

      <div class="CompetitiveDetailsLayout__operators">
        <slot name="operators" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.CompetitiveDetailsLayout {
  padding: 8px 0;
}

.CompetitiveDetailsLayout__main {
  margin-top: -8px;

  @include tablet {
    margin-top: -16px;
  }

  .onx-grid {
    padding: 0 8px;

    @include tablet {
      padding: 0 16px;
    }
  }
}
</style>
