import Base from './base';

export class MarketGrowth extends Base {
  constructor(token) {
    super();
    this.token = `Bearer ${token}`;
  }

  getStores({ brandId, competitorId, dashboard = 'market-growth' }) {
    return this.get(`${this.v2}${dashboard}/store_locations/${brandId}/${competitorId}/`, this.token);
  }
}
