import { computed } from 'vue';

import type { Operator } from '@/types/Operator';
import type { Dashboards } from '@/constants/dashboards';
import useFilters from '@/composables/useFilters';
import useNetworkOperatorsWithHomeNetwork from '@/composables/useNetworkOperatorsWithHomeNetwork';
import useAnalytics from '@/composables/useAnalytics';

const useMapOperatorSelector = (dashboard: Dashboards) => {
  const { locationId, mapOperator, setFilters } = useFilters(dashboard);
  const networkOperators = useNetworkOperatorsWithHomeNetwork(dashboard, locationId);
  const { track } = useAnalytics();

  const selectedMapOperator = computed({
    get: () => {
      const selectedOperator = networkOperators.value.find(
        (operator) => operator.canonical_network_id === parseInt(mapOperator.value, 10),
      );

      if (!selectedOperator) {
        return networkOperators.value[0];
      }

      return selectedOperator;
    },
    set: (operator: any) => {
      setFilters({ mapOperator: operator.canonical_network_id });
    },
  });

  const computedMapOperatorCanonicalNetworkId = computed(() => {
    return selectedMapOperator.value?.canonical_network_id;
  });

  const onMapOperatorChange = (newMapOperator: Operator) => {
    selectedMapOperator.value = newMapOperator;
    track('map operator selector change', {
      operatorName: newMapOperator.name_mapped,
    });
  };

  return { selectedMapOperator, onMapOperatorChange, networkOperators, computedMapOperatorCanonicalNetworkId };
};

export default useMapOperatorSelector;
