<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { FocusHeader } from '@/focus/focus-header';
import ROUTES from '@/constants/routes';
import { OnxTab, OnxTabs, OnxTabsContext } from '@/components/onx/tabs';
import useQoESelectableMetricTabs from '@/focus/composables/useQoESelectableMetricTabs';
import useAnalytics from '@/composables/useAnalytics';
import { MetricSubtypes } from '@/types/MetricSubtypes';

const route = useRoute();
const router = useRouter();
const { track } = useAnalytics();

const selectedTab = computed(() => {
  return route.query.metricSubtype;
});

const onTabChange = async (id: string) => {
  if (id === selectedTab.value) {
    return;
  }

  // deploymentType is not available for reliability, so it is removed
  // it would otherwise affect the charts without a way to change deploymentType
  const deploymentType = id === MetricSubtypes.ReliabilitySessionRelability ? undefined : route.query.deploymentType;

  await router.push({
    name: ROUTES.FocusQoEDetails,
    query: {
      ...route.query,
      metricSubtype: id,
      deploymentType,
      // Unassign qoeCharts and mapMetric so, for example, Games does not end up with selections from Video
      // This will force the qoe chart and map selectors to use defaults for the new metric category
      qoeCharts: undefined,
      mapMetric: undefined,
    },
  });

  track('focus qoe details page view', {
    selectedMetric: id,
  });
};

const { qoeSelectableMetricTabs } = useQoESelectableMetricTabs();
</script>

<template>
  <FocusHeader>
    <template #tabs>
      <OnxTabsContext :selected-tab="selectedTab">
        <OnxTabs @change="onTabChange">
          <OnxTab v-for="[mectricSubtype, name] in qoeSelectableMetricTabs" :id="mectricSubtype" :key="mectricSubtype">
            {{ name }}
          </OnxTab>
        </OnxTabs>
      </OnxTabsContext>
    </template>
  </FocusHeader>
</template>
