<template>
  <div class="CustomTooltip">
    <div
      ref="tooltipDirective"
      v-click-outside="close"
      v-tooltip="{
        content: message,
        html: true,
        triggers: [!matches.laptop.value ? 'click' : 'hover'],
        placement,
        distance,
        delay: {
          show: 0,
          hide: 0,
        },
        hideTriggers: [!matches.laptop.value ? 'click' : 'hover'],
        popperClass: 'tooltip',
        autoHide: true,
      }"
    >
      <slot>
        <InfoIcon />
      </slot>
    </div>
  </div>
</template>

<script>
import InfoIcon from '../onx/icons/InfoIcon.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';

export default {
  name: 'CustomTooltip',
  props: {
    message: { type: String, required: true },
    distance: { type: Number, default: 16 },
    placement: { type: String, default: 'right' },
  },
  setup() {
    const matches = useBreakpoints();
    return { matches };
  },
  methods: {
    close() {
      this.$refs.tooltipDirective?._tooltip?.hide();
    },
  },
  components: { InfoIcon },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import '@material/elevation';

$background-color: $color-blue-bg-dark;
$font-color: $color-white;
$padding: $padding-medium;
$padding-alt: 5px;
$box-shadow: 0 $padding-alt $padding-alt * 2 rgba(black, 0.1);

.CustomTooltip {
  position: relative;
  display: inline-block;
}

.tooltip.v-popper--theme-tooltip {
  display: block !important;
  // z-index: $z-index-tooltip;

  // @media screen and (max-width: $ci-breakpoint-tablet) {
  //   z-index: $z-index-tooltip-mobile;
  // }

  .v-popper__inner {
    @include elevation($elevation-2);
    background: $background-color;
    color: $color-white;
    border-radius: $border-radius-medium;
    padding: 8px;
    max-width: pxToRem(252);
    font-size: $font-size-12;
    line-height: $font-size-14;
  }

  .v-popper__arrow-outer {
    border-color: $background-color;
  }
}

.list-styled {
  list-style: initial;
  padding: 0 0 8px 16px;

  li {
    margin-bottom: 8px;
  }
}
</style>
