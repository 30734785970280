<script setup>
import { computed } from 'vue';
import LoaderGrid from '@/components/LoaderGrid';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon';
import OnxButton from '@/components/onx/OnxButton';

const props = defineProps({
  name: {
    type: String,
    default: '',
    required: true,
  },
  description: {
    type: String,
    default: '',
    required: true,
  },
  enabled: {
    type: Boolean,
    default: true,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  actionsDisabled: {
    type: Boolean,
    default: false,
  },
  logo: {
    type: String,
    required: true,
  },
});

const productImageClass = computed(() => ['product', 'product__image', `product--${props.name}__image`]);
</script>

<template>
  <div class="product">
    <div :class="productImageClass" />
    <div class="product__info">
      <div class="product__logo">
        <img :src="logo" :alt="props.name" />
      </div>
      <p class="product__description">
        {{ props.description }}
      </p>
      <div class="product__status">
        <OnxButton
          variant="tertiary"
          size="sm"
          class="metrics-button"
          :disabled="props.actionsDisabled || props.loading"
        >
          <span v-if="props.enabled != null">
            {{ props.enabled ? 'Launch' : 'Contact Sales' }}
          </span>
          <ChevronRightIcon v-if="props.enabled" small />
        </OnxButton>
      </div>
    </div>

    <template v-if="loading">
      <LoaderGrid centered overlay />
    </template>
  </div>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;

.product {
  min-width: 288px;
  background-color: white;
  padding-bottom: 32px;
  cursor: pointer;
  position: relative;

  &__image {
    opacity: 0.9;
    width: 100%;
    height: 100px;
  }
  &__info {
    padding-left: 32px;
    padding-right: 32px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__logo {
    text-align: center;
    & img {
      height: 32px;
    }
  }
  &__description {
    padding-top: 32px;
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-roboto-regular;
    font-size: $font-size-12;
    line-height: 150%;

    /* or 18px */
    display: flex;
    align-items: center;
    text-align: center;

    /* Charcoal/400 */
    color: var(--charcoal-400);
  }
  &__status {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }

  &--spotlight__image {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 80%),
      url('@/assets/onx/products/onxspotlight.png'), center;
    background-size: cover;
  }
  &--focus__image {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 80%),
      url('@/assets/onx/products/onxfocus.png'), center;
    background-size: cover;
  }
  &--360__image {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 80%), url('@/assets/onx/products/onx360.png'),
      center;
    background-size: cover;
  }
  &--data__image {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 80%),
      url('@/assets/onx/products/onxdata.png'), center;
    background-size: cover;
  }
}
</style>
