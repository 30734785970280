import { createStore } from 'vuex';
import actions from './actions';
import * as m from './arrayModules';
import auth from './modules/auth';
import charts from './modules/charts';
import competitive from './modules/competitive';
import dashboard from './modules/dashboard';
import location from './modules/location';
import marketGrowth from './modules/marketGrowth';
import metrics from './modules/metrics';
import page from './page';
import * as c from './treeModules';

// Create the store
const store = createStore({
  actions: actions,
  modules: {
    dashboard,
    competitive,
    auth,
    charts,
    location,
    metrics,
    marketGrowth,
    page,
    ...m,
    ...c,
  },
  plugins: [page.plugins[0]],
});

export default store;
