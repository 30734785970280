<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  labelRight: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const classObject = computed(() => {
  return {
    'onx-toggle--disabled': props.disabled,
  };
});

const onChange = (e) => {
  emit('update:modelValue', e.target.checked);
};
</script>

<template>
  <label class="onx-toggle" :class="classObject">
    <span v-if="label" class="onx-toggle__label">
      {{ label }}
    </span>

    <input type="checkbox" :checked="modelValue" :disabled="disabled" @input="onChange" />
    <span class="onx-toggle__slider" />

    <span v-if="labelRight" class="onx-toggle__label onx-toggle__label-right">
      {{ labelRight }}
    </span>
  </label>
</template>

<style lang="scss">
@use 'scss/mixins';

.onx-toggle {
  --offset: 2px;
  --diameter: 12px;
  cursor: pointer;

  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: mixins.pxToRem(12);

  &__label {
    margin-right: 4px;
  }

  &__label-right {
    margin-left: 4px;
  }

  &--disabled {
    pointer-events: none;

    .onx-toggle__slider {
      background-color: var(--onx-toggle-disabled-bg-color) !important;
    }

    .onx-toggle__label {
      color: var(--onx-toggle-disabled-label-color) !important;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:not(:checked) {
      + .onx-toggle__slider {
        background-color: var(--onx-toggle-off-bg-color);
      }

      &:hover + .onx-toggle__slider {
        background-color: var(--onx-toggle-off-hover-bg-color);
      }

      &:focus + .onx-toggle__slider {
        background-color: var(--onx-toggle-off-focus-bg-color);
      }
    }

    &:checked {
      + .onx-toggle__slider {
        background-color: var(--onx-toggle-on-bg-color);

        // This moves the handle
        &:before {
          transform: translate(8px, -50%);
        }
      }

      &:hover + .onx-toggle__slider {
        background-color: var(--onx-toggle-on-hover-bg-color);
      }

      &:focus + .onx-toggle__slider {
        background-color: var(--onx-toggle-on-focus-bg-color);
      }
    }
  }

  &__slider {
    position: relative;
    width: calc(var(--diameter) * 2);
    height: calc(var(--diameter) + var(--offset) * 2);
    border-radius: 2px;
    cursor: pointer;
    transition: 300ms;
    left: -2px; // the input, in spite of width: 0, has a width of 4. This centers the slide between the labels

    &:before {
      position: absolute;
      content: '';
      height: var(--diameter);
      width: var(--diameter);
      background-color: var(--onx-toggle-handle-bg-color);
      top: 50%;
      left: var(--offset);
      transform: translate(0, -50%);
      transition: 300ms;
      border-radius: 2px;
    }
  }
}
</style>
