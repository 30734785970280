import { getTooltipState } from '@/utils/charts';

type ValueFn = (args: { value: any }) => number | string;

const simpleStructureSubmetricByOperatorTooltip =
  ({ horizontal = false, valueFn }: { horizontal?: boolean; valueFn?: ValueFn }) =>
  (context: any, tooltip: any, chartData: any) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const tt = context.tooltip;
    const tooltipState = getTooltipState(context.chart, tt);

    const currentDataset = chartData.value.datasets[chartData.value.labels.indexOf(tt.title[0])];

    tooltip.value = {
      ...tooltip.value,
      ...tooltipState,
      datapoints: [
        {
          ...currentDataset,
          value: valueFn ? valueFn({ value: currentDataset.data[0][dataAxis] }) : currentDataset.data[0][dataAxis],
        },
      ],
      title: tt.title[0],
    };
  };

export default simpleStructureSubmetricByOperatorTooltip;
