import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';
import { DateIntervalEnum } from '@/types/MetricRequestParameters';

import transformSimpleStructureSubmetricsByOperator from '@/chart-metric-definitions/data-transformers/transformSimpleStructureSubmetricsByOperator';
import simpleStructureSubmetricByOperatorTooltip from '@/chart-metric-definitions/tooltips/simpleStructureSubmetricByOperatorTooltip';
import { exportEcqCcqSubmetricToCsv } from '@/focus/csv-export-overrides/exportEcqCcqSubmetricsToCsv';
import {
  ecqCcqUploadColumns,
  ecqCcqIcmpLatencyColumns,
  ecqCcqDownloadThroughputColumns,
  ecqCcqPacketLossDiscardTestsColumns,
  ecqCcqJitterLatencyColumns,
  ecqCcqColumns,
} from '@/360/core/table-columns/ecqCcqColumns';

import transformCdnIp360StackPerOperator from '@/chart-metric-definitions/data-transformers/transformCdnIp360StackPerOperator';
import exportCdnIpTestServersToCsv from '@/360/csv-export-overrides/exportCdnIpTestServersToCsv';
import coreCdnIpSpeedTooltipPlugin from '@/chart-metric-definitions/tooltips/coreCdnIpSpeedTooltip';
import { commonDatasetTransformer } from '@/360/core/table-columns/commonDatasetTransformer';

const testServersChartAttrs = {
  chartConfig: {
    options: {
      scales: {
        x: {
          bounds: 'ticks',
          type: 'time',
          time: {
            unit: 'week',
            isoWeekday: true,
            displayFormats: {
              month: 'LLL d',
            },
            round: 'day',
          },
        },
      },
    },
  },
  chartTooltipShowSecondaryValue: true,
  max: 100,
  dateInterval: DateIntervalEnum.Week,
};

const testServersTooltipPlugin = coreCdnIpSpeedTooltipPlugin({
  valueFn: ({ dataAxis, datapoint }) =>
    datapoint.dataset.data.find((point: any) => point.date === datapoint.raw.date)[dataAxis] + '%',
  secondaryValueFn: ({ datapoint }) =>
    datapoint.dataset.data.find((point: any) => point.date === datapoint.raw.date).secondaryValue,
});

const onx360CoreEndpointIPEcqCcqMetricDefinitions: Record<string, ChartMetricDefinition[]> = {
  withOperators: [
    {
      metricSubtype: MetricSubtypes.Ecq,
      chartType: ChartTypesEnum.BarChart,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Excellent Consistent Quality (%)',
        horizontal: true,
        nbDays: 0,
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportEcqCcqSubmetricToCsv({ metricColumnName: 'Excellent Consistent Quality' }),
    },
    {
      metricSubtype: MetricSubtypes.Ccq,
      chartType: ChartTypesEnum.BarChart,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Core Consistent Quality (%)',
        horizontal: true,
        nbDays: 0,
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportEcqCcqSubmetricToCsv({ metricColumnName: 'Core Consistent Quality' }),
    },
    {
      metricSubtype: MetricSubtypes.EcqCcqDownload360,
      chartType: ChartTypesEnum.BarChart,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Download Throughput (Mbps)',
        horizontal: true,
        nbDays: 0,
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportEcqCcqSubmetricToCsv({ metricColumnName: 'Download Throughput' }),
    },
    {
      metricSubtype: MetricSubtypes.EcqCcqUpload360,
      chartType: ChartTypesEnum.BarChart,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Upload Throughput (Mbps)',
        horizontal: true,
        nbDays: 0,
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportEcqCcqSubmetricToCsv({ metricColumnName: 'Upload Throughput' }),
    },
    {
      metricSubtype: MetricSubtypes.EcqCcqLatency360,
      chartType: ChartTypesEnum.BarChart,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Latency (ms)',
        horizontal: true,
        nbDays: 0,
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      onExportCsv: exportEcqCcqSubmetricToCsv({ metricColumnName: 'Latency' }),
    },
    {
      metricSubtype: MetricSubtypes.CdnIpEcqCcqUpload360,
      chartType: ChartTypesEnum.Dataset,
      dataset: 'onx360_cdnip_ecqccq_upload',
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Upload Throughput (Mbps) by IP',
      },
      chartContainerClass: 'onx-grid-item__full-width',
      columns: ecqCcqUploadColumns,
      transformData: commonDatasetTransformer,
      initialSortColumnKey: 'percentage',
      initialSortDirection: 'desc',
    },
    {
      metricSubtype: MetricSubtypes.CdnIpEcqCcqIcmpLatency360,
      chartType: ChartTypesEnum.Dataset,
      dataset: 'onx360_cdnip_ecqccq_icmplatency',
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory ICMP Latency (ms) by IP',
      },
      chartContainerClass: 'onx-grid-item__full-width',
      columns: ecqCcqIcmpLatencyColumns,
      transformData: commonDatasetTransformer,
      initialSortColumnKey: 'percentage',
      initialSortDirection: 'desc',
    },
    {
      metricSubtypes: [MetricSubtypes.CdnIpEcqCcqDownloadThroughput360, MetricSubtypes.CdnIpEcqCcqTtfb360],
      chartType: ChartTypesEnum.Dataset,
      dataset: 'onx360_cdnip_ecqccq_download_ttfb',
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Download Throughput Tests by IP',
      },
      chartContainerClass: 'onx-grid-item__full-width',
      columns: ecqCcqDownloadThroughputColumns,
      transformData: commonDatasetTransformer,
      initialSortColumnKey: 'percentage',
      initialSortDirection: 'desc',
    },
    {
      metricSubtypes: [MetricSubtypes.CdnIpEcqCcqPacketLossDiscard360, MetricSubtypes.CdnIpEcqCcqPacketLossLost360],
      chartType: ChartTypesEnum.Dataset,
      dataset: 'onx360_cdnip_ecqccq_packet_loss',
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Packet Loss and Discard Tests by IP',
      },
      chartContainerClass: 'onx-grid-item__full-width',
      columns: ecqCcqPacketLossDiscardTestsColumns,
      transformData: commonDatasetTransformer,
      initialSortColumnKey: 'percentage',
      initialSortDirection: 'desc',
    },
    {
      metricSubtypes: [MetricSubtypes.CdnIpEcqCcqJitter360, MetricSubtypes.CdnIpEcqCcqLatency360],
      chartType: ChartTypesEnum.Dataset,
      dataset: 'onx360_cdnip_ecqccq_jitter_latency',
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Jitter and Latency Tests by IP',
      },
      chartContainerClass: 'onx-grid-item__full-width',
      columns: ecqCcqJitterLatencyColumns,
      transformData: commonDatasetTransformer,
      initialSortColumnKey: 'percentage',
      initialSortDirection: 'desc',
    },
    {
      metricSubtypes: [MetricSubtypes.CdnIpEcq360, MetricSubtypes.CdnIpCcq360],
      chartType: ChartTypesEnum.Dataset,
      dataset: 'onx360_cdnip_ecqccq_ecq_ccq',
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory Excellent & Core Consistent Quality Tests by IP',
      },
      chartContainerClass: 'onx-grid-item__full-width',
      columns: ecqCcqColumns,
      transformData: commonDatasetTransformer,
      initialSortColumnKey: 'cq_valid_rows',
      initialSortDirection: 'desc',
    },
  ],
  byOperator: [
    {
      metricSubtype: MetricSubtypes.CdnIpEcqCcqDownloadThroughputTestServers360Stack,
      chartType: ChartTypesEnum.BarChart,
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformCdnIp360StackPerOperator('percentage', 'counts'),
      onExportCsv: exportCdnIpTestServersToCsv,
      tooltipPlugin: testServersTooltipPlugin,
      chartAttrs: {
        ...testServersChartAttrs,
        chartTitlePlaceholders: ':connectionCategory Download Throughput Test Servers by IP for :operator',
      },
    },
    {
      metricSubtype: MetricSubtypes.CdnIpEcqCcqUploadThroughputTestServers360Stack,
      chartType: ChartTypesEnum.BarChart,
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformCdnIp360StackPerOperator('percentage', 'counts'),
      onExportCsv: exportCdnIpTestServersToCsv,
      tooltipPlugin: testServersTooltipPlugin,
      chartAttrs: {
        ...testServersChartAttrs,
        chartTitlePlaceholders: ':connectionCategory Upload Throughput Test Servers by IP for :operator',
      },
    },
    {
      metricSubtype: MetricSubtypes.CdnIpEcqCcqIcmpLatencyTestServers360Stack,
      chartType: ChartTypesEnum.BarChart,
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformCdnIp360StackPerOperator('percentage', 'counts'),
      onExportCsv: exportCdnIpTestServersToCsv,
      tooltipPlugin: testServersTooltipPlugin,
      chartAttrs: {
        ...testServersChartAttrs,
        chartTitlePlaceholders: ':connectionCategory ICMP Test Servers by IP for :operator',
      },
    },
    {
      metricSubtype: MetricSubtypes.CdnIpEcqCcqServerResponseTestServers360Stack,
      chartType: ChartTypesEnum.BarChart,
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformCdnIp360StackPerOperator('percentage', 'counts'),
      onExportCsv: exportCdnIpTestServersToCsv,
      tooltipPlugin: testServersTooltipPlugin,
      chartAttrs: {
        ...testServersChartAttrs,
        chartTitlePlaceholders: ':connectionCategory Server Response Test Servers by IP for :operator',
      },
    },
  ],
};

export default onx360CoreEndpointIPEcqCcqMetricDefinitions;
