import { computed } from 'vue';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import useLocations from '@/composables/useLocations';
import { Dashboards } from '@/constants/dashboards';

const useLookingAtLocationTitle = () => {
  const { geohashes } = useGeohashesQueryParam();
  const { currentLocation } = useLocations(Dashboards.Focus);

  const computedLookingAtTitle = computed(() => {
    if (geohashes.value.length === 1) {
      return `Looking at a single geohash`;
    }

    if (geohashes.value.length > 1) {
      return `Looking at multiple geohashes`;
    }

    if (!currentLocation.value) {
      return '';
    }

    return `Looking at ${currentLocation.value.name}`;
  });

  return { lookingAtTitle: computedLookingAtTitle };
};

export default useLookingAtLocationTitle;
