<script setup lang="ts">
import { computed } from 'vue';

import useFocusChartConnectionCategories from '@/focus/composables/useFocusChartConnectionCategories';
import OnxButton from '@/components/onx/OnxButton.vue';
import OnxDropdown from '@/components/onx/OnxDropdown.vue';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon.vue';
import OnxCheckbox from '@/components/onx/OnxCheckbox/OnxCheckbox.vue';
import { OnxList, OnxListItem } from '@/components/onx/OnxList';

const { onCategoryChange, selectedChartConnectionCategories } = useFocusChartConnectionCategories();

const categoriesFilteredBySelectedAndDisabled = computed(() => {
  return selectedChartConnectionCategories.value.filter((c) => c.selected && !c.disabled);
});

const selectedChartConnectionCategoryLabels = computed(() => {
  return categoriesFilteredBySelectedAndDisabled.value.map((c) => c.categoryLabel).join(', ');
});
</script>

<template>
  <OnxDropdown :popper-hide-triggers="[]">
    <OnxButton variant="tertiary">
      <span class="onx-chart-connection-categories-selector__label">Connection Category:</span>
      <span>{{ selectedChartConnectionCategoryLabels }}</span>
      <ChevronDownIcon />
    </OnxButton>

    <template #content>
      <OnxList class="onx-chart-connection-categories-selector__list">
        <OnxListItem
          v-for="c in selectedChartConnectionCategories"
          :key="c.categoryLabel"
          :inset="c.subCategory"
          :disabled="c.disabled || (categoriesFilteredBySelectedAndDisabled.length === 1 && c.selected)"
          @click="onCategoryChange(c)"
        >
          <OnxCheckbox
            :label="c.categoryLabel"
            :model-value="c.selected && !c.disabled"
            @click.prevent
            :disabled="c.disabled || (categoriesFilteredBySelectedAndDisabled.length === 1 && c.selected)"
          />
        </OnxListItem>
      </OnxList>
    </template>
  </OnxDropdown>
</template>

<style lang="scss">
.onx-chart-connection-categories-selector__list {
  .onx-list__item--disabled {
    .main {
      opacity: 1 !important;
    }
  }
}
.onx-chart-connection-categories-selector__label {
  color: black;
  display: inline-block;
  margin-right: 10px;
}
</style>
