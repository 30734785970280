<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { useStore } from 'vuex';
import OnxHeadline from '../typography/OnxHeadline.vue';
import OnxTrendChart, { OnxTrendChartProps } from '@/components/onx/charts/OnxTrendChart.vue';
import useFilters from '@/composables/useFilters';
import { Dashboards } from '@/constants/dashboards';

const props = defineProps<OnxTrendChartProps>();
const { aggregation, bbox, chartTitle, chartTitleTooltip, deploymentType, enabled, geohashes, location, metric } =
  toRefs(props);
const store = useStore();
const metricsByIdentifier = computed(() => store.getters['metrics/byIdentifier']);

const { metricSubtype } = useFilters(Dashboards.Focus);

const identifiedMetric = metricsByIdentifier.value[props.metric];

const subtitle = `${metricSubtype.value?.toUpperCase()} Threshold is at ${identifiedMetric.cq_threshold.threshold}${
  identifiedMetric.cq_threshold.units.short
}`;
</script>

<template>
  <OnxTrendChart
    :aggregation="aggregation"
    :bbox="bbox"
    :chart-title="chartTitle"
    :chart-title-tooltip="chartTitleTooltip"
    :dashboard="dashboard"
    :enabled="enabled"
    :geohashes="geohashes"
    :location="location"
    :metric="metric"
    :operators="operators"
    :screenshot-subtitle="screenshotSubtitle"
    :screenshot-title="screenshotTitle"
    :deployment-type="deploymentType"
  >
    <template #title>
      <OnxHeadline as="h3">
        {{ chartTitle }}
        <div class="subtitle">{{ subtitle }}</div>
      </OnxHeadline>
    </template>
  </OnxTrendChart>
</template>

<style lang="scss" scoped>
.subtitle {
  margin-top: 5px;
  font-size: small;
  font-weight: lighter;
  color: grey;
}
</style>
