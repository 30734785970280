import { useQuery } from '@tanstack/vue-query';

import type {
  CoverageTopicCountryInfoResponse,
  OvershootingTopicCountryInfoResponse,
  CongestionTopicCountryInfoResponse,
} from '@/types/DashboardTopicCountryInfoResponse';
import { DashboardTopics } from '@/constants/dashboards';
import osApi from '@/api/osApi';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';

type Responses = {
  [DashboardTopics.COVERAGE]: CoverageTopicCountryInfoResponse;
  [DashboardTopics.OVERSHOOTING]: OvershootingTopicCountryInfoResponse;
  [DashboardTopics.CONGESTION]: CongestionTopicCountryInfoResponse;
};

const useDashboardTopicCountryInfo = <T extends DashboardTopics>(dashboardTopic: T, countryIso3: string) => {
  const dashboard = useCurrentDashboardName();
  return useQuery({
    queryKey: ['dashboardTopicCountryInfo', dashboard, dashboardTopic, countryIso3],
    queryFn: () =>
      osApi.get<Responses[T]>(
        `/${dashboard.value}/topic-info/${dashboardTopic}/country-info/${countryIso3.toLowerCase()}/`,
      ),
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
  });
};

export default useDashboardTopicCountryInfo;
