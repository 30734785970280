import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import type { RefLike } from '@/types/helpers/RefLike';
import type { PolygonResponse } from '@/types/PolygonResponse';
import osApi from '@/api/osApi';
import { Dashboards } from '@/constants/dashboards';

const usePolygons = (
  dashboard: Dashboards,
  options: { geocoding: RefLike<number>; countryIso3: RefLike<string>; enabled?: RefLike<boolean> },
) => {
  const { countryIso3, enabled, geocoding } = options;
  const localEnabled = computed(() => {
    if (enabled) {
      return Boolean(countryIso3.value) && enabled.value;
    }

    return Boolean(countryIso3.value);
  });

  const queryParams = computed(() => {
    return {
      country: countryIso3.value,
    };
  });

  return useQuery({
    queryKey: ['polygons', geocoding, countryIso3],
    queryFn: () =>
      osApi.get<PolygonResponse>(`${dashboard}/polygon/${geocoding.value}/`, {
        params: queryParams.value,
      }),
    staleTime: 24 * 60 * 60 * 1000,
    enabled: localEnabled,
  });
};

export default usePolygons;
