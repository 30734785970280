import isNil from 'lodash/isNil';
import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { getDatumValue } from '@/utils/viewHelpers';

const transformSimpleStructureSubmetricsByOperator: DataTransformerConstructor<
  [MetricStructuresEnum.RankedSimple | MetricStructuresEnum.PercentageSimple],
  Required<Pick<DataTransformerFnOptions, 'operators'>> & Pick<DataTransformerFnOptions, 'selectedConnectionCategories'>
> =
  ({ operators }) =>
  ([response], horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const metrics = operators
      .map((operator) => {
        const datum = metricsFilteredByOperator.find((datum) => {
          return datum.canonical_network_id === operator.canonical_network_id;
        });

        if (!datum || isNil(getDatumValue(datum))) {
          return undefined;
        }

        const color = `#${operator.hex_color}`;
        return {
          data: [
            {
              ...datum,
              [dataAxis]: getDatumValue(datum),
              [labelAxis]: operator.name_mapped,
              lci: datum.lci ?? null,
              uci: datum.uci ?? null,
            },
          ],
          type: 'bar',
          backgroundColor: color,
          color,
          label: operator.name_mapped,
          yAxisID: 'y',
          xAxisID: 'x',
          dataAxis,
          labelAxis,
          meta: {
            imageExportLegend: {
              color,
              label: operator.name_mapped,
              y: getDatumValue(datum),
            },
          },
        };
      })
      .filter(Boolean) as DataTransformerReturnedItem[];

    return metrics;
  };

export default transformSimpleStructureSubmetricsByOperator;
