export const API_DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const API_LONG_DATE_FORMAT = 'dd MMMM yyyy';
export const API_SHORT_DATE_FORMAT = 'dd MMM';
export const X_AXIS_DATE_FORMAT = 'MMM d';
export const PROGRAMMATIC_FORMAT = 'yyyyMMdd';

export default {
  API_DEFAULT_DATE_FORMAT,
  API_LONG_DATE_FORMAT,
  API_SHORT_DATE_FORMAT,
  X_AXIS_DATE_FORMAT,
};
