import chroma from 'chroma-js';

import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import barChartAxesFromOrientation from '@/utils/barChartAxesFromOrientation';

import { toFixedNoZeroPad } from '@/utils/helpers';

const transformOvershootingScoreByENodeBId: DataTransformerConstructor<
  [MetricStructuresEnum.OvershootingScoreByENodeBId],
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>>
> =
  ({ mainOperator }) =>
  ([response], horizontal?: boolean) => {
    const { dataAxis, labelAxis } = barChartAxesFromOrientation(!!horizontal);

    const dataFilteredByMainOperator = response.results.filter((datum) => {
      return datum.canonical_network_id === mainOperator.canonical_network_id;
    });

    if (dataFilteredByMainOperator.length === 0) {
      return [];
    }

    const startColor = 'rgb(0, 64, 71)';
    const endColor = 'rgb(0, 226, 249)';

    const minAndMaxValues = dataFilteredByMainOperator.reduce(
      (acc, datum) => {
        const sampleSize = datum.site_samples;
        if (sampleSize < acc.min) {
          acc.min = sampleSize;
        }
        if (sampleSize > acc.max) {
          acc.max = sampleSize;
        }
        return acc;
      },
      { min: Infinity, max: -Infinity },
    );

    const colorScale = chroma.scale([startColor, endColor]).domain([0, minAndMaxValues.max]);

    const metrics = dataFilteredByMainOperator.map((datum) => {
      const color = colorScale(datum.site_samples).hex();
      return {
        data: [
          {
            ...datum,
            [dataAxis]: datum.site_overshooting_score,
            [labelAxis]: datum.enodebid.toString(),
          },
        ],
        type: 'bar',
        backgroundColor: color,
        color: color,
        label: datum.enodebid.toString(),
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color: color,
            label: datum.enodebid.toString(),
            y: `${toFixedNoZeroPad(datum.site_overshooting_score, 2)} (${toFixedNoZeroPad(datum.site_samples, 2)})`,
          },
        },
      };
    });

    return metrics;
  };

export default transformOvershootingScoreByENodeBId;
