<template>
  <div class="onx-period-comparisons__grid__previous">
    <slot />
  </div>
</template>
<style lang="scss">
@use 'scss/mixins';
@import 'scss/onx-breakpoints.module';

// Previous items fill the left column
.onx-period-comparisons__grid__previous {
  min-width: 0;
  grid-column: 1;

  // On smaller screens, all items stack, with previous items last
  @include smaller-than-laptop() {
    grid-column: 1;
    order: 1;
  }
}
</style>
