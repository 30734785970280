export default {
  Spotlight: 'r/Spotlight',
  CompetitiveDetails: 'r/CompetitiveDetails',
  CompetitiveConnectionCategory: 'r/CompetitiveConnectionCategory',
  CompetitiveIntelligence: 'r/CompetitiveIntelligence',
  CompetitiveOverview: 'r/CompetitiveOverview',

  Focus: 'r/Focus',
  FocusQoE: 'r/FocusQoE',
  FocusQoEDetails: 'r/FocusQoEDetails',
  FocusCoreHealth: 'r/FocusCoreHealth',
  FocusCoreHealthByCDN: 'r/FocusCoreHealthByCDN',
  FocusCoreHealthByOperatorCDN: 'r/FocusCoreHealthByOperatorCDN',
  FocusRANHealth: 'r/FocusRANHealth',
  FocusRANHealthCoverage: 'r/FocusRANHealthCoverage',
  FocusRANHealthAvailability: 'r/FocusRANHealthAvailability',
  FocusRANHealthNetworkRollout: 'r/FocusRANHealthNetworkRollout',
  FocusRANHealthDeployedSpectrum: 'r/FocusRANHealthDeployedSpectrum',

  ThreeSixty: 'r/ThreeSixty',
  ThreeSixtyCoverage: 'r/ThreeSixtyCoverage',
  ThreeSixtyCoverageNetworkCoverage: 'r/ThreeSixtyCoverageNetworkCoverage',
  ThreeSixtyCoverageAvailability: 'r/ThreeSixtyCoverageAvailability',
  ThreeSixtyCoverageAvailability5G: 'r/ThreeSixtyCoverageAvailability5G',
  ThreeSixtyDeployedSpectrum: 'r/ThreeSixtyDeployedSpectrum',
  ThreeSixtyBaseStation: 'r/ThreeSixtyBaseStation',
  ThreeSixtyBaseStationSiteMapper: 'r/ThreeSixtyBaseStationSiteMapper',
  ThreeSixtyBaseStationCellCongestion: 'r/ThreeSixtyBaseStationCellCongestion',
  ThreeSixtyBaseStationCellCapacity: 'r/ThreeSixtyBaseStationCellCapacity',
  ThreeSixtyBaseStationOvershooting: 'r/ThreeSixtyBaseStationOvershooting',
  ThreeSixtyCore: 'r/ThreeSixtyCore',
  ThreeSixtyCoreEndpointIPSpeed: 'r/ThreeSixtyCoreEndpointIPSpeed',
  ThreeSixtyCoreEndpointIPGamesAndVoice: 'r/ThreeSixtyCoreEndpointIPGamesAndVoice',
  ThreeSixtyCoreEndpointIPEcqCcq: 'r/ThreeSixtyCoreEndpointIPEcqCcq',
  ThreeSixtyCoreEndpointIPVideoABR: 'r/ThreeSixtyCoreEndpointIPVideoABR',
  ThreeSixtyCoreEndpointIPVideoLive: 'r/ThreeSixtyCoreEndpointIPVideoLive',

  Colors: 'r/Colors',
  Landing: 'Landing',
  LogoutCallback: 'LogoutCallback',
  Login: 'Login',
  MarketGrowth: 'r/MarketGrowth',
  MarketGrowthDetails: 'r/MarketGrowthDetails',
  MarketGrowthOverview: 'r/MarketGrowthOverview',
  PlainJson: 'r/PlainJson',
};
