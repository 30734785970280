import { createAuth0 } from '@auth0/auth0-vue';

const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_CONFIG_DOMAIN,
  client_id: import.meta.env.VITE_AUTH0_CONFIG_CLIENT_ID,
  redirect_uri: window.location.origin,
  audience: import.meta.env.VITE_AUTH0_CONFIG_AUDIENCE,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
});

export default auth0;
