import capitalize from 'lodash/capitalize';
import menuItem from './menuItem';

const identity = (x) => x;
const findMetricBySelectedMetricType = (metrics, selectedMetric) => {
  return (
    metrics.find((metric) => metric.type === selectedMetric.type && metric.subtype !== selectedMetric.subtype) ||
    metrics[0]
  );
};
const createMenuListItem = (metrics, selectedMetric, name, findMetric = findMetricBySelectedMetricType) => {
  if (!metrics) {
    return null;
  }

  const correspondingMetric = findMetric(metrics, selectedMetric);

  if (!correspondingMetric) {
    return null;
  }

  return menuItem({
    label: name || correspondingMetric.name,
    value: correspondingMetric.key,
    active: correspondingMetric.key === selectedMetric.key,
  });
};

/*
 *  metrics {Object}  {[], []} Object of arrays of metrics grouped by subtype
 *  @return {Array}  [{}, {}] array of objects for configuring the menu
 */
export function menuListItems(metrics, selected) {
  return {
    overall: {
      title: 'Overall',
      list: [
        createMenuListItem(metrics.videoexperience, selected, 'Video Experience'),
        createMenuListItem(metrics.videoliveexperience, selected, 'Live Video Experience'),
        createMenuListItem(metrics.gamesexperience, selected, 'Games Experience'),
        createMenuListItem(metrics.voiceexperience, selected, 'Voice Experience'),
        createMenuListItem(metrics.download, selected, 'Download Speed Experience'),
        createMenuListItem(metrics.upload, selected, 'Upload Speed Experience'),
      ].filter(identity),
    },
    consistency: {
      title: 'Consistency',
      list: [
        createMenuListItem(metrics.ecq, selected, 'Excellent Consistent Quality'),
        createMenuListItem(metrics.ccq, selected, 'Core Consistent Quality'),
        createMenuListItem(metrics.consistentquality, selected, 'Consistent Quality'),
      ].filter(identity),
    },
    other: {
      title: 'Other',
      list: [
        createMenuListItem(
          metrics.extent,
          selected,
          'Reach',
          () => metrics.extent.find((metric) => metric.type === selected.type) || metrics.extent[0],
        ),
        createMenuListItem(metrics.onxcoveragesim, selected, 'Coverage'),
        createMenuListItem(metrics.availability, selected, 'Availability'),
        createMenuListItem(metrics.reliability, selected, 'Reliability'),
        createMenuListItem(metrics.availability, selected, 'No Signal Availability', () =>
          metrics.availability.find((metric) => metric.type === 'nosignal'),
        ),
        createMenuListItem(metrics.availability, selected, 'Time on WiFi', () =>
          metrics.availability.find((metric) => metric.type === 'wifi'),
        ),
      ].filter(identity),
    },
  };
}

export function geocodingListItems(geocodings, selected) {
  const displayGeocoding = geocodings.find((l) => l.id === selected);
  return [
    {
      label: capitalize(displayGeocoding.name),
      children: geocodings
        .filter((l) => l.id !== '1')
        .map((g) => ({
          label: capitalize(g.name),
          active: g.id === selected,
          value: g.id,
        })),
    },
  ];
}
