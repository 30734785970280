<script setup>
import { computed } from 'vue';
import CheckedIcon from './CheckedIcon';
import UncheckedIcon from './UncheckedIcon';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  dark: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const classObject = computed(() => {
  return {
    'onx-checkbox': true,
    'onx-checkbox--disabled': props.disabled,
    'onx-checkbox--dark': props.dark,
  };
});

const onChange = (e) => {
  emit('update:modelValue', {
    value: e.target.checked,
    label: props.label,
  });
};
</script>

<template>
  <label :class="classObject">
    <input type="checkbox" :checked="modelValue" :disabled="disabled" @input="onChange" />
    <span class="onx-checkbox__container">
      <CheckedIcon v-if="modelValue" />
      <UncheckedIcon v-else />
    </span>

    <slot>
      <span v-if="label" class="onx-checkbox__label">
        {{ label }}
      </span>
    </slot>
  </label>
</template>

<style scoped lang="scss">
.onx-checkbox {
  @import 'scss/mixins';

  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: pxToRem(12);
  line-height: pxToRem(14);
  z-index: 1;
  cursor: pointer;

  &__container {
    position: relative;

    margin-right: 10px;
    width: 12px;
    height: 12px;

    transition: transition();

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      content: '';
      width: 95%;
      height: 95%;
      border-width: 0;
      border-radius: 6px;
      border-style: solid;
      border-color: var(--onx-checkbox-hover-shadow-light-color);
      box-sizing: content-box;
    }
  }

  &--dark {
    & .onx-checkbox__container::after {
      border-color: var(--onx-checkbox-hover-shadow-dark-color);
    }

    &.onx-checkbox--disabled {
      color: var(--onx-checkbox-disabled-dark-color);

      & .onx-checkbox__container {
        color: var(--onx-checkbox-disabled-dark-color) !important;
      }

      & .onx-checkbox__label {
        color: var(--onx-checkbox-disabled-dark-label-color) !important;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    & .onx-checkbox__container {
      color: var(--onx-checkbox-disabled-color) !important;
    }

    & .onx-checkbox__label {
      color: var(--onx-checkbox-disabled-label-color) !important;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:not(:checked) {
      & + .onx-checkbox__container {
        color: var(--onx-checkbox-off-color);
      }

      &:hover + .onx-checkbox__container {
        color: var(--onx-checkbox-off-hover-color);

        &::after {
          border-width: 4px;
        }
      }

      &:focus + .onx-checkbox__container {
        color: var(--onx-checkbox-off-focus-color);
      }
    }

    &:checked {
      & + .onx-checkbox__container {
        color: var(--onx-checkbox-on-color);
      }

      &:hover + .onx-checkbox__container {
        color: var(--onx-checkbox-on-hover-color);

        &::after {
          border-width: 4px;
        }
      }

      &:focus + .onx-checkbox__container {
        color: var(--onx-checkbox-on-focus-color);
      }
    }
  }
}
</style>
