import { type Chart, type Plugin, type LegendItem } from 'chart.js';
import chroma from 'chroma-js';

import getOrCreateLegendList from '@/components/visual/chart/line-chart-plugins/getOrCreateLegendList';
import createBaseLegendItem from '@/components/visual/chart/line-chart-plugins/createBaseLegendItem';

/** Helper function to quickly toggle the disabled class on an LI for each legend item */
const addOrRemoveDisabled = (li: HTMLLIElement, hidden: boolean) => {
  if (hidden) {
    li.classList.add('disabled');
  } else {
    li.classList.remove('disabled');
  }
};

const createLegendItem = (item: LegendItem, chart: Chart, allItems: LegendItem[]) => {
  const legendHoverGrayOutColor = chroma(
    window.getComputedStyle(document.documentElement).getPropertyValue('--charcoal-200'),
  )
    .alpha(0.1)
    .css();

  const li = createBaseLegendItem({
    border: `${item.lineWidth}px solid ${item.strokeStyle}`,
    fontColor: item.fontColor as string,
    text: item.text,
  });

  li.setAttribute('title', item.text);
  li.addEventListener('click', () => {
    if (!item.datasetIndex) {
      return;
    }

    // if all items are visible, hide everything else
    const visibleLabels = new Set<string>(chart.getSortedVisibleDatasetMetas().map((meta) => meta.label));
    if (visibleLabels.size === allItems.length) {
      chart.config.data.datasets.forEach((_, i) => {
        chart.setDatasetVisibility(i, i === item.datasetIndex); // visible only when it's the clicked item
      });

      // if only one set is visible and it's the one clicked, show all
    } else if (visibleLabels.size === 1 && chart.isDatasetVisible(item.datasetIndex)) {
      chart.config.data.datasets.forEach((d, i) => {
        chart.setDatasetVisibility(i, true);
      });

      // otherwise just toggle it
    } else {
      const newDatasetVisibility = !chart.isDatasetVisible(item.datasetIndex);
      chart.setDatasetVisibility(item.datasetIndex, newDatasetVisibility);
    }

    chart.update();
  });

  li.addEventListener('mouseenter', () => {
    chart.config.data.datasets.forEach((d) => {
      const chartDataset: typeof d & { oldColors?: [string, string] } = d;

      if (li.textContent !== chartDataset.label) {
        chartDataset.oldColors = [chartDataset.backgroundColor as string, chartDataset.borderColor as string];
        chartDataset.backgroundColor = legendHoverGrayOutColor;
        chartDataset.borderColor = legendHoverGrayOutColor;
      }
    });
    chart.update();
  });

  li.addEventListener('mouseleave', () => {
    chart.config.data.datasets.forEach((d) => {
      const chartDataset: typeof d & { oldColors?: [string, string] } = d;

      if (chartDataset.oldColors) {
        chartDataset.backgroundColor = chartDataset.oldColors[0];
        chartDataset.borderColor = chartDataset.oldColors[1];
        delete chartDataset.oldColors;
      }
    });
    chart.update();
  });

  return li;
};

const afterUpdate: Plugin['afterUpdate'] = (chart, args, options) => {
  const ul = getOrCreateLegendList(options.containerID as string);

  if (!ul) {
    return;
  }

  ul.classList.add('highlight-on-hover');

  if (!chart.options?.plugins?.legend?.labels?.generateLabels) {
    return;
  }

  // Reuse the built-in legendItems generator
  const items = chart.options.plugins.legend.labels.generateLabels(chart).filter((item) => item.lineWidth);
  const reusedItems: LegendItem[] = [];

  // Remove old legend items by iterating through ul children and comparing their textContent to item.text
  Array.from(ul.children).forEach((li) => {
    const item = items.find((l) => l.text === li.textContent);
    if (!item) {
      ul.removeChild(li);
    } else {
      addOrRemoveDisabled(li as HTMLLIElement, !!item.hidden);
      reusedItems.push(item);
    }
  });

  items.forEach((item) => {
    if (!reusedItems.includes(item)) {
      const li = createLegendItem(item, chart, items);
      addOrRemoveDisabled(li as HTMLLIElement, !!item.hidden);
      ul.appendChild(li);
    }
  });
};

const htmlLegendPlugin = () => {
  return {
    id: 'htmlLegend',
    afterUpdate,
  };
};

export default htmlLegendPlugin;
