<script setup lang="ts">
import { toRefs, withDefaults, useAttrs } from 'vue';

import type { Operator } from '@/types/Operator';

import ChoroplethMap from '@/components/visual/map/ChoroplethMap.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import CoverageMapLegend from '@/components/visual/CoverageMapLegend.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import { Dashboards } from '@/constants/dashboards';
import useVegaMap from '@/components/onx/vega-map/useVegaMap';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import OnxButton from '@/components/onx/OnxButton.vue';
import { MapEvents, MapEventListenerAttrs } from '@/components/visual/map/MapEvents';

type Props = {
  networkOperator: Operator;
  endpoint: string;
  title: string;
  displayLegend?: boolean;
  enableGeohashes?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  displayLegend: true,
  enableGeohashes: false,
});
const { enableGeohashes, networkOperator } = toRefs(props);

const attrs = useAttrs();

const {
  computedLegendEntries,
  computedLocationId,
  computedPolygonData,
  computedTitle,
  handleNewBounds,
  mapIsLoading,
  setupImageLayer,
} = useVegaMap(Dashboards.Focus, {
  endpoint: props.endpoint,
  title: props.title,
  networkOperator,
  enableGeohashes,
});

const { clearGeohashes, geohashes, onGeohashChange } = useGeohashesQueryParam();

const onMapReady = (map: mapboxgl.Map) => {
  setupImageLayer(map);

  const mapReadyListenerAttr = attrs[MapEventListenerAttrs[MapEvents.MapReady]];
  if (typeof mapReadyListenerAttr === 'function') {
    mapReadyListenerAttr(map);
  }
};
</script>

<template>
  <OnxPaper class="coverage-map__map-container" v-bind="attrs">
    <div class="coverage-map__map-header">
      <OnxHeadline as="h3" class="coverage-map__map-header__headline">{{ computedTitle }}</OnxHeadline>
    </div>

    <div class="coverage-map__map-wrapper">
      <ChoroplethMap
        class="coverage-map__map"
        v-if="computedPolygonData !== null && networkOperator"
        :key="computedLocationId"
        v-model="computedLocationId"
        :geo-json="computedPolygonData"
        :choropleth-data="computedPolygonData.features"
        :network-id="networkOperator.canonical_network_id"
        :no-data-color="'transparent'"
        :display-color-scales="false"
        @[MapEvents.NewBounds]="handleNewBounds"
        @[MapEvents.MapReady]="onMapReady"
        :disable-location-change-on-click="enableGeohashes"
        enable-actions-on-polygons-without-data
        @feature-select="onGeohashChange"
        :id="networkOperator.name_mapped"
      >
        <template #legend v-if="!mapIsLoading">
          <CoverageMapLegend :legend="computedLegendEntries" />
        </template>
      </ChoroplethMap>

      <OnxButton v-if="enableGeohashes && geohashes.length > 0" class="map-geohash-reset-btn" @click="clearGeohashes">
        Reset geohash selection
      </OnxButton>
    </div>

    <LoaderGrid v-if="mapIsLoading" overlay />
  </OnxPaper>
</template>
