import { exportToCsv } from '@/utils/files';

const exportVideoResToCsv = (data: { labels: string[]; datasets: any[] }, title: string) => {
  // in order to get the bars to have the correct labels at the bottom of the chart, some views use X as date, and others
  // as a network. If the data has a date in X, we'll know we don't have network operator values.
  const isNetworkDate = data.datasets.some((dataset: any) => {
    return dataset.data.some((datum: any) => (datum.x as string).match(/^\d{4}-\d{2}-\d{2}$/));
  });

  let headers;
  let rows;
  if (isNetworkDate) {
    headers = ['Resolution', 'Date', 'Mean', 'LCI', 'UCI'];
    const rows = data.datasets.flatMap((dataset: any) => {
      return dataset.data.map((datum: any) => {
        // datum.date => network
        return [
          dataset.label,
          datum.date,
          (datum.y || 0).toFixed(2),
          (datum.lci || 0).toFixed(2),
          (datum.uci || 0).toFixed(2),
        ];
      });
    });

    exportToCsv(`${title}.csv`, [headers, ...rows]);
  } else {
    headers = ['Resolution', 'Operator', 'Date', 'Mean', 'LCI', 'UCI'];
    rows = data.datasets.flatMap((dataset: any) => {
      return dataset.data.map((datum: any) => {
        const date = datum.date.split('T')[0]; // convert datetime to date only
        return [dataset.label, datum.x, date, datum.y.toFixed(2), datum.lci.toFixed(2), datum.uci.toFixed(2)];
      });
    });

    exportToCsv(`${title}.csv`, [headers, ...rows]);
  }
};

export default exportVideoResToCsv;
