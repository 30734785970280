import { computed } from 'vue';

import Routes from '@/constants/routes';
import { Dashboards } from '@/constants/dashboards';
import { useOnxLicencePermission, OnxPermissionTopics } from '@/composables/useOnxLicencePermission';

const useFocusRANHealthTabAvailability = () => {
  const hasCoverage = useOnxLicencePermission(Dashboards.Focus, OnxPermissionTopics.RANHealthCoverage);
  const hasNetworkRollout = useOnxLicencePermission(Dashboards.Focus, OnxPermissionTopics.RANHealthNetworkRollout);
  const hasDeployedSpectrum = useOnxLicencePermission(Dashboards.Focus, OnxPermissionTopics.RANHealthDeployedSpectrum);

  const tabAvailability = {
    [Routes.FocusRANHealthCoverage]: hasCoverage,
    [Routes.FocusRANHealthNetworkRollout]: hasNetworkRollout,
    [Routes.FocusRANHealthDeployedSpectrum]: hasDeployedSpectrum,
  };

  const firstAvailableSubtabForRANHealth = computed(() => {
    return [
      Routes.FocusRANHealthCoverage,
      Routes.FocusRANHealthNetworkRollout,
      Routes.FocusRANHealthDeployedSpectrum,
    ].find((subtab) => tabAvailability[subtab]);
  });

  return {
    tabAvailability,
    firstAvailableSubtabForRANHealth,
  };
};

export default useFocusRANHealthTabAvailability;
