import { Column } from '@/components/onx/table';
import { Operator } from '@/types/Operator';

const localeSort = (a: string, b: string) => a.localeCompare(b);
const numberSort = (a: number, b: number) => a - b;

export type SitemapperTableRow = {
  operator: Operator;
  cell_technology: string;
  cell_enodebid: number;
  cell_estimatedfirstrecord: string;
  cell_estimatedlastrecord: string;
  cell_estimatedlatitude: number;
  cell_estimatedlongitude: number;
  cell_estimatedband: number;
  cell_quantifiedestimatedlocationconfidence: number;
};

export const sitemapperDetailsColumns: Column<SitemapperTableRow>[] = [
  {
    value: (row) => row.operator.name_mapped,
    header: 'Operator',
    key: 'operator',
    sort: localeSort,
    filterable: true,
  },
  {
    value: (row) => row.cell_technology,
    header: 'Cell Technology',
    key: 'cell_technology',
    sort: localeSort,
    filterable: true,
  },
  {
    value: (row) => row.cell_enodebid,
    header: 'eNodeB IDs',
    key: 'cell_enodebid',
    sort: numberSort,
  },
  {
    value: (row) => row.cell_estimatedfirstrecord, // string, always in "YYYY-MM-DD" format
    header: 'Estimated First Record',
    key: 'cell_estimatedfirstrecord',
    sort: localeSort, // sort by date. No need to convert to date then convert back to string?,
    filterable: true,
  },
  {
    value: (row) => row.cell_estimatedlastrecord,
    header: 'Estimated Last Record',
    key: 'cell_estimatedlastrecord',
    sort: localeSort,
    filterable: true,
  },
  {
    value: (row) => row.cell_estimatedlatitude,
    header: 'Estimated Latitude',
    key: 'cell_estimatedlatitude',
    sort: numberSort,
    filterable: true,
  },
  {
    value: (row) => row.cell_estimatedlongitude,
    header: 'Estimated Longitude',
    key: 'cell_estimatedlongitude',
    sort: numberSort,
    filterable: true,
  },
  // NOTE: comment out for now.  Once brytlyt db fix is release, we can uncomment this again
  // {
  //   value: (row) => row.cell_estimatedband,
  //   header: '# of Estimated Bands',
  //   key: 'cell_estimatedband',
  //   sort: numberSort,
  // },
  {
    cell: (row) => `${row.cell_quantifiedestimatedlocationconfidence.toFixed(1)}%`,
    value: (row) => row.cell_quantifiedestimatedlocationconfidence,
    header: 'Location Confidence (Average)',
    key: 'cell_quantifiedestimatedlocationconfidence',
    sort: numberSort,
  },
];
