import { METRIC_TYPES } from '@/constants/constants';

const metricsDBToHeavyDBConnectionCategoryValue = (connectionCategory: string) => {
  let normalizedConnectionCategory = connectionCategory;

  if ([METRIC_TYPES.FiveG, METRIC_TYPES.ThreeG, METRIC_TYPES.TwoG].includes(connectionCategory)) {
    normalizedConnectionCategory = connectionCategory.toUpperCase();
  } else if (connectionCategory === METRIC_TYPES.FourG) {
    normalizedConnectionCategory = '4G';
  } else if (connectionCategory === METRIC_TYPES.NoSignal) {
    normalizedConnectionCategory = 'no signal';
  }

  return normalizedConnectionCategory;
};

export default metricsDBToHeavyDBConnectionCategoryValue;
