import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

import { connectionCategoryColors } from '@/constants/colorScales';
import metricsDBToHeavyDBConnectionCategoryValue from '@/utils/metricsDBToHeavyDBConnectionCategoryValue';

const transformGeohashCountByConnectionCategory: DataTransformerConstructor<
  [MetricStructuresEnum.CoverageGeohashCountsByCategory],
  Required<Pick<DataTransformerFnOptions, 'mainOperator' | 'selectedConnectionCategories'>>
> =
  ({ mainOperator, selectedConnectionCategories }) =>
  ([response], horizontal?: boolean) => {
    if (!mainOperator) {
      return [];
    }

    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const operatorData = response.results.find((datum) => {
      return datum.canonical_network_id === mainOperator.canonical_network_id;
    });

    if (!operatorData) {
      return [];
    }

    const datasets = selectedConnectionCategories
      .map((connectionCategory) => {
        const category = metricsDBToHeavyDBConnectionCategoryValue(connectionCategory.categoryValue);

        const data = [
          {
            ...operatorData,
            // @ts-ignore
            [dataAxis]: operatorData.counts[category],
            [labelAxis]: category,
          } as typeof operatorData & { x: number | string; y: number | string },
        ];

        // @ts-ignore
        const color = connectionCategoryColors[category.toUpperCase()];

        return {
          data,
          type: 'bar',
          backgroundColor: color,
          color,
          label: mainOperator.name_mapped,
          yAxisID: 'y',
          xAxisID: 'x',
          dataAxis,
          labelAxis,
          meta: {
            imageExportLegend: {
              color,
              label: connectionCategory.categoryLabel,
              y: data[0][dataAxis],
            },
          },
        };
      })
      .sort((a, b) => {
        const aValue = a.data[0][dataAxis];
        const bValue = b.data[0][dataAxis];

        if (typeof aValue !== 'number') {
          console.warn(`Sorting failed. Expected value to be number, got ${typeof aValue}`, { value: aValue });
          return 0;
        }

        if (typeof bValue !== 'number') {
          console.warn(`Sorting failed. Expected value to be number, got ${typeof bValue}`, { value: bValue });
          return 0;
        }

        return bValue - aValue;
      });

    return datasets;
  };

export default transformGeohashCountByConnectionCategory;
