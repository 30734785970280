<script setup>
import capitalize from 'lodash/capitalize';
import { defineAsyncComponent, computed } from 'vue';

const props = defineProps({
  metricKind: {
    type: String,
    required: true,
  },
});

const iconComponent = computed(() => {
  return defineAsyncComponent(() => import(`./${capitalize(props.metricKind)}Icon.vue`));
});
</script>

<template>
  <component :is="iconComponent" />
</template>
