<script setup>
import RankingTable from '@/components/comparison/RankingTable';
import ScoreChangeTable from '@/components/comparison/ScoreChangeTable';
import OnxPaper from '@/components/onx/OnxPaper';

const props = defineProps({
  ...RankingTable.props,
  showScoreChange: {
    type: Boolean,
    default: false,
  },
  hideContent: {
    type: Boolean,
    default: false,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['ranking-table:click', 'header:click']);
</script>

<template>
  <OnxPaper class="onx-metric-box">
    <div
      v-if="$slots.header"
      class="onx-metric-box__header"
      :class="{
        'onx-metric-box__header--no-border': !ranks.length || hideContent,
      }"
      @click="emit('header:click')"
    >
      <slot name="header" />
    </div>

    <slot v-if="ranks.length && !hideContent">
      <div class="onx-metric-box__content" :class="{ 'onx-metric-box__content--inset': props.hideRank }">
        <RankingTable
          v-if="!showScoreChange"
          :bigger-better="props.biggerBetter"
          :ranks="props.ranks"
          :min-and-max="props.minAndMax"
          :is-variable-metric-category="props.isVariableMetricCategory"
          :hide-rank="props.hideRank"
          @click="emit('ranking-table:click', $event)"
        />
        <ScoreChangeTable v-else :ranks="props.ranks" />
      </div>
    </slot>

    <div v-if="$slots.footer && !hideActions" class="onx-metric-box__footer">
      <slot name="footer" />
    </div>
  </OnxPaper>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';
@import 'scss/onx-breakpoints.module';

.onx-metric-box {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 358px;
  border-radius: 2px;

  &__ranking-table {
    margin-bottom: 48px !important;

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    flex: 1;
    padding: 24px 16px 48px 8px;

    @include desktop {
      padding: 24px 24px 48px 16px;
    }

    &--inset {
      padding-left: 16px;

      @include desktop {
        padding-left: 24px;
      }
    }
  }

  &__header {
    border-bottom: 1px solid var(--onx-divider-color);
  }

  &__header--no-border {
    border-bottom: none;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 0 16px;
    height: 40px;

    border-top: 1px solid var(--onx-divider-color);
  }

  &__header,
  &__footer {
    padding-left: 16px;
    padding-right: 16px;

    @include desktop {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
</style>
