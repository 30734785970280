import osApi from '@/api/osApi';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { MetricResponse } from '@/types/MetricResponse';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import { Column } from '@/components/onx/table';

import { Dashboards } from '@/constants/dashboards';

import { exportToCsv } from '@/utils/files';

const createExportSimpleTableFullMetricToCsv =
  <T extends MetricStructuresEnum, U = any>({
    columns,
    metricSubtype,
  }: {
    metricSubtype: MetricSubtypes;
    columns: Column<U>[];
  }) =>
  async (
    _: any,
    title: string,
    {
      location,
      transform,
    }: {
      location: number;
      transform: (data: any) => any;
    },
  ) => {
    const url = `/${Dashboards.ThreeSixty}/metrics/${metricSubtype}_overall/${location}/90days/`;

    const response = await osApi.get<MetricResponse<T>>(url);

    const transformedData = transform([response.data]) as U[];
    const rows = transformedData.map((row) => columns.map((column) => column.value(row)));
    const headers = columns.map((column) => column.header);

    exportToCsv(`${title}.csv`, [headers, ...rows]);
  };

export default createExportSimpleTableFullMetricToCsv;
