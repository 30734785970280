import { ref, watch, Ref } from 'vue';
import debounce from 'lodash/debounce';

import { RefLike } from '@/types/helpers/RefLike';

const useDebouncedRef = <T>(inputRef: RefLike<T>, delay: number) => {
  const debouncedRef = ref<T>(inputRef.value) as Ref<T>;

  const updater = debounce(() => {
    debouncedRef.value = inputRef.value;
  }, delay);

  watch(inputRef, updater);

  return debouncedRef;
};

export default useDebouncedRef;
