import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import {
  chartMetricDefinitionsByPage,
  isChartMetricDefinitionsObject,
} from '@/chart-metric-definitions/ChartMetricDefinitions';
import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import Routes from '@/constants/routes';

const getAvailableChartMetrics = (metricSubtype: SelectableMetrics, routeName: string) => {
  const metricDefinitions = chartMetricDefinitionsByPage[routeName];

  if (!metricDefinitions) {
    return [];
  }

  let metrics: ChartMetricDefinition[];
  if (isChartMetricDefinitionsObject(metricDefinitions)) {
    if (routeName === Routes.FocusQoEDetails) {
      metrics = metricDefinitions[metricSubtype] ?? [];
    } else {
      metrics = Object.values(metricDefinitions)?.flat(2) ?? [];
    }
  } else {
    metrics = metricDefinitions;
  }

  return metrics;
};

export default getAvailableChartMetrics;
