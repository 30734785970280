import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { CountsByBandMetric, MetricStructuresEnum } from '@/types/MetricStructures';

const transformBandCountsPerOperator: DataTransformerConstructor<
  [MetricStructuresEnum.BandCounts],
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>>
> =
  ({ mainOperator }) =>
  ([response], horizontal?: boolean): DataTransformerReturnedItem[] => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const operatorBandCounts: CountsByBandMetric | undefined = response.results.find((datum) => {
      return mainOperator.canonical_network_id === datum.canonical_network_id;
    });

    if (!operatorBandCounts) {
      return [];
    }

    const color = `#${mainOperator.hex_color}`;

    const allBands = Object.keys(operatorBandCounts.counts);

    return [
      {
        data: allBands.map((band) => ({
          [dataAxis]: operatorBandCounts.counts[band], // number
          date: operatorBandCounts.date, // string
          [labelAxis]: band, // string
        })),
        type: 'bar',
        backgroundColor: color,
        color,
        label: mainOperator.name_mapped,
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color,
            label: mainOperator.name_mapped,
          },
        },
      },
    ];
  };

export default transformBandCountsPerOperator;
