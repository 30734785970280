import { MetricSubtypes } from '@/types/MetricSubtypes';
import { MetricStructuresEnum } from '@/types/MetricStructures';

import { type CongestionMostCongestedCellsRow } from '@/chart-metric-definitions/data-transformers/prepareCongestionMostCongestedCellsForTable';
import { congestionMostCongestedCellsColumns } from '@/360/base-station/table-columns/congestionMostCongestedCellsColumns';

import createExportSimpleTableFullMetricToCsv from '@/360/csv-export-overrides/createExportSimpleTableFullMetricToCsv';

const exportCongestionMostCongestedCellsToCsv = createExportSimpleTableFullMetricToCsv<
  MetricStructuresEnum.CongestionMostCongestedCells,
  CongestionMostCongestedCellsRow
>({ metricSubtype: MetricSubtypes.CongestionMostCongestedCellsFull, columns: congestionMostCongestedCellsColumns });

export default exportCongestionMostCongestedCellsToCsv;
