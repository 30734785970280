import { computed, Ref, unref, MaybeRef } from 'vue';
import { useQueries } from '@tanstack/vue-query';
import { isDate, format } from 'date-fns';
import mapboxgl from 'mapbox-gl';

import type { MetricSubtypes } from '@/types/MetricSubtypes';
import type { DateIntervalEnum } from '@/types/MetricRequestParameters';
import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import type { MetricResponse } from '@/types/MetricResponse';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

import osApi from '@/api/osApi';
import { Dashboards } from '@/constants/dashboards';

/**
 * Used to GET specific metric information for one or more metrics
 * Example URL: https://platform-test.opensignal.com/api/api/v2/focus/metrics/gamespacketloss_5g/7/90days/
 */
const useMetrics = <T extends MetricStructuresEnum>(
  dashboard: Dashboards,
  {
    aggregation,
    bbox,
    comparison = false,
    connectionCategory,
    dateInterval,
    enabled,
    endDate,
    geohashes,
    location,
    metrics,
    nbDays = 180,
    operatorInfo = true,
  }: {
    metrics: Ref<MetricSubtypes[]>;
    connectionCategory: AvailableConnectionCategory['categoryValue'];
    location: Ref<string | number>;
    aggregation: Ref<string>;
    operatorInfo?: MaybeRef<boolean>;
    nbDays?: MaybeRef<number | undefined>;
    comparison?: MaybeRef<boolean>;
    endDate?: MaybeRef<Date | undefined>;
    geohashes?: MaybeRef<string[] | undefined>;
    enabled?: MaybeRef<boolean | undefined>;
    bbox?: MaybeRef<mapboxgl.LngLatBounds | undefined>;
    dateInterval?: DateIntervalEnum;
  },
) => {
  const queryParams = computed(() => {
    const endDateValue = unref(endDate);
    const endDateString = endDateValue && isDate(endDateValue) ? format(endDateValue, 'yyyy-MM-dd') : endDateValue;
    const bboxValue = unref(bbox);
    const bboxString = bboxValue
      ? [bboxValue.getWest(), bboxValue.getSouth(), bboxValue.getEast(), bboxValue.getNorth()].join(',')
      : undefined;

    return {
      operator_info: unref(operatorInfo),
      nb_days: unref(nbDays),
      comparison: unref(comparison),
      end_date: endDateString,
      geohashes: unref(geohashes),
      bbox: bboxString,
      date_interval: dateInterval,
    };
  });

  const queries = useQueries({
    queries: metrics.value.map((metricSubtype) => {
      const metric = `${metricSubtype}_${connectionCategory}`;
      return {
        queryKey: [
          'metrics',
          metric,
          location,
          aggregation,
          operatorInfo,
          nbDays,
          comparison,
          endDate,
          geohashes,
          bbox,
          dateInterval,
        ],
        queryFn: async () => {
          return osApi.get<MetricResponse<T>>(
            `/${dashboard}/metrics/${metric}/${location.value}/${aggregation.value}/`,
            {
              params: queryParams.value,
            },
          );
        },
        staleTime: 1 * 60 * 60 * 1000, // 1 hour
        refetchOnWindowFocus: false,
        retry: 1,
        enabled,
      };
    }),
  });

  // TypeScript issue (or I am missing something)
  // TypeScript says queries is a ShallowRef, so .value is expected
  // But it's just an array, so .value is undefined
  // Whether it's a .value or not, unref takes out the actual value T, and then it's made into a consistent ComputedRef<T>
  const computedQueries = computed(() => {
    return unref(queries);
  });

  const someLoading = computed(() => {
    return computedQueries.value.some((query) => query.isLoading);
  });

  const somePending = computed(() => {
    return computedQueries.value.some((query) => query.isPending);
  });

  return {
    queries: computedQueries,
    someLoading,
    somePending,
  };
};

export default useMetrics;
