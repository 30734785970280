<template>
  <button class="MenuToggle" :class="{ 'MenuToggle--collapsed': !isMenuVisible }" @click="toggleMenu(triggerResize)">
    <FontAwesomeIcon v-if="isMenuVisible" :icon="icons.faChevronLeft" size="sm" />
    <FontAwesomeIcon v-else :icon="icons.faBars" size="sm" />
  </button>
</template>

<script>
import { faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MenuToggle',
  components: {
    FontAwesomeIcon,
  },
  props: {
    triggerResize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      icons: {
        faBars,
        faChevronLeft,
      },
    };
  },
  computed: {
    ...mapGetters({
      isMenuVisible: 'dashboard/isMenuVisible',
    }),
  },
  methods: {
    ...mapActions({
      toggleMenu: 'dashboard/toggleMenu',
    }),
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;

.MenuToggle {
  background: $color-white;
  position: absolute;
  top: 58px;
  right: -12px;
  z-index: $z-index-sidebar-toggle;
  width: 24px;
  height: 24px;
  font-size: 12px;
  border-radius: 50%;
  border: 0;
  opacity: 0;
  transition: $sidebar-transition;
  outline: 0;
  padding: 0;
  text-align: center;
  line-height: 24px;
  box-shadow: 0 0 40px rgba($ci-dark-blue, 0.4);
  color: $ci-dark-blue;

  @media screen and (max-width: $ci-breakpoint-tablet) {
    opacity: 1;
  }

  &--collapsed {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }

  .svg-inline--fa {
    vertical-align: 0;
    margin-left: 0;
  }

  .fa-chevron-left {
    margin-left: -2px;
  }
}
</style>
