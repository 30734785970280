const cache = {};

export default function create(namespace, initial = []) {
  if (!namespace) {
    throw new TypeError('No namespace provided for the array-store 💣');
  }

  cache[namespace] = {};
  const types = {
    SET: `${namespace}:SET`,
    SET_KEY: `${namespace}:SET_KEY`,
    SET_CURRENT: `${namespace}:SET_CURRENT`,
    ADD: `${namespace}:ADD`,
    REPLACE: `${namespace}:REPLACE`,
  };

  const state = () => ({
    array: initial,
    byIdentifier: {},
    current: null,
    key: 'key',
  });

  const mutations = {
    [types.SET](state, payload) {
      if (Array.isArray(payload)) {
        state.array = [...payload];
        return;
      }

      const { array, identifier } = payload;
      state.array = [...array];

      if (identifier) {
        array.forEach((item) => {
          state.byIdentifier[item[identifier]] = item;
        });
      }
    },
    [types.SET_KEY](state, key) {
      state.key = key;
    },
    [types.SET_CURRENT](state, current) {
      state.current = current;
    },
    [types.ADD](state, array) {
      state.array = state.array.concat(array);
    },
    [types.REPLACE](state, [index, item]) {
      state.array.splice(index, 1, item);
    },
  };

  const getters = {
    [namespace]: (state) => state.array,
    [`${namespace}/byIdentifier`]: (state) => state.byIdentifier,
    [`${namespace}/current`]: (state) => {
      if (state.byIdentifier[state.current]) {
        return state.byIdentifier[state.current];
      }

      if (cache[state.current]) {
        return cache[state.current];
      }

      return (
        state.array.find((i) => {
          if (i[state.key] === state.current) {
            cache[state.current] = i;
            return true;
          }

          return false;
        }) || {}
      );
    },
  };

  return {
    types,
    state,
    mutations,
    getters,
  };
}
