import { exportToCsv } from '@/utils/files';

const exportCdnIpLiveVideoResolutionToCsv = (data: { labels: string[]; datasets: any[] }, title: string) => {
  const headers = ['Operator', 'CDN', 'IP', 'Resolution', 'Percentage'];
  const rows = data.datasets.flatMap((dataset: any) => {
    return dataset.data.map((datum: any) => {
      return [datum.operator.name_mapped, datum.cdn, datum.ip, datum.resolution, datum[dataset.dataAxis]];
    });
  });

  exportToCsv(`${title}.csv`, [headers, ...rows]);
};

export default exportCdnIpLiveVideoResolutionToCsv;
