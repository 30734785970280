import type { Dashboards } from '@/constants/dashboards';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useCheckIfMetricSubtypeExists from '@/composables/useCheckIfMetricSubtypeExists';
import useUser from '@/components/onx/composables/useUser';

export const OnxPermissionTopics = {
  myDashboards: 'myDashboards',
  geohash: 'geohash',
  RANHealth: 'RANHealth',
  RANHealthCoverage: 'RANHealthCoverage',
  RANHealthNetworkRollout: 'RANHealthNetworkRollout',
  RANHealthDeployedSpectrum: 'RANHealthDeployedSpectrum',
} as const;

export type OnxPermissionTopics = (typeof OnxPermissionTopics)[keyof typeof OnxPermissionTopics];

export const useOnxLicencePermission = (dashboard: Dashboards, onxPermissionTopic: OnxPermissionTopics) => {
  const { data } = useDashboardInfo(dashboard);
  const { checkIfMetricSubtypeExists } = useCheckIfMetricSubtypeExists();
  const { user } = useUser();

  const onx_licence_permission = data.value?.data.onx_licence_permission;

  const hasCoverage =
    onx_licence_permission?.vega_endpoints_enabled.includes('coverage') &&
    checkIfMetricSubtypeExists(MetricSubtypes.CoverageGeohash7CountSubcategory);
  const hasNetworkRollout = onx_licence_permission?.vega_endpoints_enabled.includes('network_rollout');
  const hasDeployedSpectrum =
    user.value?.uses_brytlyt && onx_licence_permission?.vega_endpoints_enabled.includes('deployed_spectrum');

  const predicateFunctionLookup = {
    [OnxPermissionTopics.myDashboards]: onx_licence_permission?.my_dashboard_enabled,
    [OnxPermissionTopics.geohash]: onx_licence_permission?.geohash_enabled,
    [OnxPermissionTopics.RANHealth]: hasCoverage || hasNetworkRollout,
    [OnxPermissionTopics.RANHealthCoverage]: hasCoverage,
    [OnxPermissionTopics.RANHealthNetworkRollout]: hasNetworkRollout,
    [OnxPermissionTopics.RANHealthDeployedSpectrum]: hasDeployedSpectrum,
  };

  return predicateFunctionLookup[onxPermissionTopic] ?? false;
};
