import { format } from 'date-fns';
import { LABEL_OVERRIDES } from '../constants/chartjs';
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats';
import { getSafeDate } from '@/utils/date';

export const getTooltipState = (chart, tooltip) => {
  const positionY = chart.canvas.offsetTop;
  const positionX = chart.canvas.offsetLeft;
  const width = parseInt(chart.canvas.style.width);
  const posLeft = width / 2 > tooltip.caretX;
  const tooltipStyle = {
    opacity: 1,
    left: posLeft ? positionX + tooltip.caretX + 10 + 'px' : 'auto',
    right: posLeft ? 'auto' : width + positionX - tooltip.caretX + 30 + 'px',
    top: positionY + 10 + 'px',
    padding: tooltip.yPadding + 'px ' + tooltip.xPadding + 'px',
  };
  return {
    posClass: tooltip.yAlign || 'no-transform',
    tooltipStyle: tooltipStyle,
    display: !!tooltip.opacity,
  };
};

export const findDataType = (data, label, dataType) => {
  return data.find((i) => i.filterBy === dataType && i.label === label).y;
};

export const getDatapointsByIndex = (datasets, index, isHorizontal, sort = true) => {
  if (index >= 0) {
    const result = datasets.map((dataset) => {
      const datapoint = dataset.data[index];
      return {
        ...dataset,
        data: datapoint,
        value: typeof datapoint !== 'object' ? datapoint : isHorizontal ? datapoint.x : datapoint.y,
        uci: datapoint.uci,
        lci: datapoint.lci,
        color: dataset.backgroundColor,
        label: dataset.label,
      };
    });

    if (sort) {
      return result.sort((a, b) => b.value - a.value);
    } else {
      return result;
    }
  }

  return undefined;
};

export const getDistributionDatapointsByIndex = (datasets, index) => {
  return index >= 0
    ? datasets
        .map((dataset) => {
          const datapoint = dataset.data[index];
          return {
            ...dataset,
            value: datapoint,
            color: dataset.backgroundColor,
            label: dataset.label,
          };
        })
        .sort((a, b) => b.value - a.value)
    : undefined;
};

export const getDateFromDatapoints = (datapoints) => {
  return datapoints[0] && format(getSafeDate(datapoints[0].x), API_LONG_DATE_FORMAT);
};

export const getDatapointsByDate = (datasets, date, noDecimals) => {
  const enhancedDatasets = datasets
    .filter((item) => item.data.find((point) => point.x === date))
    .map((dataset) => {
      const point = dataset.data.find((point) => point.x === date);
      const compare = dataset.compare ? dataset.compare.data.find((point) => point.x === date) : {};

      return {
        color: dataset.operatorColor || dataset.borderColor,
        display: !dataset.filterOut,
        filterBy: dataset.filterBy,
        label: dataset.label,
        x: point.x,
        y: point.y,
        value: noDecimals ? point.y : point.y ? Number.parseFloat(point.y).toFixed(2) : point.y,
        compare: {
          value: noDecimals ? compare.y : compare.y ? Number.parseFloat(compare.y).toFixed(2) : compare.y,
          lci: compare.lci,
          uci: compare.uci,
        },
      };
    });

  return enhancedDatasets
    .filter((i) => i.display)
    .map((d) => {
      return {
        ...d,
        uci: findDataType(enhancedDatasets, d.label, 'uci'),
        lci: findDataType(enhancedDatasets, d.label, 'lci'),
      };
    })
    .sort((a, b) => b.value - a.value);
};

export const getLinechartRange = (min, max, proportion) => {
  const diff = Math.max(Math.floor((max - min) / proportion), 1);
  // we provide 100 so we avoid availability percentage charts to go above 100
  const suggestedMax = Math.min(max + diff, 100);
  const suggestedMin = Math.max(min - diff, 0);

  return {
    suggestedMin,
    suggestedMax,
  };
};

export const multiChartId = (identifier) => `chart-${identifier}`;

export const getDatasetLabel = (label) => (LABEL_OVERRIDES[label] ? LABEL_OVERRIDES[label] : label);

export const getInitialDatasetLabel = (label) => {
  for (const initialLabel in LABEL_OVERRIDES) {
    if (LABEL_OVERRIDES[initialLabel] === label) {
      return initialLabel;
    }
  }

  return label;
};
