import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

const congestionColorsMap = {
  ran: 'rgba(134, 34, 117, 1)',
  non_ran: 'rgba(83, 182, 176, 1)',
  at_risk: 'rgba(243, 178, 62, 1)',
  underutilized: 'rgba(235, 78, 99, 1)',
};

const congestionLabelMap = {
  ran: 'RAN Congestion',
  non_ran: 'Non-RAN Congestion',
  at_risk: 'At Risk',
  underutilized: 'Underutilized Cells',
};

export const transformCongestionCategoryByOperator: DataTransformerConstructor<
  [MetricStructuresEnum.Breakdown],
  Required<Pick<DataTransformerFnOptions, 'operators'>> & Pick<DataTransformerFnOptions, 'selectedConnectionCategories'>
> =
  ({ operators }) =>
  ([response], horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const datasets = Object.keys(metricsFilteredByOperator[0].mean).reduce(
      (acc, key) => {
        const color = congestionColorsMap[key as keyof typeof congestionColorsMap];

        acc[key] = {
          type: 'bar',
          label: congestionLabelMap[key as keyof typeof congestionLabelMap] ?? key,
          backgroundColor: color,
          color,
          data: [],
          dataAxis,
          labelAxis,
        };

        return acc;
      },
      {} as Record<string, DataTransformerReturnedItem>,
    );

    metricsFilteredByOperator.forEach((datum) => {
      const operator = operators.find((operator) => operator.canonical_network_id === datum.canonical_network_id);

      if (!operator) {
        return;
      }

      Object.keys(datum.mean).forEach((key) => {
        datasets[key].data.push({
          ...datum,
          mean: datum.mean[key],
          [dataAxis]: datum.mean[key],
          [labelAxis]: operator.name_mapped,
        });
      });
    });

    const sorted = Object.keys(congestionColorsMap).map((key) => datasets[key]);
    return sorted;
  };
