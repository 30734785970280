import { CDNS } from '@/constants/constants';
import { Column } from '@/components/onx/table';

export type CoreEndpointDatasetRow = {
  canonical_network_id: number;
  cdn: string;
  count: number;
  date: string;
  ip: string;
  location: number;
  operator_name?: string;
  percentage: number;
};

export const commonCoreEndpointDatasetColumns: Column<CoreEndpointDatasetRow>[] = [
  {
    key: 'canonical_network_id',
    header: 'Operator',
    value(row) {
      return row.canonical_network_id;
    },
    cell(row) {
      return row.operator_name || row.canonical_network_id;
    },
  },
  {
    key: 'cdn',
    header: 'Endpoint',
    value(row) {
      if (row.cdn in CDNS) {
        return CDNS[row.cdn as keyof typeof CDNS];
      }
      return row.cdn;
    },
  },
  {
    key: 'ip',
    header: 'IP',
    value(row) {
      return row.ip;
    },
    sort: (a, b) => a.localeCompare(b),
  },
  {
    key: 'percentage',
    header: 'Percentage of Total Tests',
    value: (row) => row.percentage,
    cell: (row) => `${row.percentage.toFixed(2)}%`,
    sort: (a, b) => a - b,
  },
];
