import { exportToCsv } from '@/utils/files';

const exportFailuresToCsv = (data: { labels: string[]; datasets: any[] }, title: string) => {
  const headers = ['Failure', 'Operator', 'Date', 'Percentage'];
  const rows = data.datasets.flatMap((dataset: any) => {
    return dataset.data.map((datum: any) => {
      const date = datum.date.split('T')[0]; // convert datetime to date only
      return [dataset.label, datum.x, date, datum.y.toFixed(2)];
    });
  });

  exportToCsv(`${title}.csv`, [headers, ...rows]);
};

export default exportFailuresToCsv;
