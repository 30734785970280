<script setup lang="ts">
import { computed, onMounted } from 'vue';

import OnxVegaMap from '@/components/onx/vega-map/OnxVegaMap.vue';
import { Dashboards } from '@/constants/dashboards';
import { MapOperatorSelector, useMapOperatorSelector } from '@/components/map-operator-selector';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import useGeocoding from '@/composables/useGeocoding';
import { OS_GEOCODINGS } from '@/constants/constants';
import OnxStackedBarChart from '@/components/onx/charts/OnxBarChart.vue';
import useFilters from '@/composables/useFilters';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import transformGeohashCountByOperator from '@/chart-metric-definitions/data-transformers/transformGeohashCountByOperator';
import { getDatapointsByIndex, getTooltipState } from '@/utils/charts';
import { exportCoverageSubcategoriesGeohashCountsToCsv } from '@/focus/csv-export-overrides/exportCoverageCategoriesToCsv';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import useDebouncedRef from '@/composables/useDebouncedRef';
import useAnalytics from '@/composables/useAnalytics';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import useUser from '@/components/onx/composables/useUser';

const { track } = useAnalytics();
const { user } = useUser();
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
const { selectedMapOperator } = useMapOperatorSelector(Dashboards.Focus);
const { actualGeocoding } = useGeocoding(Dashboards.Focus);
const enableHeavyDbPolygons = computed(() => {
  return actualGeocoding.value === OS_GEOCODINGS.cities;
});

const { aggregation, locationId } = useFilters(Dashboards.Focus);
const { geohashes } = useGeohashesQueryParam();
const debouncedGeohashes = useDebouncedRef(geohashes, 500);

const tooltipPlugin = (context: any, tooltip: any, chartData: any) => {
  const tt = context.tooltip;
  const tooltipState = getTooltipState(context.chart, tt);

  const datapoints = getDatapointsByIndex(chartData.value.datasets, chartData.value.labels.indexOf(tt.title[0])).filter(
    (d: any) => {
      return d.data.y > 0;
    },
  );

  const total = datapoints.reduce((acc: any, curr: any) => {
    return acc + curr.data.y;
  }, 0);

  const datapointsWithPercentages = datapoints.map((d: any) => {
    return {
      ...d,
      secondaryValue: `${((d.data.y / total) * 100).toFixed(2)}%`,
    };
  });

  if (datapoints.length) {
    tooltip.value = {
      ...tooltip.value,
      ...tooltipState,
      datapoints: datapointsWithPercentages,
      title: tt.title[0],
    };
  } else {
    tooltip.value.display = false;
  }
};

onMounted(() => {
  track('focus RAN health coverage page view');
});
</script>

<template>
  <template v-if="selectedMapOperator">
    <div class="ran-health-coverage__operator-selector-container">
      <MapOperatorSelector>
        <OnxButton variant="tertiary">
          <span>Map Operator: {{ selectedMapOperator?.name_mapped }}</span>
          <ChevronDownIcon />
        </OnxButton>
      </MapOperatorSelector>
    </div>

    <div class="onx-grid">
      <OnxSwyftMap
        v-if="user?.uses_brytlyt"
        title="Coverage"
        :network-operators="[selectedMapOperator]"
        class="sm:onx-col-5 md:onx-col-8"
        :geohash-level="7"
        map-endpoint="maps/coverage"
        :enable-geohashes="enableHeavyDbPolygons"
        :location-id="locationId"
        :key="`${locationId}-brytlyt`"
      />

      <OnxVegaMap
        v-else
        class="sm:onx-col-5 md:onx-col-8"
        :network-operator="selectedMapOperator"
        endpoint="render-coverage-vega"
        title="Coverage"
        :enable-geohashes="enableHeavyDbPolygons"
        :key="`${locationId}-vega`"
      />

      <OnxStackedBarChart
        :dashboard="Dashboards.Focus"
        class="sm:onx-col-3 md:onx-col-4"
        metric="coveragegeohash7countsubcategory_overall"
        :location="locationId"
        :aggregation="aggregation"
        :geohashes="debouncedGeohashes"
        :transform="transformGeohashCountByOperator({ operators: selectedOperators })"
        chart-title="Overall Coverage Geohash 7 Counts"
        chart-title-tooltip="Geohash bins categorized by dominant technology sub-type"
        :chart-tooltip-plugin="tooltipPlugin"
        chart-tooltip-show-secondary-value
        :on-export-to-csv="exportCoverageSubcategoriesGeohashCountsToCsv"
      />
    </div>
  </template>
  <LoaderGrid v-else />
</template>

<style lang="scss">
@import 'scss/coverage-map';

.ran-health-coverage__operator-selector-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-bottom: 8px;
  @include laptop() {
    margin-bottom: 16px;
  }
}
</style>
