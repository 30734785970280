import { commonCoreEndpointDatasetColumns, CoreEndpointDatasetRow } from './commonColumns';
import type { Column } from '@/components/onx/table';

export const gamesAndVoiceColumns: Column<
  CoreEndpointDatasetRow & {
    packet_loss: number;
    packet_delay: number;
    jitter: number;
  }
>[] = [
  ...commonCoreEndpointDatasetColumns,
  {
    value: (row) => row.packet_loss,
    cell: (row) => `${row.packet_loss.toFixed(2)}%`,
    header: 'Packet Loss',
    key: 'packet_loss',
    sort: (a, b) => a - b,
  },
  {
    value: (row) => row.packet_delay,
    cell: (row) => `${row.packet_delay.toFixed(2)}ms`,
    header: 'Packet Delay',
    key: 'packet_delay',
    sort: (a, b) => a - b,
  },
  {
    value: (row) => row.jitter,
    cell: (row) => `${row.jitter.toFixed(2)}ms`,
    header: 'Jitter',
    key: 'jitter',
    sort: (a, b) => a - b,
  },
];
