<script setup>
import { computed } from 'vue';
import RankDownIcon from '@/components/onx/icons/rank/RankDownIcon.vue';
import RankNoChangeIcon from '@/components/onx/icons/rank/RankNoChangeIcon.vue';
import RankUpIcon from '@/components/onx/icons/rank/RankUpIcon.vue';

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
});

const icon = computed(() => {
  if (props.value === null) {
    return null;
  }

  if (!props.value) {
    return RankNoChangeIcon;
  } else if (props.value > 0) {
    return RankUpIcon;
  } else {
    return RankDownIcon;
  }
});

const deltaLabel = computed(() => {
  const { value } = props;

  if (value === null) {
    return 'N/A';
  }

  if (!value) {
    return '-';
  }

  return value < 0 ? `${value}` : `+${value}`;
});
</script>

<template>
  <div class="onx-delta">
    <component :is="icon" small />
    <span
      class="onx-delta__label"
      :class="{
        'onx-delta__label--na': props.value === null,
      }"
      >{{ deltaLabel }}</span
    >
  </div>
</template>

<style scoped lang="scss">
@import 'scss/mixins';

.onx-delta {
  display: flex;
  align-items: center;
  gap: 4px;
}

.onx-delta__label {
  font-size: pxToRem(12);
  color: var(--onx-caption-color);
  white-space: nowrap;
}

.onx-delta__label--na {
  color: var(--charcoal-200);
}
</style>
