import { getTooltipState, getDatapointsByIndex } from '@/utils/charts';

const videoLiveTimeOnResolutionByIPTooltip = (horizontal?: boolean) => (context: any, tooltip: any, chartData: any) => {
  const dataAxis = horizontal ? 'x' : 'y';

  const tt = context.tooltip;
  const tooltipState = getTooltipState(context.chart, tt);

  const datapoints = getDatapointsByIndex(
    chartData.value.datasets,
    chartData.value.labels.indexOf(tt.title[0]),
    horizontal,
  ).filter((d: any) => {
    return d.data[dataAxis] > 0;
  });

  if (datapoints.length) {
    tooltip.value = {
      ...tooltip.value,
      ...tooltipState,
      datapoints: datapoints.map((d: any) => ({
        ...d,
        value: `${d.value}%`,
      })),
      title: tt.title[0],
    };
  } else {
    tooltip.value.display = false;
  }
};

export default videoLiveTimeOnResolutionByIPTooltip;
