import { Ref, ref, watch, watchEffect, computed } from 'vue';
import mapboxgl from 'mapbox-gl';
import debounce from 'lodash/debounce';
import { useQuery } from '@tanstack/vue-query';

import osApi from '@/api/osApi';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import type { RenderImageResponse } from '@/types/RenderImageResponse';

const getSwyftTileSource = (map: mapboxgl.Map) => {
  return map.getSource<mapboxgl.RasterTileSource>('swyft-tiles');
};

type UseBrytlytLayerArgs = {
  mapEndpoint: string;
  geohashLevel: number;
  countryIso3: Ref<string>;
  canonicalNetworkIds: Ref<number[]>;
  locationId: Ref<number>;
  connectionCategories?: Ref<string[]>;
};

const useSwyftLayer = ({
  canonicalNetworkIds,
  connectionCategories,
  countryIso3,
  geohashLevel,
  locationId,
  mapEndpoint,
}: UseBrytlytLayerArgs) => {
  const dashboard = useCurrentDashboardName();
  const loading = ref(true);
  const legendEntries = ref<{ color: string; label: string }[]>([]);

  const canonicalNetworkIdQueryStr = computed(() => canonicalNetworkIds.value.join(','));
  const connectionCategoriesQueryStr = computed(() => connectionCategories?.value.join(',').replace('lte', '4g'));

  const swyftTiles = computed(() => {
    const url = new URL(`${import.meta.env.VITE_BASE_URL}api/v2/${dashboard.value}/${mapEndpoint}/`);

    url.searchParams.append('zoom_level', '{z}');
    url.searchParams.append('x_tile', '{x}');
    url.searchParams.append('y_tile', '{y}');

    url.searchParams.append('output_type', 'png');
    url.searchParams.append('geohash_level', geohashLevel.toString());
    url.searchParams.append('country_iso3', countryIso3.value);
    url.searchParams.append('canonical_network_ids', canonicalNetworkIdQueryStr.value);
    url.searchParams.append('location_id', locationId.value.toString());

    if (connectionCategoriesQueryStr.value) {
      url.searchParams.append('connection_categories', connectionCategoriesQueryStr.value);
    }

    return [decodeURI(url.toString())];
  });

  const query = useQuery({
    queryKey: [
      mapEndpoint,
      dashboard,
      geohashLevel,
      countryIso3,
      canonicalNetworkIds,
      locationId,
      connectionCategoriesQueryStr,
    ],
    queryFn: async () => {
      const params = {
        zoom_level: 1,
        x_tile: 1,
        y_tile: 1,
        geohash_level: geohashLevel,
        country_iso3: countryIso3.value,
        canonical_network_ids: canonicalNetworkIdQueryStr.value,
        connection_categories: connectionCategoriesQueryStr.value,
        location_id: locationId.value,
        output_type: 'legend',
      };

      return osApi.get<RenderImageResponse>(`/${dashboard.value}/${mapEndpoint}/`, {
        params,
      });
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
  });

  watchEffect(() => {
    const response = query.data.value;

    if (!response) {
      legendEntries.value = [];
      return;
    }

    legendEntries.value = response.data.legend.domain.map((domainEntry, i) => {
      return {
        color: response.data.legend.range[i],
        label: domainEntry,
      };
    });
  });

  const setup = (map: mapboxgl.Map) => {
    // debouncing so the loader ends once events stop firing
    const initialLoadingHandler = debounce((e: mapboxgl.MapSourceDataEvent) => {
      if (e.sourceId === 'swyft-tiles') {
        loading.value = !e.isSourceLoaded;

        if (map && e.isSourceLoaded) {
          map.off('sourcedata', initialLoadingHandler);
        }
      }
    }, 100);

    if (map) {
      map.addSource('swyft-tiles', {
        id: 'swyft-tiles',
        type: 'raster',
        tiles: swyftTiles.value,
      });

      map.on('sourcedata', initialLoadingHandler);
      map.on('error', () => {
        loading.value = false;
      });

      map.addLayer(
        {
          id: 'swyft-layer',
          type: 'raster',
          source: 'swyft-tiles',
          paint: {
            'raster-opacity': 0.85,
          },
        },
        'z-index-1',
      );
    }

    watch(swyftTiles, () => {
      const swyftTilesSource = getSwyftTileSource(map);
      if (swyftTilesSource) {
        swyftTilesSource.setTiles(swyftTiles.value);
      }
    });
  };

  return {
    setup,
    loading,
    legendEntries,
  };
};

export default useSwyftLayer;
