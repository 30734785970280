import has from 'lodash/has';
import get from 'lodash/get';

export const getScoreChange = (current, previous) => {
  return Math.round((current - previous) * 100) / 100;
};

export const getScoreChangeVsOthers = (currentHomeValue, previousHomeValue, vsValue, vsPreviousValue) => {
  const vsChange = getScoreChange(vsValue, vsPreviousValue);
  const homeChange = getScoreChange(currentHomeValue, previousHomeValue);

  return getScoreChange(homeChange, vsChange);
};

export const getScoreChanges = (data, homeData, valueProperty, compareTo, isHome) => {
  let rankChange = null;
  let change = null;
  let vsOthers = null;

  if (data.comparison && has(data.comparison[compareTo], valueProperty)) {
    rankChange = get(data.comparison[compareTo], 'rank');
  }

  if (data.previous && has(data.previous[compareTo], valueProperty)) {
    change = getScoreChange(data[valueProperty], get(data.previous[compareTo], valueProperty));
  }

  if (!isHome && has(data.previous[compareTo], valueProperty) && has(homeData?.previous[compareTo], valueProperty)) {
    vsOthers = getScoreChangeVsOthers(
      homeData[valueProperty],
      get(homeData.previous[compareTo], valueProperty),
      data[valueProperty],
      get(data.previous[compareTo], valueProperty),
    );
  }

  return [rankChange, change, vsOthers];
};
