<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import { FieldGroup } from '@/components/menu';
import OnxButtonLink from '@/components/onx/OnxButtonLink.vue';
import LocationSelector from '@/components/location-search/LocationSelector.vue';

const store = useStore();
const currentLocation = computed(() => store.getters['location/currentLocation']);

type Props = {
  countriesOnly?: boolean;
  onLocationChange: (location: any) => void;
  onResetLocation: () => void;
  hiddenGeocodings?: number[];
  enableBboxFiltering?: boolean;
};

const props = defineProps<Props>();

const showResetButton = computed(() => {
  return props.enableBboxFiltering || parseInt(currentLocation.value?.granularityId) !== 1;
});
</script>

<template>
  <FieldGroup label="Geographies">
    <LocationSelector
      :countries-only="!!countriesOnly"
      @navigate="onLocationChange"
      :hidden-geocodings="hiddenGeocodings"
      :enableBboxFiltering="enableBboxFiltering"
    />

    <template #title-sibling v-if="showResetButton">
      <OnxButtonLink size="sm" data-test-id="focus_lhn_reset-location-btn" @click="onResetLocation">
        <span>Reset</span>
      </OnxButtonLink>
    </template>

    <slot />
  </FieldGroup>
</template>
