<template>
  <div class="CompetitiveChartTitle">
    <div class="CompetitiveChartTitle__text">
      <div class="CompetitiveChartTitle__title">
        Competitive Trends | {{ titleLabels.chartTitle.geography }}

        <CustomTooltip v-if="tooltip" :message="tooltip">
          <QuestionMark />
        </CustomTooltip>
      </div>
      <div class="CompetitiveChartTitle__subtitle">
        <span> {{ titleLabels.fullTitle }} ({{ titleLabels.chartTitle.metricUnit }}) </span>
        <span v-if="titleLabels.warning">
          | No data available for selected end date. The closest available date was automatically selected.
        </span>
      </div>
    </div>
    <div class="CompetitiveChartTitle__tools">
      <slot />
    </div>
  </div>
</template>

<script>
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import QuestionMark from '@/components/tooltip/QuestionMark';

export default {
  name: 'CompetitiveChartTitle',
  components: {
    QuestionMark,
    CustomTooltip,
  },
  props: {
    titleLabels: {
      type: Object,
      default: undefined,
    },
    tooltip: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.CompetitiveChartTitle {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;

  @include tablet {
    .CompetitiveChartTitle__text {
      flex: 1;
    }
    .CompetitiveChartTitle__tools {
      flex: 0 0 auto;
      margin-top: 0;
    }
  }

  &__title {
    font-size: $font-size-14;
    line-height: 26px;
    font-weight: 700;
    color: var(--charcoal-500);
    text-transform: uppercase;
  }
  &__subtitle {
    font-size: $font-size-14;
    line-height: 16px;
    color: var(--charcoal-500);
  }
  &__date {
    font-weight: $font-weight-roboto-medium;
  }
}
.CompetitiveChartTitle__text {
  flex: 0 0 100%;
}
.CompetitiveChartTitle__tools {
  flex: 0 0 100%;
  margin-top: 8px;
}
</style>
