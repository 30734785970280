import { computed } from 'vue';
import { useRoute } from 'vue-router';
import ROUTE_GROUPS from '@/constants/routeGroups';

export enum PRODUCT {
  FOCUS,
  SPOTLIGHT,
  THREE_SIXTY,
  NONE,
}

/**
 * Given the current route, returns info about the current product.
 */
export const useProduct = () => {
  const route = useRoute();
  const product = computed(() => {
    if (route.matched.some((record) => record.name && ROUTE_GROUPS.Focus.includes(record.name as string))) {
      return PRODUCT.FOCUS;
    } else if (route.matched.some((record) => record.name && ROUTE_GROUPS.Spotlight.includes(record.name as string))) {
      return PRODUCT.SPOTLIGHT;
    } else if (route.matched.some((record) => record.name && ROUTE_GROUPS.ThreeSixty.includes(record.name as string))) {
      return PRODUCT.THREE_SIXTY;
    } else {
      return PRODUCT.NONE;
    }
  });

  return {
    product,
  };
};
