<script setup lang="ts">
import { computed } from 'vue';

import useMapOperatorSelector from './useMapOperatorSelector';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';

const dashboard = useCurrentDashboardName();
const { networkOperators, onMapOperatorChange, selectedMapOperator } = useMapOperatorSelector(dashboard.value);

const items = computed(() => {
  return networkOperators.value.map((network) => ({
    keyAccessor: network.canonical_network_id,
    label: network.name_mapped,
    value: network,
    checked: network.canonical_network_id === selectedMapOperator.value.canonical_network_id,
  }));
});
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Operator:"
    :selectedLabel="selectedMapOperator?.name_mapped"
    :items="items"
    @list-item-click="onMapOperatorChange"
  />
</template>
