import { format } from 'date-fns';
import { API_SHORT_DATE_FORMAT } from '@/constants/dateFormats';
import { getTooltipState } from '@/utils/charts';

type ValueFn = (args: { datapoint: any; dataAxis: 'x' | 'y' }) => number | string;

const coreCdnIpSpeedTooltipPlugin =
  ({
    horizontal = false,
    secondaryValueFn,
    valueFn,
  }: {
    horizontal?: boolean;
    valueFn: ValueFn;
    secondaryValueFn?: ValueFn;
  }) =>
  (context: any, tooltip: any) => {
    const dataAxis = horizontal ? 'x' : 'y';

    const tt = context.tooltip;
    const tooltipState = getTooltipState(context.chart, tt);

    const datapoints = tt.dataPoints.map((datapoint: any) => {
      return {
        value: valueFn({ datapoint, dataAxis }),
        secondaryValue: secondaryValueFn?.({ datapoint, dataAxis }),
        color: datapoint.dataset.backgroundColor,
        label: datapoint.dataset.label,
        data: datapoint,
      };
    });

    if (datapoints.length) {
      tooltip.value = {
        ...tooltip.value,
        ...tooltipState,
        datapoints,
        title: format(new Date(tt.dataPoints[0].raw.date), API_SHORT_DATE_FORMAT),
      };
    } else {
      tooltip.value.display = false;
    }
  };

export default coreCdnIpSpeedTooltipPlugin;
