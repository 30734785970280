<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useActions } from 'vuex-composition-helpers';
import FocusBreadcrumbs from './FocusBreadcrumbs.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import useUser from '@/components/onx/composables/useUser';
import OnxSidebar from '@/components/onx/OnxSidebar.vue';
import PerformanceLayout from '@/components/performance/PerformanceLayout.vue';
import { Dashboards } from '@/constants/dashboards';
import FocusMenu from '@/focus/focus-menu/FocusMenu.vue';
import PossibleConnectionCategoriesProvider from '@/connection-categories-provider/PossibleConnectionCategoriesProvider.vue';
import allPossibleFocusConnectionCategories from '@/focus/constants/allPossibleFocusConnectionCategories';
import useAnalytics from '@/composables/useAnalytics';

const matches = useBreakpoints();
const { setUser, setUserSettings, user } = useUser();
const { checkDisclaimer } = useActions(['checkDisclaimer']);
const { track } = useAnalytics();

const ready = ref(false);

onMounted(async () => {
  if (!user.value.id) {
    const res = await setUser();

    if (res) {
      checkDisclaimer();
    }
  }

  await setUserSettings(Dashboards.Focus);

  ready.value = true;

  track('focus');
});
</script>

<template>
  <PossibleConnectionCategoriesProvider :value="allPossibleFocusConnectionCategories">
    <PerformanceLayout v-if="ready" class="Focus">
      <template v-if="matches.laptop.value" #sidebar>
        <OnxSidebar>
          <FocusMenu />
        </OnxSidebar>
      </template>

      <template #breadcrumbs>
        <FocusBreadcrumbs />
      </template>

      <template #header>
        <router-view name="header" />
      </template>

      <template #content>
        <router-view />
      </template>
    </PerformanceLayout>
    <LoaderGrid v-else />
  </PossibleConnectionCategoriesProvider>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.Focus {
  & .onx-grid.fluid {
    @include desktop {
      --onx-grid-fluid-item-default-min-width: 500px;
    }
  }

  .PerformanceLayout__header {
    position: sticky;
    top: -46px;
    z-index: 100;
  }

  .PerformanceLayout__content {
    padding: 8px;
    overflow: initial;

    @include laptop {
      padding: 16px;
    }
  }
}
</style>
