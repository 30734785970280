import { computed } from 'vue';

import { useStore } from 'vuex';

import type { MetricSubtypes } from '@/types/MetricSubtypes';

const useCheckIfMetricSubtypeExists = () => {
  const store = useStore();

  const checkIfMetricSubtypeExists = (metricSubtype: MetricSubtypes) => {
    const existingMetricSubtypes = store.getters['metrics/subtypes'];
    return !!existingMetricSubtypes[metricSubtype];
  };

  const ready = computed(() => {
    const existingMetricSubtypes = store.getters['metrics/subtypes'];
    return Object.keys(existingMetricSubtypes).length > 0;
  });

  return { checkIfMetricSubtypeExists, ready };
};

export default useCheckIfMetricSubtypeExists;
