import { MetricResponse } from '@/types/MetricResponse';
import { MetricStructuresEnum, MetricStructures } from '@/types/MetricStructures';
import { METRIC_STRUCTURE_TO_VALUE_FIELD_MAP } from '@/constants/constants';

const getAvailableCDNsFromMetricResponse = <Structure extends MetricStructuresEnum>(
  response: MetricResponse<Structure>,
) => {
  let cdns: string[];

  switch (response.structure) {
    case MetricStructuresEnum.Binnedcdn:
      const results = response.results as MetricStructures[MetricStructuresEnum.Binnedcdn][];
      cdns = (results[0]?.cdn || []).map((cdn: { key: string }) => cdn.key.toLowerCase());
      break;
    default:
      // @ts-ignore
      const valueField = METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[response.structure];
      // @ts-ignore
      cdns = Object.keys(response.results[0]?.[valueField] || {});
      break;
  }

  return cdns.sort();
};

export default getAvailableCDNsFromMetricResponse;
