import { computed, Ref } from 'vue';

import type { Dashboards } from '@/constants/dashboards';
import useHomeNetwork from '@/composables/useHomeNetwork';
import useNetworkOperators from '@/composables/useNetworkOperators';

const useNetworkOperatorsWithHomeNetwork = (dashboard: Dashboards, locationId: Ref<number>) => {
  const homeNetwork = useHomeNetwork(dashboard, locationId);
  const { data: response } = useNetworkOperators(dashboard, locationId);

  const networkOperatorsWithHomeNetwork = computed(() => {
    if (!response.value || !homeNetwork.value) return [];

    const result = response.value.data.results.filter((operator) => {
      return operator.canonical_network_id !== homeNetwork.value?.canonical_network_id;
    });

    result.unshift(homeNetwork.value);

    return result;
  });

  return networkOperatorsWithHomeNetwork;
};

export default useNetworkOperatorsWithHomeNetwork;
