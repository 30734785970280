<template>
  <div class="PerformanceLayout">
    <div v-if="$slots.sidebar && matches.laptop.value" class="PerformanceLayout__sidebar">
      <slot name="sidebar" />
    </div>
    <div class="PerformanceLayout__main">
      <div class="PerformanceLayout__breadcrumbs">
        <slot name="breadcrumbs" />
      </div>
      <div class="PerformanceLayout__header">
        <slot name="header" />
      </div>
      <div class="PerformanceLayout__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';

export default {
  name: 'PerformanceLayout',
  components: {},
  props: {},
  setup() {
    const matches = useBreakpoints();

    return {
      matches,
    };
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';
@import 'scss/onx-breakpoints.module';

.PerformanceLayout {
  display: flex;
  height: 100vh;

  @include laptop {
    height: calc(100vh - var(--onx-navbar-height));
  }

  &__sidebar {
    @extend %sideBar;
    transition: all 0.3s ease-in-out;
  }

  &__main {
    @extend %mainContent;
    background-color: var(--charcoal-0);
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 8px 0 16px;
  }

  &__breadcrumbs {
    padding: 1rem;
    width: 100%;
  }

  &__header {
    width: 100%;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
  }
}
</style>
