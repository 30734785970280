import { computed } from 'vue';

import { Dashboards } from '@/constants/dashboards';
import useFilters from '@/composables/useFilters';
import useNetworkOperatorsWithHomeNetwork from '@/composables/useNetworkOperatorsWithHomeNetwork';
import { Operator } from '@/types/Operator';
import useAnalytics from '@/composables/useAnalytics';

const useSelectableNetworkOperators = (dashboard: Dashboards) => {
  const { locationId, operators, setFilters } = useFilters(dashboard);
  const networkOperatorsWithHomeNetwork = useNetworkOperatorsWithHomeNetwork(dashboard, locationId);
  const { track } = useAnalytics();

  const computedOperators = computed<(Operator & { selected: boolean; disabled: boolean })[]>({
    get: () =>
      networkOperatorsWithHomeNetwork.value.map((operator) => ({
        ...operator,
        selected: operators.value.length === 0 || operators.value.includes(operator.canonical_network_id.toString()),
        disabled: operators.value.length === 1 && operators.value.includes(operator.canonical_network_id.toString()),
      })),
    set: (selectedOperator: any) => {
      const workingOperatorIds =
        operators.value.length === 0
          ? networkOperatorsWithHomeNetwork.value.map((o) => o.canonical_network_id.toString())
          : [...operators.value];

      if (!selectedOperator.selected) {
        workingOperatorIds.push(selectedOperator.canonical_network_id.toString());
      } else {
        workingOperatorIds.splice(workingOperatorIds.indexOf(selectedOperator.canonical_network_id.toString()), 1);
      }

      setFilters({
        operators: workingOperatorIds,
      }).then(() => {
        track('sidebar network operator change', {
          operator: selectedOperator.name_mapped,
          operatorId: selectedOperator.canonical_network_id,
          selectedValues: workingOperatorIds.map(
            (id) =>
              networkOperatorsWithHomeNetwork.value.find((o) => o.canonical_network_id.toString() === id)?.name_mapped,
          ),
        });
      });
    },
  });

  const selectedOperators = computed(() => {
    return computedOperators.value.filter((operator) => operator.selected);
  });

  return {
    operators: computedOperators,
    selectedOperators,
  };
};

export default useSelectableNetworkOperators;
