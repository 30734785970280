import { watchEffect, ref, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import mapboxgl from 'mapbox-gl';

import type { RefLike } from '@/types/helpers/RefLike';
import type { Dashboards } from '@/constants/dashboards';
import osApi from '@/api/osApi';
import type { RenderImageResponse } from '@/types/RenderImageResponse';

type Options = {
  chartWidth: RefLike<number>;
  chartHeight: RefLike<number>;
  countryIso3: RefLike<string>;
  latMin: RefLike<number>;
  latMax: RefLike<number>;
  lngMin: RefLike<number>;
  lngMax: RefLike<number>;
  canonicalNetworkId: RefLike<number>;
  enabled: RefLike<boolean>;
  bbox?: RefLike<mapboxgl.LngLatBounds | null>;
};

const useVegaImageQuery = (dashboard: Dashboards, endpoint: string, params: Options) => {
  const { bbox, canonicalNetworkId, chartHeight, chartWidth, countryIso3, enabled, latMax, latMin, lngMax, lngMin } =
    params;
  const mapRef = ref<mapboxgl.Map>();

  const query = useQuery({
    queryKey: [
      endpoint,
      dashboard,
      chartWidth,
      chartHeight,
      countryIso3,
      latMin,
      latMax,
      lngMin,
      lngMax,
      canonicalNetworkId,
      bbox,
    ],
    queryFn: () =>
      osApi.get<RenderImageResponse>(`${dashboard}/${endpoint}/`, {
        params: {
          chart_width: chartWidth.value,
          chart_height: chartHeight.value,
          country_iso3: countryIso3.value,
          lat_min: latMin.value,
          lat_max: latMax.value,
          lng_min: lngMin.value,
          lng_max: lngMax.value,
          canonical_network_id: canonicalNetworkId.value,
        },
      }),
    staleTime: 24 * 60 * 60 * 1000,
    retry: 1,
    enabled,
  });

  const computedVegaCoverageImage = computed(() => {
    if (!query.data.value?.data.image) {
      return '';
    }

    return `data:image/png;base64,${query.data.value?.data.image}`;
  });

  const setupImageLayer = (map: mapboxgl.Map) => {
    mapRef.value = map;
  };

  watchEffect(() => {
    if (!mapRef.value || !computedVegaCoverageImage.value) {
      return;
    }

    const bounds = mapRef.value.getBounds();

    if (!bounds) {
      return;
    }

    const source = mapRef.value?.getSource<mapboxgl.ImageSource>('vega-image');
    const coordinates = [
      bounds.getNorthWest().toArray(),
      bounds.getNorthEast().toArray(),
      bounds.getSouthEast().toArray(),
      bounds.getSouthWest().toArray(),
    ] as const satisfies mapboxgl.ImageSourceSpecification['coordinates'];

    if (!source) {
      mapRef.value.addSource('vega-image', {
        type: 'image',
        url: computedVegaCoverageImage.value,
        coordinates,
      });

      mapRef.value.addLayer(
        {
          id: 'vega-layer',
          type: 'raster',
          source: 'vega-image',
        },
        'z-index-1',
      );
    } else {
      source.updateImage({
        url: computedVegaCoverageImage.value,
        coordinates,
      });
    }
  });

  return {
    setupImageLayer,
    query,
  };
};

export default useVegaImageQuery;
