<template>
  <RadioButtons
    v-if="single"
    group-name="operators"
    :options="availableNetworks"
    value-prop="canonical_network_id"
    label-prop="name_mapped"
    :selected-option="selected?.canonical_network_id"
    @select="changeSelection"
  />

  <ul v-else class="onx-networks-menu">
    <li
      v-for="network in availableNetworks"
      :key="network.canonical_network_id"
      class="onx-networks-menu__operator"
      :class="{ 'onx-networks-menu__operator--disabled': !isSelectable(network) }"
    >
      <OnxCheckbox
        :model-value="network.selected"
        :label="network.name_mapped"
        :disabled="!isSelectable(network)"
        dark
        @click.prevent="changeSelection(network.canonical_network_id)"
      />
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RadioButtons from '@/components/menu/RadioButtons';
import OnxCheckbox from '@/components/onx/OnxCheckbox';
import router from '@/router';

export default {
  name: 'NetworksMenu',
  components: {
    OnxCheckbox,
    RadioButtons,
  },
  props: {
    single: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maxNetworksDisplayed: 7,
    };
  },
  computed: {
    ...mapGetters({
      availableNetworks: 'charts/ci_networks',
      compare: 'competitive/compare',
      homeNetwork: 'charts/homeNetwork',
      hiddenNetworkIds: 'charts/hiddenNetworksIds',
      isMobile: 'page/isMobile',
    }),
    selected() {
      if (this.single) {
        const selectedNetworkId = parseInt(router.currentRoute.value.params.network, 10);
        return this.availableNetworks.find((network) => network.canonical_network_id === selectedNetworkId);
      }

      return this.availableNetworks.filter((network) => network.selected);
    },
    isActive() {
      return this.single ? !this.compare : this.compare;
    },
  },
  methods: {
    ...mapActions(['toggleOperator']),
    itemIsActive(network, selected) {
      return this.single ? parseInt(router.currentRoute.value.params.network) === network : selected;
    },
    changeSelection(canonicalNetworkId) {
      if (this.single) {
        router.push({
          name: router.currentRoute.value.name,
          params: {
            ...router.currentRoute.value.params,
            network: canonicalNetworkId.toString(),
          },
        });
      } else {
        if (!this.compare) {
          this.changeMode();
        }
        this.toggleOperator(canonicalNetworkId);
      }
    },
    isHomeNetwork(network) {
      return this.homeNetwork && network.canonical_network_id === this.homeNetwork.canonical_network_id;
    },
    isSelectable(network) {
      if (this.isHomeNetwork(network)) {
        return false;
      }

      return !(this.selected.length === 2 && network.selected);
    },
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';

.onx-networks-menu {
  display: flex;
  flex-direction: column;
  color: var(--white);
  font-size: pxToRem(14);
  list-style: none;
  padding-inline-start: 0;
  margin: 0;

  &__operator {
    height: 24px;

    &--disabled {
      color: var(--charcoal-300);
    }
  }
}
</style>
