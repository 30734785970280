<template>
  <div class="os-flatpickr" :class="inputCssClass" @click="togglePicker()">
    <flat-pickr ref="flatPickr" v-model="givenDate" :config="config" :disabled="disabled" />

    <CalendarIcon class="os-flatpickr__icon" />

    <ChevronDownIcon class="os-flatpickr__ripple" />
  </div>
</template>

<script>
import { isRef, unref } from 'vue';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import CalendarIcon from '@/components/onx/icons/CalendarIcon';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon';

export default {
  name: 'Flatpickr',
  components: {
    flatPickr,
    CalendarIcon,
    ChevronDownIcon,
  },
  props: {
    min: { type: Date, default: () => new Date() },
    max: { type: Date, default: () => new Date() },
    date: { type: Date, required: true },
    disable: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isDisabled: false,
    };
  },
  computed: {
    inputCssClass() {
      const cssClasses = {
        'os-flatpickr--disabled': this.disabled,
      };

      if (this.theme) {
        cssClasses['os-flatpickr--' + this.theme] = true;
      }

      return cssClasses;
    },
    parsedMin() {
      return this.getDatePickerAdjustedDate(this.min);
    },
    parsedMax() {
      return this.getDatePickerAdjustedDate(this.max);
    },
    givenDate: {
      get() {
        return this.getDatePickerAdjustedDate(this.date);
      },
      set(dates) {
        // eventhough we could get Z dates
        // the only way to know what the user seen is to get a naive date
        // that we format to ISO Z standard
        // this.date is the actual URL date
        const rawDate = `${dates}T00:00:00.000Z`;

        if (!dates) {
          return;
        }

        if (this.date.toISOString() !== rawDate) {
          this.$emit('selected', rawDate);
        }
      },
    },
    config() {
      return {
        dateFormat: 'Y-m-d',
        altInput: true,
        defaultHour: 0,
        altFormat: 'd M Y',
        minDate: this.getDatePickerAdjustedDate(this.min),
        maxDate: this.getDatePickerAdjustedDate(this.max),
        disable: this.disable,
        disableMobile: true,
        clickOpens: false,
      };
    },
  },
  methods: {
    getDatePickerAdjustedDate(d) {
      const date = isRef(d) ? unref(d) : d;

      // date picker always shows local time so we need to adjust the date to force
      // the picker to display the URL equivalent UTC time
      if (date.getTimezoneOffset() <= 0) {
        return new Date(date.toDateString());
      } else {
        return new Date(new Date(date.toDateString()).getTime() + 1000 * 60 * 60 * 24);
      }
    },
    togglePicker() {
      if (this.$refs.flatPickr.fp.isOpen) {
        this.$refs.flatPickr.fp.close();
      } else {
        this.$refs.flatPickr.fp.open();
      }
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';

.os-flatpickr {
  position: relative;
  width: 100%;

  .form-control {
    padding: 0 0 0 24px;
    font-size: $font-size-12;
    color: var(--white);
    cursor: pointer;
    background: none;
    border: 0;
    width: 100%;
    height: pxToRem(24);

    &:focus,
    &:active {
      outline: 0;
    }

    &.active {
      ~ .os-flatpickr__ripple {
        transform: translate(0, -50%) rotate(-180deg);
      }
    }
  }

  &--disabled {
    .form-control {
      cursor: not-allowed;
      color: var(--charcoal-300);
    }
  }
}

.os-flatpickr__icon {
  color: var(--white) !important;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
  width: auto !important;
}

.os-flatpickr__ripple {
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  color: var(--charcoal-150);
  transform: translate(0, -50%);
  transition: transition();
  width: auto;
  pointer-events: none;
}

.flatpickr-calendar {
  border-radius: 4px;
  font-size: $font-size-12;

  &::before,
  &::after {
    display: none;
  }
}

.flatpickr-calendar,
.dayContainer,
.flatpickr-days {
}

.flatpickr-days {
  &.selected {
    @extend %active;
  }
}

.flatpickr-day {
  color: var(--charcoal-400);

  &:hover {
    background: var(--teal-200);
    border-color: var(--teal-200);
  }

  &.selected {
    background: var(--teal-500);
    border-color: var(--teal-500);
    cursor: default;

    &:hover {
      background: var(--teal-500);
      border-color: var(--teal-500);
    }
  }
}
</style>
