import { computed, Ref } from 'vue';
import { useStore } from 'vuex';

import type { Dashboards } from '@/constants/dashboards';
import useDashboardInfo from '@/composables/useDashboardInfo';

const useHomeNetwork = (dashboard: Dashboards, locationId: Ref<number | string>) => {
  const { data: response } = useDashboardInfo(dashboard);
  const store = useStore();
  const locationsById = store.getters['locations/byIdentifier'];

  const homeNetwork = computed(() => {
    return response.value?.data.home_networks.find(
      (network) => network.country_iso3 === locationsById[locationId.value]?.iso3,
    );
  });

  return homeNetwork;
};

export default useHomeNetwork;
