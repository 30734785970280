// metric.subtype
export const TOOLTIP_MESSAGES = {
  aggregation: 'Time period of historical data that is used for calculation of the metric',
  compareTo: 'Comparison of latest metric values to 7/30/90 days ago or last public report',
  technology: 'Set your default layer view for all metrics here',
  datePicker:
    'Dashboard timezone is UTC. For weekly metrics, date selected will be adjusted to fit the last available date closest to selected End Date',
  peakdownload:
    'Measures the fastest speeds users experience on the network in real world situations, based on speed experienced by the 98th percentile of users, in Mbps.',
  peakupload:
    'Measures the fastest speeds users experience on the network in real world situations, based on speed experienced by the 98th percentile of users, in Mbps.',
  peaklatency: 'Lowest value of latency measured on the network, in ms',
  consistencydownload:
    'Proportion of speed measurements (0-100%) that are above 3 Mbps for 3G and 4G or 10 Mbps for 5G',
  consistencyupload: 'Proportion of speed measurements (0-100%) that are above 1 Mbps',
  binneddownload: 'Distribution of download speed samples',
  frdownload: 'Average 5G download speed by frequency range',
  frupload: 'Average 5G upload speed by frequency range',
  binnedupload: 'Distribution of upload speed samples',
  binnedlatency: 'Distribution of latency samples',
  hourlyvideoexperience:
    'Average video score measured in the Opensignal (0-100) scale, across all resolutions and providers',
  v1cdnresvideoexperience:
    'Average video score (0-100) measured for particular provider and resolution. Different scaling method (similar to MOS scale) is applied here, as compared to overall video experience metric. Max. possible score for 360p resolution video is 45, 75 for 720p resolution',
  v2cdnresvideoexperience:
    'Average video score (0-100) measured for particular provider and resolution. Different scaling method (similar to MOS scale) is applied here, as compared to overall video experience metric. Max. possible score for 360p resolution video is 45, 75 for 720p resolution, 87.5 for 1080p and 97.5 for 4K ',
  videoexperience:
    'Opensignal have broadened the number of services & resolutions that are used in our Video Experience metric with the addition of AWS CloudFront & Google Cloud Platform, and 1080p & 4K (2160p) resolutions for all providers. The changes are effective from February 2022.',
  videoabrstalling: 'Proportion of users that have experienced a stalling event during the video test (0-100%)',
  videoloadtime: 'Time taken for initial buffer to load and for the picture to start moving in seconds',
  binnedvideothroughput: 'Average throughput/data rate (Mbps) experienced by the user during the video test',
  binnedvideodataconsumed: 'Average data consumption (MB) for the video test',
  enodeb: `Count of distinct eNodeB's observed in the network`,
  cell: 'Count of distinct 4G Cells observed in the network',
  cellbanddist:
    'Proportion of 4G cells detected per frequency band in the network. Metric values are rounded to two decimal places.',
  enodebcellcountdist: 'Distribution of 4G Cells per eNodeB observed in the network',
  categorydownload:
    'Average 4G download speed by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categorypeakdownload:
    'Peak 4G download speed by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categorypeakupload:
    'Peak 4G upload speed by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categoryupload: 'Average 4G upload speed by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categorylatency: 'Average 4G latency by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  categorypeaklatency: 'Minimum 4G latency by LTE UE Category. LTE UE Category is based on 3GPP TS36.306 specification',
  androidmodeldownload:
    'Average 4G download speed by device make & model, for the top 10 most popular Android devices in Opensignal data',
  androidmodelpeakdownload:
    'Peak 4G download speed by device make & model, for the top 10 most popular Android devices in Opensignal data',
  androidmodelpeakupload:
    'Peak 4G upload speed by device make & model, for the top 10 most popular Android devices in Opensignal data',
  androidmodelupload:
    'Average 4G upload speed by device make & model, for the top 10 most popular Android devices in Opensignal data',
  iosmodeldownload:
    'Average 4G download speed by device make & model, for the top 10 most popular iOS devices in Opensignal data',
  iosmodelpeakdownload:
    'Peak 4G download speed by device make & model, for the top 10 most popular iOS devices in Opensignal data',
  iosmodelpeakupload:
    'Peak 4G upload speed by device make & model, for the top 10 most popular iOS devices in Opensignal data',
  iosmodelupload:
    'Average 4G upload speed by device make & model, for the top 10 most popular iOS devices in Opensignal data',
  downloadstallingoccurrence:
    'Percentage of devices that have experienced at least one stalling event during the download speed test. Stalling event is defined as average speed falling below 500 kbps for the duration of 1 second.',
  poorsignallevel: 'Percentage of LTE RSRP readings that were below specified threshold (-115 dBm)',
  hourlydownloadstallingoccurrence:
    'Percentage of devices that have experienced at least one stalling event during the download speed test. Stalling event is defined as average speed falling below 500 kbps for the duration of 1 second.',
  hourlypoorsignallevel: 'Percentage of LTE RSRP readings that were below specified threshold (-115 dBm)',
  poorsignalquality: 'Percentage of LTE RSRQ readings that were below specified threshold (-15 dB)',
  hourlypoorsignalquality: 'Percentage of LTE RSRQ readings that were below specified threshold (-15 dB)',
  voicertt: 'Average one-way UDP packet delay per device between client and the server',
  voicepacketloss: 'Average one-way UDP packet loss per device between client and the server',
  gamesrtt: 'Average one-way UDP packet delay per device between client and the server',
  gamespacketloss: 'Average one-way UDP packet loss per device between client and the server',
  metricGeographies:
    'The "Top and Bottom" location view displays Regions/Cities with ranks only - sorted by the mean value of the metric. <br><br>To see all ranked and un-ranked Regions/Cities click on the "Reveal All" view where Regions/Cities without ranks are displayed at the bottom of the list.',
  competitiveEndDateInfo:
    'Competitive Intelligence Dashboard is using an "exclusive" end date convention.<br><br> This means that for a 90 day aggregation time period from January 1st to March 31st, Competitive Intelligence Dashboard will show "date ending April 1st."',
  performanceEndDateInfo:
    'Performance Intelligence Dashboard is using an "exclusive" end date convention.<br><br> This means that for a 90 day aggregation time period from January 1st to March 31st, Performance Intelligence Dashboard will show "date ending April 1st."',
  frequencyRange: `
    5G Frequency Range segmentation allows you to see how Frequency Ranges deployed impact on User Experience for key 5G Experiential Metrics.
    <p>Frequency Ranges are defined as follows:</p>
    <ul>
      <li>Low Range: <1 Ghz</li>
      <li>Mid Range: 1-3 Ghz</li>
      <li>High Range: 3-6 Ghz</li>
    </ul>
    mmWave corresponds to Range >6 Ghz. It is reported as a separate Technology and is not included in High Range bucket.`,
  ccq: 'Core Consistent Quality',
  availabilityAward_main:
    'This metric corresponds to the "Availability" award metric and represents the sum of 3G Availability, 4G Availability and 5G Availability for All Users',
  availabilityAward_5guser: 'This metric corresponds to the "5G Availability" award metric',
  availabilityAward_4guser: 'This metric corresponds to the "4G Availability" award metric',

  spotlight_availability_2g: `2G Availability shows the proportion of time all Opensignal users on an operator's network had a 2G connection.`,
  spotlight_availability_3g: `3G Availability shows the proportion of time all Opensignal users on an operator's network had a 3G connection.`,
  spotlight_availability_4g: `4G Availability shows the proportion of time all Opensignal users on an operator's network had a 4G connection.`,
  spotlight_availability_5g: `5G Availability shows the proportion of time all Opensignal users on an operator's network had a 5G connection.`,
  spotlight_availability_lte: `4G Availability shows the proportion of time all Opensignal users on an operator's network had a 4G connection.`,
  spotlight_availability_nosignal: `No Signal Availability shows the proportion of time Opensignal users have no signal.`,
  spotlight_availability_overall: `Overall Availability shows the proportion of time all Opensignal users on an operator's network had either a 2G, 3G, 4G or 5G connection.`,
  spotlight_availability_wifi: `Wifi Availability shows the proportion of time Opensignal users are on a wifi connection.`,
  spotlight_ccq_3g: `3G Core Consistent Quality measures if the operator's 3G network is sufficient to support common mobile application requirements at a level that is 'good enough' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_ccq_5g: `5G Core Consistent Quality measures if the operator's 5G network is sufficient to support common mobile application requirements at a level that is 'good enough' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_ccq_lte: `4G Core Consistent Quality measures if the operator's 4G network is sufficient to support common mobile application requirements at a level that is 'good enough' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_ccq_overall: `Quality calculation providing an indication of customer experience for services such as web browsing and simple applications, calculated from seven test types based on pass percentage of thresholds. Core Consistent Quality comprises a historical approach to common coverage areas and sample balancing.`,
  spotlight_consistentquality_3g: `3G Consistent Quality measures if the operator's 3G network is sufficient to support common mobile application requirements at a level that is 'good enough' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_consistentquality_4g: `4G Consistent Quality measures if the operator's 4G network is sufficient to support common mobile application requirements at a level that is 'good enough' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_consistentquality_5g: `5G Consistent Quality measures if the operator's 5G network is sufficient to support common mobile application requirements at a level that is 'good enough' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_consistentquality_lte: `4G Consistent Quality measures if the operator's 4G network is sufficient to support common mobile application requirements at a level that is 'good enough' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_consistentquality_overall: `Overall Consistent Quality measures if the operator's network is sufficient to support common mobile application requirements at a level that is 'good enough' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_coverage_5g: `5G Coverage Experience metric measures the extent of operator's 5G mobile network in the places people live, work and travel.`,
  spotlight_coverage_lte: `4G Coverage Experience metric measures the extent of operators's 4G mobile network in the places people live, work and travel.`,
  spotlight_coverage_overall: `Overall Coverage Experience metric measures the extent of operator's mobile network in the places people live, work and travel.`,
  spotlight_download_2g: `2G Download Speed Experience represents the typical everyday download speeds a user experiences across an operator's 2G mobile data network.`,
  spotlight_download_3g: `3G Download Speed Experience represents the typical everyday download speeds a user experiences across an operator's 3G mobile data network.`,
  spotlight_download_4g: `4G Download Speed Experience represents the typical everyday download speeds a user experiences across an operator's 4G mobile data network.`,
  spotlight_download_5g: `5G Download Speed Experience represents the typical everyday download speeds a user experiences across an operator's 5G mobile data network.`,
  spotlight_download_5gmmwave: `mmWave Download Speed Experience represents the typical everyday download speeds a user experiences across an operator's mmWave mobile data network.`,
  spotlight_download_lte: `4G Download Speed Experience represents the typical everyday download speeds a user experiences across an operator's 4G mobile data network.`,
  spotlight_download_overall: `Overall Download Speed Experience represents the typical everyday download speeds a user experiences across an operator's mobile data networks.`,
  spotlight_ecq_3g: `3G Excellent Consistent Quality measures if the operator's 3G network is sufficient to support common mobile application requirements at a level that is 'excellent' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_ecq_5g: `5G Excellent Consistent Quality measures if the operator's 5G network is sufficient to support common mobile application requirements at a level that is 'excellent' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_ecq_lte: `4G Excellent Consistent Quality measures if the operator's 4G network is sufficient to support common mobile application requirements at a level that is 'excellent' for users to maintain (or complete) various typical tasks on their devices.`,
  spotlight_ecq_overall: `Quality calculation providing an indication of customer experience for high performance activities such as live HD video streaming, calculated from seven test types based on pass percentage of more demanding thresholds. Excellent Consistent Quality comprises a historical approach to common coverage areas and sample balancing.`,
  spotlight_extent_4g: `4G Reach metric measure how mobile users experience the geographical extent of an operator's 4G network.`,
  spotlight_extent_5g: `5G Reach metric measure how mobile users experience the geographical extent of an operator's 5G network.`,
  spotlight_extent_lte: `4G Reach metric measure how mobile users experience the geographical extent of an operator's 4G network.`,
  spotlight_games_3g: `3G Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's 3G network.`,
  spotlight_games_5g: `5G Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's 5G network.`,
  spotlight_games_lte: `4G Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's 4G network.`,
  spotlight_games_overall: `Overall Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's networks.`,
  spotlight_gamesexperience_3g: `3G Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's 3G network.`,
  spotlight_gamesexperience_4g: `4G Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's 4G network.`,
  spotlight_gamesexperience_5g: `5G Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's 5G network.`,
  spotlight_gamesexperience_5gmmwave: `mmWave Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's mmWave network.`,
  spotlight_gamesexperience_lte: `4G Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's 4G network.`,
  spotlight_gamesexperience_overall: `Overall Games Experience measures how mobile users experience real-time multiplayer mobile gaming on an operator's networks.`,
  spotlight_onxcoveragesim_4g: `4G Coverage Experience metric measures the extent of operators's 4G mobile network in the places people live, work and travel.`,
  spotlight_onxcoveragesim_5g: `5G Coverage Experience metric measures the extent of operator's 5G mobile network in the places people live, work and travel.`,
  spotlight_onxcoveragesim_lte: `4G Coverage Experience metric measures the extent of operators's 4G mobile network in the places people live, work and travel.`,
  spotlight_onxcoveragesim_overall: `Overall Coverage Experience metric measures the extent of operator's mobile network in the places people live, work and travel.`,
  spotlight_reliability_3g: `3G Reliability Experience measures the ability of Opensignal users to connect to and successfully complete basic tasks on communication service providers' 3G network.`,
  spotlight_reliability_4g: `4G Reliability Experience measures the ability of Opensignal users to connect to and successfully complete basic tasks on communication service providers' 4G network.`,
  spotlight_reliability_5g: `5G Reliability Experience measures the ability of Opensignal users to connect to and successfully complete basic tasks on communication service providers' 5G network.`,
  spotlight_reliability_lte: `4G Reliability Experience measures the ability of Opensignal users to connect to and successfully complete basic tasks on communication service providers' 4G network.`,
  spotlight_reliability_overall: `Overall Reliability Experience measures the ability of Opensignal users to connect to and successfully complete basic tasks on communication service providers' networks.`,
  spotlight_upload_2g: `2G Upload Speed Experience represents the typical everyday upload speeds a user experiences across an operator's 2G mobile data network.`,
  spotlight_upload_3g: `3G Upload Speed Experience represents the typical everyday upload speeds a user experiences across an operator's 3G mobile data network.`,
  spotlight_upload_4g: `4G Upload Speed Experience represents the typical everyday upload speeds a user experiences across an operator's 4G mobile data network.`,
  spotlight_upload_5g: `5G Upload Speed Experience represents the typical everyday upload speeds a user experiences across an operator's 5G mobile data network.`,
  spotlight_upload_5gmmwave: `mmWave Upload Speed Experience represents the typical everyday upload speeds a user experiences across an operator's mmWave mobile data network.`,
  spotlight_upload_lte: `4G Upload Speed Experience represents the typical everyday upload speeds a user experiences across an operator's 4G mobile data network.`,
  spotlight_upload_overall: `Overall Upload Speed Experience represents the typical everyday upload speeds a user experiences across an operator's mobile data networks.`,
  spotlight_video_3g: `3G Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's 3G network.`,
  spotlight_video_4g: `4G Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's 4G network.`,
  spotlight_video_5g: `5G Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's 5G network.`,
  spotlight_video_lte: `4G Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's 4G network.`,
  spotlight_video_overall: `Overall Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's networks.`,
  spotlight_videoexperience_3g: `3G Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's 3G network.`,
  spotlight_videoexperience_4g: `4G Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's 4G network.`,
  spotlight_videoexperience_5g: `5G Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's 5G network.`,
  spotlight_videoexperience_5gmmwave: `mmWave Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's mmWave network.`,
  spotlight_videoexperience_lte: `4G Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's 4G network.`,
  spotlight_videoexperience_overall: `Overall Video Experience quantifies the quality of video streamed to mobile devices by measuring real-world video streams over an operator's networks.`,
  spotlight_videolive_3g: `3G Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's 3G network`,
  spotlight_videolive_5g: `5G Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's 5G network`,
  spotlight_videolive_lte: `4G Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's 4G network.`,
  spotlight_videolive_overall: `Overall Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's networks.`,
  spotlight_videoliveexperience_3g: `3G Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's 3G network`,
  spotlight_videoliveexperience_4g: `4G Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's 4G network.`,
  spotlight_videoliveexperience_5g: `5G Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's 5G network.`,
  spotlight_videoliveexperience_5gmmwave: `mmWave Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's mmWave network.`,
  spotlight_videoliveexperience_lte: `4G Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's 4G network.`,
  spotlight_videoliveexperience_overall: `Overall Live Video Experience quantifies the quality of real-time video streamed to mobile devices by measuring video streams over an operator's networks.`,
  spotlight_voice_3g: `3G Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's 3G network.`,
  spotlight_voice_5g: `5G Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's 5G network.`,
  spotlight_voice_lte: `4G Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's 4G network.`,
  spotlight_voice_overall: `Overall Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's networks.`,
  spotlight_voiceexperience_3g: `3G Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's 3G network.`,
  spotlight_voiceexperience_4g: `4G Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's 4G network.`,
  spotlight_voiceexperience_5g: `5G Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's 5G network.`,
  spotlight_voiceexperience_5gmmwave: `mmWave Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's mmWave network.`,
  spotlight_voiceexperience_lte: `4G Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's 4G network.`,
  spotlight_voiceexperience_overall: `Opensignal's Voice App Experience measures the quality of experience for over-the-top (OTT) voice services over an operator's networks.`,
};
