<template>
  <div class="QuestionMark" :class="questionMarkCssClass">?</div>
</template>

<script>
export default {
  name: 'QuestionMark',
  props: {
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    questionMarkCssClass() {
      if (this.theme) {
        return 'QuestionMark--' + this.theme;
      }

      return '';
    },
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
$size: pxToRem(16px);
$ci-size: pxToRem(20px);

.QuestionMark {
  background-color: $color-icon-bg;
  border-radius: 50%;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-10;
  height: $size;
  line-height: $size;
  text-align: center;
  vertical-align: middle;
  width: $size;
  margin-left: 5px;

  &:hover {
    background-color: $color-electric-blue;
  }

  &--ci {
    background: $gold;
    color: $ci-dark-blue;
    width: $ci-size;
    height: $ci-size;
    line-height: $ci-size;
    font-size: $font-size-14;
    margin-top: -3px;

    &:hover {
      background: $color-white;
    }
  }

  &--ci-active {
    background: $color-white;
    color: $ci-dark-blue;
    width: $ci-size;
    height: $ci-size;
    line-height: $ci-size;
    font-size: $font-size-14;
    margin-top: -3px;
  }
}
</style>
