import { METRIC_TYPE_NAMES, METRIC_TYPES } from '@/constants/constants';
import type { MetricTypeNamesIndex } from '@/types/MetricTypeNamesIndex';

export type AvailableConnectionCategory = {
  categoryLabel: string;
  categoryValue: string;
  subCategory: boolean;
};

export const defaultCategoryValue = METRIC_TYPES.FourG;

export const allPossibleConnectionCategories: AvailableConnectionCategory[] = [
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.FiveG as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.FiveG,
    subCategory: false,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.Mmwave as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.Mmwave,
    subCategory: true,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.FiveGHigh as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.FiveGHigh,
    subCategory: true,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.FiveGMid as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.FiveGMid,
    subCategory: true,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.FiveGLow as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.FiveGLow,
    subCategory: true,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.FourG as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.FourG,
    subCategory: false,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.ThreeG as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.ThreeG,
    subCategory: false,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.TwoG as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.TwoG,
    subCategory: false,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.NoSignal as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.NoSignal,
    subCategory: false,
  },
  {
    categoryLabel: METRIC_TYPE_NAMES[METRIC_TYPES.Emergency as MetricTypeNamesIndex],
    categoryValue: METRIC_TYPES.Emergency,
    subCategory: false,
  },
];
