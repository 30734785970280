import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import useQueryParamSingleSelect from '@/composables/useQueryParamSingleSelect';
import {
  chartMetricDefinitionsByPage,
  isChartMetricDefinitionsObject,
} from '@/chart-metric-definitions/ChartMetricDefinitions';
import useCheckIfMetricSubtypeExists from '@/composables/useCheckIfMetricSubtypeExists';
import getLabelForMetricSubtype from '@/focus/metric-selector/labelsByMetricSubtype';
import useAvailableChartMetricsForSelector from '@/focus/metric-selector/useAvailableChartMetricsForSelector';
import {
  ChartMetricDefinition,
  isChartMetricDefinitionWithSingleSubtype,
} from '@/chart-metric-definitions/MetricDefinition';
import useAnalytics from '@/composables/useAnalytics';

/**
 * @param queryParamName - The name of the query parameter to use. It will appear as `foo=bar` in the URL.
 */
const useChartMetricSingleSelect = <T extends ChartMetricDefinition = ChartMetricDefinition>(
  queryParamName: string,
) => {
  const router = useRouter();
  const availableMetrics = useAvailableChartMetricsForSelector();
  const { checkIfMetricSubtypeExists } = useCheckIfMetricSubtypeExists();
  const { track } = useAnalytics();

  const { onChange, selectedValue } = useQueryParamSingleSelect<MetricSubtypes>(
    queryParamName,
    computed(() => availableMetrics.value[0]?.metricSubtype),
  );

  const chartMetricsLabel = computed(() => {
    return getLabelForMetricSubtype(selectedValue.value);
  });

  const selectedChartMetrics = computed(() => {
    const metricSubtype = router.currentRoute.value.query.metricSubtype as SelectableMetrics;
    const metricDefinitions = chartMetricDefinitionsByPage[router.currentRoute.value.name as string];

    if (isChartMetricDefinitionsObject(metricDefinitions) && metricDefinitions[metricSubtype]) {
      return metricDefinitions[metricSubtype]
        .filter((metric) => {
          if (!metric.chartGroup) {
            return false;
          }

          return selectedValue.value === metric.chartGroup;
        })
        .filter((metric) => {
          if (isChartMetricDefinitionWithSingleSubtype(metric)) {
            return checkIfMetricSubtypeExists(metric.metricSubtype);
          }

          return metric.metricSubtypes.some((subtype) => checkIfMetricSubtypeExists(subtype));
        }) as T[];
    }

    return [] as T[];
  });

  const onChartMetricChange: typeof onChange = async (changed) => {
    await onChange(changed);
    track('single chart metric selector change', {
      changed,
      queryParamName,
    });
  };

  return {
    onChartMetricChange,
    selectedMetricSubtype: selectedValue,
    selectedChartMetrics,
    chartMetricsLabel,
    availableMetrics,
  };
};

export default useChartMetricSingleSelect;
