import { computed } from 'vue';

import useSelectableChartConnectionCategories from '@/composables/useSelectableChartConnectionCategories';

const use360ConnectionCategories = () => {
  const connectionCategories = useSelectableChartConnectionCategories(undefined);

  return computed(() => {
    // the incoming disabled property means "is the connection category hidden from this view" AKA is it even enabled for this page or not
    // but we need to modify it so that disabled means "the checkbox cannot toggle it on or off"

    // if there's only selected out of all the categories that are available, we need to disable it from the menu
    const isOnlyOneCategorySelected =
      connectionCategories.selectableChartConnectionCategories.value.filter((c) => {
        return !c.disabled && c.selected;
      }).length === 1;

    return {
      ...connectionCategories,
      selectableChartConnectionCategories: connectionCategories.selectableChartConnectionCategories.value
        .filter((c) => !c.disabled)
        .map((c) => {
          return {
            ...c,
            disabled:
              isOnlyOneCategorySelected &&
              connectionCategories.selectedChartConnectionCategories.value[0].categoryValue === c.categoryValue,
          };
        }),
      selectedChartConnectionCategories: connectionCategories.selectedChartConnectionCategories.value.filter(
        (c) => !c.disabled,
      ),
    };
  });
};

export default use360ConnectionCategories;
