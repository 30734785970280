<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { Dashboards } from '@/constants/dashboards';
import OnxHeader from '@/components/onx/header/OnxHeader.vue';
import OnxHeaderTab from '@/components/onx/tabs/OnxHeaderTab.vue';
import ROUTES from '@/constants/routes';
import use360TabAvailability from '@/360/use360TabAvailability';
import useDashboardInfo from '@/composables/useDashboardInfo';
import OnxPermissionGuard from '@/components/onx/OnxPermissionGuard.vue';
import { OnxPermissionTopics } from '@/composables/useOnxLicencePermission';

const { data: dashboardInfoResponse } = useDashboardInfo(Dashboards.ThreeSixty);
const explorerUrl = computed(() => {
  return dashboardInfoResponse.value?.data.explorer_url;
});

const router = useRouter();

const onTabChange = async (id?: string, value?: string) => {
  if (!id && !value) {
    return;
  }

  await router.push({
    query: router.currentRoute.value.query,
    name: value || id,
  });

  const content = document.querySelector('.PerformanceLayout__content');
  if (content) {
    content.scrollTop = 0;
  }
};

const {
  firstAvailableSubtabForBaseStation,
  firstAvailableSubtabForCore,
  firstAvailableSubtabForCoverage,
  tabAvailability,
} = use360TabAvailability();
</script>

<template>
  <OnxHeader @tab-change="onTabChange">
    <template #main-tabs>
      <OnxHeaderTab
        v-if="tabAvailability[ROUTES.ThreeSixtyCoverage]"
        :id="ROUTES.ThreeSixtyCoverage"
        :value="firstAvailableSubtabForCoverage"
      >
        Coverage
      </OnxHeaderTab>
      <OnxHeaderTab
        v-if="tabAvailability[ROUTES.ThreeSixtyBaseStation]"
        :id="ROUTES.ThreeSixtyBaseStation"
        :value="firstAvailableSubtabForBaseStation"
      >
        Base Station
      </OnxHeaderTab>
      <OnxHeaderTab
        v-if="tabAvailability[ROUTES.ThreeSixtyCore]"
        :id="ROUTES.ThreeSixtyCore"
        :value="firstAvailableSubtabForCore"
      >
        Core
      </OnxHeaderTab>
      <OnxPermissionGuard :dashboard="Dashboards.ThreeSixty" :topic="OnxPermissionTopics.myDashboards">
        <OnxHeaderTab as="a" :href="explorerUrl" target="_blank" class="neutral-anchor-link">
          <span>myDashboards</span>
        </OnxHeaderTab>
      </OnxPermissionGuard>
    </template>

    <template #header-controls v-if="$slots['header-controls']">
      <slot name="header-controls"></slot>
    </template>

    <template #secondary-tabs v-if="$slots['secondary-tabs']">
      <slot name="secondary-tabs" :on-tab-change="onTabChange"></slot>
    </template>
  </OnxHeader>
</template>
