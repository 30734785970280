<script setup lang="ts">
import { provide } from 'vue';

import CONNECTION_CATEGORIES_INJECT_KEY from './connCategoriesInjectKey';

import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';

type Props = {
  value: AvailableConnectionCategory[];
};

const props = defineProps<Props>();

provide(CONNECTION_CATEGORIES_INJECT_KEY, props.value);
</script>

<template>
  <slot></slot>
</template>
