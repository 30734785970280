<script setup lang="ts">
import { onMounted } from 'vue';

import { Dashboards } from '@/constants/dashboards';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';

import useAnalytics from '@/composables/useAnalytics';

import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import useLocations from '@/composables/useLocations';

const { selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
const { track } = useAnalytics();
const { locationId } = useLocations(Dashboards.Focus);

const mapclasses = 'sm:onx-col-5 md:onx-col-8 chart-height';
const mapMetrics = [
  {
    title: 'Deployed Bands',
    endpoint: 'maps/deployed-spectrum-deployed-bands',
    chartContainerClass: mapclasses,
    id: 'deployed-spectrum/deployed-spectrum-deployed-bands',
  },
  {
    title: 'Deployed Bandwidths',
    endpoint: 'maps/deployed-spectrum-deployed-bandwidths',
    chartContainerClass: mapclasses,
    id: 'deployed-spectrum/deployed-spectrum-deployed-bandwidths',
  },
  {
    title: 'Deployed Channels',
    endpoint: 'maps/deployed-spectrum-deployed-channels',
    chartContainerClass: mapclasses,
    id: 'deployed-spectrum/deployed-spectrum-deployed-channels',
  },
];

onMounted(() => {
  track('focus ran health deployed spectrum page view');
});
</script>

<template>
  <div v-if="selectedOperators">
    <div class="onx-grid">
      <OnxSwyftMap
        v-for="metric in mapMetrics"
        :key="metric.id"
        :map-endpoint="metric.endpoint"
        :title="metric.title"
        :network-operators="selectedOperators"
        :class="metric.chartContainerClass"
        :geohash-level="7"
        :location-id="locationId"
      />
    </div>
  </div>
</template>
