<script setup>
import HomeIcon from '@/components/onx/icons/HomeIcon';
import OnxButton from '@/components/onx/OnxButton';

const emit = defineEmits(['home-click']);
</script>

<template>
  <div class="onx-breadcrumbs">
    <OnxButton
      class="onx-breadcrumbs__breadcrumb"
      variant="tertiary"
      size="sm"
      data-test-id="onx-breadcrumbs_overview-btn"
      @click="emit('home-click')"
    >
      <HomeIcon smaller class="onx-breadcrumbs__home-icon" />
      <span>Overview</span>
    </OnxButton>
  </div>
</template>

<style scoped lang="scss">
.onx-breadcrumbs {
  height: 24px;

  display: flex;
  align-items: center;

  &__home-icon {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }

  &__breadcrumb {
    color: var(--onx-breadcrumb-color);
  }
}
</style>
