import { MetricSubtypes } from '@/types/MetricSubtypes';

import type { SitemapperTableRow } from '@/360/base-station/table-columns/sitemapperDetailColumns';
import { sitemapperDetailsColumns } from '@/360/base-station/table-columns/sitemapperDetailColumns';

import createExportSimpleTableFullMetricToCsv from '@/360/csv-export-overrides/createExportSimpleTableFullMetricToCsv';

export const exportSitemapperEstimatedLocationConfidenceToCsv = createExportSimpleTableFullMetricToCsv<
  any,
  SitemapperTableRow
>({ metricSubtype: MetricSubtypes.EstimatedLocationConfidenceTableFull, columns: sitemapperDetailsColumns });
