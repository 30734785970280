<template>
  <div class="TileTooltip">
    <div
      v-tooltip="{
        content: tooltipContent,
        triggers: ['hover'],
        placement: placement,
        distance: 16,
        popperClass: 'tooltip--tile ' + cssClass + (title ? ' tooltip--tileWithHeader' : ''),
        delay: {
          show: 0,
          hide: 0,
        },
        html: true,
      }"
    >
      <slot name="tooltipAnchor" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TileTooltip',
  props: {
    title: { type: String, default: '' },
    content: { type: String, required: true },
    cssClass: { type: String, default: '' },
    placement: { type: String, default: 'right' },
  },
  data() {
    return {
      // TODO: creata a component
      tooltipContent:
        (this.title ? `<div class="tooltip-header">${this.title}</div>` : '') +
        `<div class="tooltip-content">${this.content}</div>`,
    };
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import '@material/elevation';

$background-color: $color-white;
$font-color: #444a59;
$padding: 10px;
$padding-alt: 5px;
$box-shadow: 0 $padding-alt $padding-alt * 2 rgba(black, 0.1);

.TileTooltip {
  position: relative;
  display: inline-block;
}

.tooltip--tile {
  display: block !important;
  z-index: $z-index-tooltip;

  @media screen and (max-width: $ci-breakpoint-tablet) {
    z-index: $z-index-tooltip-mobile;
  }

  .tooltip-header {
    background: $ci-blue;
    height: 40px;
    display: flex;
    align-items: center;
    padding: $padding;
    box-sizing: border-box;
  }

  .tooltip-content {
    color: $font-color;
    background-color: $background-color;
    overflow: hidden;
    padding: $padding;
  }

  &.v-popper--theme-tooltip {
    .v-popper__inner {
      @include elevation($elevation-2);
      background: transparent;
      border-radius: $border-radius-small;
      padding: 0;
      max-width: pxToRem(180);
      font-size: $font-size-13;
      line-height: $font-size-16;
      overflow: hidden;
    }

    .v-popper__arrow-outer {
      border-color: $background-color;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-left: 14px;
      position: relative;

      &::before {
        content: '\2013';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  p {
    margin: 0 0 $padding;
  }
}
</style>
