<template>
  <div :class="menuCssClass" class="MenuTitle">
    <div class="MenuTitle__title">
      {{ label }}
      <span @click.stop="tooltipClick">
        <QuestionMark v-if="tooltip" class="MenuTitle__tooltip" theme="ci" />
      </span>
    </div>
  </div>
</template>

<script>
import QuestionMark from '@/components/tooltip/QuestionMark';

export default {
  name: 'MenuTitle',
  components: {
    QuestionMark,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    menuCssClass() {
      if (this.theme) {
        return 'MenuTitle--' + this.theme;
      }

      return '';
    },
  },
  methods: {
    tooltipClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;

.MenuTitle {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  overflow: hidden;
  margin-right: -10px;

  &__title {
    color: $color-blue-header;
    display: inline-block;
    position: relative;
    font-size: $font-size-14;
    font-weight: $font-weight-roboto-medium;
    font-style: italic;

    &::after {
      border-bottom: 1px solid $color-blue-separator;
      content: '';
      margin: 0 0.5rem;
      position: absolute;
      left: 100%;
      top: 50%;
      width: 50vw;
    }
  }

  &__tooltip {
    font-style: normal;
  }

  &--ci,
  &--pi {
    margin-left: -$sidebar-padding;
    margin-right: -$sidebar-padding;
    padding: 0 $sidebar-padding 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    .MenuTitle__title {
      color: var(--white);
      text-transform: uppercase;
      font-style: normal;
      font-size: $font-size-12;
      font-weight: 400;

      &::after {
        display: none;
      }
    }
  }

  &--mg {
    .MenuTitle__title {
      color: $mg-gold;

      &::after {
        border-bottom-color: $mg-gold;
      }
    }
  }
}
</style>
