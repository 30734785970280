import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';

import {
  videoTtffColumns,
  videoStallingTimeColumns,
  videoStallingOccurrenceColumns,
  videoThroughputColumns,
  videoTimeOnResColumns,
} from '@/360/core/table-columns/videoIpColumns';
import { commonDatasetTransformer } from '@/360/core/table-columns/commonDatasetTransformer';

const onx360CoreEndpointIPVideoLiveMetricDefinitions: ChartMetricDefinition[] = [
  {
    metricSubtype: MetricSubtypes.CdnIpVideoAbrTtff360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_video_ttff',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Video Time to First Frame (s) by IP',
    },

    chartContainerClass: 'onx-grid-item__full-width',
    columns: videoTtffColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoAbrStallTime360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_video_stalltime',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Video Stalling Time (ms) by IP',
    },

    chartContainerClass: 'onx-grid-item__full-width',
    columns: videoStallingTimeColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoAbrStallingOccurrence360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_video_stalling',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Video Stalling Occurrance (%) by IP',
    },

    chartContainerClass: 'onx-grid-item__full-width',
    columns: videoStallingOccurrenceColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoAbrThroughput360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_video_throughput',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Video Throughput Rate (Mbps) by IP',
    },

    chartContainerClass: 'onx-grid-item__full-width',
    columns: videoThroughputColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoAbrTimeOnRes360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_video_restime',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Video Time on Resolution (%) by IP',
    },

    chartContainerClass: 'onx-grid-item__full-width',
    columns: videoTimeOnResColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
];

export default onx360CoreEndpointIPVideoLiveMetricDefinitions;
