<script setup lang="ts">
import RouterLinkWithQuery from '@/components/RouterLinkWithQuery.vue';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon.vue';

const props = defineProps({
  to: {
    type: String,
    required: false,
  },
  first: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
<template>
  <div class="onx-breadcrumb">
    <ChevronRightIcon v-if="!props.first" />
    <template v-if="props.to">
      <RouterLinkWithQuery :to="props.to">
        <slot />
      </RouterLinkWithQuery>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';
.onx-breadcrumb {
  color: rgb(44, 62, 80);
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  > a {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: rgb(44, 62, 80);
  }

  .onx-icon {
    width: 0.75rem;
    height: 0.75rem;
  }

  & .clickable {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: var(--onx-tab-active-color);
    }
  }
}
</style>
