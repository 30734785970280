<script setup lang="ts">
import { FieldGroup } from '@/components/menu';
import OnxCheckboxList from '@/components/onx/OnxCheckboxList.vue';

type Props = {
  operatorsAccessor: any;
};

const props = defineProps<Props>();

const { operators } = props.operatorsAccessor();
</script>

<template>
  <FieldGroup label="Operators">
    <OnxCheckboxList v-model="operators" label-prop="name_mapped" />
  </FieldGroup>
</template>
