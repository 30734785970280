import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

const transformCongestionHourlyPerOperator: DataTransformerConstructor<
  [MetricStructuresEnum.Breakdown],
  Required<Pick<DataTransformerFnOptions, 'mainOperator'>> &
    Pick<DataTransformerFnOptions, 'selectedConnectionCategories'>
> =
  ({ mainOperator }) =>
  ([response], horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const datumFilteredForMainOperator = response.results.find((datum) => {
      return mainOperator.canonical_network_id == datum.canonical_network_id;
    });

    if (typeof datumFilteredForMainOperator === 'undefined') {
      return [];
    }

    const color = `#${mainOperator.hex_color}`;

    const hours = [...Array(24).keys()]; // [0, 1, ... 23]

    const data = hours.map((hour) => {
      return {
        ...datumFilteredForMainOperator,
        [dataAxis]: datumFilteredForMainOperator.mean[hour],
        [labelAxis]: String(hour),
      };
    });

    return [
      {
        data: data,
        type: 'bar',
        backgroundColor: color,
        color: color,
        label: mainOperator.name_mapped,
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color,
            label: mainOperator.name_mapped,
          },
        },
      },
    ];
  };

export default transformCongestionHourlyPerOperator;
