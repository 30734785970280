<template>
  <div class="RankingTable" :class="{ 'RankingTable--hide-rank': hideRank }">
    <template v-for="bucket in rankBuckets">
      <template v-for="(operator, bucketIndex) in bucket" :key="operator.operatorName">
        <template v-if="!hideRank">
          <div
            v-if="bucketIndex === 0"
            class="RankingTable__item__rank"
            :class="{ 'RankingTable__item__rank--draw': bucket.length > 1 }"
            :style="`grid-row: ${operator.gridRow} / span ${bucket.length}`"
          >
            <span v-if="bucket[0].rank" class="RankingTable__item__rank__label">
              {{ bucket[0].rank }}
            </span>
            <StopIcon v-else />
          </div>
        </template>
        <OperatorAvatar
          :style="`grid-row: ${operator.gridRow} / span 1`"
          :background-color="operator.operatorColor"
          :name="operator.operatorName"
        />

        <BarChartElement
          :style="`grid-row: ${operator.gridRow} / span 1`"
          :value="operator.value || 0"
          :lci="operator.lci"
          :uci="operator.uci"
          :min="minAndMax.min"
          :max="minAndMax.max"
          :color="operator.operatorColor"
          class="RankingTable__item__bar-chart"
        />

        <span class="RankingTable__item__value" :style="`grid-row: ${operator.gridRow} / span 1`">
          {{ operator.value || 0 }}
        </span>
      </template>
    </template>

    <div class="RankingTable__chart-steps" :style="`grid-row: 1 / span ${ranks.length}`">
      <div
        v-for="(step, index) in chartSteps"
        :key="step"
        class="RankingTable__chart-steps__step"
        :style="`left: ${index * 25}%`"
      >
        <span class="RankingTable__chart-steps__step__label">
          {{ step }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BarChartElement from './BarChartElement';
import StopIcon from '@/components/onx/icons/StopIcon';
import OperatorAvatar from '@/components/OperatorAvatar';
import { sortByMetricMeanAndRank } from '@/utils/data';

export default {
  name: 'RankingTable',
  components: {
    OperatorAvatar,
    BarChartElement,
    StopIcon,
  },
  props: {
    ranks: {
      type: Array,
      default: () => [],
    },
    minAndMax: {
      type: Object,
      default: () => ({}),
    },
    biggerBetter: {
      type: Boolean,
      default: true,
    },
    isVariableMetricCategory: {
      type: Boolean,
      default: false,
    },
    hideRank: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFirst() {
      return this.heroData && this.heroData.rank === 1;
    },
    isDraw() {
      const bucket = this.rankBuckets.find((i) => i.length && i[0].rank === this.heroData.rank);
      return this.heroData && bucket && bucket.length > 1;
    },
    rankBuckets() {
      if (!this.ranks.length) return [[]];

      const data = sortByMetricMeanAndRank(this.ranks, this.biggerBetter, 'value');

      return Object.values(
        data.reduce((ac, rank, i) => {
          const item = {
            ...rank,
            gridRow: i + 1,
          };

          if (item.rank) {
            if (!ac[item.rank]) {
              ac[item.rank] = [];
            }

            ac[item.rank].push(item);
          } else {
            ac['X-' + item.operatorName] = [item];
          }

          return ac;
        }, {}),
      );
    },
    chartSteps() {
      const max = Math.ceil(this.minAndMax.max);
      const step = max / 4;
      return [0, step, step * 2, step * 3, max];
    },
  },
  methods: {
    rankClass(comparisonRank, baseClass) {
      if (Number.isFinite(comparisonRank)) {
        if (comparisonRank < 0) {
          return baseClass + '--diff ' + baseClass + '--up';
        } else if (comparisonRank > 0) {
          return baseClass + '--diff ' + baseClass + '--down';
        }
      }
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';

$row-height: 28px;

.RankingTable {
  display: grid;
  grid-template-columns: [rank] 24px [operator] 28px [barchart] 1fr [value] fit-content(100%);
  grid-template-rows: auto;
  grid-auto-rows: $row-height;
  grid-gap: 8px;
  row-gap: 20px;
  align-items: center;

  width: 100%;

  &--hide-rank {
    grid-template-columns: [operator] 28px [barchart] 1fr [value] fit-content(100%);
  }
}

.RankingTable__item__rank {
  width: 24px;
  font-size: pxToRem(14);
  color: var(--dark-grey);

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  grid-column: rank / operator;
}

.RankingTable__item__rank--draw {
  height: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 65%;
    height: calc(100% - $row-height);
    border-style: solid;
    border-color: var(--dark-grey);
    border-width: 1px 0 1px 1px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    left: 50%;
  }
}

.RankingTable__item__rank__label {
  // z-index and background color hide the border of RankingTable__item__rank--draw:after
  // height cuts into the border to provide spacing between the border and the label
  // line-height centers the label vertically
  z-index: 1;
  background-color: var(--onx-paper-bg-color);
  height: 24px;
  line-height: 24px;

  // so as to prevent "." from affective horizontal centering of rank
  &:after {
    content: '.';
    position: absolute;
  }
}

.RankingTable__item__operator {
  grid-column: operator / barchart;
}

.RankingTable__item__bar-chart {
  grid-column: barchart / value;
  z-index: 1;
}

.RankingTable__item__value {
  grid-column: value;
  font-size: pxToRem(14);
}

.RankingTable__chart-steps {
  grid-column: barchart / value;
  height: 100%;
  position: relative;
}

.RankingTable__chart-steps__step {
  position: absolute;
  height: 100%;
  width: 1px;
  border-left: 1px dashed var(--charcoal-100);
}

.RankingTable__chart-steps__step__label {
  position: absolute;
  bottom: -28px;
  transform: translateX(-50%);
  font-size: pxToRem(10);
  color: var(--charcoal-200);
}
</style>
