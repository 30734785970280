import { computed } from 'vue';
import { useRoute } from 'vue-router';

import useCheckIfMetricSubtypeExists from '@/composables/useCheckIfMetricSubtypeExists';
import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import getAvailableChartMetricsForSelector from '@/focus/metric-selector/getAvailableChartMetricsForSelector';

const useAvailableChartMetricsForSelector = () => {
  const route = useRoute();
  const { checkIfMetricSubtypeExists } = useCheckIfMetricSubtypeExists();

  return computed(() => {
    const metricSubtype = route.query.metricSubtype as SelectableMetrics;
    return getAvailableChartMetricsForSelector(metricSubtype, route.name as string, checkIfMetricSubtypeExists);
  });
};

export default useAvailableChartMetricsForSelector;
