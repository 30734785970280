<script setup lang="ts">
import { computed } from 'vue';

type Props = {
  class?: string;
};

const props = withDefaults(defineProps<Props>(), {
  class: '',
});
const computedClass = computed<string>(() => `three_sixty_page ${props.class}`);
</script>

<template>
  <div :class="computedClass">
    <slot />
  </div>
</template>

<style lang="scss">
@use 'scss/mixins';
@import 'scss/onx-breakpoints.module';

.three_sixty_page {
  padding: 8px;

  @include tablet() {
    padding: 16px;
  }

  .onx-grid {
    margin-bottom: 8px;

    @include tablet() {
      margin-bottom: 16px;
    }
  }

  & .onx-grid.fluid {
    @include desktop {
      --onx-grid-fluid-item-default-min-width: 500px;
    }
  }
}
</style>
