import { exportToCsv } from '@/utils/files';

const exportDatasetToCsv =
  ({ getRow, headers }: { headers: string[]; getRow: (datum: any, dataset: any, date: string) => any[] }) =>
  (data: { datasets: any[] }, title: string) => {
    const rows = data.datasets.flatMap((dataset: any) => {
      return dataset.data.map((datum: any) => {
        const date = datum.date.split('T')[0];
        return getRow(datum, dataset, date);
      });
    });

    exportToCsv(`${title}.csv`, [headers, ...rows]);
  };

export default exportDatasetToCsv;
