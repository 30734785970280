import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

const OvershootingLikelihoodColorsByCategory = {
  minimal: 'rgba(127, 184, 0, 1)',
  low: 'rgba(102, 212, 208, 1)',
  moderate: 'rgba(255, 171, 25, 1)',
  high: 'rgba(255, 119, 115, 1)',
};

const transformCountsBreakdownStructureByOperator: (
  breakdownBy: 'counts' | 'percentage',
) => DataTransformerConstructor<
  [MetricStructuresEnum.CountsBreakdownMetric],
  Required<Pick<DataTransformerFnOptions, 'operators'>> & Pick<DataTransformerFnOptions, 'selectedConnectionCategories'>
> =
  (breakdownBy) =>
  ({ operators }) =>
  ([response], horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const datasets = Object.keys(metricsFilteredByOperator[0][breakdownBy]).reduce(
      (acc, key) => {
        const color =
          OvershootingLikelihoodColorsByCategory[
            key.toLocaleLowerCase() as keyof typeof OvershootingLikelihoodColorsByCategory
          ];
        acc[key.toLowerCase()] = {
          type: 'bar',
          label: key,
          backgroundColor: color,
          color,
          data: [],
          dataAxis,
          labelAxis,
        };

        return acc;
      },
      {} as Record<string, DataTransformerReturnedItem>,
    );

    metricsFilteredByOperator.forEach((datum) => {
      const operator = operators.find((operator) => operator.canonical_network_id === datum.canonical_network_id);

      if (!operator) {
        return;
      }

      Object.keys(datum[breakdownBy]).forEach((key) => {
        datasets[key.toLowerCase()].data.push({
          ...datum,
          count: datum.counts[key],
          percentage: datum.percentage[key],
          [dataAxis]: datum[breakdownBy][key],
          [labelAxis]: operator.name_mapped,
        });
      });
    });

    const sorted = ['high', 'moderate', 'low', 'minimal'].map((key) => datasets[key]);
    return sorted;
  };

export default transformCountsBreakdownStructureByOperator;
