<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import useFocusHeaderTabChange from './useFocusHeaderTabChange';
import { Dashboards } from '@/constants/dashboards';
import { OnxPermissionTopics } from '@/composables/useOnxLicencePermission';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import { OnxHeaderTab, OnxTabs, OnxTabsContext } from '@/components/onx/tabs';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import OnxPermissionGuard from '@/components/onx/OnxPermissionGuard.vue';
import ROUTES from '@/constants/routes';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useAnalytics from '@/composables/useAnalytics';
import useFocusRANHealthTabAvailability from '@/focus/composables/useFocusRANHealthTabAvailability';

const { track } = useAnalytics();
const { data: dashboardInfoResponse } = useDashboardInfo(Dashboards.Focus);
const explorerUrl = computed(() => {
  return dashboardInfoResponse.value?.data.explorer_url;
});

const route = useRoute();
const onTabChange = useFocusHeaderTabChange();
const headerRef = ref<HTMLDivElement | null>(null);

const headerTitleBlocks = computed(() => {
  const getHeaderTitle = route.matched[route.matched.length - 1]?.meta?.getHeaderTitle;
  if (typeof getHeaderTitle === 'function') {
    const title = getHeaderTitle(route);

    if (typeof title === 'string') {
      return [title];
    } else if (Array.isArray(title)) {
      return title;
    }
  }

  return '';
});

const { firstAvailableSubtabForRANHealth } = useFocusRANHealthTabAvailability();

const isSticky = ref(false);

onMounted(() => {
  const content = document.getElementsByClassName('PerformanceLayout__content')[0] as HTMLElement;

  const observer = new IntersectionObserver(
    ([e]) => {
      isSticky.value = !e.isIntersecting;

      content.style.paddingBottom = e.isIntersecting ? '0' : '58px';
    },
    { threshold: [0.95] },
  );

  if (headerRef.value?.parentElement) {
    observer.observe(headerRef.value.parentElement);
  }
});

const onMyDashboardsClick = () => {
  track('focus myDashboards click');
};
</script>

<template>
  <OnxPaper class="onx-focus-header" :class="{ 'onx-focus-header--sticky': isSticky }">
    <OnxTabsContext :selected-tab="route.matched?.[1]?.name">
      <OnxTabs grow class="onx-focus-header__main-tabs" @change="onTabChange">
        <OnxHeaderTab :id="ROUTES.FocusQoE" :value="ROUTES.FocusQoEDetails"> Quality of Experience </OnxHeaderTab>
        <OnxHeaderTab :id="ROUTES.FocusCoreHealth" :value="ROUTES.FocusCoreHealthByCDN"> Core Health </OnxHeaderTab>
        <OnxPermissionGuard :dashboard="Dashboards.Focus" :topic="OnxPermissionTopics.RANHealth">
          <OnxHeaderTab :id="ROUTES.FocusRANHealth" :value="firstAvailableSubtabForRANHealth">
            RAN Health
          </OnxHeaderTab>
        </OnxPermissionGuard>
        <OnxPermissionGuard :dashboard="Dashboards.Focus" :topic="OnxPermissionTopics.myDashboards">
          <OnxHeaderTab
            as="a"
            :href="explorerUrl"
            target="_blank"
            class="neutral-anchor-link"
            @click="onMyDashboardsClick"
          >
            <span>myDashboards</span>
          </OnxHeaderTab>
        </OnxPermissionGuard>
      </OnxTabs>
    </OnxTabsContext>

    <div class="onx-focus-header__content" ref="headerRef">
      <OnxHeadline as="h2" class="onx-focus-header__title">
        <span class="onx-focus-header__title__part" v-for="titleBlock in headerTitleBlocks" :key="titleBlock">
          {{ titleBlock }}
        </span>
      </OnxHeadline>

      <div v-if="$slots.tabs" class="onx-focus-header__secondary-tabs">
        <slot name="tabs" />
      </div>
    </div>
  </OnxPaper>
</template>

<style lang="scss">
@import 'scss/onx-breakpoints.module';
.onx-focus-header {
  &--sticky {
    .onx-focus-header__secondary-tabs {
      height: 0;
    }

    .onx-focus-header__content {
      min-height: 35px;

      @include desktop {
        min-height: 44px;
      }
    }
  }

  &__content {
    margin-top: 1rem;
    padding-left: 16px;
    padding-right: 16px;

    min-height: 64px;
    transition: all 0.3s ease-in-out;
  }

  &__main-tabs {
    padding: 0;
  }

  &__secondary-tabs {
    margin-left: -16px;
    margin-right: -16px;
    transition: all 0.3s ease-in-out;
    height: 38px;
    overflow-y: hidden;
  }

  &__title {
    span:not(:first-child):last-child {
      font-weight: 400;
    }

    &__part {
      margin-right: 8px;
    }
  }
}
</style>
