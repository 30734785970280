import { Ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useQuery } from '@tanstack/vue-query';

import type { OperatorsResponse } from '@/types/OperatorsResponse';
import type { Dashboards } from '@/constants/dashboards';
import osApi from '@/api/osApi';

const useNetworkOperators = (dashboard: Dashboards, locationId: Ref<number>) => {
  const store = useStore();

  const locationById = computed(() => {
    return store.getters['locations/byIdentifier'][locationId.value];
  });

  const enabled = computed(() => {
    return locationById.value !== undefined;
  });

  return useQuery({
    queryKey: ['networkOperators', locationId],
    queryFn: () =>
      osApi.get<OperatorsResponse>(
        `/${dashboard}/operators/?country_iso3=${locationById.value.iso3}&location_id=${locationId.value}`,
      ),
    staleTime: 24 * 60 * 60 * 1000,
    enabled,
  });
};

export default useNetworkOperators;
