import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { Dashboards } from '@/constants/dashboards';
import Routes from '@/constants/routes';

const RouteToDashboardNameMap: Record<string, Dashboards> = {
  [Routes.ThreeSixty]: Dashboards.ThreeSixty,
  [Routes.Focus]: Dashboards.Focus,
  [Routes.Spotlight]: Dashboards.Spotlight,
};

const useCurrentDashboardName = () => {
  const route = useRoute();

  return computed(() => {
    const dashboardName = RouteToDashboardNameMap[route.matched[0].name as string];
    return dashboardName;
  });
};

export default useCurrentDashboardName;
