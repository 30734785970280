import { MetricSubtypes } from '@/types/MetricSubtypes';

export const SelectableMetrics = {
  Games: MetricSubtypes.GamesExperience,
  GamesPacketLoss: MetricSubtypes.GamesPacketLoss,
  GamesJitter: MetricSubtypes.GamesJitter,
  GamesPacketDelay: MetricSubtypes.GamesPacketDelay,
  Download: MetricSubtypes.Download,
  Upload: MetricSubtypes.Upload,
  Ecq: MetricSubtypes.Ecq,
  EcqDownload: MetricSubtypes.EcqDownloadThroughput,
  EcqUpload: MetricSubtypes.EcqUploadThroughput,
  EcqIcmpLatency: MetricSubtypes.EcqIcmpLatency,
  EcqJitter: MetricSubtypes.EcqJitter,
  EcqPacketLoss: MetricSubtypes.EcqPacketLoss,
  EcqLatency: MetricSubtypes.EcqLatency,
  EcqTimeToFirstByte: MetricSubtypes.EcqDownloadTimeFirstByte,
  Ccq: MetricSubtypes.Ccq,
  CcqDownload: MetricSubtypes.CcqDownloadThroughput,
  CcqUpload: MetricSubtypes.CcqUploadThroughput,
  CcqIcmpLatency: MetricSubtypes.CcqIcmpLatency,
  CcqJitter: MetricSubtypes.CcqJitter,
  CcqPacketLoss: MetricSubtypes.CcqPacketLoss,
  CcqLatency: MetricSubtypes.CcqLatency,
  CcqTimeToFirstByte: MetricSubtypes.CcqDownloadTimeFirstByte,

  Video: MetricSubtypes.VideoExperience,
  VideoAbrThroughput: MetricSubtypes.VideoAbrThroughput,
  VideoAbrTtff: MetricSubtypes.VideoAbrTtff,
  VideoAbrStallingOccurrence: MetricSubtypes.VideoAbrStallingOccurrence,
  VideoAbrStallingTime: MetricSubtypes.VideoAbrStallingTime,
  VideoAbrResTime: MetricSubtypes.VideoAbrResTime,
  Voice: MetricSubtypes.VoiceExperience,

  LiveVideo: MetricSubtypes.LiveVideoExperience,
  LiveVideoOffset: MetricSubtypes.LiveVideoOffset,
  LiveVideoStallingTime: MetricSubtypes.LiveVideoStallingTime,
  LiveVideoStallingOccurrence: MetricSubtypes.LiveVideoStallingOccurrence,
  LiveVideoTtff: MetricSubtypes.LiveVideoTtff,
  LiveVideoThroughput: MetricSubtypes.LiveVideoThroughput,
  LiveVideoResTime: MetricSubtypes.LiveVideoResTime,

  // There's no standalone metric subtype for reliability.
  // Arbitrarily chose sessionreliability as a placeholder.
  Reliability: MetricSubtypes.ReliabilitySessionRelability,
} as const;
export type SelectableMetrics = (typeof SelectableMetrics)[keyof typeof SelectableMetrics];
