<script setup lang="ts">
import { ref } from 'vue';

import OnxCodeModal from '@/components/onx/OnxCodeModal.vue';
import TooltipWrapper from '@/components/visual/chart/TooltipWrapper.vue';
import OnxButton from '@/components/onx/OnxButton.vue';

export type Props = {
  sql: string;
  chartTitle: string;
};

defineProps<Props>();

const isSqlModalOpen = ref(false);
const toggleSqlModal = () => {
  isSqlModalOpen.value = !isSqlModalOpen.value;
};
</script>
<template>
  <TooltipWrapper title="View this chart's underlying SQL query">
    <OnxButton variant="primary" size="sm" @click="toggleSqlModal">
      <slot name="buttonLabel"> SQL </slot>
    </OnxButton>
  </TooltipWrapper>
  <OnxCodeModal
    v-if="sql !== undefined"
    :modal-title="`SQL for ${chartTitle}`"
    :code="sql"
    :isOpen="isSqlModalOpen"
    :onClose="toggleSqlModal"
  />
</template>
