<script setup lang="ts">
import { toRefs } from 'vue';

import OnxCheckbox from '@/components/onx/OnxCheckbox/OnxCheckbox.vue';
import OnxDropdown from '@/components/onx/OnxDropdown.vue';
import { OnxList, OnxListItem } from '@/components/onx/OnxList';
import OnxButton from '@/components/onx/OnxButton.vue';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon.vue';
import useChartTypeSelector from '@/focus/qoe/chart-type-selector/useChartTypeSelector';
import { type ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';

type Props = {
  metricDefinitions: ChartMetricDefinition[] | null | undefined;
};

const props = defineProps<Props>();
const { metricDefinitions } = toRefs(props);

const { chartTypeLabels, chartTypes, onChartTypeChange, selectedChartTypes, selectedChartTypesLabel } =
  useChartTypeSelector(metricDefinitions);
</script>

<template>
  <OnxDropdown :popper-hide-triggers="[]">
    <slot>
      <OnxButton variant="tertiary">
        <span class="chart-type-selector__label">Chart Type: </span>
        <span>{{ selectedChartTypesLabel }}</span>
        <ChevronDownIcon />
      </OnxButton>
    </slot>

    <template #content>
      <OnxList>
        <OnxListItem
          v-for="chartType in chartTypes"
          class="onx-chart-type__list"
          :key="chartType"
          @click="onChartTypeChange(chartType)"
          :disabled="selectedChartTypes.length === 1 && selectedChartTypes.includes(chartType)"
        >
          <OnxCheckbox
            :label="chartTypeLabels[chartType]"
            :model-value="selectedChartTypes.includes(chartType)"
            :disabled="selectedChartTypes.length === 1 && selectedChartTypes.includes(chartType)"
            @click.prevent
          />
        </OnxListItem>
      </OnxList>
    </template>
  </OnxDropdown>
</template>

<style scoped>
.chart-type-selector__label {
  color: black;
  display: inline-block;
  margin-right: 10px;
}
</style>
