import { computed } from 'vue';

import {
  GroupedChartMetricDefinitions,
  chartMetricDefinitionsByPage,
} from '@/chart-metric-definitions/ChartMetricDefinitions';
import { isChartMetricDefinitionWithSingleSubtype } from '@/chart-metric-definitions/MetricDefinition';
import ROUTES from '@/constants/routes';

import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import type { SelectableMetrics as SelectableMetricsType } from '@/types/metrics-selector/SelectableMetrics';
import useCheckIfMetricSubtypeExists from '@/composables/useCheckIfMetricSubtypeExists';

const useQoESelectableMetricTabs = () => {
  const { checkIfMetricSubtypeExists, ready } = useCheckIfMetricSubtypeExists();

  const qoeSelectableMetricTabs = computed(() => {
    const selectableTabNames: [SelectableMetricsType, string][] = [
      [SelectableMetrics.Download, 'Download Speed'],
      [SelectableMetrics.Upload, 'Upload Speed'],
      [SelectableMetrics.Games, 'Games'],
      [SelectableMetrics.Video, 'Video'],
      [SelectableMetrics.LiveVideo, 'Live Video'],
      [SelectableMetrics.Voice, 'Voice App'],
      [SelectableMetrics.Ecq, 'Excellent Consistent Quality'],
      [SelectableMetrics.Ccq, 'Core Consistent Quality'],
      [SelectableMetrics.Reliability, 'Reliability'],
    ];

    const metricDefinitions = chartMetricDefinitionsByPage[ROUTES.FocusQoEDetails] as GroupedChartMetricDefinitions;

    return selectableTabNames.filter(([metricSubtype]) => {
      return metricDefinitions[metricSubtype as SelectableMetrics].some((m) => {
        if (isChartMetricDefinitionWithSingleSubtype(m)) {
          return checkIfMetricSubtypeExists(m.metricSubtype);
        }

        return m.metricSubtypes.some((subtype) => checkIfMetricSubtypeExists(subtype));
      });
    });
  });

  return { qoeSelectableMetricTabs, ready };
};

export default useQoESelectableMetricTabs;
