<script setup lang="ts">
import type { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';

import use360ConnectionCategories from '@/360/use360ConnectionCategories';
import { chartMetricDefinitionsByPage } from '@/chart-metric-definitions/ChartMetricDefinitions';
import ChartRenderer from '@/chart-metric-definitions/ChartRenderer.vue';
import useCheckIfMetricExists from '@/composables/useCheckIfMetricExists';
import useFilters from '@/composables/useFilters';
import useLocations from '@/composables/useLocations';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { Dashboards } from '@/constants/dashboards';
import Routes from '@/constants/routes';
import { ChartTypesEnum } from '@/types/Charts';

const connectionCategories = use360ConnectionCategories();

const metrics = chartMetricDefinitionsByPage[Routes.ThreeSixtyCoreEndpointIPVideoLive] as ChartMetricDefinition[];
const { aggregation } = useFilters(Dashboards.ThreeSixty);
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);

const { checkIfMetricExists } = useCheckIfMetricExists();
const { locationId } = useLocations(Dashboards.ThreeSixty);
</script>

<template>
  <div class="three-sixty__charts-container">
    <div class="onx-grid fluid fit">
      <template v-for="metric in metrics">
        <template v-for="connectionCategory in connectionCategories.selectedChartConnectionCategories">
          <template v-if="checkIfMetricExists(`${metric.metricSubtype}_${connectionCategory.categoryValue}`)">
            <template v-if="metric.chartType === ChartTypesEnum.Dataset">
              <ChartRenderer
                :dashboard="Dashboards.ThreeSixty"
                :metric="metric"
                :connection-category="connectionCategory.categoryValue"
                :connection-category-label="connectionCategory.categoryLabel"
                :location="locationId"
                :geohashes="[]"
                :aggregation="aggregation"
                :operators="selectedOperators"
                :key="`${metric.metricSubtype}_${connectionCategory.categoryValue}`"
              />
            </template>
            <template v-else>
              <template
                v-for="operator in selectedOperators"
                :key="`${operator.name_mapped}-${metric.metricSubtype}_${connectionCategory.categoryValue}`"
              >
                <ChartRenderer
                  :dashboard="Dashboards.ThreeSixty"
                  :metric="metric"
                  :connection-category="connectionCategory.categoryValue"
                  :connection-category-label="connectionCategory.categoryLabel"
                  :location="locationId"
                  :geohashes="[]"
                  :aggregation="aggregation"
                  :operators="selectedOperators"
                  :main-operator="operator"
                />
              </template>
            </template>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>
