<script setup lang="ts">
type Props = {
  hidden?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  hidden: false,
});
</script>

<template>
  <div class="onx-blur-container">
    <slot />
    <div class="onx-blur-container__blurred" v-if="props.hidden" />
  </div>
</template>

<style lang="css" scoped>
.onx-blur-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.onx-blur-container__blurred {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
