import { MaybeRef, Ref, ref, unref } from 'vue';
import { Column, TableOptions } from '@/components/onx/table';

type UseTableColumnSortsReturnType<T> = {
  sortColumnKey: Ref<string | undefined>;
  sortDirection: Ref<'asc' | 'desc'>;
  onClickColumn: (column: Column<T>) => void;
};

const useTableColumnSorts = <T>(
  /** Column definitions for the current table. This builds the list of sortable columns. */
  columns: MaybeRef<Column<T>[]>,

  /** Initial sorting options */
  options?: TableOptions,
): UseTableColumnSortsReturnType<T> => {
  const validSortKeys = unref(columns)
    .filter((column) => column.sort !== undefined)
    .map((column) => column.key);
  const initialSortColumnKey =
    options?.initialSortColumnKey !== undefined && validSortKeys.includes(options.initialSortColumnKey)
      ? options.initialSortColumnKey
      : undefined;

  const sortColumnKey = ref<string | undefined>(initialSortColumnKey);
  const sortDirection = ref<'asc' | 'desc'>(options?.initialSortDirection ?? 'asc');
  const onClickColumn = (column: Column<any>) => {
    if (column.sort === undefined) {
      // this column isn't sortable
      return;
    } else if (sortColumnKey.value === column.key) {
      // if we've clicked on the same column, change the sort direction
      sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
      // clicked on a new column, set the sort column key
      sortColumnKey.value = column.key;
    }
  };

  return {
    sortColumnKey,
    sortDirection,
    onClickColumn,
  };
};

export default useTableColumnSorts;
