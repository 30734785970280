<template>
  <div class="onx-tag">
    <slot />
  </div>
</template>

<style lang="scss">
@import 'scss/mixins';

.onx-tag {
  padding: 0 8px;
  height: 16px;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: pxToRem(10);
  color: var(--white);
  background-color: var(--charcoal-500);
}
</style>
