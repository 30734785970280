import { MetricSubtypes } from '@/types/MetricSubtypes';
import { MetricStructuresEnum } from '@/types/MetricStructures';

import { type CongestionAllCellsRow } from '@/chart-metric-definitions/data-transformers/prepareCongestionAllCellsForTable';
import { congestionAllCellsColumns } from '@/360/base-station/table-columns/congestionAllCellsColumns';

import createExportSimpleTableFullMetricToCsv from '@/360/csv-export-overrides/createExportSimpleTableFullMetricToCsv';

const exportCongestionAllCellsTableToCsv = createExportSimpleTableFullMetricToCsv<
  MetricStructuresEnum.CongestionAllCells,
  CongestionAllCellsRow
>({ metricSubtype: MetricSubtypes.CongestionAllCellsFull, columns: congestionAllCellsColumns });

export default exportCongestionAllCellsTableToCsv;
