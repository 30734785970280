const createLegendItem = (item: { border: string; fontColor: string; text: string }) => {
  const li = document.createElement('li');

  // Color box
  const boxSpan = document.createElement('span');
  boxSpan.classList.add('legend-item-color');
  boxSpan.style.border = item.border;

  boxSpan.style.background = 'transparent';

  // Text
  const textContainer = document.createElement('p');
  textContainer.style.color = item.fontColor;

  const text = document.createTextNode(item.text);
  textContainer.appendChild(text);

  li.appendChild(boxSpan);
  li.appendChild(textContainer);

  return li;
};

export default createLegendItem;
