<script setup>
const props = defineProps({
  as: {
    type: String,
    default: 'h1',
    validator(value) {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
    },
  },
  noMarginTop: {
    type: Boolean,
    default: false,
  },
  noMarginBottom: {
    type: Boolean,
    default: false,
  },
  truncate: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <component
    :is="as"
    class="onx-headline"
    :class="{ 'no-margin-top': props.noMarginTop, 'no-margin-bottom': props.noMarginBottom, truncate: props.truncate }"
  >
    <slot></slot>
  </component>
</template>

<style scoped lang="scss">
@import 'scss/mixins';
@import 'scss/onx-breakpoints.module';

h1 {
  font-size: pxToRem(18);
  line-height: 1.2;

  @include tablet {
    font-size: pxToRem(20);
  }

  @include desktop {
    font-size: pxToRem(24);
  }
}

h2 {
  font-size: pxToRem(16);
  line-height: 1.2;

  @include tablet {
    font-size: pxToRem(18);
  }

  @include desktop {
    font-size: pxToRem(20);
  }
}

h3 {
  font-size: pxToRem(14);
  line-height: 1;

  @include tablet {
    font-size: pxToRem(16);
  }
}

h4 {
  font-size: pxToRem(12);
  line-height: 1;
  letter-spacing: 0.04em;

  @include tablet {
    font-size: pxToRem(14);
  }
}

h5 {
  font-size: pxToRem(12);
  line-height: 1;
  letter-spacing: 0.08em;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 1em;
  margin-bottom: 1em;

  &.no-margin-top {
    margin-top: 0;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
}
</style>
