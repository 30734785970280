import { type Plugin } from 'chart.js';

import { toFixedNoZeroPad } from '@/utils/helpers';

const afterUpdate: Plugin['afterUpdate'] = (chart, args, options) => {
  const legendContainer = document.getElementById(options.containerID as string);

  if (!legendContainer) {
    return;
  }

  const minAndMaxDatasets = chart.data.datasets.reduce(
    (acc, dataset: any) => {
      const datasetValue = dataset.data[0].site_samples;

      if (datasetValue < acc.min.value) {
        acc.min.value = datasetValue;
        acc.min.dataset = dataset;
      }

      if (datasetValue > acc.max.value) {
        acc.max.value = datasetValue;
        acc.max.dataset = dataset;
      }

      return acc;
    },
    { min: { value: Infinity, dataset: null }, max: { value: -Infinity, dataset: null } } as {
      min: { value: number; dataset: any };
      max: { value: number; dataset: any };
    },
  );

  if (!minAndMaxDatasets.min.dataset || !minAndMaxDatasets.max.dataset) {
    return;
  }

  const containerId = `${options.containerID}-gradient-legend`;
  const existingGradientElContainer = document.getElementById(containerId);

  if (existingGradientElContainer) {
    const minEl = existingGradientElContainer.querySelector('.min-text');
    const maxEl = existingGradientElContainer.querySelector('.max-text');
    const gradientEl = existingGradientElContainer.querySelector('.gradient') as HTMLDivElement | null;

    if (!minEl || !maxEl || !gradientEl) {
      return;
    }

    gradientEl.style.background = `linear-gradient(to right, ${minAndMaxDatasets.min.dataset.backgroundColor}, ${minAndMaxDatasets.max.dataset.backgroundColor})`;
    minEl.textContent = toFixedNoZeroPad(minAndMaxDatasets.min.value, 2);
    maxEl.textContent = toFixedNoZeroPad(minAndMaxDatasets.max.value, 2);
  } else {
    const gradientElContainer = document.createElement('div');
    gradientElContainer.id = `${options.containerID}-gradient-legend`;
    gradientElContainer.style.display = 'flex';
    gradientElContainer.style.alignItems = 'center';
    gradientElContainer.style.gap = '8px';
    gradientElContainer.style.fontSize = '12px';

    const gradientEl = document.createElement('div');
    gradientEl.classList.add('gradient');
    gradientEl.style.height = '16px';
    gradientEl.style.width = '100px';
    gradientEl.style.borderRadius = '4px';

    gradientEl.style.background = `linear-gradient(to right, ${minAndMaxDatasets.min.dataset.backgroundColor}, ${minAndMaxDatasets.max.dataset.backgroundColor})`;

    const minEl = document.createElement('div');
    minEl.classList.add('min-text');
    minEl.textContent = toFixedNoZeroPad(minAndMaxDatasets.min.value, 2);

    const maxEl = document.createElement('div');
    maxEl.classList.add('max-text');
    maxEl.textContent = toFixedNoZeroPad(minAndMaxDatasets.max.value, 2);

    gradientElContainer.appendChild(minEl);
    gradientElContainer.appendChild(gradientEl);
    gradientElContainer.appendChild(maxEl);

    if (options.afterText) {
      const afterTextEl = document.createElement('span');
      afterTextEl.textContent = options.afterText;
      gradientElContainer.appendChild(afterTextEl);
    }

    legendContainer.appendChild(gradientElContainer);
  }
};

const gradientLegendPlugin = ({ afterText }: { afterText?: string } = {}) => {
  return {
    id: 'gradientLegend',
    afterUpdate,
    defaults: {
      afterText,
    },
  };
};

export default gradientLegendPlugin;
