<template>
  <div class="BlurredDisclaimer">
    <div class="BlurredDisclaimer__text">
      © Copyright by Opensignal {{ new Date().getFullYear() }} <br />
      Opensignal data or analysis can not be used, or sold, for the production of public reports, media announcements,
      investor relations materials or marketing campaigns on a global, regional, national or local basis.
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlurredDisclaimer',
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;

.BlurredDisclaimer {
  position: relative;
  font-size: $font-size-12;
  opacity: 0.9;
  color: var(--dark-teal-150);

  &__text {
    padding: pxToRem(10);
    line-height: pxToRem(16);
    letter-spacing: pxToRem(0.4);
    text-align: center;
  }
}
</style>
