import { inject } from 'vue';

import CONNECTION_CATEGORIES_INJECT_KEY from './connCategoriesInjectKey';
import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';

const usePossibleConnectionCategories = () => {
  return inject<AvailableConnectionCategory[]>(CONNECTION_CATEGORIES_INJECT_KEY, []);
};

export default usePossibleConnectionCategories;
