import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import getAvailableMapMetricsForSelector from '@/focus/metric-selector/getAvailableMapMetricsForSelector';

const useAvailableMapMetricsForSelector = () => {
  const route = useRoute();

  return computed(() => {
    const metricSubtype = route.query.metricSubtype as SelectableMetrics;
    return getAvailableMapMetricsForSelector(metricSubtype, route.name as string);
  });
};

export default useAvailableMapMetricsForSelector;
