export enum ChartTypesEnum {
  BarChart = 'barChart',
  CdnBar = 'cdnBar',
  CdnBinned = 'cdnBinned',
  CdnIpStackedBar = 'cdnIpStackedBar',
  CdnTrend = 'cdnTrend',
  CoverageMap = 'coverage-map',
  Dataset = 'dataset',
  Distribution = 'dist',
  FailureStackedBar = 'failureStackedBar',
  Gauge = 'gauge',
  MultiMetricTable = 'multiMetricTable',
  OperatorBar = 'operatorBar',
  Table = 'table',
  ThresholdTrend = 'thresholdTrend',
  Trend = 'trend',
  VideoResStackedBar = 'videoResStackedBar',
  HourlyTrend = 'hourly-trend',
}

export type ChartTrendSeriesInputData<T> = {
  backgroundColor: string;
  color: string;
  data: T[];
  label: string;
};
