<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'medium',
    validator(value) {
      return ['small', 'medium', 'large'].includes(value);
    },
  },
});

const classObject = computed(() => {
  return {
    'onx-paragraph': true,
    [`onx-paragraph--${props.size}`]: true,
  };
});
</script>

<template>
  <p :class="classObject">
    <slot />
  </p>
</template>

<style scoped lang="scss">
@import 'scss/mixins';

.onx-paragraph {
  color: var(--onx-paragraph-color);
  margin-bottom: pxToRem(32);

  &--small {
    font-size: pxToRem(12);
  }

  &--medium {
    font-size: pxToRem(14);
  }

  &--large {
    font-size: pxToRem(16);
  }
}
</style>
