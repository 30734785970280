<script setup>
import { computed } from 'vue';

const props = defineProps({
  depth: {
    type: Number,
    default: 1,
    validator(value) {
      return value >= 1 && value <= 4;
    },
  },
});

const classObject = computed(() => {
  return {
    'onx-paper': true,
    [`onx-paper--depth-${props.depth}`]: true,
  };
});
</script>

<template>
  <div :class="classObject">
    <slot />
  </div>
</template>

<style lang="scss">
.onx-paper {
  background-color: var(--onx-paper-bg-color);

  &--depth-1 {
    box-shadow: var(--onx-paper-depth-1);
  }

  &--depth-2 {
    box-shadow: var(--onx-paper-depth-2);
  }

  &--depth-3 {
    box-shadow: var(--onx-paper-depth-3);
  }

  &--depth-4 {
    box-shadow: var(--onx-paper-depth-4);
  }
}
</style>
