<script setup>
import { computed } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'md'].includes(value),
  },
});

const classes = computed(() => {
  const { variant } = props;

  return {
    'onx-caption': true,
    [`onx-caption--${variant}`]: true,
  };
});
</script>

<template>
  <span :class="classes">
    <slot />
  </span>
</template>

<style scoped lang="scss">
@import 'scss/mixins';

.onx-caption {
  color: var(--onx-caption-color);
  font-size: pxToRem(12);

  &--md {
    font-size: pxToRem(14);
  }
}
</style>
