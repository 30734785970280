import { ref, computed } from 'vue';
import { type LngLatBounds } from 'mapbox-gl';

const useMapSizeAndBounds = () => {
  const mapWidthRef = ref(0);
  const mapHeightRef = ref(0);
  const latMinRef = ref(NaN);
  const latMaxRef = ref(NaN);
  const lngMinRef = ref(NaN);
  const lngMaxRef = ref(NaN);
  const bbox = ref<LngLatBounds | null>(null);

  const setBounds = (bounds: LngLatBounds) => {
    latMinRef.value = bounds.getSouth();
    latMaxRef.value = bounds.getNorth();
    lngMinRef.value = bounds.getWest();
    lngMaxRef.value = bounds.getEast();

    bbox.value = bounds;
  };

  const resetBounds = () => {
    latMinRef.value = NaN;
    latMaxRef.value = NaN;
    lngMinRef.value = NaN;
    lngMaxRef.value = NaN;

    bbox.value = null;
  };

  const handleNewBounds = ({ bounds, size }: { size: { x: number; y: number }; bounds: LngLatBounds }) => {
    mapWidthRef.value = size.x;
    mapHeightRef.value = size.y;

    setBounds(bounds);
  };

  const mapSizeAndBoundsReady = computed(() => {
    return mapWidthRef.value > 0 && mapHeightRef.value > 0 && bbox.value != null;
  });

  return {
    handleNewBounds,
    setBounds,
    resetBounds,
    mapWidth: mapWidthRef,
    mapHeight: mapHeightRef,
    latMin: latMinRef,
    latMax: latMaxRef,
    lngMin: lngMinRef,
    lngMax: lngMaxRef,
    mapSizeAndBoundsReady,
    bbox,
  };
};

export default useMapSizeAndBounds;
