<template>
  <tooltip-wrapper title="Export as CSV">
    <div class="csv-export" :class="{ 'csv-export--disabled': disabled }">
      <slot>
        <FontAwesomeIcon :icon="icons.faFileCsv" size="1x" />
      </slot>
    </div>
  </tooltip-wrapper>
</template>

<script>
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import TooltipWrapper from '@/components/visual/chart/TooltipWrapper.vue';

export default {
  name: 'CsvExportButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TooltipWrapper,
    FontAwesomeIcon,
  },
  data() {
    return {
      icons: {
        faFileCsv,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'scss/variables.module' as *;
@import 'scss/components';

.csv-export {
  @extend %boxIcon;
  position: relative;
  color: $color-blue-header;

  &:hover {
    color: $color-white;
    background-color: $color-blue-header;
  }

  &--disabled {
    pointer-events: none;
  }
}
</style>
