<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  collapsed: {
    type: Boolean,
    default: false,
  },
});

const _collapsed = ref(props.collapsed);
const toggle = () => {
  _collapsed.value = !_collapsed.value;
};

watch(
  () => props.collapsed,
  (value) => {
    _collapsed.value = value;
  },
);
</script>

<template>
  <slot :collapsed="_collapsed" :toggle="toggle" />
</template>
