<template>
  <CustomTooltip :message="name" placement="top">
    <div :style="componentStyle" class="onx-operator-avatar" :class="{ 'onx-operator-avatar--sm': size === 'sm' }">
      {{ name[0] }}
    </div>
  </CustomTooltip>
</template>

<script>
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';

export default {
  name: 'OperatorAvatar',
  components: { CustomTooltip },
  props: {
    name: { type: String, required: true },
    backgroundColor: { type: String, required: true },
    size: {
      type: String,
      default: null,
      validator(value) {
        return ['sm'].includes(value);
      },
    },
  },
  computed: {
    componentStyle() {
      return {
        backgroundColor: this.backgroundColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.onx-operator-avatar {
  border-radius: 50%;
  color: white;

  height: 28px;
  width: 28px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 14px;
}

.onx-operator-avatar--sm {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  margin: 0 4px;
}
</style>
