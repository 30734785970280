<script setup lang="ts">
import { onMounted, toRefs, watch, ref, isRef } from 'vue';
// @ts-ignore
import Chart from 'chart.js/auto';
import { ChartConfiguration, ChartType, ChartData, Plugin } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import get from 'lodash/get';
import merge from 'lodash/merge';
import RankingTableTooltip from './RankingTableTooltip.vue';
import NoData from '@/components/NoData.vue';
import colors from '@/utils/colorPalette';
import { getDatapointsByIndex, getTooltipState } from '@/utils/charts';
import type { MaybeRef } from '@/types/helpers/RefLike';
import { getTextColor } from '@/utils/colors';

export type Props = {
  chartId: string;
  orientation?: string;
  chartData: ChartData;
  stacked?: boolean;
  width: number;
  height: number | string;
  max?: MaybeRef<number>;
  config?: MaybeRef<object>;
  showLegend?: boolean;
  showConfidenceIntervals?: boolean;
  showCumulativeLine?: boolean;
  hideDatalabels?: boolean;
  getTooltipTitle?: (title: string) => string;
  showSecondaryValue?: boolean;
  bottomPluginContainerId?: string;
  plugins?: Plugin[];
};

const props = withDefaults(defineProps<Props>(), {
  plugins: () => [] as Plugin[],
});
const { chartData, chartId, config, getTooltipTitle, height, hideDatalabels, max, showConfidenceIntervals, width } =
  toRefs(props);

const isEmpty = ref(false);

const getChart = (): Chart | undefined => {
  const chart = Chart.getChart(chartId.value);

  return chart;
};

const tooltip = ref({
  datapoints: undefined,
  display: false,
  posClass: '',
  tooltipStyle: {},
  unit: '',
  title: '',
  secondaryTitle: '',
});

const OnxTooltipPlugin = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  afterDatasetsDraw(chart: any, args: any, options: any) {
    //Draw Confidence interval
    const ctx = chart.ctx;

    chart.data.datasets.map(function (dataset: any, i: number) {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden && showConfidenceIntervals?.value) {
        meta.data.map((element: any, index: number) => {
          ctx.fillStyle = colors.colorBlack;
          ctx.strokeStyle = colors.colorBlack;
          ctx.lineWidth = 1;

          ctx.beginPath();

          const position = element.tooltipPosition();

          if (props.orientation !== 'horizontal') {
            const top = dataset.data[index].uci - dataset.data[index].y;
            const bot = dataset.data[index].y - dataset.data[index].lci;

            const vScale = chart.scales.y.maxHeight / chart.scales.y.max;

            const ptop = position.y - top * vScale;
            const pbot = position.y + bot * vScale;

            const barWidth = element.width;
            const confLineWidth = barWidth > 10 ? 5 : barWidth / 2;

            ctx.moveTo(position.x - confLineWidth, ptop);
            ctx.lineTo(position.x + confLineWidth, ptop);
            ctx.moveTo(position.x, ptop);
            ctx.lineTo(position.x, pbot);
            ctx.moveTo(position.x - confLineWidth, pbot);
            ctx.lineTo(position.x + confLineWidth, pbot);
          } else {
            const left = dataset.data[index].x - dataset.data[index].lci;
            const right = dataset.data[index].uci - dataset.data[index].x;
            const hScale = chart.scales.x.maxWidth / chart.scales.x.max;
            const pleft = position.x - left * hScale;
            const pright = position.x + right * hScale;
            const barHeight = element.height;
            const confLineHeight = barHeight > 10 ? 5 : barHeight / 2;

            ctx.moveTo(pleft, position.y - confLineHeight);
            ctx.lineTo(pleft, position.y + confLineHeight);
            ctx.moveTo(pleft, position.y);
            ctx.lineTo(pright, position.y);
            ctx.moveTo(pright, position.y - confLineHeight);
            ctx.lineTo(pright, position.y + confLineHeight);
          }

          ctx.stroke();
          ctx.restore();
        });
      }
    });
  },
};

const renderChart = () => {
  const isVertical = props.orientation !== 'horizontal';

  const baseConfig = {
    type: 'bar' as ChartType,
    grouped: true,
    data: chartData.value,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: isVertical ? 'x' : 'y',
      scales: (() => {
        const scales: any = {};

        if (props.showCumulativeLine) {
          scales.yAxisCumulative = {
            display: true,
            position: 'right',

            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            offset: false,
            beingAtZero: true,
            max: 100,
          };

          scales.xAxisCumulative = {
            display: false,
            offset: false,
          };
        }

        if (isVertical) {
          scales.x = {
            stacked: props.stacked,
          };

          scales.y = {
            beginAtZero: true,
            min: 0,
            max: max?.value,
            stacked: props.stacked,
          };
        } else {
          scales.y = {
            stacked: props.stacked,
          };

          scales.x = {
            beginAtZero: true,
            min: 0,
            max: max?.value,
            stacked: props.stacked,
          };
        }

        return scales;
      })(),
      plugins: {
        legend: {
          display: props.showLegend,
          position: 'bottom',
        },
        tooltip: {
          enabled: false,
          mode: isVertical ? 'x' : 'y',
          intersect: false,
          position: 'nearest',
          external: (context) => {
            const tt = context.tooltip;
            const tooltipState = getTooltipState(getChart(), tt);
            const datapoints = getDatapointsByIndex(
              chartData.value.datasets,
              chartData.value.labels?.indexOf(tt.title[0]),
              !isVertical,
            );

            if (datapoints?.length) {
              tooltip.value = {
                ...tooltip.value,
                ...tooltipState,
                datapoints,
                title: getTooltipTitle?.value ? getTooltipTitle.value(tt.title[0]) : `Operator: ${tt.title[0]}`,
              };
            }
          },
        },
        datalabels: {
          display: hideDatalabels?.value ? false : 'auto',
          color: function (context) {
            return getTextColor(context.dataset.backgroundColor);
          },
          anchor: 'end',
          align: 'start',
          clamp: true,
          rotation: isVertical ? -90 : 0,
          labels: {
            title: {
              font: {
                size: 12,
              },
            },
          },
          // eslint-disable-next-line  @typescript-eslint/no-unused-vars
          formatter: function (value, context) {
            return `${isVertical ? value.y : value.x}`;
          },
        },
        gradientLegend: {
          containerID: props.bottomPluginContainerId,
        },
      },
    },
    plugins: [ChartDataLabels, OnxTooltipPlugin, ...props.plugins],
  } as ChartConfiguration;

  const propConfig = isRef(config) ? config.value : config;
  const mergedConfig = merge({ ...baseConfig }, { ...(propConfig || {}) });

  const tooltipPlugin = get(propConfig, 'options.plugins.tooltip.external') as any;
  if (typeof tooltipPlugin === 'function') {
    mergedConfig.options!.plugins!.tooltip!.external = (context: any) => {
      tooltipPlugin(context, tooltip, chartData);
    };
  }

  new Chart(chartId.value, merge(baseConfig, mergedConfig));
};

onMounted(() => {
  renderChart();
});

watch(
  () => props.chartData,
  (newChartData) => {
    const chart = getChart();

    if (chart) {
      chart.data = newChartData;
      chart.update();
    }

    if (!newChartData.datasets.length) {
      isEmpty.value = true;
    }
  },
);
</script>

<template>
  <div :style="{ position: 'relative', height: typeof height === 'number' ? `${height}px` : height }">
    <canvas :id="chartId" :height="height" :width="width"></canvas>
  </div>

  <RankingTableTooltip
    :pos-class="tooltip.posClass"
    :tooltip-style="tooltip.tooltipStyle"
    :datapoints="tooltip.datapoints"
    :display="tooltip.display"
    :title="tooltip.title"
    :secondary-title="tooltip.secondaryTitle"
    :unit="tooltip.unit"
    :show-secondary-value="showSecondaryValue || !!showCumulativeLine"
  />

  <NoData v-if="isEmpty" />
</template>
