<script setup lang="ts">
import OnxTag from '@/components/onx/tags/OnxTag.vue';

const Percentiles = [10, 25, 50, 75, 90, 95, 100];

type Props = {
  modelValue: number;
};

defineProps<Props>();
const emit = defineEmits(['update:modelValue']);
</script>

<template>
  <div class="onx-percentile-control">
    <span>Percentile:</span>
    <OnxTag
      class="onx-percentile-control__percentile-tag"
      v-for="percentile in Percentiles"
      :key="percentile"
      :class="{ 'onx-percentile-control__percentile-tag--selected': percentile === modelValue }"
      @click="emit('update:modelValue', percentile)"
    >
      {{ percentile }}
    </OnxTag>
  </div>
</template>

<style lang="scss">
@import 'scss/mixins';

.onx-percentile-control {
  display: flex;
  align-items: center;
  font-size: pxToRem(12);
  gap: 4px;
  color: var(--charcoal-400);

  &__percentile-tag {
    cursor: pointer;

    background-color: var(--charcoal-0);
    color: var(--charcoal-500);

    &:hover {
      background-color: var(--charcoal-100);
    }

    &--selected {
      background-color: var(--teal-500);
      color: var(--white);
    }
  }
}
</style>
