import Base from './base';

export class Trends extends Base {
  constructor(token) {
    super();
    this.token = `Bearer ${token}`;
    this.endpoint = 'metrics/';
  }

  getTrends({
    agg = '90days',
    comparison = false,
    dashboard = this.defaultDashboard,
    date,
    days = 10,
    location,
    metric,
  }) {
    let params = `?operator_info=yes&nb_days=${days}&comparison=${comparison}`;

    if (date) {
      params += `&end_date=${date.substr(0, 10)}`;
    }

    return this.get(`${this.v1}${dashboard}/${this.endpoint}${metric}/${location}/${agg}/${params}`, this.token);
  }
}
