import { MetricSubtypes } from '@/types/MetricSubtypes';

export const RANHealthTitles = {
  Summary: 'Summary',
  Coverage: 'Coverage',
  Availability: 'Availability',
  NetworkRollout: 'Network rollout',
  DeployedSpectrum: 'Deployed Spectrum',
};

export const MetricSubtypeTitles = {
  [MetricSubtypes.Download]: 'Download Speed',
  [MetricSubtypes.PeakDownload]: 'Peak Download',
  [MetricSubtypes.ConsistencyDownload]: 'Consistency Download',
  [MetricSubtypes.Upload]: 'Upload Speed',
  [MetricSubtypes.PeakUpload]: 'Peak Upload',
  [MetricSubtypes.ConsistencyUpload]: 'Consistency Upload',

  [MetricSubtypes.Ecq]: 'ECQ',
  [MetricSubtypes.EcqDownloadThroughput]: 'Download Throughput',
  [MetricSubtypes.EcqUploadThroughput]: 'Upload Throughput',
  [MetricSubtypes.EcqIcmpLatency]: 'ICMP Latency',
  [MetricSubtypes.EcqJitter]: 'Jitter',
  [MetricSubtypes.EcqPacketLoss]: 'Packet Loss',
  [MetricSubtypes.EcqLatency]: 'Latency',
  [MetricSubtypes.EcqDownloadTimeFirstByte]: 'Time to First Byte',

  [MetricSubtypes.Ccq]: 'CCQ',
  [MetricSubtypes.CcqDownloadThroughput]: 'Download Throughput',
  [MetricSubtypes.CcqUploadThroughput]: 'Upload Throughput',
  [MetricSubtypes.CcqIcmpLatency]: 'ICMP Latency',
  [MetricSubtypes.CcqJitter]: 'Jitter',
  [MetricSubtypes.CcqPacketLoss]: 'Packet Loss',
  [MetricSubtypes.CcqLatency]: 'Latency',
  [MetricSubtypes.CcqDownloadTimeFirstByte]: 'Time to First Byte',

  [MetricSubtypes.VideoExperience]: 'Video Experience',
  [MetricSubtypes.VideoAbrThroughput]: 'Video Throughput',
  [MetricSubtypes.VideoAbrStallingOccurrence]: 'Stalling Occurrence',
  [MetricSubtypes.VideoAbrStallingTime]: 'Stalling Time',
  [MetricSubtypes.VideoAbrTtff]: 'Time to First Frame',
  [MetricSubtypes.VideoAbrResTime]: 'Video Time on Resolution',

  [MetricSubtypes.LiveVideoExperience]: 'Live Video Experience',
  [MetricSubtypes.LiveVideoThroughput]: 'Live Video Throughput',
  [MetricSubtypes.LiveVideoStallingTime]: 'Live Video Stalling Time',
  [MetricSubtypes.LiveVideoStallingOccurrence]: 'Live Video Stalling Occurrence',
  [MetricSubtypes.LiveVideoTtff]: 'Live Video Time to First Frame',
  [MetricSubtypes.LiveVideoResTime]: 'Live Video Time on Resolution',
  [MetricSubtypes.LiveVideoOffset]: 'Live Video Average Offset',

  [MetricSubtypes.GamesExperience]: 'Games Experience',
  [MetricSubtypes.GamesPacketDelay]: 'Packet Delay',
  [MetricSubtypes.GamesPacketLoss]: 'Packet Loss',
  [MetricSubtypes.GamesJitter]: 'Jitter',

  [MetricSubtypes.VoiceExperience]: 'Voice Experience',
  [MetricSubtypes.ReliabilitySessionRelability]: 'Reliability',
};
