<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { format } from 'sql-formatter';
import { type LngLatBounds } from 'mapbox-gl';

import type { MetricStructuresEnum } from '@/types/MetricStructures';
import type { DataTransformerResponseCallback } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricSubtypes } from '@/types/MetricSubtypes';
import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';

import { Dashboards } from '@/constants/dashboards';
import { useOnxTable, Column } from '@/components/onx/table';
import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import { exportToCsv } from '@/utils/files';
import OnxRawTable from '@/components/onx/table/OnxRawTable.vue';
import useEndDate from '@/composables/useEndDate';
import useMetrics from '@/composables/useMetrics';
import ViewChartSqlButton from '@/components/visual/chart/ViewChartSqlButton.vue';

interface Props {
  dashboard: Dashboards;
  metrics: MetricSubtypes[];
  connectionCategory: AvailableConnectionCategory['categoryValue'];
  location: string | number;
  aggregation: string;
  bbox?: LngLatBounds;
  transform: DataTransformerResponseCallback<any>;
  enabled?: boolean;
  onExportCsv?: (columns: string[], rows: any[][], filename: string) => void;

  /* Table Options */
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: 'asc' | 'desc';
  tableTitle: string;
  tableSubtitle?: string;
  tableTooltip?: string;
  exportTitle?: string;
  exportSubtitle?: string;
}

const props = withDefaults(defineProps<Props>(), {
  enabled: true,
});

const { aggregation, columns, enabled, location, metrics, tableTitle, tableTooltip } = toRefs(props);
const { currentEndDate } = useEndDate(props.dashboard);

const { queries, someLoading, somePending } = useMetrics<MetricStructuresEnum.Breakdown>(props.dashboard, {
  metrics,
  connectionCategory: props.connectionCategory,
  location,
  aggregation,
  endDate: currentEndDate,
  geohashes: [],
  nbDays: 0,
  enabled,
  bbox: props.bbox,
});

const rows = computed(() => {
  if (someLoading.value || somePending.value) {
    return [];
  }

  const responses = queries.value
    .map((query) => query.data?.data)
    .filter((data): data is NonNullable<typeof data> => Boolean(data));
  return props.transform(responses);
});

const sqlStatements = computed(() => {
  if (someLoading.value || somePending.value) {
    return [];
  }

  return queries.value
    .map((query) => {
      if (query.data?.data?.sql) {
        return format(query.data.data.sql, {
          language: 'sql',
        });
      } else {
        return undefined;
      }
    })
    .filter(Boolean) as string[];
});

const { clearColumnFilter, columnFilters, onClickColumn, setColumnFilter, sortColumnKey, sortDirection, sortedRows } =
  useOnxTable(rows, columns, {
    initialSortColumnKey: props.initialSortColumnKey,
    initialSortDirection: props.initialSortDirection,
  });

const handleExportToCsv = () => {
  if (someLoading.value) {
    return;
  }

  if (props.onExportCsv) {
    props.onExportCsv(
      columns.value.map((column) => column.header),
      sortedRows.value.map((row) =>
        props.columns.map((column) => (column.cell ? column.cell(row) : column.value(row))),
      ),
      tableTitle.value,
    );
    return;
  }

  const data = [
    props.columns.map((column) => column.header),
    ...sortedRows.value.map((row) =>
      props.columns.map((column) => (column.cell ? column.cell(row) : column.value(row))),
    ),
  ];

  exportToCsv(tableTitle.value, data);
};
</script>

<template>
  <OnxChartContainer
    :title="tableTitle"
    :subtitle="tableSubtitle"
    :chart-title-tooltip="tableTooltip"
    :export-title="exportTitle"
    :export-subtitle="exportSubtitle"
    enable-csv-export
    :enable-image-export="false"
    @exportToCsv="handleExportToCsv"
    :loading="someLoading"
    :no-data="sortedRows.length === 0"
    data-test-id="onx-table__root"
  >
    <div class="onx-table-wrapper">
      <OnxRawTable
        :rows="sortedRows"
        :columns="columns"
        :column-filters="columnFilters"
        :sort-column-key="sortColumnKey"
        :sort-direction="sortDirection"
        @column:click="onClickColumn"
        @column:clear-filter="clearColumnFilter"
        @column:set-filter="setColumnFilter"
      />
    </div>
    <template #tools v-if="sqlStatements.length > 0">
      <ViewChartSqlButton
        v-for="(sql, index) in sqlStatements"
        :key="`${index}${index}`"
        :sql="sql"
        :chartTitle="tableTitle"
      >
        <template #buttonLabel>SQL {{ index + 1 }}</template>
      </ViewChartSqlButton>
    </template>
  </OnxChartContainer>
</template>

<style lang="scss">
.onx-table-wrapper {
  overflow-x: auto;
  width: 100%;
  max-height: 310px;
  overflow-y: scroll;
}

.onx-table__export {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 8px;
}
</style>
