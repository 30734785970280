import upperFirst from 'lodash/upperFirst';

export enum MapEvents {
  MapReady = 'mapReady',
  NewBounds = 'newBounds',
}

export const MapEventListenerAttrs = {
  [MapEvents.MapReady]: `on${upperFirst(MapEvents.MapReady)}`,
  [MapEvents.NewBounds]: `on${upperFirst(MapEvents.NewBounds)}`,
};
