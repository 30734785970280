<script setup lang="ts">
import { onMounted } from 'vue';

import { Dashboards } from '@/constants/dashboards';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import OnxVegaMap from '@/components/onx/vega-map/OnxVegaMap.vue';
import useAnalytics from '@/composables/useAnalytics';
import useUser from '@/components/onx/composables/useUser';
import OnxSwyftMap from '@/components/onx/swyft-map/OnxSwyftMap.vue';
import useLocations from '@/composables/useLocations';
import useSynchronizedMaps from '@/composables/useSynchronizedMaps';
import { MapEvents } from '@/components/visual/map/MapEvents';

const { selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
const { track } = useAnalytics();
const { user } = useUser();
const { locationId } = useLocations(Dashboards.Focus);

onMounted(() => {
  track('focus ran health network rollout page view');
});

const { onMapReady } = useSynchronizedMaps();
</script>

<template>
  <div class="onx-grid fluid fit">
    <template v-for="operator in selectedOperators" :key="operator.canonical_network_id">
      <OnxSwyftMap
        v-if="user?.uses_brytlyt"
        title="5G Rollout"
        :network-operators="[operator]"
        :geohash-level="7"
        map-endpoint="maps/network-rollout"
        :location-id="locationId"
        @[MapEvents.MapReady]="onMapReady"
        :key="`${locationId}-brytlyt`"
      />
      <OnxVegaMap
        v-else
        :network-operator="operator"
        endpoint="render-5grollout-vega"
        title="5G Rollout"
        :display-legend="false"
        @[MapEvents.MapReady]="onMapReady"
        :key="`${locationId}-vega`"
      />
    </template>
  </div>
</template>
