import { computed, ref, unref, MaybeRef, reactive } from 'vue';
import { Column, TableOptions } from '@/components/onx/table';

const useOnxTable = <T>(
  maybeRefRows: MaybeRef<T[]>,
  maybeRefColumns: MaybeRef<Column<T>[]>,
  options?: TableOptions,
) => {
  const validSortKeys = unref(maybeRefColumns)
    .filter((column) => column.sort !== undefined)
    .map((column) => column.key);
  const initialSortColumnKey =
    options?.initialSortColumnKey !== undefined && validSortKeys.includes(options.initialSortColumnKey)
      ? options.initialSortColumnKey
      : undefined;

  const sortColumnKey = ref<string | undefined>(initialSortColumnKey);
  const sortDirection = ref<'asc' | 'desc'>(options?.initialSortDirection ?? 'asc');
  const onClickColumn = (column: Column<any>) => {
    if (column.sort === undefined) {
      // this column isn't sortable
      return;
    } else if (sortColumnKey.value === column.key) {
      // if we've clicked on the same column, change the sort direction
      sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
    } else {
      // clicked on a new column, set the sort column key
      sortColumnKey.value = column.key;
    }
  };

  const columnFilters = reactive<Record<string, any>>({});
  const setColumnFilter = (column: Column<any>, row: any) => {
    if (column.filterable) {
      columnFilters[column.key] = column.value(row);
    }
  };

  const clearColumnFilter = (column: Column<any>) => {
    delete columnFilters[column.key];
  };

  const filteredRows = computed(() => {
    const rows = unref(maybeRefRows);
    const columns = unref(maybeRefColumns);

    return rows.filter((row) => {
      return columns.every((column) => {
        if (columnFilters[column.key] === undefined) {
          // no filter for this column
          return true;
        }

        const rowValue = column.value(row);
        if (typeof rowValue === 'string') {
          // case-insensitive string matching
          return rowValue.toLowerCase().includes(columnFilters[column.key].toLowerCase());
        } else {
          // strict equality
          return rowValue === columnFilters[column.key];
        }
      });
    });
  });

  const sortedRows = computed(() => {
    const rows = unref(filteredRows);
    const columns = unref(maybeRefColumns);

    if (columns.length === 0 || sortColumnKey.value === undefined) {
      return rows;
    }

    const sortColumn = columns.find((column) => column.key === sortColumnKey.value);
    if (!sortColumn || sortColumn.sort === undefined) {
      return rows;
    }

    const newRows = [...rows];
    const sortFunction = sortColumn.sort;
    return newRows.sort((a, b) => {
      const aCell = sortColumn.value(a);
      const bCell = sortColumn.value(b);
      return sortDirection.value === 'asc' ? sortFunction(aCell, bCell) : sortFunction(bCell, aCell);
    });
  });

  return {
    sortColumnKey,
    sortDirection,
    onClickColumn,

    columnFilters,
    setColumnFilter,
    clearColumnFilter,

    sortedRows,
  };
};

export default useOnxTable;
