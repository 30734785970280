<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import type { Column } from '@/components/onx/table';
import SortOrderIcon from '@/components/onx/icons/SortOrderIcon.vue';

import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';

interface Props {
  rows: any[];
  columns: Column<any>[];
  columnFilters?: Record<string, any>;
  sortColumnKey: string | undefined;
  sortDirection: 'asc' | 'desc' | undefined;
}

const props = defineProps<Props>();

const emit = defineEmits(['column:click', 'column:setFilter', 'column:clearFilter']);
</script>

<template>
  <table class="onx-table">
    <thead>
      <tr>
        <th
          v-for="column in props.columns"
          :key="column.key"
          @click="emit('column:click', column)"
          :class="{ active: sortColumnKey === column.key, sortable: column.sort !== undefined }"
        >
          <label class="onx-table__column-header">
            <SortOrderIcon
              v-if="column.sort !== undefined"
              :active="sortColumnKey === column.key"
              :ascending="sortDirection === 'asc'"
            />
            <template v-if="columnFilters?.[column.key]">
              <span>{{ columnFilters[column.key] }}</span>
              <FontAwesomeIcon :icon="faTimes" size="1x" @click.stop="emit('column:clearFilter', column)" />
            </template>
            <span v-else>
              {{ column.header }}
            </span>
          </label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, index) in rows" :key="index">
        <td v-for="column in props.columns" :key="column.key" @click.stop="emit('column:setFilter', column, row)">
          {{ column.cell ? column.cell(row) : column.value(row) }}
        </td>
      </tr>
    </tbody>
  </table>
  <div v-if="rows.length === 0" class="onx-chart-container__no-data">
    <OnxHeadline as="h3">No rows visible</OnxHeadline>
  </div>
</template>

<style lang="scss">
.onx-table {
  table-layout: fixed;

  thead {
    background-color: var(--onx-table-thead-border-color);

    th {
      border-bottom: 2px solid transparent;
      position: sticky;
      top: 0;
      background-color: var(--onx-table-thead-border-color);

      &.active.sortable,
      &:hover.sortable {
        color: var(--onx-table-thead-active-color);
        border-bottom: 2px solid var(--onx-table-thead-active-color);
        cursor: pointer;

        label.onx-table__column-header {
          cursor: pointer;
        }
      }
    }

    label.onx-table__column-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      gap: 8px;

      > span {
        user-select: none;
      }
    }
  }
  tbody {
    display: table-body;
    width: 100%;

    tr {
      background-color: var(--onx-table-row-bg-color);
      &:nth-of-type(even) {
        background-color: var(--onx-table-tbody-tr-odd-color);
      }
      &.highlighted,
      &:hover {
        background-color: var(--onx-table-row-highlighted-bg-color);
        color: var(--onx-table-tr-active-color);
      }
    }
  }
}
</style>
