import getAvailableChartMetrics from './getAvailableChartMetrics';

import { MetricSubtypes } from '@/types/MetricSubtypes';
import type { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';

import getLabelForMetricSubtype from '@/focus/metric-selector/labelsByMetricSubtype';
import { isChartMetricDefinitionWithSingleSubtype } from '@/chart-metric-definitions/MetricDefinition';

const getAvailableChartMetricsForSelector = (
  metricSubtype: SelectableMetrics,
  routeName: string,
  checkIfMetricSubtypeExists: (a: MetricSubtypes) => boolean,
) => {
  const metrics = getAvailableChartMetrics(metricSubtype, routeName).filter((item) => {
    if (isChartMetricDefinitionWithSingleSubtype(item)) {
      return checkIfMetricSubtypeExists(item.metricSubtype);
    }

    return item.metricSubtypes.some((subtype) => checkIfMetricSubtypeExists(subtype));
  });

  if (!metrics) {
    return [];
  }

  const uniqueSubtypes = new Set(metrics.map((metric) => metric.chartGroup));
  return [...uniqueSubtypes]
    .filter((subtype): subtype is MetricSubtypes => subtype !== undefined)
    .map((subtype) => ({
      metricSubtype: subtype,
      label: getLabelForMetricSubtype(subtype),
    }));
};

export default getAvailableChartMetricsForSelector;
