<script setup lang="ts">
import OnxRadioButton, { OnxRadioButtonProps } from '@/components/onx/OnxRadioButton.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import OnxList from '@/components/onx/OnxList/OnxList.vue';
import OnxListItem, { OnxListItemProps } from '@/components/onx/OnxList/OnxListItem.vue';
import OnxDropdown from '@/components/onx/OnxDropdown.vue';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon.vue';
import naiveId from '@/utils/naiveId';

type RadioButtonProps = Omit<OnxRadioButtonProps, 'modelValue' | 'inheritColor' | 'disabled' | 'name'>;
type ListItemProps = Omit<OnxListItemProps, 'as' | 'large' | 'extraXPadding'>;

type Props = {
  label: string;
  selectedLabel: string | undefined; // undefined allowed for initial state
  items: ({
    keyAccessor: number | string | symbol;
    label: string;
    className?: string;
    checked?: boolean;
  } & ListItemProps &
    RadioButtonProps)[];
  listContainerClass?: string;
  disabled?: boolean;
};

const randomRadioButtonGroupName = naiveId();

defineProps<Props>();
const emit = defineEmits(['listItemClick']);
</script>

<template>
  <OnxDropdown :disabled="disabled">
    <slot>
      <OnxButton variant="tertiary" :disabled="disabled">
        <span
          class="onx-radio-button-list-dropdown-label"
          :class="{ 'onx-radio-button-list-dropdown-label--disabled': disabled }"
        >
          {{ label }}
        </span>
        <span>{{ selectedLabel }}</span>
        <ChevronDownIcon />
      </OnxButton>
    </slot>

    <template #content>
      <OnxList :class="listContainerClass">
        <OnxListItem
          v-for="item in items"
          :key="item.keyAccessor"
          :inset="item.inset"
          :disabled="item.disabled"
          :class="item.className"
          @click="emit('listItemClick', item.value)"
        >
          <OnxRadioButton
            :name="randomRadioButtonGroupName"
            :label="item.label"
            :value="item.value"
            :checked="item.checked"
            inherit-color
          />
        </OnxListItem>
      </OnxList>
    </template>
  </OnxDropdown>
</template>

<style scoped lang="scss">
.onx-radio-button-list-dropdown-label {
  color: black;
  display: inline-block;
  margin-right: 10px;

  &--disabled {
    color: inherit;
  }
}
</style>
