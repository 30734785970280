import { isRef, computed, unref } from 'vue';

import type { MetricDescriptor } from '@/types/MetricDescriptor';
import type { MaybeRef } from '@/types/helpers/RefLike';
import type { MetricStructures, MetricStructuresEnum } from '@/types/MetricStructures';

import { get5stepsPercentiles } from '@/utils/data';
import { getDatumValue } from '@/utils/viewHelpers';

const colorScaleIntervals = <S extends MetricStructuresEnum>(
  data: MetricStructures[S][] | { value: number }[],
): [number, number][] => {
  const percentiles = get5stepsPercentiles(data, 'value'); // get 6 bounds for 5 bucketted percentiles, eg [0, 20th, 40th, 60th, 80th, 100th]
  return [
    // convert into 5 pairs of bounds, eg [[0, 20th], [20th, 40th], [40th, 60th], [60th, 80th], [80th, 100th]]
    [percentiles[0], percentiles[1]],
    [percentiles[1], percentiles[2]],
    [percentiles[2], percentiles[3]],
    [percentiles[3], percentiles[4]],
    [percentiles[4], percentiles[5]],
  ];
};

const useMapPolygonColorScale = <S extends MetricStructuresEnum>(
  maybeRefMetric: MaybeRef<MetricDescriptor | undefined>,
  metricList: MaybeRef<MetricStructures[S][] | { value: number }[]>,
  overrideColors?: string[],
) => {
  const colors = overrideColors || ['#AB4C7D', '#C5787D', '#E5B608', '#7FB982', '#56A67D'];

  const computedColorScaleIntervals = computed(() => {
    const metric = unref(maybeRefMetric);

    if (!metric) {
      return [];
    }

    const biggerIsBetter = metric.bigger_is_better;
    const data = isRef(metricList) ? metricList.value : metricList;
    const values = data.map((item) => ({ value: getDatumValue(item) || (item as any).value }));

    return colorScaleIntervals(values)
      .map((value, index) => ({
        color: biggerIsBetter ? colors[index] : colors[4 - index],
        min: value[0],
        max: value[1],
        order: biggerIsBetter ? 4 - index : index,
      }))
      .sort((a, b) => a.order - b.order);
  });

  return { colorScaleIntervals: computedColorScaleIntervals };
};

export default useMapPolygonColorScale;
