<template>
  <div class="onx-breadcrumbs">
    <slot />
  </div>
</template>
<style lang="scss">
.onx-breadcrumbs {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
</style>
