import get from 'lodash/get';
import basicStore from './basic';
import { V1_CDN_RES, V2_CDN_RES, V3_CDN_RES } from '@/constants/cdnRes';
import { METRIC_STRUCTURE_TO_VALUE_FIELD_MAP, METRIC_CDNS, METRIC_CPS, METRIC_TYPES } from '@/constants/constants';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import { getUnit, getMetric, getParentOfChart, toMainMetric, isHero } from '@/utils/metrics';

const { baseGetters, baseMutations, baseState, baseTypes } = basicStore('metrics');

const types = {
  ...baseTypes,
  SET_PRIMARY: 'metrics:SET_PRIMARY',
  SET_SUPPORTING: 'metrics:SET_SUPPORTING',
  METRICS: 'metrics/allMetrics',
  PRIMARY: 'metrics/primaryMetric',
  PRIMARY_KEY: 'metrics/primaryMetricKey',
  DEFAULT_TECHNOLOGY: 'metrics/defaultTechnology',
  SUPPORTING: 'metrics/supportingMetric',
  CATEGORY: 'metrics/category',
  AVAILABLE_CPS: 'metrics/cps',
  AVAILABLE_CDNS: 'metrics/cdns',
  PI_MENU: 'metrics/menuMetric',
  DISPLAY_3G_PI_USER_EXPERIENCE: 'metrics/display3GPIUserExperience',
  DISPLAY_4G_PI_USER_EXPERIENCE: 'metrics/display4GPIUserExperience',
  DISPLAY_5G_PI_USER_EXPERIENCE: 'metrics/display5GPIUserExperience',
  DISPLAY_5G_MMWAVE_PI_USER_EXPERIENCE: 'metrics/display5GMmWavePIUserExperience',
  PRIMARY_UNIT: 'metrics/primaryUnit',
  SUPPORTING_UNIT: 'metrics/supportingUnit',
  METRIC_KEYS: 'metrics/metricKeys',
  SUBTYPES: 'metrics/subtypes',
  METRICS_WITH_SELECTION: 'metrics/metricsWithSelection',
  LICENCES: 'metrics/licences',
  TOOLTIP: 'metrics/tooltip',
  SUPPORTING_TOOLTIP: 'metrics/supportingTooltip',
  AVAILABLE_METRIC_CATEGORIES: 'metrics/availableMetricCategories',
  METRIC_CATEGORIES_BY_TECH: 'metrics/metricCategoriesByTech',
  // TODO get a better name - it's actually the key of data for a metric
  GET_RANK: 'metrics/getRank',
  GET_SUBTYPE: 'metrics/getSubtype',
  GET_LICENCE: 'metrics/getLicence',
  GET_TOOLTIP: 'metrics/getTooltip',
  GET_METRIC_BY_IDENTIFIER: 'metrics/byIdentifier',
};

const rootTypes = {
  CDN_RES_V2: `chart/isCdnResV2`,
  CDN_RES_V3: `chart/isCdnResV3`,
  CI_METRIC_TYPE: 'competitive/defaultMetricType',
};

const state = () => ({
  ...baseState(),
  primaryMetric: '',
  supportingMetric: '',
  licences: [],
  pseudoMetrics: [
    {
      key: 'gamesrtt_5gmmwave',
      type: '5gmmwave',
      subtype: 'gamesrtt',
    },
    {
      key: 'gamesrtt_5g',
      type: '5g',
      subtype: 'gamesrtt',
    },
    {
      key: 'gamesrtt_lte',
      type: 'lte',
      subtype: 'gamesrtt',
    },
    {
      key: 'gamesrtt_3g',
      type: '3g',
      subtype: 'gamesrtt',
    },

    {
      key: 'gamespacketloss_5gmmwave',
      type: '5gmmwave',
      subtype: 'gamespacketloss',
    },
    {
      key: 'gamespacketloss_5g',
      type: '5g',
      subtype: 'gamespacketloss',
    },
    {
      key: 'gamespacketloss_lte',
      type: 'lte',
      subtype: 'gamespacketloss',
    },
    {
      key: 'gamespacketloss_3g',
      type: '3g',
      subtype: 'gamespacketloss',
    },

    {
      key: 'gamesjitter_5gmmwave',
      type: '5gmmwave',
      subtype: 'gamesjitter',
    },
    {
      key: 'gamesjitter_5g',
      type: '5g',
      subtype: 'gamesjitter',
    },
    {
      key: 'gamesjitter_lte',
      type: 'lte',
      subtype: 'gamesjitter',
    },
    {
      key: 'gamesjitter_3g',
      type: '3g',
      subtype: 'gamesjitter',
    },

    {
      key: 'download_5glow',
      type: '5g',
      subtype: 'download',
      kind: 'download',
    },
    {
      key: 'download_5gmid',
      type: '5g',
      subtype: 'download',
      kind: 'download',
    },
    {
      key: 'download_5ghigh',
      type: '5g',
      subtype: 'download',
      kind: 'download',
    },

    {
      key: 'upload_5glow',
      type: '5g',
      subtype: 'upload',
      kind: 'upload',
    },
    {
      key: 'upload_5gmid',
      type: '5g',
      subtype: 'upload',
      kind: 'upload',
    },
    {
      key: 'upload_5ghigh',
      type: '5g',
      subtype: 'upload',
      kind: 'upload',
    },
  ],
});
const getters = {
  ...baseGetters,
  [types.METRICS]: (state) => state.array,
  [types.LICENCES]: (state) => state.licences,
  [types.PRIMARY]: (state) => getMetric(state.array, state.primaryMetric),
  [types.PRIMARY_KEY]: (state) => state.primaryMetric,
  [types.DEFAULT_TECHNOLOGY]: (state, getters) => {
    if (getters[types.DISPLAY_4G_PI_USER_EXPERIENCE]) {
      return 'lte';
    }
    if (getters[types.DISPLAY_5G_PI_USER_EXPERIENCE]) {
      return '5g';
    }
    if (getters[types.DISPLAY_3G_PI_USER_EXPERIENCE]) {
      return '3g';
    }
    if (getters[types.DISPLAY_5G_MMWAVE_PI_USER_EXPERIENCE]) {
      return '5gmmwave';
    }
  },
  [types.SUPPORTING]: (state) => getMetric(state.array, state.supportingMetric),
  [types.CATEGORY]: (state, getters) => {
    switch (getters[types.PRIMARY].category) {
      case 'devices':
      case 'infrastructure':
        return getters[types.PRIMARY].category;
      default:
        return 'experience';
    }
  },
  [types.METRIC_CATEGORIES_BY_TECH]: (state, getters) => {
    return getters[types.METRICS].reduce((acc, metric) => {
      const technology = metric.type;
      if (acc[technology] && acc[technology].includes(metric.category)) {
        return acc;
      }
      return {
        ...acc,
        [technology]: [...(acc[technology] || []), metric.category],
      };
    }, {});
  },
  /**
   * Returns available metric categories by tech of the current primary metric
   * when overrideTech is not defined.
   * Otherwise, returns those of the specified metric
   * @returns {(overrideTech: string) => array}
   */
  [types.AVAILABLE_METRIC_CATEGORIES]: (_, getters) => (overrideTech) => {
    const technology = !overrideTech ? toMainMetric(getters[types.PRIMARY].type) : overrideTech;
    return getters[types.METRIC_CATEGORIES_BY_TECH][technology] || [];
  },
  [types.AVAILABLE_CPS]: (state, getters) => METRIC_CPS[get(getters, [types.PRIMARY, 'kind'])] || [],
  [types.AVAILABLE_CDNS]: (state, getters, rootState, rootGetters) => {
    const supportedTypes = ['experience', 'stalling', 'ttff', 'stalltime'];

    if (rootGetters[rootTypes.CDN_RES_V3]) {
      return V3_CDN_RES;
    }

    if (supportedTypes.includes(get(getters, [types.PRIMARY, 'kind']))) {
      const isV2 = rootGetters[rootTypes.CDN_RES_V2];
      return isV2 ? V2_CDN_RES : V1_CDN_RES;
    }
    return METRIC_CDNS[get(getters, [types.PRIMARY, 'kind'])] || [];
  },
  [types.PI_MENU]: (state, getters, rootState) => {
    let metric = getMetric(state.array, getParentOfChart(state.primaryMetric));

    if (!metric.key) {
      metric = getMetric(state.pseudoMetrics, getParentOfChart(state.primaryMetric)) || {};
    }

    return {
      key: 'infrastructure_lte',
      type: 'lte',
      subtype: 'infrastructure',
      ...metric,
    };
  },
  [types.DISPLAY_3G_PI_USER_EXPERIENCE]: (state, getters) => {
    return (
      getters[types.METRICS].filter((metric) => {
        return (
          !isHero(metric.key) &&
          metric.type === METRIC_TYPES.ThreeG &&
          !metric.licence.includes('pi_devices') &&
          !metric.licence.includes('pi_infra')
        );
      }).length > 0
    );
  },
  [types.DISPLAY_4G_PI_USER_EXPERIENCE]: (state, getters) => {
    return (
      getters[types.METRICS].filter((metric) => {
        return (
          !isHero(metric.key) &&
          metric.type === METRIC_TYPES.FourG &&
          !metric.licence.includes('pi_devices') &&
          !metric.licence.includes('pi_infra')
        );
      }).length > 0
    );
  },
  [types.DISPLAY_5G_PI_USER_EXPERIENCE]: (state, getters) => {
    return getters[types.METRICS].some((metric) => metric.type === METRIC_TYPES.FiveG);
  },
  [types.DISPLAY_5G_MMWAVE_PI_USER_EXPERIENCE]: (state, getters) => {
    return getters[types.METRICS].some((metric) => metric.type === METRIC_TYPES.Mmwave);
  },
  [types.PRIMARY_UNIT]: (state, getters) => getUnit(getters[types.PRIMARY]),
  [types.SUPPORTING_UNIT]: (state, getters) => getUnit(getters[types.SUPPORTING]),
  [types.METRIC_KEYS]: () => state.array.map((m) => m.key),
  [types.METRICS_WITH_SELECTION]: () =>
    state.array.map((m) => ({
      ...m,
      selected: m.key === state.primaryMetric,
    })),
  [types.TOOLTIP]: (state, getters, rootState, rootGetters) => {
    if (getters[types.PRIMARY].subtype === 'cdnresvideoexperience') {
      return rootGetters[rootTypes.CDN_RES_V2]
        ? TOOLTIP_MESSAGES[`v2${getters[types.PRIMARY].subtype}`]
        : TOOLTIP_MESSAGES[`v1${getters[types.PRIMARY].subtype}`];
    }

    return TOOLTIP_MESSAGES[getters[types.PRIMARY].subtype];
  },
  [types.SUPPORTING_TOOLTIP]: (state, getters) => TOOLTIP_MESSAGES[getters[types.SUPPORTING].subtype],
  [types.SUBTYPES]: (state) =>
    state.array.reduce(
      (structure, metric) => ({
        ...structure,
        [metric.subtype]: [...(structure[metric.subtype] || []), metric],
      }),
      {},
    ),
  [types.GET_METRIC_BY_IDENTIFIER]: (state) => state.byIdentifier,
  // not cached getters
  [types.GET_SUBTYPE]: (state) => (key) => state.array.find((m) => m.key === key).subtype,
  [types.GET_RANK]: (state) => (key) => METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[getMetric(state.array, key).structure],
  [types.GET_LICENCE]: (state) => (key) =>
    METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[getMetric(state.array, key).licence] || '',
  [types.GET_RANK]: (state) => (key) => METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[getMetric(state.array, key).structure],
  [types.GET_TOOLTIP]: (state) => (key) => TOOLTIP_MESSAGES[getMetric(state.array, key).subtype],
};

const actions = {};
const mutations = {
  ...baseMutations,
  [types.SET_PRIMARY](state, metric) {
    state.primaryMetric = metric;
  },
  [types.SET_SUPPORTING](state, metric) {
    state.supportingMetric = metric;
  },
};

export default {
  state,
  getters,
  actions,
  types,
  mutations,
};
