<script setup lang="ts">
import { ref } from 'vue';
import useIntersectionObserver from '@/composables/useIntersectionObserver';

const observable = ref<HTMLDivElement | null>(null);

const { visible } = useIntersectionObserver(observable);
</script>

<template>
  <div ref="observable"><slot :visible="visible" /></div>
</template>
