<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { MenuTitle } from '@/components/menu';
import useLocations from '@/composables/useLocations';
import { Dashboards } from '@/constants/dashboards';
import SidebarOperators from '@/components/SidebarOperators.vue';
import SidebarLocationSearch from '@/components/SidebarLocationSearch.vue';
import OnxCheckboxList from '@/components/onx/OnxCheckboxList.vue';
import FieldGroup from '@/components/menu/FieldGroup.vue';
import use360ConnectionCategories from '@/360/use360ConnectionCategories';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import useMapBboxFilterStore from '@/pinia-stores/useMapBboxFilterStore';

const connectionCategories = use360ConnectionCategories();
const mapBboxFilterStore = useMapBboxFilterStore();

const router = useRouter();
const route = useRoute();
const { currentCountry } = useLocations(Dashboards.ThreeSixty);

const sidebarOperatorsAccessor = () => useSelectableNetworkOperators(Dashboards.ThreeSixty);

const onLocationChange = async (location: any) => {
  await router.push({
    query: {
      ...route.query,
      geohashes: [],
      location: location.key,
      operators: undefined,
    },
  });

  mapBboxFilterStore.reset();
};

const onResetLocation = async () => {
  await router.push({
    query: {
      ...route.query,
      geohashes: [],
      location: currentCountry.value.key,
    },
  });

  mapBboxFilterStore.reset();
};
</script>

<template>
  <MenuTitle label="Parameters" theme="ci" />

  <SidebarLocationSearch
    :on-location-change="onLocationChange"
    :on-reset-location="onResetLocation"
    :enable-bbox-filtering="mapBboxFilterStore.updateResultsWhenMapMoves"
  >
    <span v-if="mapBboxFilterStore.updateResultsWhenMapMoves" class="visible-map-area-filtering">
      Visible Map Area Filtering
    </span>
  </SidebarLocationSearch>

  <SidebarOperators :operators-accessor="sidebarOperatorsAccessor" />

  <template v-if="connectionCategories.selectableChartConnectionCategories.length > 0">
    <FieldGroup label="Connection Categories">
      <OnxCheckboxList
        v-model="connectionCategories.selectableChartConnectionCategories"
        label-prop="categoryLabel"
        id-prop="categoryValue"
        @update:modelValue="connectionCategories.onCategoryChange"
      />
    </FieldGroup>
  </template>
</template>

<style lang="scss">
.visible-map-area-filtering {
  color: var(--white);
  font-size: 12px;
  height: 32px;
  line-height: 32px;
}
</style>
