<template>
  <div class="FlatTableItem" @click="$emit('click')">
    <span class="FlatTableItem__OperatorAvatar">
      <OperatorAvatar v-if="operatorName" :name="operatorName" :background-color="operatorColor" />
    </span>
    <span class="FlatTableItem__value">
      {{ value === null ? '-' : value }}
    </span>
  </div>
</template>

<script>
import OperatorAvatar from '../OperatorAvatar';
import { numberToTwoDecimals } from '@/utils/filters';

export default {
  name: 'FlatTableItem',
  components: {
    OperatorAvatar,
  },
  filters: {
    numberToTwoDecimals,
  },
  props: {
    operatorColor: { type: String, default: undefined },
    operatorName: { type: String, default: undefined },
    value: { type: [String, Number], default: undefined },
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;

.FlatTableItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-gray-border-table;
  &:last-child {
    border-bottom: none;
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
  }

  &__OperatorAvatar {
    padding: 0;
  }

  &__value {
    @include xy-cell(shrink);
    font-size: $font-size-18;
    font-weight: $font-weight-roboto-light;
    padding: 0;

    &--compare {
      border-left: 1px solid $color-gray-border-table;
      padding: 0 $table-padding;
    }
  }
}
</style>
