<script setup>
import { computed } from 'vue';

import LoaderGrid from '@/components/LoaderGrid.vue';

const props = defineProps({
  button: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  smaller: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

// If props.button is true, then set type to button. Otherwise undefined, to omit attr
const btnType = computed(() => {
  return props.button ? 'button' : undefined;
});

const as = computed(() => {
  return props.button ? 'button' : 'i';
});

const classObject = computed(() => {
  return {
    'onx-icon': true,
    'onx-icon--button': props.button,
    'onx-icon--small': props.small,
    'onx-icon--smaller': props.smaller,
    'onx-icon--active': props.active,
    'onx-icon--disabled': props.disabled,
  };
});
</script>

<template>
  <component :is="as" :type="btnType" :class="classObject">
    <slot v-if="!loading"></slot>
    <LoaderGrid v-else />
  </component>
</template>

<style scoped lang="scss">
.onx-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  color: inherit;

  &--small {
    width: 16px;
    height: 16px;
  }

  &--smaller {
    width: 12px;
    height: 12px;
  }

  &--button {
    border: none;
    border-radius: var(--onx-btn-border-radius);
    background: transparent;
    cursor: pointer;

    &:hover {
      background: var(--onx-btn-hover-bg);
    }
  }

  &--active {
    color: var(--onx-icon-active-color);
  }

  &--disabled {
    color: var(--charcoal-100) !important;

    &:hover {
      background: transparent !important;
    }
  }
}
</style>
