<script setup>
import { useRoute } from 'vue-router';

import { OnxTabsContext, OnxTabs, OnxTab } from '@/components/onx/tabs';
import ROUTES from '@/constants/routes';
import { FocusHeader, useFocusHeaderTabChange } from '@/focus/focus-header';

const route = useRoute();
const onTabChange = useFocusHeaderTabChange();
</script>

<template>
  <FocusHeader>
    <template #tabs>
      <OnxTabsContext :selected-tab="route.matched?.[2]?.name">
        <OnxTabs @change="onTabChange">
          <OnxTab :id="ROUTES.FocusCoreHealthByCDN"> By CDN </OnxTab>
          <OnxTab :id="ROUTES.FocusCoreHealthByOperatorCDN"> By Operator CDN </OnxTab>
        </OnxTabs>
      </OnxTabsContext>
    </template>
  </FocusHeader>
</template>
