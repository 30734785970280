<template>
  <div ref="chartTooltip" :class="posClass" class="FlatTableTooltip">
    <div v-show="display" class="FlatTableTooltip__wrapper">
      <div class="FlatTableTooltip__list">
        <FlatTableItem
          v-for="datum in tooltipDatapoints"
          :key="datum.label"
          :value="datum.value"
          :operator-name="Array.isArray(datum.color) ? '-' : datum.label"
          :operator-color="Array.isArray(datum.color) ? colors.colorBrandBlue : datum.color"
        />
      </div>
    </div>
  </div>
</template>
<script>
import uniqBy from 'lodash/uniqBy';
import FlatTableItem from '@/components/comparison/FlatTableItem';
import colors from '@/utils/colorPalette';

export default {
  name: 'FlatTableTooltip',
  components: {
    FlatTableItem,
  },
  props: {
    posClass: {
      type: String,
      default: '',
    },
    datapoints: {
      type: Array,
      default: () => [],
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorBrandBlue: colors.colorBrandBlue,
    };
  },
  computed: {
    tooltipDatapoints() {
      if (!this.datapoints || !this.datapoints.length) {
        return [];
      }
      return uniqBy(this.datapoints, (point) => {
        return point.label;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;

.FlatTableTooltip {
  position: relative;
  background: transparent;
  color: $color-text-black;
  transition: all 0.1s ease;
  pointer-events: none;
  font-size: $font-size-13;
  padding-left: 10px;
  margin: 0;

  &__list {
    padding-left: 1em;
    display: flex;
    list-style-type: none;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}
</style>
