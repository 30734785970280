<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import { OnxTabs, OnxTabsContext } from '@/components/onx/tabs';

const route = useRoute();

const headerTitleBlocks = computed(() => {
  const getHeaderTitle = route.matched[route.matched.length - 1]?.meta?.getHeaderTitle;
  if (typeof getHeaderTitle === 'function') {
    const title = getHeaderTitle(route);

    if (typeof title === 'string') {
      return [title];
    } else if (Array.isArray(title)) {
      return title;
    }
  }

  return '';
});

const emit = defineEmits(['tab-change']);

const onTabChange = (tab, value) => {
  emit('tab-change', tab, value);
};
</script>

<template>
  <OnxPaper class="onx-header">
    <OnxTabsContext :selected-tab="route.matched?.[1]?.name">
      <OnxTabs grow class="onx-header__main-tabs" @change="onTabChange">
        <slot name="main-tabs" />
      </OnxTabs>
    </OnxTabsContext>

    <div class="onx-header__content">
      <OnxHeadline as="h2" class="onx-header__title">
        <span class="onx-header__title__part" v-for="titleBlock in headerTitleBlocks" :key="titleBlock">
          {{ titleBlock }}
        </span>
      </OnxHeadline>
      <div v-if="$slots['header-controls']" class="onx-header__controls">
        <slot name="header-controls" />
      </div>
    </div>
    <div v-if="$slots['secondary-tabs']" class="onx-header__secondary-tabs">
      <slot name="secondary-tabs" />
    </div>
  </OnxPaper>
</template>

<style lang="scss">
.onx-header {
  &__content {
    padding-left: 16px;
    padding-right: 16px;
    min-height: 64px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__main-tabs {
    padding: 0;
  }

  &__secondary-tabs {
    margin-left: -16px;
    margin-right: -16px;
  }

  &__title {
    span:not(:first-child):last-child {
      font-weight: 400;
    }

    &__part {
      margin-right: 8px;
    }
  }
}
</style>
