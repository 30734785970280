import { CoreEndpointDatasetRow } from './commonColumns';
import { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import { Operator } from '@/types/Operator';

export const commonDatasetTransformer = (params: {
  operators?: Operator[];
  mainOperator?: Operator;
  connectionCategory?: string;
  selectedConnectionCategories?: AvailableConnectionCategory[];
}) => {
  return (rows: CoreEndpointDatasetRow[]) => {
    return rows.map((row) => {
      const operator = params.operators?.find((operator) => operator.canonical_network_id === row.canonical_network_id);

      if (operator) {
        return {
          ...row,
          operator_name: operator.name_mapped,
        };
      } else {
        return row;
      }
    });
  };
};
