import { computed } from 'vue';

import type { DataTransformerResponseCallback } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';

import useFilters from '@/composables/useFilters';
import { Dashboards } from '@/constants/dashboards';
import { MetricStructuresEnum, RankedSimpleMetric } from '@/types/MetricStructures';
import { getTrendSeries } from '@/utils/viewHelpers';
import { Operator } from '@/types/Operator';
import { getMetricCdns } from '@/utils/metrics';
import { CDNS } from '@/constants/constants';
import { videoResColorMap } from '@/constants/colorScales.js';
import { ChartTrendSeriesInputData } from '@/types/Charts';

const useCoreHealthByCDN = () => {
  const { metricSubtype } = useFilters(Dashboards.Focus);

  const cdns = computed((): string[] => {
    if (!metricSubtype.value) {
      return [];
    }

    return getMetricCdns(metricSubtype.value);
  });

  return {
    cdns,
    filterByCdn: (
      cdn: string,
      filteredOperators: Operator[],
    ): DataTransformerResponseCallback<
      ChartTrendSeriesInputData<RankedSimpleMetric>[],
      [MetricStructuresEnum.Breakdown]
    > => {
      return ([response]) => {
        const values = response.results
          .filter((d) => {
            return d.mean?.hasOwnProperty(cdn);
          })
          .map((d) => {
            return {
              ...d,
              lci: d.lci?.[cdn] || 0,
              mean: d.mean[cdn],
              uci: d.uci?.[cdn] || 0,
            };
          }) as RankedSimpleMetric[];

        return Object.values(getTrendSeries(filteredOperators, values, response.start_date, response.end_date));
      };
    },
    transformResolutionBreakdownMetrics: (
      cdn: string,
      operator: Operator,
    ): DataTransformerResponseCallback<any, [MetricStructuresEnum.ResolutionBreakdown]> => {
      return ([response]) => {
        const filteredMetrics = response.results
          .filter((datum) => {
            return datum.mean.hasOwnProperty(cdn) && datum.canonical_network_id === operator.canonical_network_id;
          })
          .map((datum) => {
            const lci = datum.lci ? { ...datum.lci[cdn] } : undefined;
            const uci = datum.uci ? { ...datum.uci[cdn] } : undefined;
            const mean = datum.mean ? { ...datum.mean[cdn] } : undefined;
            return {
              ...datum,
              date: datum.date.substring(0, 10),
              lci,
              uci,
              mean,
            };
          });

        const pivotedMetrics = ['low', '360', '480', '720', '1080', 'high'].map((resolution) => {
          const data = filteredMetrics.map((datum) => {
            return {
              ...datum,
              lci: datum.lci ? (datum.lci as any)[resolution] : undefined,
              uci: datum.uci ? (datum.uci as any)[resolution] : undefined,
              y: datum.mean ? (datum.mean as any)[resolution] : undefined,
              mean: datum.mean ? (datum.mean as any)[resolution] : undefined,
              x: datum.date,
            };
          });

          const color = (videoResColorMap as any)[resolution];

          return {
            data,
            type: 'bar',
            backgroundColor: color,
            color: color,
            label: resolution,
            yAxisID: 'y',
            categoryPercentage: 1,
            barPercentage: 1,
          };
        });

        return pivotedMetrics;
      };
    },
    getChartTitle: (metricLabel: string, cdn: string, connectionCategoryLabel: string): string => {
      const cdnTitle = CDNS.hasOwnProperty(cdn) ? (CDNS as any)[cdn] : cdn;

      return `${connectionCategoryLabel} ${metricLabel} for ${cdnTitle}`;
    },
  };
};

export default useCoreHealthByCDN;
