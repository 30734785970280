import colors from '@/utils/colorPalette';

export const DEFAULT_DASHBOARD = 'competitive';
export const LATEST_STRING = 'latest';

export const AGGREGATIONS = [
  {
    label: '7 days',
    value: '7days',
    days: 7,
    full: '7 days aggregation',
  },
  {
    label: '30 days',
    value: '30days',
    days: 30,
    full: '30 days aggregation',
  },
  {
    label: '90 days',
    value: '90days',
    days: 90,
    full: '90 days aggregation',
  },
];

export const FOCUS_AGGREGATIONS = [...AGGREGATIONS];

export const COMPARE_TO = {
  '7days': {
    label: '7 days ago',
    tileLabel: '7 days ago',
    value: '7days',
  },
  '30days': {
    label: '30 days ago',
    tileLabel: '30 days ago',
    value: '30days',
  },
  '90days': {
    label: '90 days ago',
    tileLabel: '90 days ago',
    value: '90days',
  },
  lpr: {
    label: 'last public report',
    tileLabel: 'last report',
    value: 'lpr',
  },
};

export const LICENCES_BY_TECHNOLOGY = {
  video: {
    FourG: {
      CI: '', // no equivalent
      PI: 'pi_video',
    },
    FiveG: {
      CI: 'ci_5g_video',
      PI: 'pi_5g_video',
    },
    mmWave: {
      CI: 'ci_5gmmwave_video',
      PI: 'pi_5gmmwave_video',
    },
  },
  games: {
    FourG: {
      CI: 'ci_games',
      PI: 'pi_games',
    },
    FiveG: {
      CI: 'ci_5g_games',
      PI: 'pi_5g_games',
    },
    mmWave: {
      CI: 'ci_5gmmwave_games',
      PI: 'pi_5gmmwave_games',
    },
  },
  voice: {
    FourG: {
      CI: 'ci_voice',
      PI: 'pi_voice',
    },
    FiveG: {
      CI: 'ci_5g_voice',
      PI: 'pi_5g_voice',
    },
    mmWave: {
      CI: 'ci_5gmmwave_voice',
      PI: 'pi_5gmmwave_voice',
    },
  },
  latency: {
    FourG: {
      CI: '',
      PI: 'pi_latency',
    },
    FiveG: {
      CI: 'ci_5g_latency',
      PI: 'pi_5g_latency',
    },
  },
  download: {
    FourG: {
      CI: '',
      PI: 'pi_speed',
    },
    FiveG: {
      CI: '',
      PI: 'pi_5g_speed',
    },
    mmWave: {
      CI: 'ci_5gmmwave_download',
      PI: 'pi_5gmmwave_speed',
    },
  },
  upload: {
    FourG: {
      CI: '',
      PI: 'pi_speed',
    },
    FiveG: {
      CI: '',
      PI: 'pi_5g_speed',
    },
    mmWave: {
      CI: 'ci_5gmmwave_upload',
      PI: 'pi_5gmmwave_speed',
    },
  },
  extent: {
    FourG: {
      CI: 'ci_4g_reach',
      PI: '',
    },
    FiveG: {
      CI: 'ci_5g_reach',
      PI: '',
    },
  },
};

// METRIC_STRUCTURE_TO_VALUE_FIELD_MAP is used to get the data value accessor (the values in the dict)
// from metric structure (the keys in the dict)
// for example data[METRIC_TYPE_MAP[metric.structure]] would be data['mean'] for a metric with structure 'ranked_simple'
export const METRIC_STRUCTURE_TO_VALUE_FIELD_MAP = {
  breakdown: 'mean',
  mean: 'mean',
  estimate_simple: 'estimate',
  ranked_simple: 'mean',
  simple_count: 'count',
  simple: 'mean',
  predictedmean: 'quant',
  simple_median: 'median',
  simple_threshold: 'threshold',
  count: 'count',
  percentage_simple: 'percentage',
  cdn_res: 'mean',
};

export const DEPLOYMENT_OPTIONS = ['Standalone', 'Non-standalone'];

export const FREQUENCY_RANGES = ['Low', 'Mid', 'High'];

export const STAR_RATING_CATEGORY_TITLES = ['Download Speed', 'Video Experience', 'Coverage'];
export const STAR_RATING_CATEGORY_KEYS = ['download_speed', 'video_experience', 'coverage'];

export const METRIC_TYPES = {
  Overall: 'overall',
  Mmwave: '5gmmwave',
  FiveGUser: '5guser',
  FiveG: '5g',
  FiveGLow: '5glow',
  FiveGMid: '5gmid',
  FiveGHigh: '5ghigh',
  FourG: 'lte',
  ThreeG: '3g',
  TwoG: '2g',
  NoSignal: 'nosignal',
  Emergency: 'emergency',
};

export enum DeploymentTypes {
  All = '',
  FiveGSA = '5g_sa',
  FiveGNSA = '5g_nsa',
}

export const METRIC_TYPE_NAMES = {
  '3glte5g': 'Overall',
  overall: 'Overall',
  '5gmmwave': 'mmWave',
  '5glow': 'Low Range',
  '5gmid': 'Mid Range',
  '5ghigh': 'High Range',
  '5guser': '5G User',
  '5g': '5G',
  lte: '4G',
  '3g': '3G',
  '2g': '2G',
  wifi: 'WiFi',
  nosignal: 'No Signal',
  emergency: 'Emergency',
};

export const FIVEG_LICENSES = [
  'ci_5g_availability',
  'ci_5g_games',
  'ci_5g_metrics',
  'ci_5g_reach',
  'ci_5g_video',
  'ci_5g_voice',
  'ci_5g_latency',
];

export const FIVEG_METRIC_SUBTYPES = ['5glow', '5gmid', '5ghigh'];

export const FIVEG_METRICS_TYPES = [METRIC_TYPES.FiveG, ...FIVEG_METRIC_SUBTYPES];

export const FIVEG_SUBTYPES_METRICS = ['download', 'upload'];

export const METRIC_TYPE_OPTIONS = [
  {
    label: 'Overall',
    value: METRIC_TYPES.Overall,
  },
  {
    label: 'mmWave',
    value: METRIC_TYPES.Mmwave,
  },
  {
    label: '5G',
    value: METRIC_TYPES.FiveG,
  },
  {
    label: '4G',
    value: METRIC_TYPES.FourG,
  },
  {
    label: '3G',
    value: METRIC_TYPES.ThreeG,
  },
  {
    label: '2G',
    value: METRIC_TYPES.TwoG,
  },
];

export const METRIC_CPS = {
  voice: ['ec2', 'gce'],
  rtt: ['ec2', 'gce'],
  gamesrtt: ['ec2', 'gce'],
  packet_loss: ['ec2', 'gce'],
  gamespacketloss: ['ec2', 'gce'],
  jitter: ['ec2', 'gce'],
  gamesjitter: ['ec2', 'gce'],
};

export const CPS_LIST = ['ec2', 'gce'];
export const CP_LABELS = {
  ec2: 'EC2',
  gce: 'Google Compute Engine',
};

export const GEO_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const CPS = {
  ...CP_LABELS,
};

export const METRIC_CDNS = {
  upload: ['akamai', 'cloudfront'],
  uploadthroughput: ['aws', 'google'],
  download: ['akamai', 'cloudfront', 'google storage'],
  downloadtimefirstbyte: ['ec2'],
  downloadthroughput: ['akamai', 'cloudfront'],
  latency: ['akamai', 'cloudfront', 'google storage', 'google.com'],
  ecqdownload: ['akamai', 'cloudfront', 'google'],
  ecqupload: ['aws', 'google'],
  ecqlatency: ['ec2'],
  ecqicmplatency: ['akamai', 'cloudfront', 'google'],
  ecqpacketloss: ['ec2'],
  ecqjitter: ['ec2'],
  ecqdownloadtimefirstbyte: ['akamai', 'cloudfront', 'google'],
  ccqdownload: ['akamai', 'cloudfront', 'google'],
  ccqupload: ['aws', 'google'],
  ccqlatency: ['ec2'],
  ccqicmplatency: ['akamai', 'cloudfront', 'google'],
  ccqpacketloss: ['ec2'],
  ccqjitter: ['ec2'],
  ccqdownloadtimefirstbyte: ['akamai', 'cloudfront', 'google'],
  gamesexperience: ['ec2', 'gce'],
  gamesrtt: ['ec2', 'gce'],
  gamesjitter: ['ec2', 'gce'],
  gamespacketloss: ['ec2', 'gce'],
  jitter: ['ec2'],
  icmplatency: ['akamai', 'cloudfront', 'google'],
  packetloss: ['ec2'],
  videoabrexperience: ['akamai', 'cloudfront', 'googlecloud', 'youtube'],
  videoexperience: ['akamai', 'cloudfront', 'googlecloud', 'youtube'],
  videoabrttff: ['akamai', 'cloudfront', 'googlecloud', 'youtube'],
  videoabrstalling: ['akamai', 'cloudfront', 'googlecloud', 'youtube'],
  videoabrstalltime: ['akamai', 'cloudfront', 'googlecloud', 'youtube'],
  videoabrrestime: ['akamai', 'cloudfront', 'googlecloud', 'youtube'],
  videoabrthroughput: ['akamai', 'cloudfront', 'googlecloud', 'youtube'],
  voiceexperience: ['ec2', 'gce'],
  videoliveexperience: ['cloudfront'],
  videoliveoffset: ['cloudfront'],
  videolivettff: ['cloudfront'],
  videolivestalltime: ['cloudfront'],
  videolivestalling: ['cloudfront'],
  videolivethroughput: ['cloudfront'],
  videoliverestime: ['cloudfront'],
};

export const CDNS_LIST = [
  'akamai',
  'aws',
  'ec2',
  'cloudfront',
  'gce',
  'google',
  'google storage',
  'google.com',
  'googlecloud',
  'youtube',
];

export const CDN_LABELS = {
  akamai: 'Akamai',
  aws: 'Amazon Web Services',
  cloudfront: 'CloudFront',
  ec2: 'Amazon EC2',
  gce: 'Google Compute Engine',
  google: 'Google',
  'google storage': 'Google',
  'google.com': 'google.com',
};

export const CDNS = {
  ...CDN_LABELS,
  googlecloud: 'Google Cloud',
  youtube: 'YouTube',
};

export const PILLS = [
  // the "key" corresponds to a metric's "kind"
  {
    key: 'video',
    metric: 'videoexperience_overall',
  },
  {
    key: 'videolive',
    metric: 'videoliveexperience_overall',
  },
  {
    key: 'games',
    metric: 'gamesexperience_overall',
  },
  {
    key: 'voice',
    metric: 'voiceexperience_overall',
  },
  {
    key: 'download',
    metric: 'download_overall',
  },
  {
    key: 'upload',
    metric: 'upload_overall',
  },
  {
    key: 'latency',
    metric: 'latency_overall',
  },
  {
    key: 'availability',
    metric: 'availability_lte',
  },
  {
    key: 'coverage',
    metric: 'onxcoveragesim_overall',
  },
  {
    key: 'extent',
    metric: 'extent_5g',
  },
  {
    key: 'ccq',
    metric: 'ccq_overall',
  },
  {
    key: 'ecq',
    metric: 'ecq_overall',
  },
  {
    key: 'consistentquality',
    metric: 'consistentquality_overall',
  },
  {
    key: 'reliability',
    metric: 'reliability_overall',
  },
];

export const WEEKLY_METRICS_CATEGORIES = ['infrastructure', 'devices'];
export const DAILY_LTE_ONLY_METRICS = [
  'hourlypoorsignalquality_lte',
  'poorsignalquality_lte',
  'hourlypoorsignallevel_lte',
  'poorsignallevel_lte',
];

export const METRIC_HERO_LIST = [
  'download',
  'upload',
  'latency',
  'videoexperience',
  'voiceexperience',
  'gamesexperience',
  'enodeb',
  'availability',
  'ccq',
  'ecq',
];

export const DEFAULT_DISTRIBUTION_PERCENTILE = 90;

export const CHART_MODIFIERS = {
  enodeb: 'Overview',
  cellbanddist: 'Distribution',
  enodebcellcountdist: 'Distribution',
};

export const PRIMARY_SIBLINGS = ['cdn', 'cdnops', 'binned', 'hourly', 'peak', 'consistency', 'ip', 'fr'];
export const LATENCY_SIBLINGS = ['cdn', 'cdnops', 'binned', 'hourly', 'peak'];
export const VIDEO_TTFF_SIBLINGS = ['trend', 'cdn', 'cdnops', 'binnedcdn', 'dp'];
export const VIDEO_STALLING_SIBLINGS = ['trend', 'cdn', 'cdnops', 'dp'];
export const VIDEO_STALLING_TIME_SIBLINGS = ['trend', 'cdn', 'cdnops', 'binnedcdn', 'dp'];
export const VIDEO_EXPERIENCE_SIBLINGS = ['cdn', 'cdnops', 'binnedcdn', 'dp'];
export const VIDEO_ABR_THROUGHPUT_SIBLINGS = ['trend', 'cdn', 'cdnops', 'binnedcdn', 'dp'];
export const INFRA_SIBLINGS = ['enodeb', 'cell', 'cellbanddist', 'enodebcellcountdist'];
export const LTE_3GPP_SIBLINGS = [
  'categorydownload',
  'categorypeakdownload',
  'categoryupload',
  'categorypeakupload',
  'categorylatency',
  'categorypeaklatency',
];
export const MAKE_MODEL_SIBLINGS = ['mmdownload', 'mmpeakdownload', 'mmupload', 'mmpeakupload'];
export const SERVICE_RELIABILITY_SIBLINGS = [
  'downloadstallingoccurrence',
  'hourlydownloadstallingoccurrence',
  'poorsignallevel',
  'hourlypoorsignallevel',
  'poorsignalquality',
  'hourlypoorsignalquality',
];
export const VOICE_SIBLINGS = ['trend', 'cp', 'cpops', 'binned'];

export const GAMES_SIBLINGS = ['gamesrtt', 'cp', 'cpops', 'binned', 'cpip'];
export const GAMES_LOSS_SIBLINGS = ['gamespacketloss', 'cp', 'cpops', 'binned', 'cpip'];
export const GAMES_JITTER_SIBLINGS = ['trend', 'cp', 'cpops', 'binned', 'cpip'];
export const CCQ_SIBLINGS = ['trend'];

export const METRIC_CHART_INFO = {
  hourlypoorsignalquality: {
    hero: 'availability',
    heroSufix: 'nosignal',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS,
  },
  poorsignalquality: {
    hero: 'availability',
    heroSufix: 'nosignal',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS,
  },
  hourlypoorsignallevel: {
    hero: 'availability',
    heroSufix: 'nosignal',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS,
  },
  poorsignallevel: {
    hero: 'availability',
    heroSufix: 'nosignal',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS,
  },
  hourlydownloadstallingoccurrence: {
    hero: 'download',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS,
  },
  downloadstallingoccurrence: {
    hero: 'download',
    menu: false,
    parent: 'downloadstallingoccurrence',
    siblings: SERVICE_RELIABILITY_SIBLINGS,
  },
  categorydownload: {
    hero: 'download',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS,
  },
  categorypeakdownload: {
    hero: 'download',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS,
  },
  categorypeakupload: {
    hero: 'upload',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS,
  },
  categoryupload: {
    hero: 'upload',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS,
  },
  categorypeaklatency: {
    hero: 'latency',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS,
  },
  categorylatency: {
    hero: 'latency',
    menu: false,
    parent: 'categorydownload',
    siblings: LTE_3GPP_SIBLINGS,
  },
  androidmodeldownload: {
    hero: 'download',
    menu: false,
    parent: 'androidmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS,
  },
  androidmodelpeakdownload: {
    hero: 'download',
    menu: false,
    parent: 'androidmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS,
  },
  androidmodelpeakupload: {
    hero: 'upload',
    menu: false,
    parent: 'androidmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS,
  },
  androidmodelupload: {
    hero: 'upload',
    menu: false,
    parent: 'androidmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS,
  },
  iosmodeldownload: {
    hero: 'download',
    menu: false,
    parent: 'iosmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS,
  },
  iosmodelpeakdownload: {
    hero: 'download',
    menu: false,
    parent: 'iosmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS,
  },
  iosmodelpeakupload: {
    hero: 'upload',
    menu: false,
    parent: 'iosmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS,
  },
  iosmodelupload: {
    hero: 'upload',
    menu: false,
    parent: 'iosmodeldownload',
    siblings: MAKE_MODEL_SIBLINGS,
  },
  enodeb: {
    hero: 'enodeb',
    menu: false,
    parent: 'infrastructure',
    siblings: INFRA_SIBLINGS,
  },
  cell: {
    hero: 'enodeb',
    menu: false,
    parent: 'infrastructure',
    siblings: INFRA_SIBLINGS,
  },
  enodebcellcountdist: {
    hero: 'enodeb',
    menu: false,
    parent: 'infrastructure',
    siblings: INFRA_SIBLINGS,
  },
  cellbanddist: {
    hero: 'enodeb',
    menu: false,
    parent: 'infrastructure',
    siblings: INFRA_SIBLINGS,
  },
  cdnupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS,
  },
  cdndownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS,
  },
  ipupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS,
  },
  ipdownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS,
  },
  frupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS,
  },
  frdownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS,
  },
  cdnlatency: {
    hero: 'latency',
    menu: false,
    parent: 'latency',
    siblings: LATENCY_SIBLINGS,
  },

  hourlyupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS,
  },
  hourlydownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS,
  },
  hourlylatency: {
    hero: 'latency',
    menu: false,
    parent: 'latency',
    siblings: LATENCY_SIBLINGS,
  },
  binnedupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS,
  },
  binneddownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS,
  },
  binnedlatency: {
    hero: 'latency',
    menu: false,
    parent: 'latency',
    siblings: LATENCY_SIBLINGS,
  },
  peakupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS,
  },
  peakdownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS,
  },
  peaklatency: {
    hero: 'latency',
    menu: false,
    parent: 'latency',
    siblings: LATENCY_SIBLINGS,
  },
  consistencyupload: {
    hero: 'upload',
    menu: false,
    parent: 'upload',
    siblings: PRIMARY_SIBLINGS,
  },
  consistencydownload: {
    hero: 'download',
    menu: false,
    parent: 'download',
    siblings: PRIMARY_SIBLINGS,
  },
  cdnvideoabrexperience: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS,
  },
  dpvideoabrexperience: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS,
  },
  binnedcdnvideoabrexperience: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS,
  },
  videoabrttff: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrttff',
    siblings: VIDEO_TTFF_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  cdnvideoabrttff: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrttff',
    siblings: VIDEO_TTFF_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  binnedcdnvideoabrttff: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrttff',
    siblings: VIDEO_TTFF_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  dpvideoabrttff: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrttff',
    siblings: VIDEO_TTFF_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  videoabrstalling: {
    hero: 'videoexperience',
    menu: true,
    parent: 'videoabrstalling',
    siblings: VIDEO_STALLING_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  cdnvideoabrstalling: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrstalling',
    siblings: VIDEO_STALLING_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  dpvideoabrstalling: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrstalling',
    siblings: VIDEO_STALLING_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  cdnipvideoabrstalling: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrstalling',
    siblings: VIDEO_STALLING_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  videoabrstalltime: {
    hero: 'videoexperience',
    menu: true,
    parent: 'videoabrstalltime',
    siblings: VIDEO_STALLING_TIME_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  cdnvideoabrstalltime: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrstalltime',
    siblings: VIDEO_STALLING_TIME_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  dpvideoabrstalltime: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrstalltime',
    siblings: VIDEO_STALLING_TIME_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  binnedcdnvideoabrstalltime: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrstalltime',
    siblings: VIDEO_STALLING_TIME_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  binnedvideothroughput: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS,
  },
  binnedvideodataconsumed: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoexperience',
    siblings: VIDEO_EXPERIENCE_SIBLINGS,
  },
  videoabrthroughput: {
    hero: 'videoexperience',
    menu: true,
    parent: 'videoabrthroughput',
    siblings: VIDEO_ABR_THROUGHPUT_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  cdnvideoabrthroughput: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrthroughput',
    siblings: VIDEO_ABR_THROUGHPUT_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  dpvideoabrthroughput: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrthroughput',
    siblings: VIDEO_ABR_THROUGHPUT_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },
  binnedcdnvideoabrthroughput: {
    hero: 'videoexperience',
    menu: false,
    parent: 'videoabrthroughput',
    siblings: VIDEO_ABR_THROUGHPUT_SIBLINGS,
    isHeroLabelsFromChartMetric: true,
  },

  voiceexperience: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS,
  },
  cpvoiceexperience: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS,
  },
  cpopsvoiceexperience: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS,
  },
  binnedvoiceexperience: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS,
  },
  voicertt: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS,
  },
  voicepacketloss: {
    hero: 'voiceexperience',
    menu: false,
    parent: 'voiceexperience',
    siblings: VOICE_SIBLINGS,
  },

  cpgamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS,
  },
  gamesexperience: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesexperience',
    siblings: GAMES_SIBLINGS,
  },
  gamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS,
  },
  cpipgamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS,
  },
  cpopsgamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS,
  },
  binnedgamesrtt: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesrtt',
    siblings: GAMES_SIBLINGS,
  },

  gamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS,
  },
  cpgamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS,
  },
  cpipgamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS,
  },
  cpopsgamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS,
  },
  binnedgamespacketloss: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamespacketloss',
    siblings: GAMES_LOSS_SIBLINGS,
  },
  gamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS,
  },
  cpgamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS,
  },
  cpipgamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS,
  },
  cpopsgamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS,
  },
  binnedgamesjitter: {
    hero: 'gamesexperience',
    menu: false,
    parent: 'gamesjitter',
    siblings: GAMES_JITTER_SIBLINGS,
  },
  ccqdownload: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS,
  },
  ccqupload: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS,
  },
  ccqlatency: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS,
  },
  ccqicmplatency: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS,
  },
  ccqjitter: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS,
  },
  ccqpacketloss: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS,
  },
  ccqdownloadtimefirstbyte: {
    hero: 'ccq',
    menu: false,
    parent: 'ccq',
    siblings: CCQ_SIBLINGS,
  },
  ecqdownload: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS,
  },
  ecqupload: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS,
  },
  ecqlatency: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS,
  },
  ecqicmplatency: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS,
  },
  ecqjitter: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS,
  },
  ecqpacketloss: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS,
  },
  ecqdownloadtimefirstbyte: {
    hero: 'ecq',
    menu: false,
    parent: 'ecq',
    siblings: CCQ_SIBLINGS,
  },
};

export const METRIC_GROUP_LABEL = {
  throughput: 'Throughput Rate',
  consumed: 'Data Consumption',
  consistency: 'Speed Consistency',
  peakspeed: 'Peak Speed',
  minimumlatency: 'Minimum Latency',
  trend: 'Trend',
  cp: 'By Cloud Platform',
  cpip: 'By Cloud Provider IP Address',
  cdn: 'By CDN',
  ip: 'By CDN IP Address',
  binned: 'Distribution',
  binnedcdn: 'Distribution',
  binnedcdnres: 'Distribution',
  dp: 'Hour of Day',
  hourly: 'Hour of Day',
  cdnres: 'Provider and Resolution',
  cdnresip: 'By CDN IP Address',
  enodeb: 'eNB Count',
  cell: '4G Cells Count',
  enodebcellcountdist: '4G Cells per eNB',
  cellbanddist: '4G Cells per Frequency Band',
  categorydownload: 'Download Speed',
  categorypeakdownload: 'Download Peak Speed',
  categoryupload: 'Upload Speed',
  categorypeakupload: 'Upload Peak Speed',
  categorylatency: 'Latency',
  categorypeaklatency: 'Minimum Latency',
  mmdownload: 'Download Speed',
  mmpeakdownload: 'Download Peak Speed',
  mmupload: 'Upload Speed',
  mmpeakupload: 'Upload Peak Speed',
  downloadstallingoccurrence: 'Stalling Occurrence',
  hourlydownloadstallingoccurrence: 'Stalling Occurrence - Hourly',
  poorsignalquality: 'Poor Signal Quality',
  hourlypoorsignalquality: 'Poor Signal Quality - Hourly',
  poorsignallevel: 'Poor Signal Strength',
  hourlypoorsignallevel: 'Poor Signal Strength - Hourly',
  voicertt: 'Voice App Packet Delay',
  voicepacketloss: 'Voice App Packet Loss',
  frdownload: 'By Frequency Range',
  frupload: 'By Frequency Range',
};

export const METRIC_GROUP_ALTERNATE_LABEL = {
  cpops: 'By Operator - Cloud Platform',
  cdnops: 'By Operator - CDN',
  cdnresops: 'By Operator - Provider & Resolution',
};

export const METRIC_GROUP_ABR_LABEL = {
  cdn: 'By Provider',
};

export const METRIC_GROUP_ABR_ALTERNATE_LABEL = {
  cdnops: 'By Operator - Provider',
};

export const ALTERNATE_CHART_VIEWS = ['cdnresops', 'cpops', 'cdnops'];

export const PI_METRICS_MENU = [
  {
    metricCategory: 'speed',
    main: 'download',
    category: 'experience',
    label: 'Speed',
    expand: true,
    expanded: false,
    sub: [
      {
        label: 'Download Speed',
        metric: 'download',
      },
      {
        label: 'Upload Speed',
        metric: 'upload',
      },
    ],
  },
  {
    metricCategory: 'responsiveness',
    main: 'latency',
    category: 'experience',
    label: 'Latency',
    expanded: false,
    sub: [
      {
        label: 'Latency Experience',
        metric: 'latency',
      },
    ],
  },
  {
    metricCategory: 'video',
    main: 'videoexperience',
    category: 'experience',
    label: 'Video',
    expand: true,
    expanded: false,
    sub: [
      {
        label: 'Video Experience',
        metric: 'videoexperience',
      },
      {
        label: 'Time to First Frame',
        metric: 'videoabrttff',
      },
      {
        label: 'Stalling Occurrence',
        metric: 'videoabrstalling',
      },
      {
        label: 'Stalling Time',
        metric: 'videoabrstalltime',
      },
      // {
      //   label: 'Stalling Time',
      //   metric: '###'
      // },
      {
        label: 'Video Throughput Rate',
        metric: 'videoabrthroughput',
      },
      // {
      //   label: 'Percent Time on Resolution',
      //   metric: '###'
      // }
    ],
  },
  {
    metricCategory: 'voice',
    main: 'voiceexperience',
    category: 'experience',
    label: 'Voice',
    sub: [
      {
        label: 'Voice App Experience',
        metric: 'voiceexperience',
      },
    ],
  },
  {
    metricCategory: 'games',
    main: 'gamesrtt',
    category: 'experience',
    label: 'Games Experience',
    expand: true,
    expanded: false,
    sub: [
      {
        label: 'Games Experience Packet Delay',
        metric: 'gamesrtt',
      },
      {
        label: 'Games Experience Packet Loss',
        metric: 'gamespacketloss',
      },
      {
        label: 'Games Experience Jitter',
        metric: 'gamesjitter',
      },
    ],
  },
  {
    metricCategory: 'ccq',
    main: 'ccqdownload',
    category: 'experience',
    label: 'Core Consistent Quality',
    expand: true,
    expanded: false,
    overall: true,
    sub: [
      {
        label: 'Download throughput',
        metric: 'ccqdownload',
      },
      {
        label: 'Upload throughput',
        metric: 'ccqupload',
      },
      {
        label: 'UDP latency',
        metric: 'ccqlatency',
      },
      {
        label: 'ICMP latency',
        metric: 'ccqicmplatency',
      },
      {
        label: 'UDP jitter',
        metric: 'ccqjitter',
      },
      {
        label: 'UDP packet loss discard percentage',
        metric: 'ccqpacketloss',
      },
      {
        label: 'Download throughput time to first byte',
        metric: 'ccqdownloadtimefirstbyte',
      },
    ],
  },
  {
    metricCategory: 'ecq',
    main: 'ecq',
    category: 'experience',
    label: 'Excellent Consistent Quality',
    expand: true,
    expanded: false,
    overall: true,
    sub: [
      {
        label: 'Download throughput',
        metric: 'ecqdownload',
      },
      {
        label: 'Upload throughput',
        metric: 'ecqupload',
      },
      {
        label: 'UDP latency',
        metric: 'ecqlatency',
      },
      {
        label: 'ICMP latency',
        metric: 'ecqicmplatency',
      },
      {
        label: 'UDP jitter',
        metric: 'ecqjitter',
      },
      {
        label: 'UDP packet loss discard percentage',
        metric: 'ecqpacketloss',
      },
      {
        label: 'Download throughput time to first byte',
        metric: 'ecqdownloadtimefirstbyte',
      },
    ],
  },
  {
    metricCategory: 'service_reliability',
    main: 'downloadstallingoccurrence',
    category: 'experience',
    label: 'Other',
    expand: true,
    expanded: false,
    sub: [
      {
        label: 'Service Reliability',
        metric: 'downloadstallingoccurrence',
      },
    ],
  },
  {
    main: 'enodeb',
    category: 'infrastructure',
    disabled: true,
  },
  {
    main: 'categorydownload',
    category: 'devices',
    label: 'By 3GPP LTE Category',
    expanded: false,
  },
  {
    main: 'androidmodeldownload',
    category: 'devices',
    label: 'By Make & Model',
    expand: true,
    expanded: true,
    sub: [
      {
        label: 'Android',
        metric: 'androidmodeldownload',
      },
      {
        label: 'iOS',
        metric: 'iosmodeldownload',
      },
    ],
  },
];

export const PI_OVERVIEW_METRICS_MENU = [
  {
    group: 'download',
    metricCategory: 'speed',
    main: 'download',
    category: 'experience',
    kind: 'download',
    label: 'Download Speed Experience',
    metric: 'download',
  },
  {
    group: 'download',
    metricCategory: 'speed',
    main: 'peakdownload',
    category: 'experience',
    kind: 'download',
    label: 'Peak Speed',
    submetric: true,
    metric: 'peakdownload',
  },
  {
    group: 'download',
    metricCategory: 'speed',
    main: 'consistencydownload',
    category: 'experience',
    kind: 'download',
    label: 'Consistency',
    submetric: true,
    metric: 'consistencydownload',
  },
  {
    group: 'upload',
    metricCategory: 'speed',
    main: 'upload',
    category: 'experience',
    kind: 'upload',
    label: 'Upload Speed Experience',
    expanded: false,
    metric: 'upload',
  },
  {
    group: 'upload',
    metricCategory: 'speed',
    main: 'peakupload',
    category: 'experience',
    kind: 'upload',
    label: 'Peak Speed',
    submetric: true,
    metric: 'peakupload',
  },
  {
    group: 'upload',
    metricCategory: 'speed',
    main: 'consistencyupload',
    category: 'experience',
    kind: 'upload',
    label: 'Consistency',
    submetric: true,
    metric: 'consistencyupload',
  },
  {
    metricCategory: 'responsiveness',
    main: 'latency',
    category: 'experience',
    kind: 'latency',
    label: 'Latency Experience',
    expanded: false,
    metric: 'latency',
  },
  {
    metricCategory: 'responsiveness',
    main: 'peaklatency',
    category: 'experience',
    kind: 'latency',
    label: 'Minimum Latency',
    submetric: true,
    metric: 'peaklatency',
  },
  {
    metricCategory: 'video',
    main: 'videoexperience',
    category: 'experience',
    kind: 'video',
    label: 'Video Experience',
    expanded: false,
    metric: 'videoexperience',
  },
  {
    metricCategory: 'video',
    main: 'videoabrstalling',
    category: 'experience',
    kind: 'video',
    label: 'Video Stalling Occurrence',
    submetric: true,
    metric: 'videoabrstalling',
  },
  {
    metricCategory: 'video',
    main: 'videoabrstalltime',
    category: 'experience',
    kind: 'video',
    label: 'Video Stalling Time',
    submetric: true,
    metric: 'videoabrstalltime',
  },
  {
    metricCategory: 'video',
    main: 'videoabrthroughput',
    category: 'experience',
    kind: 'video',
    label: 'Video Throughput Rate',
    submetric: true,
    metric: 'videoabrthroughput',
  },
  {
    metricCategory: 'voice',
    main: 'voiceexperience',
    category: 'experience',
    kind: 'voice',
    label: 'Voice App Experience',
    expanded: false,
    metric: 'voiceexperience',
  },
  {
    metricCategory: 'games',
    main: 'gamesexperience',
    category: 'experience',
    kind: 'rtt',
    label: 'Games Experience',
    expanded: false,
    metric: 'gamesexperience',
  },
  {
    metricCategory: 'games',
    main: 'gamesrtt',
    category: 'experience',
    kind: 'games',
    label: 'Packet Delay',
    submetric: true,
    metric: 'gamesrtt',
  },
  {
    metricCategory: 'games',
    main: 'gamespacketloss',
    category: 'experience',
    kind: 'packet_loss',
    label: 'Packet Loss',
    submetric: true,
    metric: 'gamespacketloss',
  },
  {
    metricCategory: 'games',
    main: 'gamesjitter',
    category: 'experience',
    kind: 'jitter',
    label: 'Jitter',
    submetric: true,
    metric: 'gamesjitter',
  },
  {
    metricCategory: 'ccq',
    main: 'ccq',
    category: 'experience',
    kind: 'ccq',
    label: 'Core Consistent Quality',
    submetric: false,
    metric: 'ccq',
    overall: true,
  },
  {
    metricCategory: 'ccq',
    main: 'ccqdownload',
    category: 'experience',
    kind: 'ccq',
    label: 'Download throughput',
    submetric: true,
    metric: 'ccqdownload',
    overall: true,
  },
  {
    metricCategory: 'ccq',
    main: 'ccqupload',
    category: 'experience',
    kind: 'ccq',
    label: 'Upload throughput',
    submetric: true,
    metric: 'ccqupload',
    overall: true,
  },
  {
    metricCategory: 'ccq',
    main: 'ccqlatency',
    category: 'experience',
    kind: 'ccq',
    label: 'UDP latency',
    submetric: true,
    metric: 'ccqlatency',
    overall: true,
  },
  {
    metricCategory: 'ccq',
    main: 'ccqicmplatency',
    category: 'experience',
    kind: 'ccq',
    label: 'ICMP latency',
    submetric: true,
    metric: 'ccqicmplatency',
    overall: true,
  },
  {
    metricCategory: 'ccq',
    main: 'ccqjitter',
    category: 'experience',
    kind: 'ccq',
    label: 'UDP jitter',
    submetric: true,
    metric: 'ccqjitter',
    overall: true,
  },
  {
    metricCategory: 'ccq',
    main: 'ccqpacketloss',
    category: 'experience',
    kind: 'ccq',
    label: 'UDP packet loss discard percentage',
    submetric: true,
    metric: 'ccqpacketloss',
    overall: true,
  },
  {
    metricCategory: 'ccq',
    main: 'ccqdownloadtimefirstbyte',
    category: 'experience',
    kind: 'ccq',
    label: 'Download throughput time to first byte',
    submetric: true,
    metric: 'ccqdownloadtimefirstbyte',
    overall: true,
  },
  {
    metricCategory: 'ecq',
    main: 'ecq',
    category: 'experience',
    kind: 'ecq',
    label: 'Excellent Consistent Quality',
    submetric: false,
    metric: 'ecq',
    overall: true,
  },
  {
    metricCategory: 'ecq',
    main: 'ecqdownload',
    category: 'experience',
    kind: 'ecq',
    label: 'Download throughput',
    submetric: true,
    metric: 'ecqdownload',
    overall: true,
  },
  {
    metricCategory: 'ecq',
    main: 'ecqupload',
    category: 'experience',
    kind: 'ecq',
    label: 'Upload throughput',
    submetric: true,
    metric: 'ecqupload',
    overall: true,
  },
  {
    metricCategory: 'ecq',
    main: 'ecqlatency',
    category: 'experience',
    kind: 'ecq',
    label: 'UDP latency',
    submetric: true,
    metric: 'ecqlatency',
    overall: true,
  },
  {
    metricCategory: 'ecq',
    main: 'ecqicmplatency',
    category: 'experience',
    kind: 'ecq',
    label: 'ICMP latency',
    submetric: true,
    metric: 'ecqicmplatency',
    overall: true,
  },
  {
    metricCategory: 'ecq',
    main: 'ecqjitter',
    category: 'experience',
    kind: 'ecq',
    label: 'UDP jitter',
    submetric: true,
    metric: 'ecqjitter',
    overall: true,
  },
  {
    metricCategory: 'ecq',
    main: 'ecqpacketloss',
    category: 'experience',
    kind: 'ecq',
    label: 'UDP packet loss discard percentage',
    submetric: true,
    metric: 'ecqpacketloss',
    overall: true,
  },
  {
    metricCategory: 'ecq',
    main: 'ecqdownloadtimefirstbyte',
    category: 'experience',
    kind: 'ecq',
    label: 'Download throughput time to first byte',
    submetric: true,
    metric: 'ecqdownloadtimefirstbyte',
    overall: true,
  },
];

export const SPOTLIGHT_TO_FOCUS_ALLOWED = [
  'videoexperience',
  'voiceexperience',
  'gamesexperience',
  'download',
  'upload',
  'ecq',
  'ccq',
];

export const MG_STRATEGIES = {
  attack: {
    label: 'Attack',
  },
  harvest: {
    label: 'Harvest',
  },
  defend: {
    label: 'Defend',
  },
  opportunistic: {
    label: 'Opportunistic',
  },
};

export const MG_METRICS = {
  good_availability_delta: {
    label: 'Good Availability Δ',
    value: 'good_availability_delta',
    scale: [
      {
        label: '≥ 0',
        color: '#378e3d',
      },
      {
        label: '< 0',
        color: colors.noDataColor,
      },
    ],
  },
  device_share_delta: {
    label: 'Market Share Δ',
    value: 'device_share_delta',
    scale: [
      {
        label: '> 5%',
        color: '#378e3d',
        range: {
          min: 5,
        },
      },
      {
        label: '0 to 5%',
        color: '#83e587',
        range: {
          min: 0,
          max: 5,
        },
      },
      {
        label: '-1% to -5%',
        color: '#fda5a0',
        range: {
          min: -5,
          max: 0,
        },
      },
      {
        label: '< -5%',
        color: '#fe433a',
        range: {
          max: -5,
        },
      },
    ],
  },
  strategy_delta: {
    label: 'Strategy',
    value: 'strategy_delta',
    scale: [
      {
        label: 'Attack',
        color: colors.colorMgAttack,
      },
      {
        label: 'Harvest',
        color: colors.colorMgHarvest,
      },
      {
        label: 'Defend',
        color: colors.colorMgDefend,
      },
      {
        label: 'Opportunistic',
        color: colors.colorMgOpportunistic,
      },
    ],
  },
};

export const USER_GROUPS = [
  {
    label: 'All Users',
    value: 'main',
    supportedTypes: Object.values(METRIC_TYPES).filter((type) => type !== METRIC_TYPES.Mmwave),
  },
  {
    label: '5G Users',
    value: '5guser',
    supportedTypes: Object.values(METRIC_TYPES).filter((type) => type !== METRIC_TYPES.Mmwave),
  },
  {
    label: '4G Users',
    value: '4guser',
    supportedTypes: [METRIC_TYPES.Overall, METRIC_TYPES.FourG, METRIC_TYPES.ThreeG, METRIC_TYPES.TwoG],
  },
  {
    label: '3G Users',
    value: '3guser',
    supportedTypes: [METRIC_TYPES.Overall, METRIC_TYPES.ThreeG, METRIC_TYPES.TwoG],
  },
];

export const DEFAULT_USER_GROUP = 'main';

export const UNSUPPORTED_TYPES_FOR_GROUPS = ['latency', 'extent', 'coverage'];

export const TIMEFRAME_DAYS_OPTIONS = [
  {
    title: '30 days',
    key: '30',
  },
  {
    title: '90 days',
    key: '90',
  },
  {
    title: '180 days',
    key: '180',
  },
  {
    title: '1 year',
    key: '365',
  },
];

// for PRI we need to be able to display operators from both PRI and USA,
// this config can be used in these type of cases
export const OPERATORS_COUNTRY_OVERRIDES = {
  PRI: ['PRI', 'USA'],
};

export const PI_OVERVIEW_PREV_BROADBAND_TECH = 'pi_overview_prev_broadband_tech';

export const OS_GEOCODINGS = {
  countries: 1,
  regions: 2,
  cities: 3,
};

export const TAB_NAMES_BY_GRANULARITY = {
  countries: 'National',
  regions: 'By region',
  cities: 'By metro area',
};

export const COPYRIGHT = `© ${new Date().getFullYear()} Opensignal Limited. All Rights Reserved.`;

export const HARD_FIRST_DATE_AVAILABLE = '2018-05-31T00:00:00.000Z';
