<template>
  <div
    class="DynamicColourScale"
    :class="{ 'DynamicColourScale--collapsed': !matches.laptop.value && collapsed }"
    @click.stop="toggle"
  >
    <div v-if="hasSemanticMeaning" class="DynamicColourScale__label">HIGHER</div>
    <div v-if="importance" class="DynamicColourScale__label">MORE IMPORTANT</div>
    <div v-for="(range, index) in ranges" :key="index" class="DynamicColourScale__row">
      <div :style="{ backgroundColor: range.color }" class="DynamicColourScale__color" />
      <div v-if="range.label" class="DynamicColourScale__value">
        <span>{{ range.label }}</span>
      </div>
      <div v-else class="DynamicColourScale__value">
        <span v-if="range.max === undefined && range.min !== 0">&gt; </span>
        <span v-if="range.min === undefined">&lt; </span>
        <span v-if="range.min !== undefined">{{ round(range.min) }}</span>
        <span v-if="range.min !== undefined && range.max !== undefined && range.max >= 0"> - </span>
        <span v-if="range.min !== undefined && range.max !== undefined && range.max < 0"> to </span>
        <span v-if="range.max !== undefined">{{ round(range.max) }}</span>
      </div>
    </div>
    <div v-if="hasSemanticMeaning" class="DynamicColourScale__label">LOWER</div>
    <div v-if="importance" class="DynamicColourScale__label">LESS IMPORTANT</div>
    <div v-if="unit" class="DynamicColourScale__unit">in {{ unit }}</div>
  </div>
</template>

<script>
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';

export default {
  name: 'DynamicColourScale',
  props: {
    ranges: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: '',
    },
    importance: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const matches = useBreakpoints();

    return { matches };
  },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    hasSemanticMeaning() {
      return this.ranges.find((p) => p.negative);
    },
  },
  methods: {
    round(value) {
      return Math.round(value * 100) / 100;
    },
    toggle() {
      if (!this.matches.laptop.value) {
        this.collapsed = !this.collapsed;
      }
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;
@import '@material/elevation';
@import 'scss/onx-breakpoints.module';

.DynamicColourScale {
  @include elevation($elevation-2);
  background-color: var(--white);
  padding: pxToRem(12) pxToRem(8);
  font-size: pxToRem(12);
  line-height: pxToRem(16);
  border-radius: pxToRem(2);
  cursor: pointer;

  @include laptop {
    cursor: default;
  }

  &__label {
    @include xy-grid();
    color: var(--charcoal-500);
  }

  &__row {
    @include xy-grid();
    padding: 4px 0;
  }

  &__color {
    $square-size: pxToRem(16);
    @include xy-cell(shrink, $gutters: 0);
    width: $square-size;
    height: $square-size;
    border-radius: 2px;
  }

  &__value {
    @include xy-cell(auto, $gutter-position: left);
    text-align: right;
    flex: 1 0 auto;
  }

  &__unit {
    border-top: 1px solid $color-text-default;
    text-align: right;
    margin-top: 0.2em;
    padding-top: 0.2em;
    line-height: 1em;
  }
}

.DynamicColourScale--collapsed {
  padding: 8px;

  .DynamicColourScale__row {
    display: none;
    padding: 0;

    &:first-child {
      display: block;

      .DynamicColourScale__color ~ div {
        display: none;
      }
    }
  }

  .DynamicColourScale__unit {
    display: none;
  }
}
</style>
