import { getDatapointsByIndex, getTooltipState } from '@/utils/charts';

const coverageSubcategoriesGeohashCountTooltipPlugin =
  (horizontal?: boolean) => (context: any, tooltip: any, chartData: any) => {
    const dataAxis = horizontal ? 'x' : 'y';

    const tt = context.tooltip;
    const tooltipState = getTooltipState(context.chart, tt);

    const datapoints = getDatapointsByIndex(
      chartData.value.datasets,
      chartData.value.labels.indexOf(tt.title[0]),
      horizontal,
    ).filter((d: any) => {
      return d.data[dataAxis] > 0;
    });

    const total = datapoints.reduce((acc: any, curr: any) => {
      return acc + curr.data[dataAxis];
    }, 0);

    const datapointsWithPercentages = datapoints.map((d: any) => {
      return {
        ...d,
        secondaryValue: `${((d.data[dataAxis] / total) * 100).toFixed(2)}%`,
      };
    });

    if (datapoints.length) {
      tooltip.value = {
        ...tooltip.value,
        ...tooltipState,
        datapoints: datapointsWithPercentages,
        title: tt.title[0],
      };
    } else {
      tooltip.value.display = false;
    }
  };

export default coverageSubcategoriesGeohashCountTooltipPlugin;
