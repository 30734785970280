import { MaybeRef, computed, unref } from 'vue';
import { Location } from '@/types/Location';
import { Operator } from '@/types/Operator';
import { CDNS } from '@/constants/constants';
import { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';

export type TitlePlaceholders = {
  /** EG {name_mapped: "Telus", ...} */
  operator?: MaybeRef<Operator | undefined>;

  /** EG "mmWave" or "5G" */
  connectionCategory?: MaybeRef<string>;

  period?: MaybeRef<string>;

  /** EG Akamai or Google Compute Engine */
  cdn?: MaybeRef<string>;

  /** Mbps or Proportion of Device. Leave the parenthesis in the title `(:unit)` */
  unit?: MaybeRef<string>;

  /** { name: "London", ...} */
  location?: MaybeRef<Location | string>;

  /** EG [{name_mapped: "Telus", ...}, {name_mapped: "Bell", ...}] */
  operators?: MaybeRef<Operator[]>;

  /** EG ['5G', 'mmWave'] */
  connectionCategories?: MaybeRef<string[]>;

  /** EG ['Akamai', 'Google Compute Engine'] */
  cdns?: MaybeRef<string[]>;

  /** EG ['Mbps', 'Proportion of Device'] */
  units?: MaybeRef<string[]>;

  deploymentType?: MaybeRef<AvailableDeploymentType>;
};

export const replacePlaceholders = (titleWithPlaceholders: string, options: any) => {
  let title = titleWithPlaceholders;
  if (!title) {
    return '';
  }

  // Handle replacements of arrays
  // do this first because we want to replace :cdns before we look at :cdn
  // :connectionCategories and :units
  const simpleStringArrayReplacementKeys: Array<keyof TitlePlaceholders> = ['connectionCategories', 'units'];
  for (const key of simpleStringArrayReplacementKeys) {
    if (Array.isArray(options[key]) && (options[key] as string[]).length > 0) {
      title = title.replaceAll(`:${key}`, (options[key] as string[]).join(', '));
    }
  }

  // :cdns
  if (Array.isArray(options.cdns) && options.cdns.length > 0) {
    const cdnLabels = [
      ...new Set<string>( // unique only
        options.cdns
          .filter(Boolean) // remove undefined and null and empty string
          .map((cdn: string) => {
            if (CDNS.hasOwnProperty(cdn)) {
              return CDNS[cdn as keyof typeof CDNS];
            } else {
              return cdn;
            }
          }),
      ),
    ].join(', ');
    title = title.replaceAll(':cdns', cdnLabels);
  }

  // :operators
  if (Array.isArray(options.operators) && options.operators.length > 0) {
    title = title.replaceAll(
      ':operators',
      options.operators
        .filter(Boolean) // remove undefined and null
        .map((operator: Operator) => operator.name_mapped)
        .join(', '),
    );
  }

  // Handle simple replacements
  if (options.connectionCategory && options.connectionCategory.length > 0) {
    title = title.replaceAll(':connectionCategory', options.connectionCategory);
  }

  if (options.unit && options.unit.length > 0) {
    title = title.replaceAll(':unit', options.unit);
  }

  if (options.cdn) {
    if (CDNS.hasOwnProperty(options.cdn)) {
      title = title.replaceAll(':cdn', CDNS[options.cdn as keyof typeof CDNS]);
    } else {
      title = title.replaceAll(':cdn', options.cdn);
    }
  }

  if (options.operator) {
    title = title.replaceAll(':operator', options.operator.name_mapped);
  }

  if (options.location) {
    if (typeof options.location === 'string') {
      title = title.replaceAll(':location', options.location);
    } else {
      title = title.replaceAll(':location', options.location.name);
    }
  }

  // Deployment type is 5g only, so a title may contain it, but it may not be applicable to it
  // Remove placeholder if undefined, as is the case for anything other than 5G
  // Otherwise replace with inTitleLabel
  title = title.replaceAll(':deploymentType', options.deploymentType?.inTitleLabel || '');

  // Replace consecutive spaces with one
  return title.trim().replace(/  +/g, ' ');
};

/**
 * @param titleWithPlaceholders A string with placeholders like `:connectionCategory Download Speed (:unit) Trend for :cdn`
 * @param maybeRefOptions whatever placeholders you need replaced. See Args
 * @returns the final title with placeholders replaced. Any placeholders that weren't supplied are left in-place.
 * @see Args
 */
export const usePlaceholderTitle = (
  titleWithPlaceholders: MaybeRef<string>,
  maybeRefOptions: MaybeRef<TitlePlaceholders>,
) => {
  const titleWithoutPlaceholders = computed<string>(() => {
    const options = unref(maybeRefOptions);
    const unreffedTitleWithPlaceholders = unref(titleWithPlaceholders);
    if (!unreffedTitleWithPlaceholders) {
      return '';
    }

    return replacePlaceholders(unreffedTitleWithPlaceholders, {
      connectionCategory: unref(options.connectionCategory),
      deploymentType: unref(options.deploymentType),
      unit: unref(options.unit),
      cdn: unref(options.cdn),
      operator: unref(options.operator),
      location: unref(options.location),
      operators: unref(options.operators),
      connectionCategories: unref(options.connectionCategories),
      cdns: unref(options.cdns),
      units: unref(options.units),
    });
  });

  return {
    titleWithoutPlaceholders,
  };
};
