<script setup lang="ts">
import { onUnmounted, ref } from 'vue';
import OnxButton from './OnxButton.vue';
import SimpleDialog from '@/components/SimpleDialog.vue';

export type Props = {
  modalTitle: string;
  code: string;
  isOpen: boolean;
  onClose: () => void;
};

const { code, isOpen, modalTitle, onClose } = defineProps<Props>();

/* Copy to Clipboard, and show visual feedback that the copy happened for a few seconds. */
const copied = ref(false);
const timeout = ref<any>(undefined);
const stopTimeout = () => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
};

const onCopyCode = () => {
  stopTimeout();
  navigator.clipboard.writeText(code);
  copied.value = true;
  timeout.value = setTimeout(() => {
    copied.value = false;
  }, 2000);
};

onUnmounted(stopTimeout);
</script>
<template>
  <SimpleDialog v-if="code !== undefined" :open="isOpen" @update:open="onClose">
    <label>{{ modalTitle }}</label>
    <pre class="code-contents">{{ code }}</pre>
    <OnxButton @click.stop="onCopyCode">
      <template v-if="copied">✓ Copied</template>
      <template v-else>Copy to clipboard</template>
    </OnxButton>
  </SimpleDialog>
</template>
<style scoped lang="scss">
pre.code-contents {
  overflow-y: scroll;
  font-size: small;
  background-color: #eff1f2;
  padding: 2px;
  max-height: 200px;
}
</style>
