import Colors from './colorPalette';

const isFinite = Number.isFinite;

const threshholdLuminosity = 210;

export const getTextColor = (backgroundHex) => {
  if (backgroundHex) {
    const colors = backgroundHex
      .substr(1)
      .match(/.{1,2}/g)
      .map((v) => parseInt(v, 16));

    colors[0] *= 0.89;
    colors[1] *= 1.1;
    colors[2] *= 0.82;

    return Math.max(...colors) < threshholdLuminosity ? Colors.colorWhite : Colors.colorTextDefault;
  }

  return Colors.colorTextDefault;
};

export const getScaleMin = (colorScale) =>
  colorScale && Math.min(...colorScale.ranges.reduce((acc, r) => (r.min !== undefined ? [r.min, ...acc] : acc), []));

export const getScaleMax = (colorScale) =>
  colorScale && Math.max(...colorScale.ranges.reduce((acc, r) => (r.max !== undefined ? [r.max, ...acc] : acc), []));

export const getRange = (colorScale, value) => {
  return (
    isFinite(value) &&
    colorScale &&
    colorScale.ranges.find((r) => {
      const ltMax = !isFinite(r.max) || value < r.max;
      const gtMin = !isFinite(r.min) || value >= r.min;
      const valueIsScaleMax = getScaleMax(colorScale) === value;
      return (valueIsScaleMax || ltMax) && gtMin;
    })
  );
};

export const getColor = (colorScale, value) => {
  const colorRange = getRange(colorScale, value);
  return colorRange && colorRange.color;
};

export const getBandFrequencyColor = (frequency, band, opacity) => {
  const luminosity = Math.min(band * 2 + 20, 50);
  return frequency > 1800
    ? `hsla(${Math.floor((frequency - 1000) / 7.5)}, 100%, ${luminosity}%, ${opacity})`
    : `hsla(${Math.floor((frequency - 650) / 4)}, 100%, ${luminosity}%, ${opacity})`;
};

export const stringToColour = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};
