import 'whatwg-fetch';
import auth0 from '@/auth0';
import { METRIC_TYPES } from '@/constants/constants';

export default class Base {
  constructor() {
    this.base = import.meta.env.VITE_BASE_URL;
    this.v1 = 'api/v1/';
    this.v2 = 'api/v2/';
    this.defaultDashboard = 'competitive';
  }

  getApiVersion(dashboard, metric = null) {
    if (dashboard === 'market-growth') {
      return this.v2;
    }
    if (metric && metric.split('_')[1] === METRIC_TYPES.Mmwave) {
      return this.v2;
    }
    return this.v2;
  }

  get(url, token) {
    return window
      .fetch(this.base + url, {
        headers: {
          'content-type': 'application/json',
          authorization: token,
        },
      })
      .then((response) => {
        if (!response.ok) {
          if ([401, 403].includes(response.status)) {
            return auth0.loginWithRedirect();
          }
          throw Error(response.message);
        }

        return response;
      })
      .then((response) => response.json());
  }

  getBlob(url, token) {
    return window
      .fetch(this.base + url, {
        headers: {
          'content-type': 'application/json',
          authorization: token,
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.message);
        }

        return response;
      })
      .then((response) => response.blob());
  }

  rawGet(url, token) {
    return window
      .fetch(url, {
        headers: {
          'content-type': 'application/json',
          authorization: token,
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.message);
        }

        return response;
      })
      .then((response) => response.json());
  }

  post(url, data, token) {
    return window
      .fetch(this.base + url, {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        method: 'POST',
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.message);
        }

        return response;
      })
      .then((response) => response.json());
  }
}
