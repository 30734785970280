<script setup lang="ts">
import { onMounted, watch, markRaw } from 'vue';
import Chart from 'chart.js/auto';
import { type Plugin, type ArcElement, type ChartData } from 'chart.js';

type Props = {
  chartId: string;
  chartData: ChartData<'doughnut'>;
  needleValue: number;
  showLegend?: boolean;
};

const props = defineProps<Props>();

function calculateLabelPosition(centerX: number, centerY: number, radius: number, endAngle: number) {
  const offsetRadius = radius + 10;

  // Calculate the x and y offsets
  const offsetX = Math.cos(endAngle) * offsetRadius;
  const offsetY = Math.sin(endAngle) * offsetRadius;

  // Calculate the absolute x and y coordinates for the text
  const textX = centerX + offsetX;
  const textY = centerY + offsetY;

  return { x: textX, y: textY };
}

const afterDatasetDraw: Plugin<'doughnut'>['afterDatasetDraw'] = (chart) => {
  const { chartArea, ctx, data } = chart;

  ctx.save();

  const chartDatasetMeta = chart.getDatasetMeta(0);
  const datasetMetaFirstDatum = chartDatasetMeta.data[0] as ArcElement;
  const { innerRadius, outerRadius, x: xCenter, y: yCenter } = datasetMetaFirstDatum;

  const dotRadius = chartArea.height * 0.02;
  const radian = Math.PI / 180;

  const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);
  const needleValue = chart.options.plugins?.gaugeNeedle?.value || 0;
  const doughtnutSegment = datasetMetaFirstDatum.circumference / Math.PI / data.datasets[0].data[0];

  const needleOverTotal = needleValue > total;
  const limitedRotationValue = needleOverTotal ? total : needleValue;
  const needleRotation = doughtnutSegment * limitedRotationValue;
  const needleColor = needleOverTotal ? 'red' : 'white';

  ctx.translate(xCenter, yCenter);
  ctx.rotate(Math.PI * (needleRotation + 1.5) + (needleOverTotal ? 0.05 : 0));

  ctx.beginPath();

  ctx.strokeStyle = 'black';
  ctx.fillStyle = needleColor;

  ctx.moveTo(0 - dotRadius, 0);
  ctx.lineTo(0, 0 - (innerRadius + (outerRadius - innerRadius) / 2));
  ctx.lineTo(dotRadius, 0);
  ctx.fill();
  ctx.stroke();

  // dot
  ctx.beginPath();
  ctx.arc(0, 0, dotRadius, 0, radian * 360);

  ctx.strokeStyle = 'black';
  ctx.fill();
  ctx.stroke();

  ctx.restore();

  // font settings
  ctx.font = '12px BeVietnamPro';
  ctx.fillStyle = 'black';
  ctx.textAlign = 'center';

  // start value
  ctx.fillText('0', xCenter - outerRadius - 10, yCenter);

  // needle value
  ctx.fillText(needleValue.toString(), xCenter, yCenter + 20);

  // labels
  let sum = 0;
  for (let i = 0; i < chartDatasetMeta.data.length; i++) {
    const chartElement = chartDatasetMeta.data[i] as ArcElement;
    const { endAngle } = chartElement;

    const textPosition = calculateLabelPosition(xCenter, yCenter, outerRadius, endAngle);
    const text = (data.datasets[0].data[i] + sum).toString();

    sum += data.datasets[0].data[i];

    ctx.fillText(text, textPosition.x, textPosition.y);
  }
};

const gaugeNeedle = {
  id: 'gaugeNeedle',
  afterDatasetDraw,
};

let gaugeChart: Chart<'doughnut'> | null = null;
const renderChart = () => {
  gaugeChart = markRaw(
    new Chart<'doughnut'>(props.chartId, {
      type: 'doughnut',
      data: props.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        parsing: {
          key: 'value',
        },
        layout: {
          padding: {
            top: 35,
            bottom: 35,
            left: 35,
            right: 35,
          },
        },
        aspectRatio: 1.75,
        circumference: 180,
        rotation: 270,
        cutout: '70%',
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          gaugeNeedle: {
            value: props.needleValue,
          },
        },
      },
      plugins: [gaugeNeedle],
    }),
  );
};

onMounted(renderChart);

watch(
  () => props.chartData,
  (newChartData) => {
    if (gaugeChart) {
      gaugeChart.data = newChartData;
      gaugeChart.update();
    }
  },
);
</script>

<template>
  <div class="gauge-chart">
    <canvas :id="chartId"></canvas>
  </div>
</template>
