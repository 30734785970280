<template>
  <main>
    <OnxNavbar v-if="hasUserData && spotlightOrFocusMatched" />
    <router-view />
    <disclaimer />
  </main>
</template>
<script>
import mp from 'mixpanel-browser';
import { mapGetters } from 'vuex';
import Disclaimer from './components/disclaimer/Disclaimer';
import OnxNavbar from './components/onx/OnxNavbar.vue';
import ROUTE_GROUPS from './constants/routeGroups';
import auth0 from '@/auth0';

export default {
  name: 'App',
  components: {
    Disclaimer,
    OnxNavbar,
  },
  data() {
    return {
      mixpanel: import.meta.env.VITE_MIXPANEL_TOKEN,
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters({
      info: 'dashboardInfo',
    }),
    hasUserData() {
      return !!Object.keys(this.user || {}).length;
    },
    spotlightOrFocusMatched() {
      if (!this.$route.matched) {
        return false;
      }

      const acceptableRoutes = [...ROUTE_GROUPS.Spotlight, ...ROUTE_GROUPS.Focus, ...ROUTE_GROUPS.ThreeSixty];

      return this.$route.matched.some((route) => acceptableRoutes.includes(route.name));
    },
  },
  mounted() {
    mp.init(this.mixpanel, { persistence: 'localStorage' });
    mp.register({ env: import.meta.env.VITE_ENV_LOCATION, email: auth0.user.value.email });
    if (import.meta.env.VITE_ENV_LOCATION !== 'prod') {
      mp.disable();
    }
    mp.track('app loaded');
  },
};
</script>
<style global lang="scss">
@use 'foundation-sites/scss/vendor/normalize' as foundationNormalize;
@use 'scss/variables.module' as *;
@import 'scss/onx-color-palette';
@import 'scss/onx-variables';
@import 'scss/onx-grid';
@import 'scss/spotlight-focus-common';
@import 'scss/utils';
@import 'scss/onx-table';
@import 'src/image-export/image-export-style.scss';

@include foundationNormalize.foundation-normalize();

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#app,
main {
  height: 100%;
}

ul {
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
}

@font-face {
  font-family: 'BeVietnamPro';
  font-weight: normal;
  font-style: normal;
  src:
    local('BeVietnamPro'),
    url('@/assets/fonts/BeVietnamPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BeVietnamPro';
  font-weight: 600;
  font-style: normal;
  src:
    local('BeVietnamPro'),
    url('@/assets/fonts/BeVietnamPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'BeVietnamPro';
  font-weight: bold;
  font-style: normal;
  src:
    local('BeVietnamPro'),
    url('@/assets/fonts/BeVietnamPro-Bold.ttf') format('truetype');
}

body {
  background-color: $color-blue-secondary !important;
  color: $color-text-default;
  font-family: 'BeVietnamPro', sans-serif;
  font-size: $base-font-size;
  font-weight: $font-weight-roboto-regular;

  &.v-popper--some-open {
    overflow: hidden;
  }
}

.v-popper__arrow-container {
  display: none;
}

.v-popper--theme-tooltip {
  .v-popper__inner {
    background: var(--onx-tooltip-bg-color);
    font-size: pxToRem(14);
  }

  .v-popper__arrow-inner {
    border-color: var(--onx-tooltip-bg-color);
  }
}

.onx-dropdown-icon {
  transition: transition();

  &--reverse {
    transform: rotate(-180deg);
  }
}

.map-geohash-reset-btn {
  position: absolute;

  bottom: 16px;
  left: 16px;
}

.neutral-anchor-link {
  color: inherit;
  text-decoration: none;
}
</style>
