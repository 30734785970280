<script setup lang="ts">
import { computed, toRefs } from 'vue';
import merge from 'lodash/merge';

import InfoIcon from '../icons/InfoIcon.vue';
import OnxHeadline from '../typography/OnxHeadline.vue';
import type { DataTransformerResponseCallback } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { ChartTrendSeriesInputData } from '@/types/Charts';
import type { MetricStructuresEnum, RankedSimpleMetric } from '@/types/MetricStructures';

import { OnxChartBaseProps } from '@/components/onx/charts/OnxChartBaseProps';
import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';
import { LineChart } from '@/components/visual';
import ViewChartSqlButton from '@/components/visual/chart/ViewChartSqlButton.vue';
import useDelayedLoader from '@/composables/useDelayedLoader';
import useEndDate from '@/composables/useEndDate';
import useFilters from '@/composables/useFilters';
import useMetric from '@/composables/useMetric';
import useMetricSource from '@/composables/useMetricSource';
import { useMetricSQL } from '@/composables/useMetricSQL';
import { Operator } from '@/types/Operator';
import { exportToCsv } from '@/utils/files';
import { getTrendSeries } from '@/utils/viewHelpers';
import naiveId from '@/utils/naiveId';

export type OnxTrendChartProps = OnxChartBaseProps & {
  geohashes: string[];
  enabled?: boolean;
  hidden?: boolean;

  /** Override CSV export */
  onExportToCsv?: (data: any[], title: string) => void | Promise<void>;

  lineChartAttrs?: Record<string, unknown>;

  operators: Operator[];
  transform?: DataTransformerResponseCallback<ChartTrendSeriesInputData<RankedSimpleMetric>[]>;
  chartConfig?: any;
  chartTooltipPlugin?: (context: any, tooltip: any, chartData: any) => void;
  nbDays?: number;
  ignoreDate?: boolean;
};

const props = withDefaults(defineProps<OnxTrendChartProps>(), {
  enabled: undefined,
  hidden: false,
  ignoreDate: false,
});

const {
  aggregation,
  bbox,
  chartTitle,
  chartTitleTooltip,
  deploymentType,
  enabled,
  geohashes,
  hidden,
  location,
  metric,
  nbDays,
  transform,
} = toRefs(props);
const { endDate, setFilters, showConfidenceIntervals } = useFilters(props.dashboard);
const { currentEndDate } = useEndDate(props.dashboard);

const computedEndDateString = computed(() => {
  if (props.ignoreDate) {
    return '';
  }

  return endDate.value || '';
});

const computedEndDate = computed(() => {
  if (!props.ignoreDate) {
    return undefined;
  }

  return currentEndDate.value;
});

const {
  data: response,
  isLoading,
  isPending,
  isRefetching,
  isSuccess,
} = useMetric<MetricStructuresEnum.RankedSimple>(props.dashboard, {
  metric,
  location,
  aggregation,
  geohashes,
  bbox,
  deploymentType,
  enabled,
  nbDays: nbDays.value,
  endDate: computedEndDate,
});

const { metricSource } = useMetricSource(response, isSuccess);
const { sql } = useMetricSQL(response, isSuccess);

const trendSeries = computed(() => {
  if (isSuccess.value && response.value?.data) {
    const data = response.value?.data;

    if (transform?.value) {
      return transform.value([data]);
    } else {
      return Object.values(getTrendSeries(props.operators, data.results, data.start_date, data.end_date));
    }
  }

  return [];
});

const screenshotLegend = computed(() => {
  if (!trendSeries.value || trendSeries.value.length === 0) {
    return [];
  }

  return trendSeries.value.map((series: any) => {
    const datapoint = series.data.find((datapoint: any) => {
      return datapoint.x === endDate.value || new Date(datapoint.x).getTime() === currentEndDate.value.getTime();
    });

    return {
      label: series.meta?.imageExportLegend?.label || series.label,
      color: series.meta?.imageExportLegend?.color || series.color,
      y: datapoint?.y,
      lci: datapoint?.lci,
      uci: datapoint?.uci,
    };
  });
});

const handlePointClick = (date: string) => {
  if (props.ignoreDate) {
    return;
  }

  setFilters({ endDate: date });
};

const { loaderTimeoutStarted, loading: showCsvDownloadLoader, startLoaderTimeout } = useDelayedLoader(2000);
const handleExportToCsv = () => {
  if (isLoading.value || !isSuccess.value || loaderTimeoutStarted.value) {
    return;
  }

  if (props.onExportToCsv) {
    const promise = props.onExportToCsv(trendSeries.value, chartTitle.value);

    if (promise instanceof Promise) {
      startLoaderTimeout(promise);
    }

    return;
  }

  const series = trendSeries.value;
  const hasConfidenceIntervals = series
    .flatMap((s: any) => s.data)
    .every((datum) => {
      return typeof datum.lci === 'number' && typeof datum.uci === 'number';
    });
  const csvData = series.flatMap((s: any) => {
    if (hasConfidenceIntervals) {
      return s.data.map((datum: any) => [
        s.label as string,
        datum.x.split('T')[0] as string, // convert datetime to date only
        datum.y as number,
        datum.lci as number,
        datum.uci as number,
      ]);
    } else {
      return s.data.map((datum: any) => [
        s.label as string,
        datum.x.split('T')[0] as string, // convert datetime to date only
        datum.y as number,
      ]);
    }
  });
  let headers;
  if (hasConfidenceIntervals) {
    headers = ['Operator', 'Date', 'Mean', 'LCI', 'UCI'];
  } else {
    headers = ['Operator', 'Date', 'Mean'];
  }

  exportToCsv(`${chartTitle.value}.csv`, [headers, ...csvData]);
};
const randomChartId = naiveId();

const lineChartConfig = {
  options: {
    plugins: {
      tooltip: {
        external: props.chartTooltipPlugin,
      },
    },
  },
};

merge(lineChartConfig, { ...(props.chartConfig || {}) });
</script>

<template>
  <OnxChartContainer
    class="onx-trend"
    :csv-export-disabled="loaderTimeoutStarted"
    :csv-export-loading="showCsvDownloadLoader"
    :enable-csv-export="true"
    :hidden="hidden"
    :loading="isLoading || isRefetching || isPending"
    :metric-source="metricSource"
    :no-data="trendSeries.length === 0"
    :onExportToCsv="handleExportToCsv"
    :chart-title-tooltip="chartTitleTooltip"
    :screenshot-legend="screenshotLegend"
    :screenshot-subtitle="screenshotSubtitle"
    :screenshot-title="screenshotTitle"
    :show-confidence-intervals="showConfidenceIntervals"
    :subtitle="chartSubtitle"
    :title="chartTitle"
  >
    <template #title>
      <slot name="title">
        <OnxHeadline as="h3">
          {{ chartTitle }}
          <CustomTooltip v-if="chartTitleTooltip" :message="chartTitleTooltip" placement="top">
            <InfoIcon class="FieldGroup__tooltipIcon" />
          </CustomTooltip>
        </OnxHeadline>
        <OnxHeadline as="h4" v-if="chartSubtitle">{{ chartSubtitle }}</OnxHeadline>
      </slot>
    </template>
    <LineChart
      v-if="isSuccess"
      :data-set="trendSeries"
      :key="`trend-${metric}-${location}`"
      :chart-id="`trend-${metric}-${location}-${randomChartId}`"
      :height="350"
      :date="computedEndDateString"
      @point="handlePointClick"
      :show-confidence-rate="showConfidenceIntervals"
      normalized
      v-bind="lineChartAttrs"
      show-legend
      :config="lineChartConfig"
    />

    <template #tools>
      <ViewChartSqlButton v-if="sql" :sql="sql" :chartTitle="chartTitle" />
    </template>
  </OnxChartContainer>
</template>

<style lang="scss"></style>
