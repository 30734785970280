<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { RouterLink } from 'vue-router';

/** Just like RouterLink, but includes query params. */

export type Props = {
  to: string;
};

const props = defineProps<Props>();
const route = useRoute();

const actualTo = computed(() => {
  const { fullPath, path } = route;
  return fullPath.replace(path, props.to);
});
</script>
<template>
  <RouterLink :to="actualTo">
    <slot />
  </RouterLink>
</template>
