import { useStore } from 'vuex';

import {
  type ChartMetricDefinition,
  isChartMetricDefinitionWithSingleSubtype,
} from '@/chart-metric-definitions/MetricDefinition';
import { type AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';

const useCheckIfMetricExists = () => {
  const store = useStore();

  function checkIfMetricExists(
    metricDefinition: ChartMetricDefinition,
    connectionCategory: AvailableConnectionCategory['categoryValue'],
  ): boolean;
  function checkIfMetricExists(metricKey: string): boolean;
  function checkIfMetricExists(
    metricKeyOrDefinition: string | ChartMetricDefinition,
    connectionCategory?: AvailableConnectionCategory['categoryValue'],
  ): boolean {
    const metricsByIdentifier = store.getters['metrics/byIdentifier'];

    if (typeof metricKeyOrDefinition === 'string') {
      return !!metricsByIdentifier[metricKeyOrDefinition];
    }

    if (!connectionCategory) {
      throw new Error('Connection category is required for metric definition');
    }

    if (isChartMetricDefinitionWithSingleSubtype(metricKeyOrDefinition)) {
      return !!metricsByIdentifier[`${metricKeyOrDefinition.metricSubtype}_${connectionCategory}`];
    }

    return metricKeyOrDefinition.metricSubtypes.every(
      (subtype) => !!metricsByIdentifier[`${subtype}_${connectionCategory}`],
    );
  }

  return { checkIfMetricExists };
};

export default useCheckIfMetricExists;
