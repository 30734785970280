<script setup>
import { computed, inject } from 'vue';

const props = defineProps({
  id: {
    type: [String, Number],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  value: {
    type: undefined,
    required: false,
    default: null,
  },
  as: {
    type: String,
    default: 'div',
    required: false,
  },
});

const selectedTab = inject('tabs:selectedTab');
const onTabChange = inject('tabs:onTabChange');
const small = inject('tabs:small');
const largerActiveTab = inject('tabs:largerActiveTab');
const grow = inject('tabs:grow');

const classes = computed(() => {
  const selected = selectedTab.value === props.id;

  return {
    'onx-tabs__tab': true,
    'onx-tabs__tab--disabled': props.disabled,
    'onx-tabs__tab--small': small.value,
    'onx-tabs__tab--grow': grow.value,
    'onx-tabs__tab--active': selected,
    'onx-tabs__tab--active--large': selected && largerActiveTab.value,
  };
});
</script>

<template>
  <component :is="as" :class="classes" @click="onTabChange(id, value)">
    <slot></slot>
  </component>
</template>

<style lang="scss">
@import 'scss/mixins';

.onx-tabs__tab {
  color: var(--onx-tab-default-color);
  border-bottom: 1px solid var(--onx-tab-default-border-color);
  cursor: pointer;
  white-space: nowrap;
  background: var(--white);

  height: 38px;
  padding: 12px 16px;
  font-size: 14px;
  text-align: center;

  &--active {
    color: var(--onx-tab-active-color);
    border-bottom: 2px solid var(--onx-tab-active-color);

    &--large {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  &:hover {
    background-color: var(--onx-tab-hover-bg-color);
  }

  &--disabled {
    color: var(--onx-tab-disabled-color);
  }

  &--small {
    height: 28px;
    font-size: 12px;
  }

  &--grow {
    flex-grow: 1;
  }

  transition: transition(background-color);
}
</style>
