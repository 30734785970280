import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

import { coverageSubcategoryColors } from '@/constants/colorScales';

const transformGeohashCountByOperator: DataTransformerConstructor<
  [MetricStructuresEnum.CoverageGeohashCountsByCategory],
  Required<Pick<DataTransformerFnOptions, 'operators'>> & Pick<DataTransformerFnOptions, 'selectedConnectionCategories'>
> =
  ({ operators, selectedConnectionCategories }) =>
  ([response], horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const selectedConnectionCategoryLabels = (selectedConnectionCategories || []).map(
      (category) => category.categoryLabel,
    );
    const subCategories = Object.keys(metricsFilteredByOperator[0].counts).filter((subCategory) => {
      if (selectedConnectionCategoryLabels.length === 0) {
        return true;
      }

      return selectedConnectionCategoryLabels.some((categoryLabel) => subCategory.startsWith(categoryLabel));
    });
    const metrics = subCategories.map<DataTransformerReturnedItem>((subCategory) => {
      const data = operators
        .map((operator) => {
          const datum = metricsFilteredByOperator.find((datum) => {
            return datum.canonical_network_id === operator.canonical_network_id;
          });

          if (!datum) {
            return undefined;
          }

          return {
            ...datum,
            // @ts-ignore
            [dataAxis]: datum.counts[subCategory],
            [labelAxis]: operator.name_mapped,
          };
        })
        .filter(Boolean);

      // @ts-ignore
      const color = coverageSubcategoryColors[subCategory];

      return {
        data,
        type: 'bar',
        backgroundColor: color,
        color,
        label: subCategory,
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color,
            label: subCategory,
          },
        },
      };
    });

    return metrics;
  };

export default transformGeohashCountByOperator;
