<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { useRoute } from 'vue-router';

/**
 * Used to conditionally draw content based on whether the current route matches a given path.
 * Matching is done with === (exact) or startsWith (not exact) against useRoute().path.
 */

export type Props = {
  /**
   * match can be a string, an array of strings, or a function that takes the current path and returns a boolean.
   * For example, the current path of `focus/qoe/details?metricSubtype=download&chartConnectionCategories=5g` is
   * `/focus/qoe/details`. Match overrides exact if it is a function.
   */
  match: string | string[] | ((path: string) => boolean);

  /** Whether to invert the result. Default is false. */
  not?: boolean;

  /** Whether to match the whole path or just the starting. Ignored if `match` is a fuction. Default is false. */
  exact?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  not: false,
  exact: false,
});
const { exact, match, not } = toRefs(props);
const route = useRoute();

// determine whether the current path matches the given matcher prop
// "not" is handled in the template below
const matches = computed<boolean>(() => {
  const { path: actualPath } = route;
  const matchValue = match.value;
  if (typeof matchValue === 'function') {
    try {
      return matchValue(actualPath);
    } catch (e) {
      console.error('Error in RouteMatches match function', e);
      return false;
    }
  } else if (Array.isArray(matchValue)) {
    if (exact.value) {
      return matchValue.includes(actualPath);
    } else {
      return matchValue.some((path) => actualPath.startsWith(path));
    }
  } else if (exact.value) {
    return matchValue === actualPath;
  } else {
    return actualPath.startsWith(matchValue);
  }
});
</script>
<template>
  <slot v-if="not ? !matches : matches" />
</template>
