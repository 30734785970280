<script setup>
import { computed } from 'vue';
import RankDownIcon from '@/components/onx/icons/rank/RankDownIcon.vue';
import RankNoChangeIcon from '@/components/onx/icons/rank/RankNoChangeIcon.vue';
import RankUpIcon from '@/components/onx/icons/rank/RankUpIcon.vue';
import { getRankLabel } from '@/utils/viewHelpers';

const props = defineProps({
  current: {
    type: Number,
    default: 0,
  },
  delta: {
    type: Number,
    default: 0,
  },
});

const icon = computed(() => {
  const { delta } = props;

  if (!delta) {
    return RankNoChangeIcon;
  } else if (delta > 0) {
    return RankDownIcon;
  } else {
    return RankUpIcon;
  }
});

const deltaLabel = computed(() => {
  const { current, delta } = props;

  if (!delta) {
    return 'No change';
  }

  return getRankLabel(current - delta);
});
</script>

<template>
  <div class="onx-delta">
    <component :is="icon" small />
    <span class="onx-delta__label">{{ deltaLabel }}</span>
  </div>
</template>

<style scoped lang="scss">
@import 'scss/mixins';

.onx-delta {
  display: flex;
  align-items: center;
  gap: 4px;
}

.onx-delta__label {
  font-size: pxToRem(12);
  color: var(--onx-caption-color);
}
</style>
