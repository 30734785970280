<script setup lang="ts">
import { ref } from 'vue';
import OnxToggle from './OnxToggle.vue';

type Props = {
  leftValue: string;
  rightValue: string;
  modelValue: string;
};

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);
const handleToggle = (value: boolean) => {
  emit('update:modelValue', value ? props.rightValue : props.leftValue);
};

// used as a dummy v-model
const toggle = ref(false);
</script>

<template>
  <OnxToggle v-model="toggle" @update:modelValue="handleToggle" class="onx-toggle-between" />
</template>

<style lang="scss">
.onx-toggle-between.onx-toggle {
  input {
    + .onx-toggle__slider {
      background-color: var(--onx-toggle-on-bg-color);
    }

    &:hover + .onx-toggle__slider {
      background-color: var(--onx-toggle-on-hover-bg-color);
    }

    &:focus + .onx-toggle__slider {
      background-color: var(--onx-toggle-on-focus-bg-color);
    }
  }
}
</style>
