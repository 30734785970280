import exportDatasetToCsv from '@/utils/exportDatasetToCsv';

const exportCdnIpTestServersToCsv = exportDatasetToCsv({
  headers: ['IP', 'CDN', 'Date', 'Percentage', 'Counts'],
  getRow: (datum: any, dataset: any, date: string) => [
    datum.ip,
    datum.cdn,
    date,
    datum.y,
    datum.secondaryValue.toFixed(2),
  ],
});

export default exportCdnIpTestServersToCsv;
