import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import getAvailableChartMetrics from '@/focus/metric-selector/getAvailableChartMetrics';
import { isChartMetricDefinitionWithSingleSubtype } from '@/chart-metric-definitions/MetricDefinition';

/**
 * Returns a list of unique connection categories available to the user based on
 * a frontend defined metric definition subset (i.e ChartMetricDefinitions.ts at this time), picked by metricSubtype and cross-referenced
 * against the /metrics API response, which only provides metrics the user has access to.
 */
const useAvailableConnectionCategories = () => {
  const router = useRouter();
  const store = useStore();
  const existingMetricSubtypes = store.getters['metrics/subtypes'];

  const connectionCategories = computed<string[]>(() => {
    const metricSubtype = router.currentRoute.value.query.metricSubtype as SelectableMetrics;
    const availableChartMetrics = getAvailableChartMetrics(metricSubtype, router.currentRoute.value.name as string);
    return Array.from(
      new Set(
        availableChartMetrics
          .flatMap((metricDefinition) => {
            if (isChartMetricDefinitionWithSingleSubtype(metricDefinition)) {
              return existingMetricSubtypes[metricDefinition.metricSubtype] ?? [];
            }

            return metricDefinition.metricSubtypes.flatMap((subtype) => existingMetricSubtypes[subtype] ?? []);
          })
          .map((metric) => metric?.type),
      ),
    );
  });

  return connectionCategories;
};

export default useAvailableConnectionCategories;
