<script setup lang="ts">
import type { OnxPermissionTopics } from '@/composables/useOnxLicencePermission';
import type { Dashboards } from '@/constants/dashboards';

import { useOnxLicencePermission } from '@/composables/useOnxLicencePermission';

const props = defineProps<{
  topic: OnxPermissionTopics;
  dashboard: Dashboards;
}>();

const visible = useOnxLicencePermission(props.dashboard, props.topic);
</script>

<template>
  <slot v-if="visible" />
</template>
