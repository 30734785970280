export const MetricSubtypes = {
  Download: 'download',
  CdnDownload: 'cdndownload',
  BinnedDownload: 'binneddownload',

  Upload: 'upload',
  CdnUpload: 'cdnupload',
  BinnedUpload: 'binnedupload',

  /* Games */
  GamesExperience: 'gamesexperience',

  GamesPacketLoss: 'gamespacketloss',
  CpGamesPacketLoss: 'cpgamespacketloss',
  BinnedGamesPacketLoss: 'binnedgamespacketloss',

  GamesJitter: 'gamesjitter',
  CpGamesJitter: 'cpgamesjitter',
  BinnedGamesJitter: 'binnedgamesjitter',

  GamesPacketDelay: 'gamesrtt',
  CpGamesPacketDelay: 'cpgamesrtt',
  BinnedGamesPacketDelay: 'binnedgamesrtt',

  PeakDownload: 'peakdownload',
  ConsistencyDownload: 'consistencydownload',

  PeakUpload: 'peakupload',
  ConsistencyUpload: 'consistencyupload',

  /* ECQ and CCQ */
  Ecq: 'ecq',
  CdnEcq: 'cdnecq',
  BinnedEcq: 'binnedecq',

  EcqDownloadThroughput: 'ecqdownload',
  CdnEcqDownloadThroughput: 'cdnecqdownload',
  BinnedEcqDownloadThroughput: 'binnedecqdownload',
  EcqThresholdDownloadThroughput: 'ecqthresholddownload',
  EcqDownloadThroughputTestFailure: 'ecqdownloadthroughputtestfailure',
  EcqServerResponseTestFailure: 'ecqserverresponsetestfailure',

  EcqUploadThroughput: 'ecqupload',
  CdnEcqUploadThroughput: 'cdnecqupload',
  BinnedEcqUploadThroughput: 'binnedecqupload',
  EcqThresholdUploadThroughput: 'ecqthresholdupload',

  EcqIcmpLatency: 'ecqicmplatency',
  CdnEcqIcmpLatency: 'cdnecqicmplatency',
  BinnedEcqIcmpLatency: 'binnedecqicmplatency',
  EcqThresholdIcmpLatency: 'ecqthresholdicmplatency',

  EcqLatency: 'ecqlatency',
  CdnEcqLatency: 'cdnecqlatency',
  BinnedEcqLatency: 'binnedecqlatency',
  EcqThresholdLatency: 'ecqthresholdlatency',

  EcqPacketLoss: 'ecqpacketloss',
  CdnEcqPacketLoss: 'cdnecqpacketloss',
  BinnedEcqPacketLoss: 'binnedecqpacketloss',
  EcqThresholdPacketLoss: 'ecqthresholdpacketloss',

  EcqJitter: 'ecqjitter',
  CdnEcqJitter: 'cdnecqjitter',
  BinnedEcqJitter: 'binnedecqjitter',
  EcqThresholdJitter: 'ecqthresholdjitter',

  EcqDownloadTimeFirstByte: 'ecqdownloadtimefirstbyte',
  CdnEcqDownloadTimeFirstByte: 'cdnecqdownloadtimefirstbyte',
  BinnedEcqDownloadTimeFirstByte: 'binnedecqdownloadtimefirstbyte',
  EcqThresholdDownloadTimeFirstByte: 'ecqthresholddownloadtimefirstbyte',

  Ccq: 'ccq',
  CdnCcq: 'cdnccq',
  BinnedCcq: 'binnedccq',

  CcqDownloadThroughput: 'ccqdownload',
  CdnCcqDownloadThroughput: 'cdnccqdownload',
  BinnedCcqDownloadThroughput: 'binnedccqdownload',
  CcqThresholdDownloadThroughput: 'ccqthresholddownload',
  CcqDownloadThroughputTestFailure: 'ccqdownloadthroughputtestfailure',
  CcqServerResponseTestFailure: 'ccqserverresponsetestfailure',

  CcqUploadThroughput: 'ccqupload',
  CdnCcqUploadThroughput: 'cdnccqupload',
  BinnedCcqUploadThroughput: 'binnedccqupload',
  CcqThresholdUploadThroughput: 'ccqthresholdupload',

  CcqIcmpLatency: 'ccqicmplatency',
  CdnCcqIcmpLatency: 'cdnccqicmplatency',
  BinnedCcqIcmpLatency: 'binnedccqicmplatency',
  CcqThresholdIcmpLatency: 'ccqthresholdicmplatency',

  CcqLatency: 'ccqlatency',
  CdnCcqLatency: 'cdnccqlatency',
  BinnedCcqLatency: 'binnedccqlatency',
  CcqThresholdLatency: 'ccqthresholdlatency',

  CcqPacketLoss: 'ccqpacketloss',
  CdnCcqPacketLoss: 'cdnccqpacketloss',
  BinnedCcqPacketLoss: 'binnedccqpacketloss',
  CcqThresholdPacketLoss: 'ccqthresholdpacketloss',

  CcqJitter: 'ccqjitter',
  CdnCcqJitter: 'cdnccqjitter',
  BinnedCcqJitter: 'binnedccqjitter',
  CcqThresholdJitter: 'ccqthresholdjitter',

  CcqDownloadTimeFirstByte: 'ccqdownloadtimefirstbyte',
  CdnCcqDownloadTimeFirstByte: 'cdnccqdownloadtimefirstbyte',
  BinnedCcqDownloadTimeFirstByte: 'binnedccqdownloadtimefirstbyte',
  CcqThresholdDownloadTimeFirstByte: 'ccqthresholddownloadtimefirstbyte',

  /* Video Experience */
  VideoExperience: 'videoexperience',
  CdnVideoAbrExperience: 'cdnvideoabrexperience',
  BinnedCdnVideoAbrExperience: 'binnedcdnvideoabrexperience',

  VideoAbrThroughput: 'videoabrthroughput',
  CdnVideoAbrThroughput: 'cdnvideoabrthroughput',
  BinnedCdnVideoAbrThroughput: 'binnedcdnvideoabrthroughput',

  VideoAbrTtff: 'videoabrttff',
  CdnVideoAbrTtff: 'cdnvideoabrttff',
  BinnedCdnVideoAbrTtff: 'binnedcdnvideoabrttff',

  VideoAbrStallingOccurrence: 'videoabrstalling',
  CdnVideoAbrStallingOccurrence: 'cdnvideoabrstalling',

  VideoAbrResTime: 'videoabrrestime',
  CdnVideoAbrResTime: 'cdnvideoabrrestime',

  VideoAbrStallingTime: 'videoabrstalltime',
  CdnVideoAbrStallingTime: 'cdnvideoabrstalltime',
  BinnedCdnVideoAbrStallingTime: 'binnedcdnvideoabrstalltime',

  /* Voice */
  VoiceExperience: 'voiceexperience',
  CpVoiceExperience: 'cpvoiceexperience',
  BinnedVoiceExperience: 'binnedvoiceexperience',

  VoicePacketLoss: 'voicepacketloss',
  CpVoicePacketLoss: 'cpvoicepacketloss',
  BinnedVoicePacketLoss: 'binnedvoicepacketloss',

  VoicePacketDelay: 'voicertt',
  CpVoicePacketDelay: 'cpvoicertt',
  BinnedVoicePacketDelay: 'binnedvoicertt',

  /* Live Video */
  LiveVideoExperience: 'videoliveexperience',
  CdnLiveVideoExperience: 'cdnvideoliveexperience',

  LiveVideoOffset: 'videoliveoffset',
  CdnLiveVideoOffset: 'cdnvideoliveoffset',
  BinnedCdnLiveVideoOffset: 'binnedcdnvideoliveoffset',

  LiveVideoStallingTime: 'videolivestalltime',
  CdnLiveVideoStallingTime: 'cdnvideolivestalltime',
  BinnedCdnLiveVideoStallingTime: 'binnedcdnvideolivestalltime',

  LiveVideoStallingOccurrence: 'videolivestalling',
  CdnLiveVideoStallingOccurrence: 'cdnvideolivestalling',

  LiveVideoTtff: 'videolivettff',
  CdnLiveVideoTtff: 'cdnvideolivettff',
  BinnedCdnLiveVideoTtff: 'binnedcdnvideolivettff',

  LiveVideoThroughput: 'videolivethroughput',
  CdnLiveVideoThroughput: 'cdnvideolivethroughput',
  BinnedCdnLiveVideoThroughput: 'binnedcdnvideolivethroughput',

  LiveVideoResTime: 'videoliverestime',
  CdnLiveVideoResTime: 'cdnvideoliverestime',

  /* Coverage Geohash */
  CoverageGeohash7CountSubcategory: 'coveragegeohash7countsubcategory',
  CoverageGeohash7CountConnectionCategory: 'coveragegeohash7countconnectioncategory',

  /* 360 */
  CdnIpDownload360: 'cdnipdownload360',
  CdnIpDownload360Trend: 'cdnipdownload360trend',
  CdnIpDownload360Stack: 'cdnipdownload360stack',

  CdnIpUpload360: 'cdnipupload360',
  CdnIpUpload360Trend: 'cdnipupload360trend',
  CdnIpUpload360Stack: 'cdnipupload360stack',

  CdnIpJitter360Trend: 'cdnipjitter360trend',

  CdnIpPacketLoss360Trend: 'cdnippacketloss360trend',
  CdnIpPacketLossLost360Trend: 'cdnippacketlossdiscard360trend',
  CdnIpUdpTestServers360Stack: 'cdnipudptestserver360stack',

  CdnIpVideoLiveStallTime360Trend: 'cdnipvideolivestalltime360trend',
  CdnIpVideoLiveStalling360Trend: 'cdnipvideolivestalling360trend',
  CdnIpVideoLiveTtff360Trend: 'cdnipvideolivettff360trend',
  CdnIpVideoLiveThroughputRate360Trend: 'cdnipvideolivethroughput360trend',
  CdnIpVideoLiveTimeOnRes: 'cdnipvideoliverestime360',

  CdnIpVideoAbrTtff360: 'cdnipvideoabrttff360',
  CdnIpVideoAbrStallTime360: 'cdnipvideoabrstalltime360',
  CdnIpVideoAbrStallingOccurrence360: 'cdnipvideoabrstalling360',
  CdnIpVideoAbrThroughput360: 'cdnipvideoabrthroughput360',
  CdnIpVideoAbrTimeOnRes360: 'cdnipvideoabrrestime360',

  CdnIpVideoLiveTtff360: 'cdnipvideolivettff360',
  CdnIpVideoLiveStallTime360: 'cdnipvideolivestalltime360',
  CdnIpVideoLiveStallingOccurrence360: 'cdnipvideolivestalling360',
  CdnIpVideoLiveThroughput360: 'cdnipvideolivethroughput360',
  CdnIpVideoLiveTimeOnRes360: 'cdnipvideoliverestime360',

  EcqCcqDownload360: 'ecqccqdownload360',
  EcqCcqUpload360: 'ecqccqupload360',
  EcqCcqLatency360: 'ecqccqlatency360',

  CdnIpEcqCcqUpload360: 'cdnipecqccqupload360',
  CdnIpEcqCcqIcmpLatency360: 'cdnipecqccqicmplatency360',
  CdnIpEcqCcqDownloadThroughputTestServers360Stack: 'cdnipecqccqdownloadtestserver360stack',
  CdnIpEcqCcqUploadThroughputTestServers360Stack: 'cdnipecqccquploadtestserver360stack',
  CdnIpEcqCcqIcmpLatencyTestServers360Stack: 'cdnipecqccqicmptestserver360stack',
  CdnIpEcqCcqServerResponseTestServers360Stack: 'cdnipecqccqserverresponsetestserver360stack',

  CdnIpGamesVoicePacketDelay360: 'cdniprtt360',
  CdnIpGamesVoicePacketLoss360: 'cdnippacketloss360',
  CdnIpGamesVoiceJitter360: 'cdnipjitter360',

  CdnIpEcq360: 'cdnipecq360',
  CdnIpCcq360: 'cdnipccq360',

  CdnIpEcqCcqDownloadThroughput360: 'cdnipecqccqdownload360',
  CdnIpEcqCcqTtfb360: 'cdnipecqccqttfb360',

  CdnIpEcqCcqJitter360: 'cdnipecqccqjitter360',
  CdnIpEcqCcqLatency360: 'cdnipecqccqlatency360',

  CdnIpEcqCcqPacketLossDiscard360: 'cdnipecqccqpacketlossdiscard360',
  CdnIpEcqCcqPacketLossLost360: 'cdnipecqccqpacketlosslost360',

  Availability4GPerformance: 'availability4gperformance360',
  Availability5GPerformance: 'availability5gperformance360',
  Availability4GShare: 'availability4gshare360',
  Availability5GShare: 'availability5gshare360',

  /* Site Mapper */
  ENodeBCount: 'enodebcount360',
  BandENodeBCount: 'bandenodebcount360',
  LocationConfidenceENodeBCount: 'locationconfidenceenodebcount360',
  FirstRecordDateAvgCellSiteCount: 'firstrecorddateavgcellsitecount360',
  EstimatedLocationConfidenceTablePartial: 'estimatedlocationconfidencetable360partial',
  EstimatedLocationConfidenceTableFull: 'estimatedlocationconfidencetable360full',

  /* Overshooting */
  OvershootingENodeBCount: 'overshootingenodebcount360',
  OvershootingLikelihoodENodeBCount: 'overshootinglikelihoodenodebcount360',
  OvershootingScoreByENodeBIdPartial: 'overshootingscorechart360partial',
  OvershootingScoreByENodeBIdFull: 'overshootingscorechart360full',
  OvershootingRecordCount: 'overshootingrecordcount360',
  ConnectionBandOvershootingRecordCount: 'connectionbandovershootingrecordcount360',
  OvershootingScoreTablePartial: 'overshootingscoretable360partial',
  OvershootingScoreTableFull: 'overshootingscoretable360full',

  /* Congestion */
  CongestionUnderutilizedCellsPartial: 'congestionunderutilizedcapacityscore360partial',
  CongestionUnderutilizedCellsFull: 'congestionunderutilizedcapacityscore360full',
  CongestionMostCongestedCellsPartial: 'congestionmostcongestedcell360partial',
  CongestionMostCongestedCellsFull: 'congestionmostcongestedcell360full',
  CongestionAllCellsPartial: 'congestionallcell360partial',
  CongestionAllCellsFull: 'congestionallcell360full',
  CongestionHourlyJitter: 'congestionhourlyjitter360',
  CongestionHourlyPacketLossDiscard: 'congestionhourlypacketlossdiscard360',
  CongestionCongestedCells: 'congestioncongestedcells360',
  CongestionRANCellCount: 'congestionrancellcount360',
  CongestionCategoryDownload: 'congestioncategorydownload360',
  CongestionCategoryUpload: 'congestioncategoryupload360',
  CongestionCategoryPacketLossDiscard: 'congestioncategorypacketlossdiscard360',
  CongestionCellBreakdown: 'congestioncellbreakdown360',
  CongestionRANDownload: 'congestionrandownload360',
  CongestionNonRANDownload: 'congestionnonrandownload360',
  CongestionAtRiskDownload: 'congestionatriskdownload360',
  CongestionUnderutilizedDownload: 'congestionunderutilizeddownload360',

  /* Reliability */
  ReliabilityCompletion: 'completion',
  ReliabilityConnectivity: 'connectivity',
  ReliabilitySufficiency: 'sufficiency',
  ReliabilityConnectionSuccess: 'connectionsuccess',
  ReliabilitySessionRelability: 'sessionreliability',

  HourlyDownload: 'hourlydownload',
  HourlyUpload: 'hourlyupload',
} as const;
export type MetricSubtypes = (typeof MetricSubtypes)[keyof typeof MetricSubtypes];
