<script setup lang="ts">
import { toRefs, computed } from 'vue';
import type mapboxgl from 'mapbox-gl';

import {
  type ChartMetricDefinition,
  isDatasetChartMetricDefinition,
  isGaugeChartMetricDefinition,
  isMultiMetricTableChartMetricDefinition,
  isTableChartMetricDefinition,
} from './MetricDefinition';
import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import type { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';
import type { Operator } from '@/types/Operator';

import IntersectionObserver from '@/components/IntersectionObserver.vue';
import OnxBarChart from '@/components/onx/charts/OnxBarChart.vue';
import OnxCdnBarChart from '@/components/onx/charts/OnxCdnBarChart.vue';
import OnxCdnDistChart from '@/components/onx/charts/OnxCdnDistChart.vue';
import OnxCdnStackedBarCharts from '@/components/onx/charts/OnxCdnStackedBarCharts.vue';
import OnxCdnTrendChart from '@/components/onx/charts/OnxCdnTrendChart.vue';
import OnxCQThresholdTrendChart from '@/components/onx/charts/OnxCQThresholdTrendChart.vue';
import OnxDistChart from '@/components/onx/charts/OnxDistChart.vue';
import OnxGaugeChart from '@/components/onx/charts/OnxGaugeChart.vue';
import OnxTrendChart from '@/components/onx/charts/OnxTrendChart.vue';
import OnxMultiMetricTable from '@/components/onx/table/OnxMultiMetricTable.vue';
import OnxTable from '@/components/onx/table/OnxTable.vue';
import { TitlePlaceholders, usePlaceholderTitle } from '@/composables/usePlaceholderTitle';
import useQoEDetails from '@/composables/useQoEDetails';
import { Dashboards } from '@/constants/dashboards';
import exportFailuresToCsv from '@/focus/csv-export-overrides/exportFailuresToCsv';
import exportVideoResToCsv from '@/focus/csv-export-overrides/exportVideoResToCsv';
import { ChartTypesEnum } from '@/types/Charts';

import OnxDatasetTable from '@/components/onx/table/OnxDatasetTable.vue';
import useCheckIfMetricExists from '@/composables/useCheckIfMetricExists';

type Props = {
  dashboard: Dashboards;
  metric: ChartMetricDefinition;
  location: string | number;
  aggregation: string;
  connectionCategory: AvailableConnectionCategory['categoryValue'];
  connectionCategoryLabel?: AvailableConnectionCategory['categoryLabel'];
  deploymentType?: AvailableDeploymentType;
  geohashes: string[];
  operators: (Operator & { selected: boolean; disabled: boolean })[];
  mainOperator?: Operator;
  selectedConnectionCategories?: AvailableConnectionCategory[];
  bbox?: mapboxgl.LngLatBounds;
  enabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  enabled: true,
});

// TODO: move transformers elsewhere and remove "useQoEDetails".
const { transformFailureBreakdownMetrics, transformVideoResTime } = useQoEDetails();

const { connectionCategory, mainOperator, operators, selectedConnectionCategories } = toRefs(props);

const titleReplacements = computed(
  () =>
    ({
      operators,
      operator: mainOperator,
      connectionCategory: props.connectionCategoryLabel,
      deploymentType: props.deploymentType,
      connectionCategories: selectedConnectionCategories?.value
        ? selectedConnectionCategories.value.map((category) => category.categoryLabel)
        : [],
    }) as TitlePlaceholders,
);

const chartTitlePlaceholders = (props.metric.chartAttrs?.chartTitlePlaceholders || '') as string;
const { titleWithoutPlaceholders } = usePlaceholderTitle(chartTitlePlaceholders, titleReplacements);

const chartSubtitlePlaceholders = (props.metric.chartAttrs?.chartSubtitlePlaceholders || '') as string;
const { titleWithoutPlaceholders: subtitleWithoutPlaceholders } = usePlaceholderTitle(
  chartSubtitlePlaceholders,
  titleReplacements,
);

const chartTitleTooltip = props.metric.chartAttrs?.chartTitleTooltip as string | undefined;
const exportSubtitle = props.metric.exportAttrs?.subtitle as string | undefined;

const { checkIfMetricExists } = useCheckIfMetricExists();
</script>

<template>
  <template v-if="checkIfMetricExists(metric, connectionCategory)">
    <template v-if="metric.chartType === ChartTypesEnum.Trend || metric.chartType === ChartTypesEnum.HourlyTrend">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxTrendChart
            v-bind="metric.chartAttrs"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :location="location"
            :aggregation="aggregation"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :bbox="bbox"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :chart-tooltip-plugin="metric.tooltipPlugin"
            :operators="operators"
            :on-export-to-csv="metric.onExportCsv"
            :deployment-type="deploymentType"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.CdnBar">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`">
        <template #default="{ visible }">
          <OnxCdnBarChart
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="`Test`"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :key="`${metric.metricSubtype}_${connectionCategory}`"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :location="location"
            :aggregation="aggregation"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :bbox="bbox"
            :deployment-type="deploymentType"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.CdnTrend">
      <OnxCdnTrendChart
        :chart-title="chartTitlePlaceholders"
        :chart-subtitle="subtitleWithoutPlaceholders"
        :chart-title-tooltip="chartTitleTooltip"
        :title-replacements="titleReplacements"
        :screenshot-subtitle="exportSubtitle"
        :dashboard="dashboard"
        :key="`${metric.metricSubtype}_${connectionCategory}`"
        :metric="`${metric.metricSubtype}_${connectionCategory}`"
        :location="location"
        :geohashes="geohashes"
        :aggregation="aggregation"
        :bbox="bbox"
        :enabled="enabled"
        :operators="operators"
        :deployment-type="deploymentType"
      />
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.CdnBinned">
      <OnxCdnDistChart
        :chart-title="chartTitlePlaceholders"
        :chart-subtitle="subtitleWithoutPlaceholders"
        :chart-title-tooltip="chartTitleTooltip"
        :title-replacements="titleReplacements"
        :screenshot-subtitle="exportSubtitle"
        :dashboard="dashboard"
        :key="`${metric.metricSubtype}_${connectionCategory}`"
        :metric="`${metric.metricSubtype}_${connectionCategory}`"
        :location="location"
        :geohashes="geohashes"
        :aggregation="aggregation"
        :bbox="bbox"
        :deployment-type="deploymentType"
      />
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.Distribution">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`">
        <template #default="{ visible }">
          <OnxDistChart
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :location="location"
            :aggregation="aggregation"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            show-cumulative-line
            :bbox="bbox"
            :deploymentType="deploymentType"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-if="metric.chartType === ChartTypesEnum.ThresholdTrend">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`">
        <template #default="{ visible }">
          <OnxCQThresholdTrendChart
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :location="location"
            :aggregation="aggregation"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :bbox="bbox"
            :operators="operators"
            :deployment-type="deploymentType"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.VideoResStackedBar">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`">
        <template #default="{ visible }">
          <OnxBarChart
            v-bind="metric.chartAttrs"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :location="location"
            :aggregation="aggregation"
            :nbDays="0"
            :transform="transformVideoResTime(operators)"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :on-export-to-csv="exportVideoResToCsv"
            :max="100"
            :bbox="bbox"
            :deployment-type="deploymentType"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.FailureStackedBar">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`">
        <template #default="{ visible }">
          <OnxBarChart
            v-bind="metric.chartAttrs"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :location="location"
            :aggregation="aggregation"
            :nbDays="0"
            :geohashes="geohashes"
            :enabled="enabled && visible"
            :transform="transformFailureBreakdownMetrics(operators)"
            :on-export-to-csv="exportFailuresToCsv"
            :max="100"
            :bbox="bbox"
            :deployment-type="deploymentType"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.BarChart">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxBarChart
            v-bind="metric.chartAttrs"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :enabled="enabled && visible"
            :location="location"
            :aggregation="aggregation"
            :geohashes="[]"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :chart-tooltip-plugin="metric.tooltipPlugin"
            :on-export-to-csv="metric.onExportCsv"
            :bbox="bbox"
            :other-request-params="metric.requestParams"
            :deployment-type="deploymentType"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="metric.chartType === ChartTypesEnum.CdnIpStackedBar">
      <OnxCdnStackedBarCharts
        :chart-title="titleWithoutPlaceholders"
        :chart-subtitle="subtitleWithoutPlaceholders"
        :chart-title-tooltip="chartTitleTooltip"
        :screenshot-subtitle="exportSubtitle"
        :dashboard="dashboard"
        :metric="metric"
        :connection-category="connectionCategory"
        :selected-connection-categories="selectedConnectionCategories"
        :location="location"
        :aggregation="aggregation"
        :geohashes="[]"
        :operators="operators"
        :main-operator="mainOperator"
        :bbox="bbox"
      />
    </template>

    <template v-else-if="isDatasetChartMetricDefinition(metric)">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxDatasetTable
            :enabled="visible"
            :columns="metric.columns"
            :initial-sort-column-key="metric.initialSortColumnKey"
            :initial-sort-direction="metric.initialSortDirection"
            :dashboard="dashboard"
            :metric="metric.dataset"
            :location="location"
            :connection-categories="[connectionCategory]"
            :table-title="titleWithoutPlaceholders"
            :table-subtitle="subtitleWithoutPlaceholders"
            :table-tooltip="chartTitleTooltip"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="isTableChartMetricDefinition(metric)">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxTable
            v-bind="metric.chartAttrs"
            :table-title="titleWithoutPlaceholders"
            :table-subtitle="subtitleWithoutPlaceholders"
            :table-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :connection-category="connectionCategory"
            :enabled="enabled && visible"
            :location="location"
            :aggregation="aggregation"
            :bbox="bbox"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :on-export-to-csv="metric.onExportCsv"
            :columns="metric.columns"
            :initial-sort-column-key="metric.initialSortColumnKey"
            :initial-sort-direction="metric.initialSortDirection"
            :other-request-params="metric.requestParams"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="isMultiMetricTableChartMetricDefinition(metric)">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxMultiMetricTable
            v-bind="metric.chartAttrs"
            :table-title="titleWithoutPlaceholders"
            :table-subtitle="subtitleWithoutPlaceholders"
            :table-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :dashboard="dashboard"
            :metrics="metric.metricSubtypes!"
            :connection-category="connectionCategory"
            :enabled="enabled && visible"
            :location="location"
            :aggregation="aggregation"
            :bbox="bbox"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :columns="metric.columns"
          />
        </template>
      </IntersectionObserver>
    </template>

    <template v-else-if="isGaugeChartMetricDefinition(metric)">
      <IntersectionObserver :key="`${metric.metricSubtype}_${connectionCategory}`" :class="metric.chartContainerClass">
        <template #default="{ visible }">
          <OnxGaugeChart
            v-bind="metric.chartAttrs"
            :dashboard="dashboard"
            :chart-title="titleWithoutPlaceholders"
            :chart-subtitle="subtitleWithoutPlaceholders"
            :chart-title-tooltip="chartTitleTooltip"
            :screenshot-subtitle="exportSubtitle"
            :metric="`${metric.metricSubtype}_${connectionCategory}`"
            :location="location"
            :aggregation="aggregation"
            :geohashes="geohashes"
            :bbox="bbox"
            :enabled="enabled && visible"
            :transform="
              metric.transformData?.({ operators, mainOperator, connectionCategory, selectedConnectionCategories })
            "
            :other-request-params="metric.requestParams"
          />
        </template>
      </IntersectionObserver>
    </template>
  </template>
</template>
