import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';

import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';
import { METRIC_TYPES } from '@/constants/constants';

import transformGeohashCountByOperator from '@/chart-metric-definitions/data-transformers/transformGeohashCountByOperator';
import transformGeohashCountByConnectionCategory from '@/chart-metric-definitions/data-transformers/transformGeohashCountByConnectionCategory';
import transformGeohashCountByConnectionCategoryByOperator from '@/chart-metric-definitions/data-transformers/transformGeohashCountByConnectionCategoryByOperator';

import coverageConnectionCategoriesGeohashCountTooltip from '@/chart-metric-definitions/tooltips/coverageConnectionCategoriesGeohashCountTooltip';
import coverageSubcategoriesGeohashCountTooltipPlugin from '@/chart-metric-definitions/tooltips/coverageSubcategoriesGeohashCountTooltip';

import naiveId from '@/utils/naiveId';

import {
  exportCoverageCategoriesGeohashCountsToCsv,
  exportCoverageSubcategoriesGeohashCountsToCsv,
  exportIndividualCoverageCategoryGeohashCountsToCsv,
} from '@/focus/csv-export-overrides/exportCoverageCategoriesToCsv';

const onx360NetworkCoverageChartMetricDefinitions: Record<string, ChartMetricDefinition[]> = {
  overall: [
    {
      metricSubtype: MetricSubtypes.CoverageGeohash7CountConnectionCategory,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartContainerClass: 'sm:onx-col-3 md:onx-col-4 chart-height',
      chartAttrs: {
        chartTooltipShowSecondaryValue: true,
        chartTitlePlaceholders: 'Overall Coverage Geohash 7 Counts for :operator',
        chartTitleTooltip: 'Geohash bins categorized by dominant technology',
      },
      transformData: transformGeohashCountByConnectionCategory,
      get tooltipPlugin() {
        return coverageConnectionCategoriesGeohashCountTooltip({
          horizontal: !!this.chartAttrs?.horizontal,
        });
      },
      onExportCsv: exportCoverageCategoriesGeohashCountsToCsv,
    },
    {
      chartType: ChartTypesEnum.CoverageMap,
      // TODO: fix for map. This is a placeholder for now. The coverage map does not need a metric subtype.
      // The map does not behave like any other chart because it does not request a metric. Rather, the backend puts together the image(s) to be used
      // All it needs is to know where in the grid to pop up, but does its own thing after that as far as this definition goes
      metricSubtype: '' as any,
      id: `coverage-map-${naiveId()}`,
    },
    {
      metricSubtype: MetricSubtypes.CoverageGeohash7CountSubcategory,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        chartTooltipShowSecondaryValue: true,
        chartTitlePlaceholders: 'Overall Coverage Geohash 7 Counts',
        chartTitleTooltip: 'Geohash bins categorized by dominant technology sub-type',
      },
      chartContainerClass: 'onx-col-12',
      transformData: transformGeohashCountByOperator,
      get tooltipPlugin() {
        return coverageSubcategoriesGeohashCountTooltipPlugin(!!this.chartAttrs?.horizontal);
      },
      onExportCsv: exportCoverageSubcategoriesGeohashCountsToCsv,
    },
  ],
  individualConnectionCategoryCountsByOperator: [
    {
      metricSubtype: MetricSubtypes.CoverageGeohash7CountConnectionCategory,
      chartType: ChartTypesEnum.BarChart,
      chartAttrs: {
        horizontal: true,
        chartTooltipShowSecondaryValue: true,
        chartTitlePlaceholders: ':connectionCategory Coverage Geohash 7 Counts (%)',
        chartTitleTooltip: 'Percentage of dominant technology across operators in selected geography.',
        titleMetricUnit: '%',
      },
      transformData: transformGeohashCountByConnectionCategoryByOperator,
      get tooltipPlugin() {
        return coverageConnectionCategoriesGeohashCountTooltip({
          horizontal: !!this.chartAttrs?.horizontal,
          valueFn: ({ dataAxis, dataset }) => `${dataset.data[0][dataAxis].toFixed(2)}%`,
          secondaryValueFn: ({ dataset }) => dataset.data[0].count,
        });
      },
      onExportCsv: exportIndividualCoverageCategoryGeohashCountsToCsv,
    },
  ],
};

export default onx360NetworkCoverageChartMetricDefinitions;
