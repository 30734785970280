import breakpoints from '@/components/onx/composables/responsive/onxBreakpoints';
import useMatchMedia from '@/components/onx/composables/responsive/useMatchMedia';

const useBreakpoints = () => {
  const queries = {
    mobile: `(min-width: 0)`,
    phablet: `(min-width: ${breakpoints.sm})`,
    tablet: `(min-width: ${breakpoints.md})`,
    largeTablet: `(min-width: ${breakpoints.lg})`,
    laptop: `(min-width: ${breakpoints.xl})`,
    desktop: `(min-width: ${breakpoints.xxl})`,
  };

  const matchesMobile = useMatchMedia(queries.mobile);
  const matchesPhablet = useMatchMedia(queries.phablet);
  const matchesTablet = useMatchMedia(queries.tablet);
  const matchesLargeTablet = useMatchMedia(queries.largeTablet);
  const matchesLaptop = useMatchMedia(queries.laptop);
  const matchesDesktop = useMatchMedia(queries.desktop);

  return {
    mobile: matchesMobile,
    phablet: matchesPhablet,
    tablet: matchesTablet,
    lgTablet: matchesLargeTablet,
    laptop: matchesLaptop,
    desktop: matchesDesktop,
  };
};

export default useBreakpoints;
