import { computed } from 'vue';

const useModel = (props, emit, key = 'modelValue') => {
  return computed({
    get: () => props[key],
    set: (value) => emit('update:modelValue', value),
  });
};

export default useModel;
