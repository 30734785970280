import { getDatapointsByIndex, getTooltipState } from '@/utils/charts';

type ValueFn = (args: { datapoint: any; value: any }) => number | string;

const availabilityShareTooltipPlugin =
  ({ horizontal = false, valueFn }: { horizontal?: boolean; valueFn?: ValueFn }) =>
  (context: any, tooltip: any, chartData: any) => {
    const dataAxis = horizontal ? 'x' : 'y';

    const tt = context.tooltip;
    const tooltipState = getTooltipState(context.chart, tt);

    const datapoints = getDatapointsByIndex(
      chartData.value.datasets,
      chartData.value.labels.indexOf(tt.title[0]),
      horizontal,
    )
      .filter((d: any) => {
        return d.data[dataAxis] > 0;
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));

    const transformedDatapoints = datapoints.map((d: any) => {
      return {
        ...d,
        ...{ value: valueFn ? valueFn({ datapoint: d, value: d.value }) : d.value },
      };
    });

    if (datapoints.length) {
      tooltip.value = {
        ...tooltip.value,
        ...tooltipState,
        datapoints: transformedDatapoints,
        title: tt.title[0],
      };
    } else {
      tooltip.value.display = false;
    }
  };

export default availabilityShareTooltipPlugin;
