import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import mapboxgl from 'mapbox-gl';

const useMapBboxFilterStore = defineStore('bboxFilter', () => {
  const bboxFilter = ref<mapboxgl.LngLatBounds>();
  const updateResultsWhenMapMoves = ref(false);
  const resetKey = ref(0);

  const handleNewBounds = ({ bounds }: { bounds: mapboxgl.LngLatBounds }) => {
    const north = bounds.getNorth();
    const south = bounds.getSouth();
    const east = bounds.getEast();
    const west = bounds.getWest();

    const parsedEast = east - west < 360 ? east : 180;
    const parsedWest = east - west < 360 ? west : -180;

    const sw = new mapboxgl.LngLat(parsedWest, south);
    const ne = new mapboxgl.LngLat(parsedEast, north);
    bboxFilter.value = new mapboxgl.LngLatBounds(sw, ne);
  };
  const bboxReady = computed(() => !!bboxFilter.value);

  const bboxBasedOnUpdateResults = computed(() => {
    return updateResultsWhenMapMoves.value ? bboxFilter.value : undefined;
  });

  const reset = () => {
    if (updateResultsWhenMapMoves.value) {
      resetKey.value += 1;
    }

    bboxFilter.value = undefined;
    updateResultsWhenMapMoves.value = false;
  };

  return {
    handleNewBounds,
    bboxFilter,
    bboxBasedOnUpdateResults,
    bboxReady,
    updateResultsWhenMapMoves,
    reset,
    resetKey,
  };
});

export default useMapBboxFilterStore;
