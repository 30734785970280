import { allPossibleConnectionCategories } from '@/constants/allPossibleConnectionCategories';
import { METRIC_TYPES } from '@/constants/constants';

const exceptions = [METRIC_TYPES.TwoG, METRIC_TYPES.NoSignal, METRIC_TYPES.Emergency];
const allPossibleFocusConnectionCategories = allPossibleConnectionCategories.filter(
  (c) => !exceptions.includes(c.categoryValue),
);

export const defaultCategoryValue = METRIC_TYPES.FourG;

export default allPossibleFocusConnectionCategories;
