<script setup lang="ts">
import { ComponentPublicInstance, computed, toRefs, ref, unref } from 'vue';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import useMetric from '@/composables/useMetric';
import useFilters from '@/composables/useFilters';
import useEndDate from '@/composables/useEndDate';
import useMetricSource from '@/composables/useMetricSource';
import useAvailableCDNsByMetricStructure from '@/composables/useAvailableCDNsByMetricStructure';
import { getCdnBars } from '@/utils/viewHelpers';
import { BarChart } from '@/components/visual';
import { Dashboards } from '@/constants/dashboards';
import { METRIC_STRUCTURE_TO_VALUE_FIELD_MAP } from '@/constants/constants';
import { exportToCsv } from '@/utils/files';
import { OnxChartBaseProps } from '@/components/onx/charts/OnxChartBaseProps';
import { useMetricSQL } from '@/composables/useMetricSQL';
import ViewChartSqlButton from '@/components/visual/chart/ViewChartSqlButton.vue';

export type Props = OnxChartBaseProps & {
  enabled?: boolean;
  geohashes: string[];
  horizontal?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  enabled: undefined,
});

const { aggregation, bbox, chartTitle, chartTitleTooltip, deploymentType, enabled, geohashes, location, metric } =
  toRefs(props);
const { operators, showConfidenceIntervals } = useFilters(Dashboards.Focus);
const { currentEndDate } = useEndDate(Dashboards.Focus);
const container = ref<ComponentPublicInstance | null>(null);
const chartWidth = computed(() => {
  if (container.value) {
    return container.value.$el.offsetWidth;
  }

  return 0;
});

const {
  data: response,
  isLoading,
  isRefetching,
  isSuccess,
  status,
} = useMetric<MetricStructuresEnum.Breakdown>(props.dashboard, {
  metric,
  location,
  aggregation,
  endDate: currentEndDate,
  nbDays: 0,
  geohashes,
  bbox,
  deploymentType,
  enabled,
});

const { metricSource } = useMetricSource(response, isSuccess);
const { sql } = useMetricSQL(response, isSuccess);
const { availableCDNs } = useAvailableCDNsByMetricStructure(metric, response, status);

const dataProperty = props.horizontal ? 'x' : 'y';
const operatorProperty = props.horizontal ? 'y' : 'x';

const barSeries = computed(() => {
  if (isSuccess.value && availableCDNs.value.length > 0) {
    const data = response.value?.data;

    if (data?.operators) {
      const filteredOperators = Object.values(data.operators).filter((operator) => {
        if (operators.value.length === 0) {
          return true;
        }

        return operators.value?.includes(operator.canonical_network_id.toString());
      });

      const accessor = METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[data.structure];
      const cdns = unref(availableCDNs);

      const groupedBars = getCdnBars(
        filteredOperators,
        data.end_date,
        data.results,
        cdns,
        accessor,
        dataProperty,
        operatorProperty,
      );

      return groupedBars;
    }
  }

  return { labels: [], datasets: [] };
});

const barSeriesMax = computed(() => {
  if (!barSeries.value) {
    return 0;
  }

  const dataMax = barSeries.value.datasets?.reduce((acc, cur) => {
    cur.data.map((item: any) => {
      if (showConfidenceIntervals) {
        acc = Math.max(acc, item[dataProperty], item.lci || 0, item.uci || 0);
      } else {
        acc = Math.max(acc, item[dataProperty]);
      }
    });

    return acc;
  }, 0);

  return Math.ceil(dataMax * 1.05);
});

const onExportToCsv = () => {
  if (isLoading.value || !isSuccess.value) {
    return;
  }

  const datasets = barSeries.value?.datasets;
  const rows = datasets.flatMap((dataset) => {
    return dataset.data.map((datum: any) => {
      return [
        dataset.label,
        datum[operatorProperty],
        (datum[dataProperty] || 0).toFixed(2),
        (datum.lci || 0).toFixed(2),
        (datum.uci || 0).toFixed(2),
      ];
    });
  });
  const headers = ['Cdn', 'Operator', 'Mean', 'LCI', 'UCI'];
  exportToCsv(`${chartTitle.value}.csv`, [headers, ...rows]);
};
const screenshotLegend = computed(() => {
  if (!barSeries.value || !barSeries.value.datasets) {
    return [];
  }

  return barSeries.value.datasets
    .filter((dataset) => {
      return typeof dataset.backgroundColor === 'string' && dataset.label;
    })
    .map((dataset) => {
      return (
        dataset.meta?.imageExportLegend || {
          color: dataset.backgroundColor as string,
          label: dataset.label as string,
        }
      );
    });
});
</script>

<template>
  <OnxChartContainer
    class="onx-cdn-bar"
    ref="container"
    :no-data="barSeries?.labels?.length === 0"
    :loading="isLoading || isRefetching"
    :metric-source="metricSource || undefined"
    :enable-csv-export="true"
    :onExportToCsv="onExportToCsv"
    :title="chartTitle"
    :subtitle="chartSubtitle"
    :chart-tooltip="chartTitleTooltip"
    :screenshot-legend="screenshotLegend"
    :screenshot-title="screenshotTitle"
    :screenshot-subtitle="screenshotSubtitle"
  >
    <BarChart
      v-if="isSuccess"
      :chart-data="barSeries"
      :chart-id="`cdn-${metric}`"
      :width="chartWidth"
      :height="350"
      :max="barSeriesMax"
      :show-legend="true"
      :show-confidence-intervals="showConfidenceIntervals"
      :orientation="props.horizontal ? 'horizontal' : 'vertical'"
    />
    <template #tools v-if="sql">
      <ViewChartSqlButton :sql="sql" :chartTitle="chartTitle" />
    </template>
  </OnxChartContainer>
</template>

<style lang="scss"></style>
