<script setup>
import { watch, ref, computed, onMounted } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { useActions, useGetters, createNamespacedHelpers } from 'vuex-composition-helpers';
import CompetitiveTile from '@/components/comparison/CompetitiveTile';
import { CompetitiveOverviewLayout } from '@/components/competitive';
import AwardIndicator from '@/components/competitive/AwardIndicator';
import HtmlImageExport from '@/components/HtmlImageExport';
import LoaderGrid from '@/components/LoaderGrid';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import useMetricBoxData from '@/components/onx/composables/useMetricBoxData';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon';
import OnxMetricIcon from '@/components/onx/icons/metrics/OnxMetricIcon';
import OnxButton from '@/components/onx/OnxButton';
import { OnxList, OnxListItem, OnxListItemText } from '@/components/onx/OnxList';
import OnxPaper from '@/components/onx/OnxPaper';
import OnxHeadline from '@/components/onx/typography/OnxHeadline';
import ROUTES from '@/constants/routes';
import { isAwardMetric } from '@/utils/metrics';
import { CustomTooltip } from '@/components/tooltip';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';

const route = useRoute();
const router = useRouter();
const matches = useBreakpoints();

const { setConnectionStats, setRouteParam } = useActions(['setRouteParam', 'setConnectionStats']);

const { ciConfig } = useGetters(['ciConfig']);
const { connectionStats: stats } = useGetters(['connectionStats']);
const { useGetters: useCompetitiveGetters } = createNamespacedHelpers(['competitive']);
const { useGetters: useLocationGetters } = createNamespacedHelpers(['location']);
const { useGetters: useAuthGetters } = createNamespacedHelpers(['auth']);

const connections = ['overall', '3glte5g', '5g', '5gmmwave', 'lte', '3g', '2g', 'wifi'];

let shouldFetch = true;

const { useGetters: useMetricsGetters } = createNamespacedHelpers(['metrics']);

const connectionStatsStore = useGetters(['connectionStats/chartPending', 'connectionStats/metric']);

const isPending = computed(() => {
  return connectionStatsStore['connectionStats/chartPending'];
});

const exportTitle = computed(() => {
  const { currentLocation } = useLocationGetters(['currentLocation']);
  const { getLastDateAvailable } = useAuthGetters(['getLastDateAvailable']);

  return `By connection ${currentLocation.value.name} ${getLastDateAvailable.value.substring(0, 10)}`;
});

const fetchData = () => {
  if (router.currentRoute.value.name !== ROUTES.CompetitiveConnectionCategory) {
    return;
  }

  if (!shouldFetch) {
    setRouteParam({ compareTo: route.params.compareTo });
    shouldFetch = true;

    return;
  }

  setRouteParam(route.params);

  setConnectionStats({
    ...router.currentRoute.value.params,
    metricString: connections.map((cc) => `${router.currentRoute.value.params.metric}_${cc}`).join(','),
  });
};

const { createMetricBoxData } = useMetricBoxData('connectionStats');
const { getOrderedValues } = useCompetitiveGetters(['getOrderedValues']);
const { allMetrics } = useMetricsGetters(['allMetrics']);

const tiles = computed(() => {
  if (!router.currentRoute.value.params.metric || !allMetrics.value) {
    return [];
  }

  const boxes = connections
    .map((connection) => {
      const metricKey = `${router.currentRoute.value.params.metric}_${connection}`;

      const metricObject = allMetrics.value.find((m) => {
        return m.key === metricKey;
      });

      if (!metricObject) {
        return;
      }

      const ranksSource = getOrderedValues.value(stats.value, metricObject);

      return createMetricBoxData(
        ranksSource,
        {
          ...metricObject,
          isAwardMetric: isAwardMetric(metricObject),
        },
        ciConfig.value.compareTo,
      );
    })
    .filter((b) => !!b && b.ranks.length);

  return boxes;
});

const goToOverview = () => {
  const { location } = route.params;
  router.push({ name: ROUTES.CompetitiveOverview, params: { location, compareTo: route.params.compareTo } });
};

onMounted(() => {
  fetchData();
});

watch(
  () => router.currentRoute.value.params,
  () => {
    fetchData();
  },
);

onBeforeRouteUpdate((to, from) => {
  shouldFetch = to.params.compareTo === from.params.compareTo;
});

const navigate = (metric) => {
  setRouteParam({ metric });

  router.push({
    name: ROUTES.CompetitiveDetails,
    params: {
      ...router.currentRoute.value.params,
      metric,
    },
  });
};

const htmlImageExportRef = ref(null);

const onHtmlImageExportListItemClick = () => {
  htmlImageExportRef.value.print();
};
</script>

<template>
  <div class="ConnectionCategory">
    <CompetitiveOverviewLayout :number-of-tiles="tiles.length">
      <template #header="headerProps">
        <OnxSpotlightHeaderNav @breadcrumbs-home-click="goToOverview">
          <template #actions>
            <HtmlImageExport
              v-show="matches.desktop.value"
              ref="htmlImageExportRef"
              :reference="headerProps.htmlImageExportData.spotlightParent.ref"
              :height="headerProps.htmlImageExportData.exportHeight"
              :title="exportTitle"
            />

            <VDropdown
              v-if="matches.mobile.value && !matches.desktop.value"
              :distance="6"
              class="onx-navigation-header__settings"
            >
              <span>
                <MenuDotsIcon button />
              </span>

              <template #popper>
                <OnxPaper :depth="3">
                  <OnxList>
                    <OnxListItem extra-x-padding @click.prevent="onHtmlImageExportListItemClick">
                      <OnxListItemText size="sm"> Save as image </OnxListItemText>
                      <template #right>
                        <DownloadFileIcon />
                      </template>
                    </OnxListItem>
                  </OnxList>
                </OnxPaper>
              </template>
            </VDropdown>
          </template>
        </OnxSpotlightHeaderNav>
      </template>

      <template #competitiveTiles>
        <template v-if="!isPending">
          <LoaderGrid class="loader" />
        </template>

        <template v-else>
          <template v-if="tiles.length">
            <CompetitiveTile
              v-for="tile in tiles"
              :key="tile.metricKind"
              :metric-type="tile.metricType"
              :metric-kind="tile.metricKind"
              :metric-unit="tile.metricUnit"
              :metric-category="tile.metricCategory"
              :bigger-better="tile.biggerBetter"
              :title="tile.title"
              :ranks="tile.ranks"
              :options="tile.options"
              :granularity="tile.granularity"
              :is-loading="false"
              @navigate="navigate(tile.metric)"
            >
              <template #title>
                <CustomTooltip
                  :message="TOOLTIP_MESSAGES[`spotlight_${tile.metricKind}_${tile.metricType}`]"
                  placement="top"
                >
                  <OnxMetricIcon :metric-kind="tile.metricKind" class="Card__header__metric-icon" />
                </CustomTooltip>

                <div class="Card__headerTitle">
                  <OnxHeadline as="h4">
                    {{ tile.title }}
                    <AwardIndicator v-if="tile.isAwardMetric" :userGroup="tile.metricUserGroup" />
                  </OnxHeadline>
                </div>
              </template>

              <template #actions>
                <OnxButton variant="tertiary" size="sm" @click="navigate(tile.metric)">
                  <span>By geography</span>
                  <ChevronRightIcon small />
                </OnxButton>
              </template>
            </CompetitiveTile>
          </template>
        </template>
      </template>
    </CompetitiveOverviewLayout>
  </div>
</template>

<style lang="scss">
.ConnectionCategory {
  .onx-metric-box__header {
    min-height: 170px;
  }

  .onx-headline {
    display: flex;
    align-items: center;
  }
}
</style>
