<template>
  <ul class="RadioButtons">
    <li v-for="option in options" :key="`RadioButtons-${option[valueProp]}`">
      <OnxRadioButton
        v-model="vModel"
        :name="groupName"
        :value="option[valueProp]"
        :label="option[labelProp]"
        @click="onSelect(option)"
      />
    </li>
  </ul>
</template>

<script>
import useModel from '@/components/onx/composables/useModel';
import OnxRadioButton from '@/components/onx/OnxRadioButton';

export default {
  name: 'RadioButtons',
  components: {
    OnxRadioButton,
  },
  props: {
    groupName: {
      type: String,
      required: true,
    },
    options: { type: Array, default: () => [] },
    selectedOption: { type: [String, Number], default: '' },
    valueProp: { type: String, default: 'value' },
    labelProp: { type: String, default: 'label' },
  },
  setup(props, context) {
    const vModel = useModel(props, context.emit, 'selectedOption');
    return {
      vModel,
    };
  },
  methods: {
    onSelect(option) {
      this.$emit('select', option[this.valueProp]);
    },
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;
@import 'scss/components';

.RadioButtons {
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
  padding: 0;
}

.RadioButtons__item {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding-top: 2px;
}

.RadioButton__input {
  display: grid;
  place-content: center;
  margin: 0 pxToRem(10) 0 0;
  color: var(--onx-radio-input-color);
  width: pxToRem(12);
  height: pxToRem(12);
  border: 1px solid var(--onx-radio-input-color);
  border-radius: 50%;
  appearance: none;
  cursor: pointer;

  &::after {
    content: '';
    width: pxToRem(6);
    height: pxToRem(6);
    border-radius: 50%;
    transform: scale(0);
    transition: transition();
    box-shadow: inset 1em 1em var(--onx-radio-input-color);
  }

  &:checked {
    border-color: var(--onx-radio-input-active-color);

    &::after {
      box-shadow: inset 1em 1em var(--onx-radio-input-active-color);
      transform: scale(1);
    }

    ~ .RadioButton__label {
      color: var(--onx-radio-label-active-color);
    }
  }

  // &:focus {
  //   outline: max(2px, 0.15em) solid var(--onx-radio-input-active-color);
  //   outline-offset: max(2px, 0.15em);
  // }
}

.RadioButton__label {
  font-size: $font-size-12;
  line-height: $font-size-14;
  color: var(--onx-radio-label-color);
  cursor: pointer;
}
</style>
