import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';
import transformCdnIpDownload360Trend from '@/chart-metric-definitions/data-transformers/transformCdnIpDownload360Trend';
import exportCdnIpSpeedTrendToCsv from '@/360/csv-export-overrides/exportCdnIpSpeedTrendToCsv';
import exportCdnIpLiveVideoResolutionToCsv from '@/360/csv-export-overrides/exportCdnIpLiveVideoResolutionToCsv';
import transformCdnIpVideoTimeOnRes360ByIPByOperator from '@/chart-metric-definitions/data-transformers/transformCdnIpVideoTimeOnRes360ByIPByOperator';
import lineChartLegendCdnListPlugin from '@/components/visual/chart/line-chart-plugins/lineChartLegendCdnListPlugin';
import videoLiveTimeOnResolutionByIPTooltip from '@/chart-metric-definitions/tooltips/videoLiveTimeOnResolutionByIPTooltip';

import {
  videoTtffColumns,
  videoStallingTimeColumns,
  videoStallingOccurrenceColumns,
  videoThroughputColumns,
  videoTimeOnResColumns,
} from '@/360/core/table-columns/videoIpColumns';
import { commonDatasetTransformer } from '@/360/core/table-columns/commonDatasetTransformer';

const cdnIpTrendChartAttrs = {
  lineChartAttrs: {
    spanGaps: false,
    useCustomLegend: true,
    plugins: [lineChartLegendCdnListPlugin()],
  },
};

const onx360CoreEndpointIPVideoLiveMetricDefinitions: ChartMetricDefinition[] = [
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveStallTime360Trend,
    chartType: ChartTypesEnum.Trend,
    transformData: transformCdnIpDownload360Trend,
    onExportCsv: exportCdnIpSpeedTrendToCsv,
    chartAttrs: {
      ...cdnIpTrendChartAttrs,
      chartTitlePlaceholders: ':connectionCategory Live Video Stalling Time (ms) Trend by IP for :operator',
    },
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveStalling360Trend,
    chartType: ChartTypesEnum.Trend,
    transformData: transformCdnIpDownload360Trend,
    onExportCsv: exportCdnIpSpeedTrendToCsv,
    chartAttrs: {
      ...cdnIpTrendChartAttrs,
      chartTitlePlaceholders: ':connectionCategory Live Video Stalling Occurrence (%) Trend by IP for :operator',
    },
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveTtff360Trend,
    chartType: ChartTypesEnum.Trend,
    transformData: transformCdnIpDownload360Trend,
    onExportCsv: exportCdnIpSpeedTrendToCsv,
    chartAttrs: {
      ...cdnIpTrendChartAttrs,
      chartTitlePlaceholders: ':connectionCategory Live Video Time to First Frame (s) Trend by IP for :operator',
    },
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveThroughputRate360Trend,
    chartType: ChartTypesEnum.Trend,
    transformData: transformCdnIpDownload360Trend,
    onExportCsv: exportCdnIpSpeedTrendToCsv,
    chartAttrs: {
      ...cdnIpTrendChartAttrs,
      chartTitlePlaceholders: ':connectionCategory Live Video Throughput Rate (Mbps) Trend by IP for :operator',
    },
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveTimeOnRes,
    chartType: ChartTypesEnum.BarChart,
    transformData: transformCdnIpVideoTimeOnRes360ByIPByOperator,
    onExportCsv: exportCdnIpLiveVideoResolutionToCsv,
    chartContainerClass: 'onx-grid-item__full-width',
    chartAttrs: {
      nbDays: 0,
      chartTitlePlaceholders: ':connectionCategory Live Video Time on Resolution (%) by IP for :operator',
    },
    get tooltipPlugin() {
      return videoLiveTimeOnResolutionByIPTooltip(!!this.chartAttrs?.horizontal);
    },
  },

  // dataset tables
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveTtff360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_videolive_ttff',
    chartContainerClass: 'onx-grid-item__full-width',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Live Video Time to First Frame (s) by IP',
    },
    columns: videoTtffColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveStallTime360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_videolive_stalltime',
    chartContainerClass: 'onx-grid-item__full-width',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Live Video Stalling Time (ms) by IP',
    },
    columns: videoStallingTimeColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveStallingOccurrence360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_videolive_stalling',
    chartContainerClass: 'onx-grid-item__full-width',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Live Video Stalling Occurrence (%) by IP',
    },
    columns: videoStallingOccurrenceColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveThroughput360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_videolive_throughput',
    chartContainerClass: 'onx-grid-item__full-width',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Live Video Throughput Rate (Mbps) by IP',
    },
    columns: videoThroughputColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
  {
    metricSubtype: MetricSubtypes.CdnIpVideoLiveTimeOnRes360,
    chartType: ChartTypesEnum.Dataset,
    dataset: 'onx360_cdnip_videolive_restime',
    chartContainerClass: 'onx-grid-item__full-width',
    chartAttrs: {
      chartTitlePlaceholders: ':connectionCategory Live Video Time On Resolution (%) by IP',
    },
    columns: videoTimeOnResColumns,
    transformData: commonDatasetTransformer,
    initialSortColumnKey: 'percentage',
    initialSortDirection: 'desc',
  },
];

export default onx360CoreEndpointIPVideoLiveMetricDefinitions;
