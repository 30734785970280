import { watchEffect, computed } from 'vue';
import { useRoute } from 'vue-router';
import { createNamespacedHelpers, useActions } from 'vuex-composition-helpers';

import { Dashboards } from '@/constants/dashboards';
import useDashboardInfo from '@/composables/useDashboardInfo';

const useLocations = (dashboard: Dashboards) => {
  const { setAsCurrentLocation } = useActions(['setAsCurrentLocation']);
  const { useGetters: useLocationGetters } = createNamespacedHelpers('location');
  const { currentCountry, currentLocation } = useLocationGetters(['currentLocation', 'currentCountry']);
  const { data: dashboardInfoResponse, isSuccess } = useDashboardInfo(dashboard);

  const route = useRoute();

  watchEffect(() => {
    if (route.query.location) {
      setAsCurrentLocation(route.query.location);
    } else if (isSuccess.value) {
      const dashboardInfoData = dashboardInfoResponse.value?.data;
      setAsCurrentLocation(dashboardInfoData?.default_country ?? dashboardInfoData?.countries_visible_full[0].id);
    }
  });

  const locationId = computed(() => {
    return parseInt(currentLocation.value?.key || route.query.location, 10);
  });

  const geocoding = computed(() => {
    return parseInt(currentLocation.value?.granularityId, 10);
  });

  const countryIso3 = computed(() => {
    return currentCountry.value?.iso3;
  });

  return {
    setAsCurrentLocation,
    locationId,
    currentLocation,
    currentCountry,
    geocoding,
    countryIso3,
  };
};

export default useLocations;
