<template>
  <div class="BarChartElement">
    <div class="BarChartElement__bar">
      <div :style="uciBarStyle" class="BarChartElement__uci-bar" />
      <div :style="valueBarStyle" class="BarChartElement__value-bar" />
      <div v-if="lci && uci" :style="confidenceIntervalStyle" class="BarChartElement__confidenceInterval">
        <div class="BarChartElement__confidenceInterval-inner">
          <div class="BarChartElement__confidenceInterval-line" />
          <div class="BarChartElement__confidenceInterval-left" />
          <div class="BarChartElement__confidenceInterval-right" />
        </div>

        <span class="BarChartElement__confidenceInterval__values" :style="confidenceIntervalValuesPosition">
          {{ lci }} / {{ uci }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarChartElement',
  props: {
    value: {
      type: Number,
      default: undefined,
      validator: (a) => Number.isFinite(a),
    },
    lci: {
      type: Number,
      default: undefined,
      validator: (a) => Number.isFinite(a),
    },
    uci: {
      type: Number,
      default: undefined,
      validator: (a) => Number.isFinite(a),
    },
    min: {
      type: Number,
      required: true,
      validator: (a) => Number.isFinite(a),
    },
    max: {
      type: Number,
      required: true,
      validator: (a) => Number.isFinite(a),
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    absoluteValue() {
      return this.value - this.min;
    },
    absoluteLci() {
      return this.lci - this.min;
    },
    absoluteUci() {
      return this.uci - this.min;
    },
    absoluteMaxValue() {
      return this.max - this.min;
    },
    valueBarStyle() {
      return {
        width: `${(this.absoluteValue / this.absoluteMaxValue) * 100}%`,
        backgroundColor: this.color,
      };
    },
    uciBarStyle() {
      return {
        width: `${(this.absoluteUci / this.absoluteMaxValue) * 100}%`,
      };
    },
    confidenceIntervalStyle() {
      const leftPerc = (this.absoluteLci / this.absoluteMaxValue) * 100;
      const widthPerc = ((this.absoluteUci - this.absoluteLci) / this.absoluteMaxValue) * 100;
      return {
        left: `${Math.abs(leftPerc)}%`,
        width: `${Math.abs(widthPerc)}%`,
      };
    },
    confidenceIntervalValuesPosition() {
      const valueBarWidth = (this.absoluteValue / this.absoluteMaxValue) * 100;

      return { transform: `translateX(-${valueBarWidth}%)` };
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';

$confidence-interval-color: rgba(0, 0, 0, 0.1);

.BarChartElement {
  &__bar {
    position: relative;
    height: 20px;
  }
  &__uci-bar {
    background-color: $confidence-interval-color;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__value-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__confidenceInterval {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
  }
  &__confidenceInterval-inner {
    height: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.1);
  }
  &__confidenceInterval-line {
    border-top: 1px solid var(--dark-grey);
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
  &__confidenceInterval-left,
  &__confidenceInterval-right {
    width: 1px;
    position: absolute;
    // Container height of 20px - 4px (top) - 4px (bottom) = 12px line height
    top: 4px;
    bottom: 4px;
  }
  &__confidenceInterval-right {
    border-right: 1px solid var(--dark-grey);
    right: 0;
  }
  &__confidenceInterval-left {
    border-left: 1px solid var(--dark-grey);
    left: 0;
  }

  &__confidenceInterval__values {
    position: absolute;
    bottom: -14px;

    font-size: pxToRem(10);
    color: var(--dark-grey);

    white-space: nowrap;
  }
}
</style>
