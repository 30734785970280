import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useQueryParamMultiSelect from '@/composables/useQueryParamMultiSelect';

const useGeohashesQueryParam = () => {
  const router = useRouter();

  const { onChange, selectedValues, ...rest } = useQueryParamMultiSelect<string>('geohashes', ref([]), {
    allowEmpty: true,
  });

  const clearGeohashes = () => {
    router.push({ query: { ...router.currentRoute.value.query, geohashes: [] } });
  };

  return {
    geohashes: selectedValues,
    onGeohashChange: onChange,
    clearGeohashes,
    ...rest,
  };
};

export default useGeohashesQueryParam;
