<script setup>
import { provide, computed } from 'vue';

const props = defineProps({
  small: {
    type: Boolean,
    default: false,
  },
  largerActiveTab: {
    type: Boolean,
    default: false,
  },
  grow: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change']);
const onTabChange = (id, value) => {
  emit('change', id, value);
};

provide('tabs:onTabChange', onTabChange);
provide(
  'tabs:small',
  computed(() => props.small),
);
provide(
  'tabs:largerActiveTab',
  computed(() => props.largerActiveTab),
);
provide(
  'tabs:grow',
  computed(() => props.grow),
);
</script>

<template>
  <div class="onx-tabs">
    <slot />
  </div>
</template>

<style lang="scss">
@import 'scss/mixins';

.onx-tabs {
  display: flex;
  align-items: center;
  padding: 0 16px;
  overflow-x: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
