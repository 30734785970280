import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';

const coreHealthByCdnChartMetricDefinitions: Record<string, ChartMetricDefinition[]> = {
  download: [
    {
      metricSubtype: MetricSubtypes.CdnDownload,
      label: 'Download Speed',
      chartGroup: MetricSubtypes.Download,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Download Speed (Mbps) for :cdn',
      },
    },
  ],
  upload: [
    {
      metricSubtype: MetricSubtypes.CdnUpload,
      label: 'Upload Speed',
      chartGroup: MetricSubtypes.Upload,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Upload Speed (Mbps) for :cdn',
      },
    },
  ],
  gamesexperience: [],
  gamespacketloss: [
    {
      metricSubtype: MetricSubtypes.CpGamesPacketLoss,
      label: 'Packet Loss',
      chartGroup: MetricSubtypes.GamesPacketLoss,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Packet Loss (%) Trend for :cdn',
      },
    },
  ],
  gamesjitter: [
    {
      metricSubtype: MetricSubtypes.CpGamesJitter,
      label: 'Jitter',
      chartGroup: MetricSubtypes.GamesJitter,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Jitter (ms) Trend for :cdn',
      },
    },
  ],
  gamesrtt: [
    {
      metricSubtype: MetricSubtypes.CpGamesPacketDelay,
      label: 'Packet Delay',
      chartGroup: MetricSubtypes.GamesPacketDelay,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Packet Delay (ms) Trend for :cdn',
      },
    },
  ],
  ecq: [],
  ecqdownload: [
    {
      metricSubtype: MetricSubtypes.CdnEcqDownloadThroughput,
      label: 'Download Throughput',
      chartGroup: MetricSubtypes.EcqDownloadThroughput,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Download Throughput (Mbps) Trend for :cdn',
      },
    },
  ],
  ecqupload: [
    {
      metricSubtype: MetricSubtypes.CdnEcqUploadThroughput,
      label: 'Upload Throughput',
      chartGroup: MetricSubtypes.EcqUploadThroughput,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Upload Throughput (Mbps) Trend for :cdn',
      },
    },
  ],
  ecqicmplatency: [
    {
      metricSubtype: MetricSubtypes.CdnEcqIcmpLatency,
      label: 'ICMP Latency',
      chartGroup: MetricSubtypes.EcqIcmpLatency,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ ICMP Latency (ms) Trend for :cdn',
      },
    },
  ],
  ecqjitter: [
    {
      metricSubtype: MetricSubtypes.CdnEcqJitter,
      label: 'Jitter',
      chartGroup: MetricSubtypes.EcqJitter,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Jitter (ms) Trend for :cdn',
      },
    },
  ],
  ecqpacketloss: [
    {
      metricSubtype: MetricSubtypes.CdnEcqPacketLoss,
      label: 'Packet Loss',
      chartGroup: MetricSubtypes.EcqPacketLoss,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Packet Loss (%) Trend for :cdn',
      },
    },
  ],
  ecqlatency: [
    {
      metricSubtype: MetricSubtypes.CdnEcqLatency,
      label: 'Latency',
      chartGroup: MetricSubtypes.EcqLatency,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Latency (ms) Trend for :cdn',
      },
    },
  ],
  ecqdownloadtimefirstbyte: [
    {
      metricSubtype: MetricSubtypes.CdnEcqDownloadTimeFirstByte,
      label: 'Time to First Byte',
      chartGroup: MetricSubtypes.EcqDownloadTimeFirstByte,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Time to First Byte (ms) Trend for :cdn',
      },
    },
  ],
  ccq: [],
  ccqdownload: [
    {
      metricSubtype: MetricSubtypes.CdnCcqDownloadThroughput,
      label: 'Download Throughput',
      chartGroup: MetricSubtypes.CcqDownloadThroughput,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Download Throughput (Mbps) Trend for :cdn',
      },
    },
  ],
  ccqupload: [
    {
      metricSubtype: MetricSubtypes.CdnCcqUploadThroughput,
      label: 'Upload Throughput',
      chartGroup: MetricSubtypes.CcqUploadThroughput,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Upload Throughput (Mbps) Trend for :cdn',
      },
    },
  ],
  ccqicmplatency: [
    {
      metricSubtype: MetricSubtypes.CdnCcqIcmpLatency,
      label: 'ICMP Latency',
      chartGroup: MetricSubtypes.CcqIcmpLatency,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ ICMP Latency (ms) Trend for :cdn',
      },
    },
  ],
  ccqjitter: [
    {
      metricSubtype: MetricSubtypes.CdnCcqJitter,
      label: 'Jitter',
      chartGroup: MetricSubtypes.CcqJitter,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Jitter (ms) Trend for :cdn',
      },
    },
  ],
  ccqpacketloss: [
    {
      metricSubtype: MetricSubtypes.CdnCcqPacketLoss,
      label: 'Packet Loss',
      chartGroup: MetricSubtypes.CcqPacketLoss,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Packet Loss (%) Trend for :cdn',
      },
    },
  ],
  ccqlatency: [
    {
      metricSubtype: MetricSubtypes.CdnCcqLatency,
      label: 'Latency',
      chartGroup: MetricSubtypes.CcqLatency,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Latency (ms) Trend for :cdn',
      },
    },
  ],
  ccqdownloadtimefirstbyte: [
    {
      metricSubtype: MetricSubtypes.CdnCcqDownloadTimeFirstByte,
      label: 'Time to First Byte',
      chartGroup: MetricSubtypes.CcqDownloadTimeFirstByte,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Time to First Byte (ms) Trend for :cdn',
      },
    },
  ],
  videoexperience: [
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrExperience,
      label: 'Video Experience',
      chartGroup: MetricSubtypes.VideoExperience,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Experience (0 - 100) Trend for :cdn',
      },
    },
  ],
  videoabrthroughput: [
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrThroughput,
      label: 'Video Throughput',
      chartGroup: MetricSubtypes.VideoAbrThroughput,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Throughput Rate (Mbps) Trend for :cdn',
      },
    },
  ],
  videoabrstalling: [
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrStallingOccurrence,
      label: 'Video Stalling Occurrence',
      chartGroup: MetricSubtypes.VideoAbrStallingOccurrence,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Stalling Occurrence (%) Trend for :cdn',
      },
    },
  ],
  videoabrstalltime: [
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrStallingTime,
      label: 'Video Stalling Time',
      chartGroup: MetricSubtypes.VideoAbrStallingTime,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Stalling Time (ms) Trend for :cdn',
      },
    },
  ],
  videoabrttff: [
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrTtff,
      label: 'Video Time to First Frame',
      chartGroup: MetricSubtypes.VideoAbrTtff,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Time to First Frame (s) Trend for :cdn',
      },
    },
  ],
  videoabrrestime: [
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrResTime,
      label: 'Video Resolution Time',
      structure: MetricStructuresEnum.ResolutionBreakdown,
      chartGroup: MetricSubtypes.VideoAbrResTime,
      chartType: ChartTypesEnum.OperatorBar,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Resolution Time for :operator-:cdn (%)',
      },
    },
  ],
  voiceexperience: [
    {
      metricSubtype: MetricSubtypes.CpVoiceExperience,
      label: 'Voice Experience',
      chartGroup: MetricSubtypes.VoiceExperience,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice App Experience (0 - 100) Trend for :cdn',
      },
    },
  ],
  videoliveexperience: [
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoExperience,
      label: 'Live Video Experience',
      chartGroup: MetricSubtypes.LiveVideoExperience,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Experience (0 - 100) Trend for :cdn',
      },
    },
  ],
  videolivethroughput: [
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoThroughput,
      label: 'Live Video Throughput',
      chartGroup: MetricSubtypes.LiveVideoThroughput,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Throughput Rate (Mbps) Trend for :cdn',
      },
    },
  ],
  videolivestalling: [
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoStallingOccurrence,
      label: 'Live Video Stalling Occurrence',
      chartGroup: MetricSubtypes.LiveVideoStallingOccurrence,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Stalling Occurrence (%) Trend for :cdn',
      },
    },
  ],
  videolivestalltime: [
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoStallingTime,
      label: 'Live Video Stalling Time',
      chartGroup: MetricSubtypes.LiveVideoStallingTime,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Stalling Time (ms) Trend for :cdn',
      },
    },
  ],
  videolivettff: [
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoTtff,
      label: 'Live Video Time to First Frame',
      chartGroup: MetricSubtypes.LiveVideoTtff,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Time to First Frame (s) Trend for :cdn',
      },
    },
  ],
  videoliverestime: [
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoResTime,
      label: 'Live Video Resolution Time',
      structure: MetricStructuresEnum.ResolutionBreakdown,
      chartGroup: MetricSubtypes.LiveVideoResTime,
      chartType: ChartTypesEnum.OperatorBar,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Resolution Time for :operator-:cdn (%)',
      },
    },
  ],
  videoliveoffset: [
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoOffset,
      label: 'Live Video Offset',
      chartGroup: MetricSubtypes.LiveVideoOffset,
      chartType: ChartTypesEnum.CdnTrend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Average Offset (s) Trend for :cdn',
      },
    },
  ],
};

export default coreHealthByCdnChartMetricDefinitions;
