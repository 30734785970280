import { computed, ref } from 'vue';

const percentile = ref(95);
export const usePercentileControl = () => {
  return {
    percentile: computed<number>({
      get: () => percentile.value,
      set: (value: number) => {
        percentile.value = value;
      },
    }),
    getPercentileCutoff: (data: number[], percentile: number) => {
      const total = data.reduce((acc, curr) => acc + curr, 0);
      const limit = (total * percentile) / 100;
      let index = 0;
      for (let runningTotal = 0; index < data.length; index++) {
        runningTotal += data[index];
        if (runningTotal >= limit) {
          break;
        }
      }
      return index;
    },
  };
};
