<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ThreeSixtyHeader from '@/360/ThreeSixtyHeader.vue';
import Routes from '@/constants/routes';
import { OnxTab, OnxTabs, OnxTabsContext } from '@/components/onx/tabs';
import use360TabAvailability from '@/360/use360TabAvailability';

const route = useRoute();
const router = useRouter();

const { firstAvailableSubtabForCore, tabAvailability } = use360TabAvailability();

onMounted(() => {
  if (route.name === Routes.ThreeSixtyCoverage) {
    router.push({
      name: firstAvailableSubtabForCore.value,
    });
  }
});
</script>

<template>
  <ThreeSixtyHeader>
    <template #secondary-tabs="{ onTabChange }">
      <OnxTabsContext :selected-tab="route.matched?.[2]?.name">
        <OnxTabs @change="onTabChange">
          <OnxTab
            v-if="tabAvailability[Routes.ThreeSixtyCoreEndpointIPSpeed]"
            :id="Routes.ThreeSixtyCoreEndpointIPSpeed"
          >
            Endpoint IP Speed
          </OnxTab>
          <OnxTab
            v-if="tabAvailability[Routes.ThreeSixtyCoreEndpointIPGamesAndVoice]"
            :id="Routes.ThreeSixtyCoreEndpointIPGamesAndVoice"
          >
            Endpoint IP Games and Voice
          </OnxTab>
          <OnxTab
            v-if="tabAvailability[Routes.ThreeSixtyCoreEndpointIPEcqCcq]"
            :id="Routes.ThreeSixtyCoreEndpointIPEcqCcq"
          >
            Endpoint IP ECQ and CCQ
          </OnxTab>
          <OnxTab
            v-if="tabAvailability[Routes.ThreeSixtyCoreEndpointIPVideoABR]"
            :id="Routes.ThreeSixtyCoreEndpointIPVideoABR"
          >
            Endpoint IP Video ABR
          </OnxTab>
          <OnxTab
            v-if="tabAvailability[Routes.ThreeSixtyCoreEndpointIPVideoLive]"
            :id="Routes.ThreeSixtyCoreEndpointIPVideoLive"
          >
            Endpoint IP Video Live
          </OnxTab>
        </OnxTabs>
      </OnxTabsContext>
    </template>
  </ThreeSixtyHeader>
</template>
