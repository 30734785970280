import { ref, onUnmounted } from 'vue';

const useMatchMedia = (query) => {
  const mediaQuery = window.matchMedia(query);
  const matches = ref(mediaQuery.matches);

  const listener = (e) => {
    matches.value = e.matches;
  };

  mediaQuery.addEventListener('change', listener, { passive: true });

  onUnmounted(() => {
    mediaQuery.removeEventListener('change', listener);
  });

  return matches;
};

export default useMatchMedia;
