import { onUnmounted } from 'vue';
import mapboxgl from 'mapbox-gl';

import { syncMaps } from '@/utils/mapbox-sync-move';

const useSynchronizedMaps = () => {
  const maps: mapboxgl.Map[] = [];
  let unsyncMaps: () => void;

  const onMapReady = (map: mapboxgl.Map) => {
    if (typeof unsyncMaps === 'function') {
      unsyncMaps();
    }

    maps.push(map);
    unsyncMaps = syncMaps(...maps);
  };

  onUnmounted(() => {
    if (typeof unsyncMaps === 'function') {
      unsyncMaps();
    }
  });

  return {
    onMapReady,
  };
};

export default useSynchronizedMaps;
