import type {
  DataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { MetricStructuresEnum } from '@/types/MetricStructures';

import { coverageSubcategoryColors } from '@/constants/colorScales';

const transformCongestionRANTechCellByOperator: DataTransformerConstructor<
  [MetricStructuresEnum.CountsBreakdownMetric],
  Required<Pick<DataTransformerFnOptions, 'operators'>> & Pick<DataTransformerFnOptions, 'selectedConnectionCategories'>
> =
  ({ operators }) =>
  ([response], horizontal?: boolean) => {
    const dataAxis = horizontal ? 'x' : 'y';
    const labelAxis = horizontal ? 'y' : 'x';

    const metricsFilteredByOperator = response.results.filter((datum) => {
      return operators.some((operator) => operator.canonical_network_id === datum.canonical_network_id);
    });

    if (metricsFilteredByOperator.length === 0) {
      return [];
    }

    const ranSubCategories = Object.keys(metricsFilteredByOperator[0].counts);
    const metrics = ranSubCategories.map<DataTransformerReturnedItem>((ranSubCategories) => {
      const data = operators
        .map((operator) => {
          const datum = metricsFilteredByOperator.find((datum) => {
            return datum.canonical_network_id === operator.canonical_network_id;
          });

          if (!datum) {
            return undefined;
          }

          return {
            ...datum,
            // @ts-ignore
            [dataAxis]: datum.counts[ranSubCategories],
            [labelAxis]: operator.name_mapped,
          };
        })
        .filter(Boolean);

      // @ts-ignore
      const color = coverageSubcategoryColors[ranSubCategories];

      return {
        data,
        type: 'bar',
        backgroundColor: color,
        color,
        label: ranSubCategories,
        yAxisID: 'y',
        xAxisID: 'x',
        dataAxis,
        labelAxis,
        meta: {
          imageExportLegend: {
            color,
            label: ranSubCategories,
          },
        },
      };
    });

    return metrics;
  };

export default transformCongestionRANTechCellByOperator;
