// @ts-nocheck
import * as Vue from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import onClickOutside from 'click-outside-vue3';
import FloatingVue from 'floating-vue';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import mapboxgl from 'mapbox-gl';

import 'floating-vue/dist/style.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import App from './App';
import NetworksMenu from './components/menu/NetworksMenu';
import CompetitiveMenu from './components/specialized/CompetitiveMenu.vue';
import store from './store';
import auth0 from '@/auth0';
import router from '@/router';
import queryClient from '@/api/queryClient';

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);
Chart.defaults.animation = false;
Chart.defaults.font.family = 'BeVietnamPro';

const pinia = createPinia();
const app = Vue.createApp(App);

if (!import.meta.env.VITE_SENTRY_DISABLED) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    attachProps: true,
    logErrors: true,
    attachStacktrace: true,
  });
}

app.use(pinia);
app.use(auth0);
app.use(router);
app.use(store);
app.use(onClickOutside);
app.use(FloatingVue, {
  themes: {
    tooltip: {
      triggers: ['hover', 'click'],
    },
  },
  defaultBoundaryElement: document.body,
});
app.use(VueQueryPlugin, { queryClient });

app.component('NetworksMenu', NetworksMenu);
app.component('CompetitiveMenu', CompetitiveMenu);

app.config.globalProperties.$filters = {
  numberFormat(value) {
    const formattedValue = new Intl.NumberFormat('en-EN').format(value);
    return formattedValue !== 'NaN' ? formattedValue : value;
  },
};

app.mount('#app');
