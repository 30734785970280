import treeStore from './tree';

// stores CI route params data that is not in the route all the time
// used for mixpanel and default values in navigation
// populated from {dashboard}/info/ and manually in every navigation via action "setRouteParam"
const ciConfig = treeStore('ciConfig', {
  metric: 'availability_lte',
  agg: '90days',
  compareTo: '90days',
  network: 'all',
  location: '',
  country: '',
  geocoding: '1',
  date: '',
});

// stores MG route params data that is not in the route all the time
// used for mixpanel and default values in navigation
// populated from {dashboard}/info/
const marketGrowthConfig = treeStore('marketGrowthConfig', {
  metric: 'strategy_delta',
  strategy: 'AH',
  agg: '180days',
});

// stores user info
// populated from {dashboard}/user/
const user = treeStore('user');

// stores geoJson data enhanced with every shape's data points for route's date and relevant metric(s)
// populated from: CI - {dashboard}/polygon/ & {dashboard}/overview/geocoding_config/
// populated from: AI - {dashboard}/polygons_by_parent/ & {dashboard}/overview/parent/ & {dashboard}/metrics/
const shapes = treeStore('shapes');

// stores os cities shapes (gocoding config 3) geoJson data enhanced with every shape's data points for route's date and metric
// populated from: {dashboard}/polygon/ & {dashboard}/overview/geocoding_config/
const cityShapes = treeStore('cityShapes');

// stores information about organisation, licenses, configured geocoding configs, start and end dates
// populated from: {dashboard}/info/
const dashboardInfo = treeStore('dashboardInfo');

export { ciConfig, marketGrowthConfig, user, shapes, cityShapes, dashboardInfo };
