import { computed, Ref } from 'vue';

import useLocations from '@/composables/useLocations';
import useMapWithPolygons from '@/composables/useMapWithPolygons';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useSwyftLayer from '@/components/onx/swyft-map/useSwyftLayer';
import { Operator } from '@/types/Operator';

export type UseSwyftMapArgs = {
  mapEndpoint: string;
  enableGeohashes?: Ref<boolean>;
  geohashLevel: number;
  title?: string;
  networkOperators: Ref<Operator[]>;
  locationId: Ref<number>;
  connectionCategories?: Ref<string[]>;
};

const useSwyftMap = ({
  connectionCategories,
  enableGeohashes,
  geohashLevel,
  locationId,
  mapEndpoint,
  networkOperators,
  title,
}: UseSwyftMapArgs) => {
  const dashboard = useCurrentDashboardName();
  const { computedLocationId, computedPolygonData, handleNewBounds, ...rest } = useMapWithPolygons(dashboard.value, {
    enableGeohashes,
  });

  const canonicalNetworkIds = computed(() => networkOperators.value.map((op) => op.canonical_network_id));
  const { countryIso3 } = useLocations(dashboard.value);
  const { legendEntries, loading, setup } = useSwyftLayer({
    countryIso3,
    geohashLevel,
    mapEndpoint,
    canonicalNetworkIds,
    locationId,
    connectionCategories,
  });

  const computedTitle = computed(() => {
    if (!title) {
      return '';
    } else {
      return `${title} for ${networkOperators.value.map((op) => op.name_mapped).join(', ')}`;
    }
  });

  return {
    computedPolygonData,
    computedTitle,
    handleNewBounds,
    computedLocationId,
    setup,
    loading,
    legendEntries,
    ...rest,
  };
};

export default useSwyftMap;
