import { RefLike } from '@/types/helpers/RefLike';

import useQueryParamMultiSelect from '@/composables/useQueryParamMultiSelect';
import useAnalytics from '@/composables/useAnalytics';

const QueryParam = 'chartConnectionCategories';

const useChartConnectionCategoriesQueryParam = (defaultValue: RefLike<string[]>) => {
  const { onChange, selectedValues, ...rest } = useQueryParamMultiSelect<string>(QueryParam, defaultValue, {
    allowEmpty: true,
  });

  const { track } = useAnalytics();

  const onChartConnectionCategoryChange: typeof onChange = (changed) => {
    track('chart connection category change', {
      changed,
      selectedValues: selectedValues.value,
      queryParam: QueryParam,
    });

    return onChange(changed);
  };

  return {
    chartConnectionCategories: selectedValues,
    onChartConnectionCategoryChange,
    ...rest,
  };
};

export default useChartConnectionCategoriesQueryParam;
