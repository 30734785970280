<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['sm', 'md'].includes(value);
    },
  },
});

const classObject = computed(() => ({
  'onx-list__item-text': true,
  'onx-list__item-text--sm': props.size === 'sm',
  'onx-list__item-text--md': props.size === 'md',
}));
</script>

<template>
  <span :class="classObject">
    <slot></slot>
  </span>
</template>

<style scoped lang="scss">
.onx-list__item-text {
  @import 'scss/mixins';

  display: flex;
  align-items: center;
  color: var(--onx-list-item-color);
  text-align: left;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;

  &--sm {
    font-size: pxToRem(12);
  }

  &--md {
    font-size: pxToRem(14);
  }
}
</style>
