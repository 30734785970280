import { exportToCsv } from '@/utils/files';

const exportCdnIpSpeedTrendToCsv = (data: any, title: any) => {
  const headers = ['IP', 'CDN', 'Date', 'Mean'];
  const rows = data.flatMap((dataset: any) => {
    return dataset.data.map((datum: any) => {
      const date = datum.date.split('T')[0]; // convert datetime to date only
      return [datum.ip, datum.cdn, date, datum.y?.toFixed(2)];
    });
  });

  exportToCsv(`${title}.csv`, [headers, ...rows]);
};

export default exportCdnIpSpeedTrendToCsv;
