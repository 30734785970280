import { ref } from 'vue';

const useDelayedLoader = (delay: number) => {
  const started = ref(false);
  const loading = ref(false);

  const startLoaderTimeout = (promise: Promise<any>) => {
    started.value = true;

    const timeoutId = setTimeout(() => {
      loading.value = true;
    }, delay);

    promise.finally(() => {
      clearTimeout(timeoutId);
      started.value = false;
      loading.value = false;
    });
  };

  return { loaderTimeoutStarted: started, loading, startLoaderTimeout };
};

export default useDelayedLoader;
