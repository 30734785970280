<script setup lang="ts">
import { toRefs } from 'vue';
import { type LngLatBounds } from 'mapbox-gl';

import { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';
import { ChartMetricDefinition } from '@/chart-metric-definitions/MetricDefinition';
import { Dashboards } from '@/constants/dashboards';
import { Operator } from '@/types/Operator';
import { usePlaceholderTitle } from '@/composables/usePlaceholderTitle';
import IntersectionObserver from '@/components/IntersectionObserver.vue';
import OnxStackedBarChart from '@/components/onx/charts/OnxBarChart.vue';
import useCheckIfMetricExists from '@/composables/useCheckIfMetricExists';
import useCoreHealthByCdn from '@/composables/useCoreHealthByCDN';
import { AvailableDeploymentType } from '@/focus/deployment-type-selector/availableDeploymentTypes';

export type Props = {
  metric: ChartMetricDefinition;
  operator: Operator;
  cdns: string[];
  connectionCategory: AvailableConnectionCategory;
  locationId: number;
  aggregation: string;
  bbox?: LngLatBounds;
  enabled?: boolean;
  deploymentType?: AvailableDeploymentType;
};

const props = withDefaults(defineProps<Props>(), {
  enabled: true,
});
const { aggregation, bbox, cdns, connectionCategory, locationId, metric, operator } = toRefs(props);
const { transformResolutionBreakdownMetrics } = useCoreHealthByCdn();
const { checkIfMetricExists } = useCheckIfMetricExists();

const getKey = (operator: Operator, connectionCategory: string, cdn: string) => {
  return `${operator.name_mapped}-${connectionCategory}-${cdn}`;
};
const replaceTitle = (cdn: string) => {
  const { titleWithoutPlaceholders } = usePlaceholderTitle(props.metric.chartAttrs?.chartTitlePlaceholders as string, {
    connectionCategory: connectionCategory.value.categoryLabel,
    operator,
    cdn,
    deploymentType: props.deploymentType,
  });
  return titleWithoutPlaceholders;
};
</script>

<template>
  <template v-for="cdn in cdns">
    <IntersectionObserver
      :key="getKey(operator, connectionCategory.categoryValue, cdn)"
      v-if="checkIfMetricExists(`${metric.metricSubtype}_${connectionCategory.categoryValue}`)"
    >
      <template #default="{ visible }">
        <OnxStackedBarChart
          :dashboard="Dashboards.Focus"
          :metric="`${metric.metricSubtype}_${connectionCategory.categoryValue}`"
          :location="locationId"
          :aggregation="aggregation"
          :chart-title="replaceTitle(cdn).value"
          :transform="transformResolutionBreakdownMetrics(cdn, operator)"
          tooltip="High corresponds to the highest two resolutions i.e. 1440p and 2160p. Low corresponds to the lowest two resolutions i.e. 144p and 240p."
          :geohashes="[]"
          :enabled="enabled && visible"
          :max="100"
          :bbox="bbox"
          :deployment-type="deploymentType"
        />
      </template>
    </IntersectionObserver>
  </template>
</template>
