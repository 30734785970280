import { type TooltipModel, Chart } from 'chart.js';

import { getTooltipState, getDatapointsByIndex } from '@/utils/charts';

const basicBarChartTooltip =
  ({ horizontal }: { horizontal?: boolean }) =>
  (context: { chart: Chart; tooltip: TooltipModel<'bar'> }, tooltip: any) => {
    const { chart, tooltip: chartJsTooltip } = context;

    const tooltipState = getTooltipState(context.chart, chartJsTooltip);
    const datapoints = getDatapointsByIndex(
      chart.data.datasets,
      chart.data.labels?.indexOf(chartJsTooltip.title[0]),
      horizontal,
      false,
    );

    if (datapoints?.length) {
      tooltip.value = {
        ...tooltip.value,
        ...tooltipState,
        datapoints,
        title: chartJsTooltip.title[0],
      };
    }
  };

export default basicBarChartTooltip;
