<script setup lang="ts">
import { computed } from 'vue';

import useMainConnectionCategory from './useMainConnectionCategory';
import { Dashboards } from '@/constants/dashboards';
import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';

const { mainConnectionCategories, onMainConnectionCategoryChange, selectedMainConnectionCategory } =
  useMainConnectionCategory(Dashboards.Focus);

const items = computed(() => {
  return mainConnectionCategories.value.map((category) => ({
    keyAccessor: category.categoryValue,
    inset: category.subCategory,
    disabled: category.disabled,
    label: category.categoryLabel,
    value: category,
    checked: category.categoryValue === selectedMainConnectionCategory.value?.categoryValue,
  }));
});
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Connection Category:"
    :selected-label="selectedMainConnectionCategory?.categoryLabel"
    list-container-class="onx-main-connection-category__list"
    :items="items"
    @list-item-click="onMainConnectionCategoryChange"
  />
</template>

<style lang="scss">
.onx-main-connection-category__list {
  .onx-list__item--disabled {
    .main {
      opacity: 1 !important;
    }
  }
}
</style>
