const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id);

  if (!legendContainer) {
    console.warn(`No legend container found with id: ${id}`);
    return;
  }

  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.classList.add('legend-list');
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export default getOrCreateLegendList;
